const MATERIAL_DEFAULTS = {
    roughness: 0.5,
    metalness: 0.5,
    opacity: 1,
    side: 'front',
    normalScale: 1,
    emissiveIntensity: 0,
    blending: 'normal',
    repeatX: 1,
    repeatY: 1,
    offsetX: 0,
    offsetY: 0,
    depthTest: true,
    depthWrite: true,
    wireframe: false,
    forceTransparent: false,
};
const DEFAULT_MATERIAL_COLOR = '#FFFFFF';
const DEFAULT_EMISSIVE_COLOR = '#FFFFFF';
const DEFAULT_SHADOW_COLOR = '#000000';
const DEFAULT_SHADOW_OPACITY = 0.4;
export { MATERIAL_DEFAULTS, DEFAULT_EMISSIVE_COLOR, DEFAULT_MATERIAL_COLOR, DEFAULT_SHADOW_COLOR, DEFAULT_SHADOW_OPACITY, };
