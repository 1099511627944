import THREE from './three';
const sameCameraType = (object, type) => {
    switch (type) {
        case 'perspective':
            return object instanceof THREE.PerspectiveCamera;
        case 'orthographic':
            return object instanceof THREE.OrthographicCamera;
        default:
            throw new Error('Unexpected camera type');
    }
};
const editCameraObject = (object, config) => {
    object.zoom = config.zoom;
    if (object instanceof THREE.OrthographicCamera) {
        object.left = config.left;
        object.right = config.right;
        object.top = config.top;
        object.bottom = config.bottom;
    }
    else if (object instanceof THREE.PerspectiveCamera) {
        object.fov = config.fov;
    }
    object.far = config.farClip;
    object.near = config.nearClip;
    object.updateProjectionMatrix();
};
const editXrCameraObject = (object, config) => {
    object.userData.xr = {
        xrCameraType: config.xrCameraType,
        phone: config.phone,
        desktop: config.desktop,
        headset: config.headset,
        nearClip: config.nearClip,
        farClip: config.farClip,
        disableWorldTracking: config.disableWorldTracking,
        enableLighting: config.enableLighting,
        enableWorldPoints: config.enableWorldPoints,
        leftHandedAxes: config.leftHandedAxes,
        mirroredDisplay: config.mirroredDisplay,
        scale: config.scale,
        enableVps: config.enableVps,
        meshGeometryFace: config.meshGeometryFace,
        meshGeometryEyes: config.meshGeometryEyes,
        meshGeometryIris: config.meshGeometryIris,
        meshGeometryMouth: config.meshGeometryMouth,
        uvType: config.uvType,
        maxDetections: config.maxDetections,
        enableEars: config.enableEars,
        direction: config.direction,
    };
};
const createCameraObject = (config) => {
    let cameraObject;
    switch (config.type) {
        case 'perspective':
            cameraObject = new THREE.PerspectiveCamera(config.fov);
            break;
        case 'orthographic':
            cameraObject =
                new THREE.OrthographicCamera(config.left, config.right, config.top, config.bottom);
            break;
        default:
            throw new Error('Unexpected camera type');
    }
    cameraObject.zoom = config.zoom;
    cameraObject.far = config.farClip;
    cameraObject.near = config.nearClip;
    cameraObject.updateProjectionMatrix();
    cameraObject.rotateOnAxis(new THREE.Vector3(0, 1, 0), Math.PI);
    cameraObject.userData.xr = {
        xrCameraType: config.xrCameraType,
        phone: config.phone,
        desktop: config.desktop,
        headset: config.headset,
        nearClip: config.nearClip,
        farClip: config.farClip,
        disableWorldTracking: config.disableWorldTracking,
        enableLighting: config.enableLighting,
        enableWorldPoints: config.enableWorldPoints,
        leftHandedAxes: config.leftHandedAxes,
        mirroredDisplay: config.mirroredDisplay,
        scale: config.scale,
        enableVps: config.enableVps,
        meshGeometryFace: config.meshGeometryFace,
        meshGeometryEyes: config.meshGeometryEyes,
        meshGeometryIris: config.meshGeometryIris,
        meshGeometryMouth: config.meshGeometryMouth,
        uvType: config.uvType,
        maxDetections: config.maxDetections,
        enableEars: config.enableEars,
        direction: config.direction,
    };
    return cameraObject;
};
export { editXrCameraObject, sameCameraType, createCameraObject, editCameraObject, };
