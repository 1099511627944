// Hardcoded these fonts for now. Will need to change to dynamically generate
// fonts base on the font file uploaded by users in the future
// TODO (tri) change static fonts to dynamic ones
const FONTS = new Map([
    [
        'Nunito', {
            json: 'https://cdn.8thwall.com/web/font-atlases/nunito/nunito.json',
            png: 'https://cdn.8thwall.com/web/font-atlases/nunito/nunito.png',
            ttf: 'https://cdn.8thwall.com/web/fonts/nunito.ttf',
        },
    ],
    [
        'Akzidenz Grotesk', {
            json: 'https://cdn.8thwall.com/web/font-atlases/akzidenz_grotesk/akzidenz_grotesk.json',
            png: 'https://cdn.8thwall.com/web/font-atlases/akzidenz_grotesk/akzidenz_grotesk.png',
            ttf: 'https://cdn.8thwall.com/web/fonts/akzidenz-grotesk-regular.ttf',
        },
    ],
    [
        'Baskerville', {
            json: 'https://cdn.8thwall.com/web/font-atlases/baskerville/baskerville.json',
            png: 'https://cdn.8thwall.com/web/font-atlases/baskerville/baskerville.png',
            ttf: 'https://cdn.8thwall.com/web/fonts/libre-baskerville-regular.ttf',
        },
    ],
    [
        'Futura', {
            json: 'https://cdn.8thwall.com/web/font-atlases/futura/futura.json',
            png: 'https://cdn.8thwall.com/web/font-atlases/futura/futura.png',
            ttf: 'https://cdn.8thwall.com/web/fonts/futura-regular.ttf',
        },
    ],
    [
        'Gotham', {
            json: 'https://cdn.8thwall.com/web/font-atlases/gotham/gotham.json',
            png: 'https://cdn.8thwall.com/web/font-atlases/gotham/gotham.png',
            ttf: 'https://cdn.8thwall.com/web/fonts/gotham-regular.ttf',
        },
    ],
    [
        'Helvetica', {
            json: 'https://cdn.8thwall.com/web/font-atlases/helvetica/helvetica.json',
            png: 'https://cdn.8thwall.com/web/font-atlases/helvetica/helvetica.png',
            ttf: 'https://cdn.8thwall.com/web/fonts/helvetica-regular.ttf',
        },
    ],
    [
        'Nanum Pen Script', {
            json: 'https://cdn.8thwall.com/web/font-atlases/nanum_pen_script/nanum_pen_script.json',
            png: 'https://cdn.8thwall.com/web/font-atlases/nanum_pen_script/nanum_pen_script.png',
            ttf: 'https://cdn.8thwall.com/web/fonts/nanum-pen-script-regular.ttf',
        },
    ],
    [
        'Press Start 2p', {
            json: 'https://cdn.8thwall.com/web/font-atlases/press_start_2p/press_start_2p.json',
            png: 'https://cdn.8thwall.com/web/font-atlases/press_start_2p/press_start_2p.png',
            ttf: 'https://cdn.8thwall.com/web/fonts/press-start-2p-regular.ttf',
        },
    ],
    [
        'Times', {
            json: 'https://cdn.8thwall.com/web/font-atlases/times/times.json',
            png: 'https://cdn.8thwall.com/web/font-atlases/times/times.png',
            ttf: 'https://cdn.8thwall.com/web/fonts/times-regular.ttf',
        },
    ],
    [
        'Inconsolata', {
            json: 'https://cdn.8thwall.com/web/font-atlases/inconsolata/inconsolata.json',
            png: 'https://cdn.8thwall.com/web/font-atlases/inconsolata/inconsolata.png',
            ttf: 'https://cdn.8thwall.com/web/fonts/inconsolata-regular.ttf',
        },
    ],
    [
        'Roboto', {
            json: 'https://cdn.8thwall.com/web/font-atlases/roboto/roboto.json',
            png: 'https://cdn.8thwall.com/web/font-atlases/roboto/roboto.png',
            ttf: 'https://cdn.8thwall.com/web/fonts/roboto-regular.ttf',
        },
    ],
]);
const DEFAULT_FONT_NAME = 'Nunito';
const DEFAULT_FONT = FONTS.get(DEFAULT_FONT_NAME);
export { FONTS, DEFAULT_FONT_NAME, DEFAULT_FONT, };
