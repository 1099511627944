/* eslint-disable */
// @ts-ignore TS2307
import ECS from './ecs-asm';
/* eslint-enable */
let verbose = false;
let loaded = false;
const asm = {};
const ecsPromise = ECS({
    print: (...args) => {
        if (verbose) {
            // eslint-disable-next-line no-console
            console.log(...args);
        }
    },
});
const PREFIX_TO_STRIP = '_c8EmAsm_';
const fixFunctions = (mod) => {
    Object.entries(mod).forEach(([key, value]) => {
        if (key.startsWith(PREFIX_TO_STRIP) && typeof value === 'function') {
            mod[key.slice(PREFIX_TO_STRIP.length)] = value;
        }
    });
};
ecsPromise.then((mod) => {
    Object.assign(asm, mod);
    fixFunctions(asm);
    asm.dataView = new DataView(asm.HEAPU8.buffer);
    loaded = true;
});
const asmReady = async () => {
    await ecsPromise;
};
const asmVerbose = (v) => {
    verbose = v;
};
const isReady = () => loaded;
export { asm, asmReady, isReady as isAsmReady, asmVerbose, };
