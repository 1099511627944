/**
 * Finds the closet ancestor entity with a given component for the given entity ID.
 *
 * @param eid - The entity ID to find the root for.
 * @returns The entity ID of the root entity with a Sprite component, or 0n if no such entity is
 * found.
 */
const findParentComponent = (world, eid, component) => {
    let current = eid;
    do {
        current = world.getParent(current);
        if (current && component.has(world, current)) {
            return current;
        }
    } while (current);
    return current;
};
export { findParentComponent };
