import { assets } from './assets';
import { FONTS } from '../shared/fonts';
const loadedFonts = new Set();
const fontPromises = new Map();
const removeOverlayUi = (el) => {
    if (el.parentNode) {
        el.parentNode.removeChild(el);
    }
};
const hexColorToRgba = (hex, alpha) => (hex + Math.round(alpha * 255).toString(16).padStart(2, '0'));
const layoutMetricsToStyle = (layoutMetrics) => {
    const style = {};
    for (const key of Object.keys(layoutMetrics)) {
        switch (key) {
            case 'top':
            case 'left':
            case 'width':
            case 'height':
                style[key] = `${layoutMetrics[key]}px`;
                break;
            case 'overflow':
                style[key] = layoutMetrics[key]?.toString() ?? 'hidden';
                break;
            default:
                break;
        }
    }
    return style;
};
const pxFromNumber = (value) => {
    if (Number.isNaN(value)) {
        return '';
    }
    return `${value}px`;
};
const resolveFont = async (font) => {
    let url = '';
    if (FONTS.has(font)) {
        url = `url(${FONTS.get(font)?.ttf})`;
    }
    else {
        const asset = await assets.load({ url: font });
        const font8Text = await asset.data.text();
        const font8Json = JSON.parse(font8Text);
        if (!asset.remoteUrl) {
            throw new Error(`Font ${font} not found`);
        }
        const bundlePath = asset.remoteUrl && asset.remoteUrl.replace(/\/[^/]*$/, '/');
        const fontUrl = await assets.load({ url: bundlePath + font8Json.fontFile });
        url = `url(${fontUrl.localUrl})`;
    }
    const fontFace = new FontFace(font, url);
    document.fonts.add(fontFace);
    loadedFonts.add(font);
    fontPromises.delete(font);
    return url;
};
const parseBackgroundSize = (backgroundSize) => {
    if (backgroundSize === 'stretch') {
        return '100% 100%';
    }
    return backgroundSize;
};
const updateOverlayUi = (el, settings, isHidden, layoutMetrics) => {
    const borderWidth = pxFromNumber(settings.borderWidth);
    const { font } = settings;
    if (font !== 'Nunito' && !loadedFonts.has(font) && !fontPromises.get(font)) {
        fontPromises.set(font, resolveFont(font));
    }
    const diff = {
        ...(layoutMetrics && layoutMetricsToStyle(layoutMetrics)),
        borderRadius: pxFromNumber(settings.borderRadius),
        // DO NOT use `border` property, Yoga expect the border to not shift the
        // layout which is not true in HTML DOM.
        boxShadow: `inset 0 0 0 ${borderWidth} ${settings.borderColor}`,
        padding: borderWidth,
        boxSizing: 'border-box',
        position: 'absolute',
        pointerEvents: 'auto',
        zIndex: '100',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        cursor: 'default',
        fontFamily: `"${font}"`,
        fontSize: pxFromNumber(settings.fontSize),
        color: settings.color,
        backgroundColor: hexColorToRgba(settings.background, settings.backgroundOpacity),
        opacity: settings.opacity.toString(),
        visibility: isHidden ? 'hidden' : undefined,
        textAlign: settings.textAlign,
    };
    Object.assign(el.style, diff);
    if (settings.image) {
        el.style.backgroundSize = parseBackgroundSize(settings.backgroundSize);
        el.style.backgroundRepeat = 'no-repeat';
        el.style.backgroundPosition = 'center';
        el.style.backgroundColor = 'transparent';
        assets.load({ url: settings.image }).then((asset) => {
            el.style.backgroundImage = `url(${asset.localUrl})`;
        });
    }
    else if (settings.text) {
        if (!el.hasChildNodes()) {
            el.textContent = settings.text;
        }
        else if (el.firstChild instanceof Text) {
            el.firstChild.textContent = settings.text;
        }
        else {
            el.insertBefore(document.createTextNode(settings.text), el.firstChild);
        }
    }
};
const createOverlayUi = (settings, isHidden, order) => {
    const element = document.createElement('div');
    element.dataset.uiOrder = order.toFixed();
    updateOverlayUi(element, settings, isHidden);
    return element;
};
export { createOverlayUi, removeOverlayUi, updateOverlayUi, };
