const addListener = (state, target, name, listener) => {
    const listeners = state._listeners.get(target) ?? new Map();
    const eventListeners = listeners.get(name) ?? new Set();
    eventListeners.add(listener);
    listeners.set(name, eventListeners);
    state._listeners.set(target, listeners);
};
const removeListener = (state, target, name, listener) => {
    state._listeners.get(target)?.get(name)?.delete(listener);
};
const MAX_BUBBLE_DEPTH = 100;
const dispatchEvent = (state, target, name, data) => {
    let currentTarget = target;
    let depth = 0;
    while (depth++ < MAX_BUBBLE_DEPTH) {
        state._queue.push({ target, name, data, currentTarget });
        if (currentTarget === state.globalId) {
            break;
        }
        currentTarget = state._world.getParent(currentTarget) || state.globalId;
    }
};
const flushEvents = (state) => {
    const events = [...state._queue];
    state._queue.length = 0;
    for (const event of events) {
        const liveListeners = state._listeners.get(event.currentTarget)?.get(event.name);
        if (liveListeners) {
            // ignore listeners added after we started iterating
            const listenersCopy = [...liveListeners];
            for (const listener of listenersCopy) {
                // the listener might have been removed from liveListeners since the copy
                if (liveListeners.has(listener)) {
                    try {
                        listener(event);
                    }
                    catch (err) {
                        // eslint-disable-next-line no-console
                        console.error(err);
                    }
                }
            }
        }
    }
};
const createEvents = (world) => {
    const state = {
        globalId: 0n,
        _listeners: new Map(),
        _queue: [],
        _world: world,
    };
    return Object.assign(state, {
        addListener: addListener.bind(null, state),
        removeListener: removeListener.bind(null, state),
        dispatch: dispatchEvent.bind(null, state),
    });
};
export { createEvents, flushEvents, };
