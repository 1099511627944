import { DEFAULT_VOLUME, DEFAULT_PITCH, DEFAULT_REF_DISTANCE, DEFAULT_DISTANCE_MODEL, DEFAULT_ROLLOFF_FACTOR, DEFAULT_MAX_DISTANCE, } from '../shared/audio-constants';
import THREE from './three';
import { assets } from './assets';
import { events } from './event-ids';
import { createInstanced } from '../shared/instanced';
const DistanceModelValues = ['exponential', 'inverse', 'linear'];
const getValidDistanceModel = (distanceModel) => (DistanceModelValues.includes(distanceModel) ? distanceModel : DEFAULT_DISTANCE_MODEL);
const createAudioManager = (world) => {
    const listener = new THREE.AudioListener();
    const { context } = listener;
    const sounds = new Map();
    const endedEventCallbacks = new Map();
    let volume = 1;
    // note: studioPaused is for saving the state internally, audioPaused is set externally by world
    let audioPaused = false;
    let studioPaused = false;
    const maybeAddEndedEventListener = (sound) => {
        if (endedEventCallbacks.get(sound.id) || sound.element.loop) {
            return;
        }
        const endedEventListener = () => {
            world.events.dispatch(sound.id, events.AUDIO_END);
        };
        sound.element.addEventListener('ended', endedEventListener);
        endedEventCallbacks.set(sound.id, endedEventListener);
    };
    const maybeRemoveEndedEventListener = (sound) => {
        const endedEventCallback = endedEventCallbacks.get(sound.id);
        if (endedEventCallback) {
            sound.element.removeEventListener('ended', endedEventCallback);
            endedEventCallbacks.delete(sound.id);
        }
    };
    const playSound = (sound) => {
        sound.paused = false;
        sound.element.autoplay = true;
        if (audioPaused || studioPaused) {
            return;
        }
        sound.element.play()
            .catch((error) => {
            world.events.dispatch(sound.id, 'audio-error', { error });
        });
    };
    const pauseSound = (sound) => {
        sound.paused = true;
        sound.element.pause();
    };
    const setOptions = (audioNode, element, options) => {
        element.volume = options?.volume ?? DEFAULT_VOLUME;
        element.loop = !!options?.loop;
        if (audioNode instanceof THREE.PositionalAudio) {
            audioNode.setRefDistance(options?.refDistance ?? DEFAULT_REF_DISTANCE);
            audioNode.setDistanceModel(getValidDistanceModel(options?.distanceModel));
            audioNode.setRolloffFactor(options?.rolloffFactor ?? DEFAULT_ROLLOFF_FACTOR);
            audioNode.setMaxDistance(options?.maxDistance ?? DEFAULT_MAX_DISTANCE);
        }
    };
    const addSound = (eid, audio) => {
        if (!audio.url) {
            return null;
        }
        const controller = new AbortController();
        const { signal } = controller;
        const audioNode = audio.positional
            ? new THREE.PositionalAudio(listener) : new THREE.Audio(listener);
        const audioElement = new Audio();
        audioElement.addEventListener('canplaythrough', () => {
            world.events.dispatch(eid, events.AUDIO_CAN_PLAY_THROUGH);
        });
        assets.load({ url: audio.url }).then((asset) => {
            if (signal.aborted) {
                return;
            }
            audioElement.src = asset.localUrl;
            // playbackRate directly affects the pitch of the audio (sample rate conversion)
            // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/playbackRate
            // Need to set defaultPlaybackRate rather than playbackRate or else the playbackRate/pitch
            // will be reset to default after load.
            audioElement.defaultPlaybackRate = audio.pitch ?? DEFAULT_PITCH;
            audioElement.preservesPitch = false;
            audioElement.load();
        });
        audioElement.crossOrigin = 'anonymous';
        setOptions(audioNode, audioElement, audio);
        audioNode.setMediaElementSource(audioElement);
        const sound = {
            id: eid,
            audioNode,
            element: audioElement,
            controller,
            url: audio.url,
            paused: !!audio.paused,
        };
        sounds.set(eid, sound);
        maybeAddEndedEventListener(sound);
        if (!audio.paused) {
            playSound(sound);
        }
        return sound;
    };
    const removeSound = (eid) => {
        const sound = sounds.get(eid);
        if (!sound) {
            return;
        }
        sound.controller.abort();
        URL.revokeObjectURL(sound.element.src);
        sound.element.pause();
        maybeRemoveEndedEventListener(sound);
        sound.audioNode.removeFromParent();
        sounds.delete(eid);
    };
    const updateSound = (eid, newAudio) => {
        const sound = sounds.get(eid);
        if (!sound) {
            return;
        }
        const { element, audioNode, url: oldAudioUrl } = sound;
        const positionalSame = !!newAudio.positional === (audioNode instanceof THREE.PositionalAudio);
        const audioUrlSame = oldAudioUrl && newAudio && newAudio.url === oldAudioUrl;
        if (audioUrlSame && positionalSame) {
            element.playbackRate = newAudio.pitch ?? DEFAULT_PITCH;
            setOptions(audioNode, element, newAudio);
            if (sound.element.loop) {
                maybeRemoveEndedEventListener(sound);
            }
            maybeAddEndedEventListener(sound);
            if (newAudio.paused) {
                pauseSound(sound);
            }
            else {
                playSound(sound);
            }
        }
        else {
            const { parent } = audioNode;
            removeSound(eid);
            const newSound = addSound(eid, newAudio);
            if (parent && newSound) {
                parent.add(newSound.audioNode);
            }
        }
    };
    const pauseSounds = () => {
        sounds.forEach((sound) => {
            sound.element.pause();
        });
    };
    const pause = () => {
        audioPaused = true;
        if (studioPaused) {
            return;
        }
        pauseSounds();
    };
    const setVolume = (newVolume) => {
        listener.setMasterVolume(newVolume);
        volume = newVolume;
    };
    const resumeSounds = () => {
        sounds.forEach((sound) => {
            if (sound.paused || sound.element.ended) {
                return;
            }
            sound.element.play()
                .catch((error) => {
                world.events.dispatch(sound.id, 'audio-error', { error });
            });
        });
    };
    const play = () => {
        audioPaused = false;
        if (studioPaused) {
            return;
        }
        context.resume();
        resumeSounds();
    };
    const start = () => {
        if (studioPaused || audioPaused) {
            return;
        }
        context.resume();
        resumeSounds();
    };
    const setStudioPause = (paused) => {
        studioPaused = paused;
        if (paused) {
            pauseSounds();
        }
        else {
            context.resume();
            // note: resume world if the user hasn't paused the audio
            if (!audioPaused) {
                resumeSounds();
            }
        }
    };
    const mute = () => {
        listener.setMasterVolume(0);
    };
    const unmute = () => {
        listener.setMasterVolume(volume);
    };
    const destroy = () => {
        document.removeEventListener('click', start);
    };
    // TODO (Julie): Think of a better way to handle this
    document.addEventListener('click', start, { once: true });
    const audio = {
        mute,
        unmute,
        pause,
        play,
        setVolume,
    };
    const internalAudio = {
        listener,
        addSound,
        removeSound,
        updateSound,
        setStudioPause,
        destroy,
    };
    return { audio, internalAudio };
};
const getAudioManager = createInstanced(createAudioManager);
export { getAudioManager, };
