import { asm } from './asm';
import { WASM_POINTER_SIZE } from './constants';
import { extractEids } from './list-data';
const setThreeParent = (world, eid, parentEid) => {
    const object = world.three.entityToObject.get(eid);
    if (object) {
        const newParent = world.three.entityToObject.get(parentEid) || world.three.scene;
        newParent.add(object);
    }
};
const setParent = (world, eid, parentEid) => {
    asm.entitySetParent(world._id, eid, parentEid);
    const disabled = asm.entityIsSelfDisabled(world._id, eid);
    if (!disabled) {
        setThreeParent(world, eid, parentEid);
    }
};
const getParent = (world, eid) => asm.entityGetParent(world._id, eid);
function* getChildren(world, parent) {
    const listPtr = asm._malloc(WASM_POINTER_SIZE);
    const count = asm.entityGetChildren(world._id, parent, listPtr);
    for (const eid of extractEids(listPtr, count)) {
        yield eid;
    }
    // This frees the entity reference list
    asm._free(asm.dataView.getUint32(listPtr, true));
    asm._free(listPtr);
}
export { setParent, setThreeParent, getParent, getChildren, };
