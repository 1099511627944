import { createInstanced } from '../shared/instanced';
const makeStringStorage = () => {
    const idToString = new Map();
    const stringToId = new Map();
    let nextId = 1;
    const get = (id) => {
        if (id === 0) {
            return '';
        }
        if (!idToString.has(id)) {
            throw new Error(`String storage does not contain id ${id}`);
        }
        return idToString.get(id);
    };
    const store = (value) => {
        if (value === '') {
            return 0;
        }
        if (stringToId.has(value)) {
            return stringToId.get(value);
        }
        const id = nextId++;
        idToString.set(id, value);
        stringToId.set(value, id);
        return id;
    };
    return { get, store };
};
const globalStringMap = createInstanced(makeStringStorage);
export { globalStringMap, };
