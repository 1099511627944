const ACTIVE_CAMERA_CHANGE = 'active-camera-change';
const ACTIVE_CAMERA_EID_CHANGE = 'active-camera-entity-change';
const XR_CAMERA_EDIT = 'xr-camera-edit';
const createCameraManager = (world, audioListener, events) => {
    let activeCameraEid_ = 0n;
    let activeCamera_;
    let attached_ = false;
    const defaultCamera_ = world.three.activeCamera;
    defaultCamera_.add(audioListener);
    const setActiveCamera = (cameraObject) => {
        if (activeCamera_ === cameraObject) {
            return;
        }
        activeCamera_ = cameraObject;
        cameraObject.add(audioListener);
        world.three.activeCamera = activeCamera_;
        events.dispatch(events.globalId, ACTIVE_CAMERA_CHANGE, { camera: cameraObject });
    };
    const getCameraObject = (eid) => (world.three.entityToObject.get(eid)?.userData.camera);
    const setActiveEid = (eid) => {
        if (!eid) {
            throw new Error('eid is required');
        }
        if (activeCameraEid_ !== eid) {
            events.dispatch(events.globalId, ACTIVE_CAMERA_EID_CHANGE, { eid });
        }
        activeCameraEid_ = eid;
        const cameraObject = getCameraObject(eid);
        if (cameraObject) {
            setActiveCamera(cameraObject);
        }
    };
    const notifyCameraAdded = (eid) => {
        if (eid === activeCameraEid_ && attached_) {
            const cameraObject = getCameraObject(eid);
            if (!cameraObject) {
                throw new Error('camera does not exist');
            }
            setActiveCamera(cameraObject);
        }
    };
    const notifyCameraRemoved = (eid) => {
        if (eid === activeCameraEid_) {
            activeCameraEid_ = 0n;
            if (attached_) {
                setActiveCamera(defaultCamera_);
            }
        }
    };
    const attach = () => {
        attached_ = true;
        if (activeCameraEid_) {
            const cameraObject = getCameraObject(activeCameraEid_);
            if (cameraObject) {
                setActiveCamera(cameraObject);
            }
        }
    };
    const detach = () => {
        attached_ = false;
        setActiveCamera(defaultCamera_);
    };
    const getActiveEid = () => activeCameraEid_;
    return {
        getActiveEid,
        setActiveEid,
        notifyCameraAdded,
        notifyCameraRemoved,
        attach,
        detach,
    };
};
export { ACTIVE_CAMERA_CHANGE, ACTIVE_CAMERA_EID_CHANGE, XR_CAMERA_EDIT, createCameraManager, };
