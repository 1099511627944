import { Light, Position, Shadow } from '../components';
import { makeSystemHelper } from './system-helper';
import { createLightObject, editLightObject, sameLightType } from '../lights';
import THREE from '../three';
const FOLLOW_LIGHT_DISTANCE = 10;
const makeShadowSystem = (world) => {
    const { enter, changed, exit } = makeSystemHelper(Shadow);
    const editShadows = (eid) => {
        const object = world.three.entityToObject.get(eid);
        if (object) {
            const { castShadow, receiveShadow } = Shadow.get(world, eid);
            object.castShadow = !!castShadow;
            object.receiveShadow = !!receiveShadow;
        }
    };
    const disableShadows = (eid) => {
        const object = world.three.entityToObject.get(eid);
        if (object) {
            object.castShadow = false;
            object.receiveShadow = false;
        }
    };
    return () => {
        exit(world).forEach(disableShadows);
        enter(world).forEach(editShadows);
        changed(world).forEach(editShadows);
    };
};
const makeLightSystem = (world) => {
    const { enter, changed, exit } = makeSystemHelper(Light);
    const activeFollowLightMap = new Map();
    const cameraDirection = new THREE.Vector3();
    const targetPosition = new THREE.Vector3();
    const tempPosition = new THREE.Vector3();
    const enableFollowLight = (eid, light) => {
        if (activeFollowLightMap.has(eid)) {
            return;
        }
        activeFollowLightMap.set(eid, light);
        world.three.scene.add(light.target);
    };
    const disableFollowLight = (eid) => {
        if (!activeFollowLightMap.has(eid)) {
            return;
        }
        const lightObject = activeFollowLightMap.get(eid);
        if (lightObject && lightObject.target) {
            world.three.scene.remove(lightObject.target);
        }
        activeFollowLightMap.delete(eid);
    };
    const addLight = (eid) => {
        const object = world.three.entityToObject.get(eid);
        if (!object) {
            return;
        }
        const lightConfig = Light.get(world, eid);
        const lightObject = createLightObject(lightConfig);
        object.userData.light = lightObject;
        object.add(lightObject);
        if (lightConfig.type === 'directional' && lightConfig.followCamera) {
            enableFollowLight(eid, lightObject);
        }
    };
    const editLight = (eid) => {
        const object = world.three.entityToObject.get(eid);
        if (!object) {
            return;
        }
        const lightObject = object.userData.light;
        if (!lightObject) {
            return;
        }
        const light = Light.get(world, eid);
        const shouldFollowCamera = light.type === 'directional' && light.followCamera;
        if (!shouldFollowCamera && activeFollowLightMap.has(eid)) {
            // only directional lights can have dynamic lighting OR the user turned off dynamic lighting
            disableFollowLight(eid);
        }
        if (!sameLightType(lightObject, light.type)) {
            object.remove(lightObject);
            const newLightObject = createLightObject(light);
            object.userData.light = newLightObject;
            object.add(newLightObject);
            if (shouldFollowCamera) {
                // new directional light
                enableFollowLight(eid, newLightObject);
            }
            return;
        }
        else if (shouldFollowCamera && !activeFollowLightMap.has(eid)) {
            // user turned on dynamic lighting
            enableFollowLight(eid, lightObject);
        }
        editLightObject(lightObject, light);
    };
    const removeLight = (eid) => {
        disableFollowLight(eid);
        const object = world.three.entityToObject.get(eid);
        if (!object) {
            return;
        }
        object.remove(object.userData.light);
    };
    const processCameraFollow = () => {
        world.three.activeCamera.getWorldDirection(cameraDirection);
        cameraDirection.normalize().multiplyScalar(FOLLOW_LIGHT_DISTANCE);
        world.three.activeCamera.getWorldPosition(targetPosition);
        targetPosition.add(cameraDirection);
        for (const [eid, light] of activeFollowLightMap.entries()) {
            light.target.position.copy(targetPosition);
            light.target.updateMatrixWorld();
            const pos = Position.get(world, eid);
            tempPosition.set(pos.x, pos.y, pos.z);
            light.position.copy(targetPosition).add(tempPosition);
        }
    };
    return () => {
        exit(world).forEach(removeLight);
        enter(world).forEach(addLight);
        changed(world).forEach(editLight);
        if (activeFollowLightMap.size > 0) {
            processCameraFollow();
        }
    };
};
export { makeShadowSystem, makeLightSystem, };
