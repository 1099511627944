import THREE from './three';
import ThreeMeshUI from './8mesh';
import { FONTS, DEFAULT_FONT, DEFAULT_FONT_NAME } from '../shared/fonts';
import { assets } from './assets';
const UI_3D_SCALE = 0.01;
const UI_3D_Z = 0.01;
const uiText = new Map();
const uiImageUrl = new Map();
const loadedFonts = new Map();
// TODO(johnny): Implement remove3dUi in ui-system.ts
const remove3dUi = (eid, eidToLoadingPromise, object) => {
    eidToLoadingPromise.delete(eid);
    uiText.delete(object);
    object.removeFromParent();
};
// three-mesh-ui use scene coordinates where 0,0 is the center of the plane with
// X going right and Y going up while layout system uses top-left corner as 0,0
// with Left going right and Top going down. These functions translate between the
// two coordinate systems.
/* Translates layout Left to scene X
 * parentWidth: width of the parent object
 * width: width of the object
 * left: left position of the object
 */
const translateX = (parentWidth, width, left) => {
    // no parentWidth means it's a root node, center object at its position
    if (parentWidth === undefined) {
        return 0;
    }
    // Offset based on top-left corner of parent, not center.
    // 0,0 is the center of the plane, X goes right
    return (-0.5 * parentWidth + (left ?? 0) + 0.5 * (width ?? 0)) * UI_3D_SCALE;
};
/* Translates layout Top to scene Y
  * parentHeight: height of the parent object
  * height: height of the object
  * top: top position of the object
  */
const translateY = (parentHeight, height, top) => {
    // no parentHeight means it's a root node, center object at its position
    if (parentHeight === undefined) {
        return 0;
    }
    // Offset based on top-left corner of parent, not center.
    // 0,0 is the center of the plane, Y goes up
    return ((0.5 * parentHeight) - (top ?? 0) - 0.5 * (height ?? 0)) * UI_3D_SCALE;
};
const fontPromises = new Map();
const fetchExistingFont = (font) => {
    if (FONTS.has(font)) {
        return FONTS.get(font);
    }
    else if (loadedFonts.has(font)) {
        const font8Data = loadedFonts.get(font);
        if (!font8Data) {
            throw new Error(`Font ${font} not found`);
        }
        return { json: font8Data.json, png: font8Data.png };
    }
    return null;
};
const resolveFont = async (font) => {
    const existingFont = fetchExistingFont(font);
    if (existingFont) {
        return existingFont;
    }
    else {
        const asset = await assets.load({ url: font });
        if (!asset.remoteUrl) {
            throw new Error(`Font ${font} not found`);
        }
        const bundlePath = asset.remoteUrl && asset.remoteUrl.replace(/\/[^/]*$/, '/');
        const font8Text = await asset.data.text();
        const font8Json = JSON.parse(font8Text);
        const texturePage = await assets.load({ url: `${bundlePath}${font8Json.pages[0]}` });
        const font8Data = {
            // TODO (Johnny): Load more than 1 texture image.
            textures: [texturePage.localUrl],
            font8: asset.localUrl,
        };
        loadedFonts.set(font, { json: font8Data.font8, png: font8Data.textures[0] });
        fontPromises.delete(font);
        return { json: font8Data.font8, png: font8Data.textures[0] };
    }
};
const update3dUi = (eid, eidToLoadingPromise, object, settings, metrics) => {
    const fontName = settings.font ?? DEFAULT_FONT_NAME;
    let font = DEFAULT_FONT;
    if (!FONTS.has(fontName) && !loadedFonts.has(fontName)) {
        let fontPromise = fontPromises.get(fontName);
        if (!fontPromise) {
            fontPromise = resolveFont(fontName);
            fontPromises.set(fontName, fontPromise);
        }
        if (eidToLoadingPromise.get(eid) !== fontPromise) {
            eidToLoadingPromise.set(eid, fontPromise);
            fontPromise.then(({ json, png }) => {
                if (eidToLoadingPromise.get(eid) === fontPromise) {
                    object.set({
                        fontFamily: json,
                        fontTexture: png,
                    });
                }
                eidToLoadingPromise.delete(eid);
            });
        }
    }
    else {
        const existingFont = fetchExistingFont(fontName);
        if (existingFont) {
            font = existingFont;
        }
        eidToLoadingPromise.delete(eid);
    }
    const dimensions = metrics ? {
        width: metrics.width * UI_3D_SCALE,
        height: metrics.height * UI_3D_SCALE,
    } : null;
    const background = !settings.image ? {
        backgroundColor: new THREE.Color(settings.background),
        backgroundOpacity: settings.backgroundOpacity,
    } : null;
    // @ts-ignore
    object.set({
        ...dimensions,
        fontColor: new THREE.Color(settings.color),
        ...background,
        fontFamily: font?.json,
        fontTexture: font?.png,
        borderRadius: [
            settings.borderRadius * UI_3D_SCALE,
            settings.borderRadius * UI_3D_SCALE,
            settings.borderRadius * UI_3D_SCALE,
            settings.borderRadius * UI_3D_SCALE,
        ],
        borderWidth: settings.borderWidth * UI_3D_SCALE,
        borderColor: settings.borderColor ? new THREE.Color(settings.borderColor) : null,
        borderOpacity: settings.borderWidth ? 1 : 0,
        fontSize: settings.fontSize * UI_3D_SCALE,
        opacity: settings.opacity,
        textAlign: settings.textAlign,
        backgroundSize: settings.backgroundSize,
    });
    if (settings.image) {
        const previousImg = uiImageUrl.get(object);
        // only load image if it's different from the previous one
        if (previousImg !== settings.image) {
            uiImageUrl.set(object, settings.image);
            if (uiText.has(object)) {
                object.remove(uiText.get(object));
                uiText.delete(object);
            }
            assets.load({ url: settings.image }).then((asset) => {
                new THREE.TextureLoader().load(asset.localUrl, (texture) => {
                    // @ts-ignore
                    object.set({
                        backgroundTexture: texture,
                        backgroundSize: settings.backgroundSize,
                    });
                });
            });
        }
    }
    else if (settings.text) {
        if (uiText.has(object)) {
            const textObj = uiText.get(object);
            // @ts-ignore
            textObj.set({
                content: settings.text,
                fontSize: settings.fontSize * UI_3D_SCALE,
            });
        }
        else {
            const textObj = new ThreeMeshUI.Text({
                content: settings.text,
                fontSize: settings.fontSize * UI_3D_SCALE,
            });
            uiText.set(object, textObj);
            object.add(textObj);
        }
    }
    if (!settings.image && uiImageUrl.has(object)) {
        uiImageUrl.delete(object);
    }
    if (!metrics) {
        return;
    }
    // @ts-ignore
    object.autoLayout = false;
    const x = translateX(metrics?.parentWidth, metrics?.width, metrics?.left);
    const y = translateY(metrics?.parentHeight, metrics?.height, metrics?.top);
    // TODO (Tri) figure out a better way to do Z index
    object.position.set(x, y, UI_3D_Z);
};
const create3dUi = (eid, eidToLoadingPromise, settings) => {
    const obj = new ThreeMeshUI.Block({
        width: 0,
        height: 0,
    });
    update3dUi(eid, eidToLoadingPromise, obj, settings);
    return obj;
};
export { create3dUi, remove3dUi, update3dUi, };
