import { vec3, quat, mat4 } from './math/math';
import { Position, Quaternion, Scale } from './components';
const setScale = (world, eid, x, y, z) => {
    Scale.set(world, eid, { x, y, z });
};
const setPosition = (world, eid, x, y, z) => {
    Position.set(world, eid, { x, y, z });
};
const setQuaternion = (world, eid, x, y, z, w) => {
    Quaternion.set(world, eid, { x, y, z, w });
};
const normalizeQuaternion = (world, eid) => {
    const quaternionCursor = Quaternion.acquire(world, eid);
    const mag = Math.sqrt(quaternionCursor.x ** 2 +
        quaternionCursor.y ** 2 +
        quaternionCursor.z ** 2 +
        quaternionCursor.w ** 2);
    quaternionCursor.x /= mag;
    quaternionCursor.y /= mag;
    quaternionCursor.z /= mag;
    quaternionCursor.w /= mag;
    Quaternion.commit(world, eid);
};
const tempTrs = {
    t: vec3.zero(),
    r: quat.zero(),
    s: vec3.zero(),
};
const getTransform = (world, eid, target) => {
    const positionCursor = Position.get(world, eid);
    const scaleCursor = Scale.get(world, eid);
    const quaternionCursor = Quaternion.get(world, eid);
    tempTrs.t.setXyz(positionCursor.x, positionCursor.y, positionCursor.z);
    tempTrs.s.setXyz(scaleCursor.x, scaleCursor.y, scaleCursor.z);
    tempTrs.r.setXyzw(quaternionCursor.x, quaternionCursor.y, quaternionCursor.z, quaternionCursor.w);
    target.makeTrs(tempTrs.t, tempTrs.r, tempTrs.s);
};
const tempMatrix = mat4.i();
const getWorldTransform = (world, eid, target) => {
    target.makeI();
    let currentEid = eid;
    while (currentEid) {
        getTransform(world, currentEid, tempMatrix);
        target.setPremultiply(tempMatrix);
        currentEid = world.getParent(currentEid);
    }
};
const setTransform = (world, eid, matrix) => {
    matrix.decomposeTrs(tempTrs);
    setPosition(world, eid, tempTrs.t.x, tempTrs.t.y, tempTrs.t.z);
    setScale(world, eid, tempTrs.s.x, tempTrs.s.y, tempTrs.s.z);
    setQuaternion(world, eid, tempTrs.r.x, tempTrs.r.y, tempTrs.r.z, tempTrs.r.w);
};
export { setScale, setPosition, setQuaternion, normalizeQuaternion, getTransform, getWorldTransform, setTransform, };
