import { vec3, quat } from '../math/math';
import { Position, Quaternion, Location } from '../components';
// TODO(alancastillo): this is just a temporary fix, delete this when HMD is fully supported
const isDeviceHeadset = async () => {
    const agent = navigator.userAgent.toLocaleLowerCase();
    if (agent.includes('oculus') || agent.includes('quest') || agent.includes('headset')) {
        return true;
    }
    return false;
};
const resolveXR8 = () => {
    if (window.loadXr8) {
        window.loadXr8();
    }
};
const ensureXr8Loaded = new Promise((resolve) => {
    if (window.XR8) {
        resolve();
        return;
    }
    window.addEventListener('xrloaded', () => {
        resolve();
    });
});
const ensureCameraFeedCanvasExist = (renderer, elementId = 'camerafeed') => {
    if (renderer && renderer.domElement) {
        return renderer.domElement;
    }
    // Make the canvas
    const newCanvas = document.createElement('canvas');
    newCanvas.id = elementId;
    document.body.insertAdjacentElement('beforeend', newCanvas);
    return newCanvas;
};
// Options for defining which portions of the face have mesh triangles returned.
const FaceMeshGeometry = {
    FACE: 'face',
    MOUTH: 'mouth',
    EYES: 'eyes',
    IRIS: 'iris',
};
// We will initialize XR8 pipeline lazily on the first world/face/hand pipeline start
let isPipelineInitialized_ = false;
const ensurePipelineInitialized = ({ camera, cameraApi, scene, renderer, events, eid, ecsRenderOverride, }) => {
    if (isPipelineInitialized_) {
        //  Check if camera or scene has changed and reinitialize
        XR8.CloudStudioThreejs.updateSceneConfig({ camera, cameraApi, scene, renderer, eid, events });
        return;
    }
    XR8.addCameraPipelineModules([
        // Draws the camera feed.
        XR8.GlTextureRenderer.pipelineModule(),
        // Sync the camera with reality
        XR8.CloudStudioThreejs.pipelineModule({
            camera,
            cameraApi,
            scene,
            renderer,
            eid,
            events,
            ecsRenderOverride,
        }),
    ]);
    isPipelineInitialized_ = true;
};
const Z_FLIP = quat.yDegrees(180);
const buildCameraApi = (world, cameraEid) => {
    const t = vec3.zero();
    const r = quat.zero();
    const s = vec3.one();
    const targetTrs = { t, r, s };
    return {
        getCameraTransform: () => {
            t.setFrom(Position.get(world, cameraEid));
            r.setFrom(Quaternion.get(world, cameraEid));
            // Convert rotation from ecs into XR8's coordinate system
            r.setTimes(Z_FLIP);
            return targetTrs;
        },
    };
};
const constructWorldCameraPipeline = async (world, eid, config, ecsRenderOverride) => {
    // Since XR8 is a singleton, we cannot create multiple instances of the camera pipeline
    // We instead will store the config and construct the camera pipeline on start.
    const config_ = { ...config };
    let xrModuleName_ = null;
    const eid_ = eid;
    const isHeadset = await isDeviceHeadset();
    resolveXR8();
    await ensureXr8Loaded;
    // Load slam chunk if it is not already loaded.
    // Check if loadChunk is defined as it may not be available in engine depending what gets released
    // first.
    if (XR8.loadChunk) {
        await XR8.loadChunk('slam');
    }
    const locations = [];
    // TODO(Riyaan): This will not be compatible with multiple spaces in Studio.
    // We should be reading Location entities from the SceneGraph instead of World.
    world.allEntities.forEach((locEid) => {
        if (Location.has(world, locEid)) {
            const location = Location.get(world, locEid);
            locations.push({
                poiId: location.poiId,
                name: location.name,
                title: location.title,
                lat: location.lat,
                lng: location.lng,
                anchorNodeId: location.anchorNodeId,
                anchorSpaceId: location.anchorSpaceId,
                imageUrl: location.imageUrl,
                anchorPayload: location.anchorPayload,
            });
        }
    });
    return {
        start: () => {
            const xrModule = XR8.XrController.pipelineModule();
            xrModuleName_ = xrModule.name;
            // We assume that the user has already called stop() by another camera pipeline
            // or the XR8 instance is clean
            ensurePipelineInitialized({
                // NOTE(dat): This is a hack. We should be using the sub-camera that eid is pointing to.
                camera: world.three.activeCamera,
                cameraApi: buildCameraApi(world, eid),
                scene: world.three.scene,
                renderer: world.three.renderer,
                events: world.events,
                eid: eid_,
                ecsRenderOverride,
            });
            XR8.addCameraPipelineModule(xrModule);
            XR8.XrController.configure({
                disableWorldTracking: config_.disableWorldTracking,
                enableLighting: config_.enableLighting,
                enableWorldPoints: config_.enableWorldPoints,
                leftHandedAxes: config_.leftHandedAxes,
                scale: config_.enableVps ? 'responsive' : config_.scale,
                enableVps: config_.enableVps,
                localizationTargets: locations.map(location => ({
                    id: location.poiId,
                    name: location.name,
                    lat: location.lat,
                    lng: location.lng,
                    title: location.title,
                    node: location.anchorNodeId,
                    spaceId: location.anchorSpaceId,
                    imageUrl: location.imageUrl,
                    anchorPayload: location.anchorPayload,
                })),
                // TODO(Riyaan): Remove this once we can automatically determine the environment
                // vpsEnvironment: 'staging',
            });
            XR8.run({
                canvas: ensureCameraFeedCanvasExist(world.three.renderer),
                verbose: false,
                cameraConfig: config_.direction,
                allowedDevices: config_.allowedDevices,
                ownRunLoop: isHeadset,
            });
        },
        stop: () => {
            XR8.stop();
            if (xrModuleName_ !== null) {
                XR8.removeCameraPipelineModule(xrModuleName_);
                xrModuleName_ = null;
            }
        },
    };
};
const constructFaceCameraPipeline = async (world, eid, config, ecsRenderOverride) => {
    const config_ = { ...config };
    let xrModuleName_ = null;
    const eid_ = eid;
    resolveXR8();
    await ensureXr8Loaded;
    // Load face chunk if it is not already loaded.
    // CloudStudioThreejs currently needs XrController to work.
    if (XR8.loadChunk) {
        await XR8.loadChunk('slam');
        await XR8.loadChunk('face');
    }
    return {
        start: () => {
            const xrModule = XR8.FaceController.pipelineModule();
            xrModuleName_ = xrModule.name;
            // Fill out mesh geometry array (temporary)
            const meshGeometry = [];
            if (config_.meshGeometryFace) {
                meshGeometry.push(FaceMeshGeometry.FACE);
            }
            if (config_.meshGeometryEyes) {
                meshGeometry.push(FaceMeshGeometry.EYES);
            }
            if (config_.meshGeometryMouth) {
                meshGeometry.push(FaceMeshGeometry.MOUTH);
            }
            if (config_.meshGeometryIris) {
                meshGeometry.push(FaceMeshGeometry.IRIS);
            }
            // Assume the user has already called stop() by another camera pipeline
            // or XR8 instance is clean
            ensurePipelineInitialized({
                camera: world.three.activeCamera,
                cameraApi: buildCameraApi(world, eid),
                scene: world.three.scene,
                renderer: world.three.renderer,
                eid: eid_,
                events: world.events,
                ecsRenderOverride,
            });
            XR8.addCameraPipelineModule(xrModule);
            XR8.FaceController.configure({
                nearClip: config_.nearClip,
                farClip: config_.farClip,
                meshGeometry,
                uvType: config_.uvType,
                maxDetections: config_.maxDetections,
                enableEars: config_.enableEars,
                coordinates: { mirroredDisplay: config_.mirroredDisplay },
            });
            XR8.run({
                canvas: ensureCameraFeedCanvasExist(world.three.renderer),
                verbose: false,
                cameraConfig: { direction: config_.direction },
                allowedDevices: config.allowedDevices,
                ownRunLoop: false,
            });
        },
        stop: () => {
            XR8.stop();
            if (xrModuleName_ !== null) {
                XR8.removeCameraPipelineModule(xrModuleName_);
                xrModuleName_ = null;
            }
        },
    };
};
export { constructWorldCameraPipeline, constructFaceCameraPipeline, };
