import { Hidden, Ui } from '../components';
import { makeSystemHelper } from './system-helper';
const dirtyUiEntities = (world, eid) => {
    if (Ui.has(world, eid)) {
        Ui.dirty(world, eid);
    }
    for (const child of world.getChildren(eid)) {
        dirtyUiEntities(world, child);
    }
};
const makeHiddenSystem = (world) => {
    const { enter, exit } = makeSystemHelper(Hidden);
    const hide = (eid) => {
        const object = world.three.entityToObject.get(eid);
        if (object) {
            object.visible = false;
            dirtyUiEntities(world, eid);
        }
    };
    const show = (eid) => {
        const object = world.three.entityToObject.get(eid);
        if (object) {
            object.visible = true;
            dirtyUiEntities(world, eid);
        }
    };
    return () => {
        exit(world).forEach(show);
        enter(world).forEach(hide);
    };
};
export { makeHiddenSystem, };
