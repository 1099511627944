import { loadScript } from '../../shared/load-script';
import { MODEL_URL, WORKER_URL } from '../../shared/splat-constants';
import { assets } from '../assets';
import { Splat } from '../components';
import { events } from '../event-ids';
import { makeSystemHelper } from './system-helper';
const makeSplatSystem = (world) => {
    const { enter, changed, exit } = makeSystemHelper(Splat);
    const managers = new Map();
    let Model;
    const getModelPromise = (() => {
        let promise = null;
        return () => {
            if (!promise) {
                promise = new Promise((resolve, reject) => {
                    loadScript(MODEL_URL).then(() => {
                        Model = window.Model;
                        Model.setInternalConfig({ workerUrl: WORKER_URL });
                        resolve();
                    }).catch(reject);
                });
            }
            return promise;
        };
    })();
    const applySplat = async (eid) => {
        const object = world.three.entityToObject.get(eid);
        if (!object) {
            return;
        }
        const { url } = Splat.get(world, eid);
        if (!url) {
            return;
        }
        assets.load({ url }).then(async (asset) => {
            await getModelPromise();
            const manager = Model.ThreejsModelManager.create({ camera: world.three.activeCamera, renderer: world.three.renderer });
            manager.configure({ pointFrustumLimit: 0 });
            const buffer = new Uint8Array(await asset.data.arrayBuffer());
            manager.setModelBytes(asset.remoteUrl, buffer);
            manager.setOnLoaded(() => {
                const model = manager.model();
                model.userData.isSplat = true;
                model.children[0].raycast = () => { };
                model.raycast = () => { };
                object.add(model);
                world.events.dispatch(eid, events.SPLAT_MODEL_LOADED, { model });
                managers.set(eid, manager);
            });
        });
    };
    const updateSplat = () => {
        managers.forEach((manager, eid) => {
            const object = world.three.entityToObject.get(eid);
            if (!object) {
                return;
            }
            manager.tick();
            const canvas = world.three.renderer.domElement;
            if (canvas && object.userData?.lastWidth !== canvas.width) {
                manager.setRenderWidthPixels(canvas.width);
                object.userData.lastWidth = canvas.width;
            }
        });
    };
    const removeSplat = (eid) => {
        const manager = managers.get(eid);
        if (manager) {
            manager.dispose();
            managers.delete(eid);
        }
        const object = world.three.entityToObject.get(eid);
        if (!object) {
            return;
        }
        delete object.userData.lastWidth;
        object.children.forEach((child) => {
            if (child.userData?.isSplat) {
                object.remove(child);
            }
        });
    };
    return () => {
        exit(world).forEach(removeSplat);
        enter(world).forEach(applySplat);
        changed(world).forEach(applySplat);
        updateSplat();
    };
};
export { makeSplatSystem, };
