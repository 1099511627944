import { loadYoga } from 'yoga-layout/load';
const yoga = {};
let loaded = false;
const yogaPromise = loadYoga().then((mod) => {
    Object.assign(yoga, mod);
    loaded = true;
});
const yogaReady = async () => {
    await yogaPromise;
};
const isYogaReady = () => loaded;
export * from 'yoga-layout/load';
export { yoga as default, yogaReady, isYogaReady, };
