const defaultDeviceSupportForCamera = {
    '3dOnly': { phone: '3D', desktop: '3D', headset: 'disabled' },
    'world': { phone: 'AR', desktop: '3D', headset: 'AR' },
    'face': { phone: 'AR', desktop: 'AR', headset: 'disabled' },
    // Note(Julie): The following are not yet supported
    'hand': { phone: 'disabled', desktop: 'disabled', headset: 'disabled' },
    'layers': { phone: 'disabled', desktop: 'disabled', headset: 'disabled' },
    'worldLayers': { phone: 'disabled', desktop: 'disabled', headset: 'disabled' },
};
const ValidConfigs = [
    { camera: '3dOnly', phone: '3D', desktop: '3D', headset: 'disabled' },
    { camera: 'world', phone: 'AR', desktop: '3D', headset: 'AR' },
    { camera: 'world', phone: 'AR', desktop: 'disabled', headset: 'AR' },
    { camera: 'world', phone: 'AR', desktop: 'disabled', headset: 'disabled' },
    { camera: 'face', phone: 'AR', desktop: 'AR', headset: 'disabled' },
    { camera: 'face', phone: 'AR', desktop: 'disabled', headset: 'disabled' },
];
const UnsupportedConfigs = [
    { camera: 'world', phone: 'AR', desktop: '3D', headset: 'disabled' },
    { camera: 'world', phone: '3D', desktop: '3D', headset: 'VR' },
    { camera: 'world', phone: '3D', desktop: '3D', headset: 'disabled' },
    { camera: 'world', phone: '3D', desktop: 'disabled', headset: 'AR' },
    { camera: 'world', phone: '3D', desktop: 'disabled', headset: 'VR' },
    { camera: 'world', phone: '3D', desktop: 'disabled', headset: 'disabled' },
    { camera: 'world', phone: 'disabled', desktop: '3D', headset: 'AR' },
    { camera: 'world', phone: 'disabled', desktop: '3D', headset: 'VR' },
    { camera: 'world', phone: 'disabled', desktop: '3D', headset: 'disabled' },
    { camera: 'world', phone: 'disabled', desktop: 'disabled', headset: 'AR' },
    { camera: 'world', phone: 'disabled', desktop: 'disabled', headset: 'VR' },
    { camera: 'world', phone: 'disabled', desktop: 'disabled', headset: 'disabled' },
    { camera: 'face', phone: 'disabled', desktop: 'disabled', headset: 'disabled' },
    { camera: '3dOnly', phone: '3D', desktop: 'disabled', headset: 'disabled' },
    { camera: '3dOnly', phone: 'disabled', desktop: '3D', headset: 'disabled' },
    { camera: '3dOnly', phone: 'disabled', desktop: 'disabled', headset: 'disabled' },
];
const checkDeviceConfig = (currentConfig) => {
    if (ValidConfigs.some(config => config.camera === currentConfig.camera &&
        config.phone === currentConfig.phone &&
        config.desktop === currentConfig.desktop &&
        config.headset === currentConfig.headset)) {
        return 'valid';
    }
    if (UnsupportedConfigs.some(config => config.camera === currentConfig.camera &&
        config.phone === currentConfig.phone &&
        config.desktop === currentConfig.desktop &&
        config.headset === currentConfig.headset)) {
        return 'not-supported';
    }
    return 'not-ready';
};
const getAllowedXrDevices = (newConfig) => {
    if (newConfig.camera === 'world') {
        if (newConfig.desktop === 'disabled') {
            if (newConfig.headset === 'VR' || newConfig.headset === 'AR') {
                return 'mobile-and-headsets';
            }
            else {
                return 'mobile';
            }
        }
        else {
            return 'any';
        }
    }
    else if (newConfig.camera === 'face') {
        if (newConfig.desktop === 'disabled') {
            return 'mobile';
        }
    }
    return 'any';
};
const getDefaultDeviceSupport = (cameraType) => (defaultDeviceSupportForCamera[cameraType] ?? {});
export { checkDeviceConfig, getAllowedXrDevices, getDefaultDeviceSupport, };
