import { GAMEPAD_CONNECTED, GAMEPAD_DISCONNECTED, } from './input-events';
import { SCREEN_TOUCH_END, SCREEN_TOUCH_MOVE, SCREEN_TOUCH_START, GESTURE_START, GESTURE_MOVE, GESTURE_END, } from './pointer-events';
const input = {
    SCREEN_TOUCH_START,
    SCREEN_TOUCH_MOVE,
    SCREEN_TOUCH_END,
    GESTURE_START,
    GESTURE_MOVE,
    GESTURE_END,
    GAMEPAD_CONNECTED,
    GAMEPAD_DISCONNECTED,
};
export { input, };
