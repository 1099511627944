// @ts-nocheck
/**

Job: Positioning inline elements according to their dimensions inside this component

Knows: This component dimensions, and its children dimensions

This module is used for Block composition (Object.assign). A Block is responsible
for the positioning of its inline elements. In order for it to know what is the
size of these inline components, parseParams must be called on its children first.

It's worth noting that a Text is not positioned as a whole, but letter per letter,
in order to create a line break when necessary. It's Text that merge the various letters
in its own updateLayout function.

 */
import * as Whitespace from '../../utils/inline-layout/Whitespace';
import * as TextAlign from '../../utils/inline-layout/TextAlign';
export default function InlineManager(Base) {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    return class InlineManager extends Base {
        /** Compute children .inlines objects position, according to their pre-computed dimensions */
        computeInlinesPosition() {
            // computed by BoxComponent
            const padding = this.padding ?? 0;
            const borderOffset = this.getBorderWidth() ?? 0;
            const innerWidth = this.getWidth() - (padding * 2) - (borderOffset * 2);
            const innerHeight = this.getHeight() - (padding * 2) - (borderOffset * 2);
            // got by MeshUIComponent
            const justification = this.getJustifyContent();
            const alignment = this.getTextAlign();
            const interline = this.getInterLine();
            // Compute lines
            const lines = this.computeLines();
            lines.interLine = interline;
            /// //////////////////////////////////////////////////////////////
            // Position lines according to justifyContent and contentAlign
            /// //////////////////////////////////////////////////////////////
            const textHeight = Math.abs(lines.height);
            // Line vertical positioning
            // without additional justification offset, the bottom of the inline should be positioned
            // at y = 0, aka the center of the block
            // eslint-disable-next-line consistent-return
            const justificationOffset = (() => {
                switch (justification) {
                    case 'start':
                        return innerHeight / 2 - textHeight;
                    case 'end':
                        return -innerHeight / 2;
                    case 'center':
                        return -textHeight / 2;
                    default:
                        // eslint-disable-next-line no-console
                        console.warn(`justifyContent: '${justification}' is not valid`);
                }
            })();
            //
            lines.forEach((line) => {
                line.y += justificationOffset;
                line.forEach((inline) => {
                    inline.offsetY += justificationOffset;
                });
            });
            // Horizontal positioning
            TextAlign.textAlign(lines, alignment, innerWidth);
            // Make lines accessible to provide helpful informations
            this.lines = lines;
        }
        calculateBestFit(bestFit) {
            if (this.childrenInlines.length === 0)
                return;
            // eslint-disable-next-line default-case
            switch (bestFit) {
                case 'grow':
                    this.calculateGrowFit();
                    break;
                case 'shrink':
                    this.calculateShrinkFit();
                    break;
                case 'auto':
                    this.calculateAutoFit();
                    break;
            }
        }
        calculateGrowFit() {
            const innerHeight = this.getHeight() - (this.padding * 2 || 0);
            // Iterative method to find a fontSize of text children that text will fit into container
            let iterations = 1;
            const heightTolerance = 0.075;
            const firstText = this.childrenInlines.find(inlineComponent => inlineComponent.isText);
            let minFontMultiplier = 1;
            let maxFontMultiplier = 2;
            let fontMultiplier = firstText._fitFontSize
                ? firstText._fitFontSize / firstText.getFontSize()
                : 1;
            let textHeight;
            do {
                textHeight = this.calculateHeight(fontMultiplier);
                if (textHeight > innerHeight) {
                    if (fontMultiplier <= minFontMultiplier) { // can't shrink text
                        this.childrenInlines.forEach((inlineComponent) => {
                            if (inlineComponent.isInlineBlock)
                                return;
                            // ensure fontSize does not shrink
                            inlineComponent._fitFontSize = inlineComponent.getFontSize();
                        });
                        break;
                    }
                    maxFontMultiplier = fontMultiplier;
                    fontMultiplier -= (maxFontMultiplier - minFontMultiplier) / 2;
                }
                else {
                    if (Math.abs(innerHeight - textHeight) < heightTolerance)
                        break;
                    if (Math.abs(fontMultiplier - maxFontMultiplier) < 5e-10)
                        maxFontMultiplier *= 2;
                    minFontMultiplier = fontMultiplier;
                    fontMultiplier += (maxFontMultiplier - minFontMultiplier) / 2;
                }
            } while (++iterations <= 10);
        }
        calculateShrinkFit() {
            const innerHeight = this.getHeight() - (this.padding * 2 || 0);
            // Iterative method to find a fontSize of text children that text will fit into container
            let iterations = 1;
            const heightTolerance = 0.075;
            const firstText = this.childrenInlines.find(inlineComponent => inlineComponent.isText);
            let minFontMultiplier = 0;
            let maxFontMultiplier = 1;
            let fontMultiplier = firstText._fitFontSize
                ? firstText._fitFontSize / firstText.getFontSize()
                : 1;
            let textHeight;
            do {
                textHeight = this.calculateHeight(fontMultiplier);
                if (textHeight > innerHeight) {
                    maxFontMultiplier = fontMultiplier;
                    fontMultiplier -= (maxFontMultiplier - minFontMultiplier) / 2;
                }
                else {
                    if (fontMultiplier >= maxFontMultiplier) { // can't grow text
                        this.childrenInlines.forEach((inlineComponent) => {
                            if (inlineComponent.isInlineBlock)
                                return;
                            // ensure fontSize does not grow
                            inlineComponent._fitFontSize = inlineComponent.getFontSize();
                        });
                        break;
                    }
                    if (Math.abs(innerHeight - textHeight) < heightTolerance)
                        break;
                    minFontMultiplier = fontMultiplier;
                    fontMultiplier += (maxFontMultiplier - minFontMultiplier) / 2;
                }
            } while (++iterations <= 10);
        }
        calculateAutoFit() {
            const innerHeight = this.getHeight() - (this.padding * 2 || 0);
            // Iterative method to find a fontSize of text children that text will fit into container
            let iterations = 1;
            const heightTolerance = 0.075;
            const firstText = this.childrenInlines.find(inlineComponent => inlineComponent.isText);
            let minFontMultiplier = 0;
            let maxFontMultiplier = 2;
            let fontMultiplier = firstText._fitFontSize
                ? firstText._fitFontSize / firstText.getFontSize()
                : 1;
            let textHeight;
            do {
                textHeight = this.calculateHeight(fontMultiplier);
                if (textHeight > innerHeight) {
                    maxFontMultiplier = fontMultiplier;
                    fontMultiplier -= (maxFontMultiplier - minFontMultiplier) / 2;
                }
                else {
                    if (Math.abs(innerHeight - textHeight) < heightTolerance)
                        break;
                    if (Math.abs(fontMultiplier - maxFontMultiplier) < 5e-10)
                        maxFontMultiplier *= 2;
                    minFontMultiplier = fontMultiplier;
                    fontMultiplier += (maxFontMultiplier - minFontMultiplier) / 2;
                }
            } while (++iterations <= 10);
        }
        /**
         * computes lines based on children's inlines array.
         * @private
         */
        computeLines() {
            // computed by BoxComponent
            const innerWidth = this.getWidth() - (this.padding * 2 || 0);
            // Will stock the characters of each line, so that we can
            // correct lines position before to merge
            const lines = [[]];
            lines.height = 0;
            const interline = this.getInterLine();
            this.childrenInlines.reduce((lastInlineOffset, inlineComponent) => {
                // Abort condition
                if (!inlineComponent.inlines)
                    return;
                /// ///////////////////////////////////////////////////////////
                // Compute offset of each children according to its dimensions
                /// ///////////////////////////////////////////////////////////
                const fontSize = inlineComponent._fitFontSize || inlineComponent.getFontSize();
                const letterSpacing = inlineComponent.isText ? inlineComponent.getLetterSpacing() * fontSize : 0;
                const whitespace = inlineComponent.getWhiteSpace();
                const breakOn = inlineComponent.getBreakOn();
                const currentInlineInfo = inlineComponent.inlines.reduce((lastInlineInlineOffset, inline, i, inlines) => {
                    const kerning = inline.kerning ? inline.kerning : 0;
                    const xoffset = inline.xoffset ? inline.xoffset : 0;
                    const xadvance = inline.xadvance ? inline.xadvance : inline.width;
                    // Line break
                    const shouldBreak = Whitespace.shouldBreak(inlines, i, lastInlineInlineOffset, whitespace, letterSpacing, breakOn, innerWidth);
                    if (shouldBreak) {
                        lines.push([inline]);
                        inline.offsetX = xoffset;
                        // restart the lastInlineOffset as zero.
                        if (inline.width === 0)
                            return 0;
                        // compute lastInlineOffset normally
                        // except for kerning which won't apply
                        // as there is visually no lefthanded glyph to kern with
                        return xadvance + letterSpacing;
                    }
                    lines[lines.length - 1].push(inline);
                    inline.offsetX = lastInlineInlineOffset + xoffset + kerning;
                    return lastInlineInlineOffset + xadvance + kerning + letterSpacing;
                }, lastInlineOffset);
                //
                // eslint-disable-next-line consistent-return
                return currentInlineInfo;
            }, 0);
            // Compute lines dimensions
            let width = 0, height = 0, lineOffsetY = interline / 2;
            // construct the lines from the bottom to the top, so that the baseline of the last line
            // is lined up with the center of the block (y=0)
            for (let i = lines.length - 1; i >= 0; i--) {
                const line = lines[i];
                // set the height of the line to the highest character in the text, do not use the
                // "lineHeight" property of the font, as it can be significantly larger than the chars
                // displayed due to extra space necessary for ascenders and descenders
                line.lineHeight = line.reduce((lineHeight, inline) => Math.max(lineHeight, inline.height), 0);
                //
                line.lineBase = line.reduce((lineBase, inline) => {
                    const newLineBase = inline.lineBase !== undefined ? inline.lineBase : inline.height;
                    return Math.max(lineBase, newLineBase);
                }, 0);
                //
                line.width = 0;
                line.height = line.lineHeight;
                const lineHasInlines = line[0];
                if (lineHasInlines) {
                    // starts by processing whitespace, it will return a collapsed left offset
                    const whitespace = this.getWhiteSpace();
                    // TODO (tri): rewrote this collapseWhitespaceOnInlines usage. We are only
                    // using it to wrap one line of text, so we can simplify it.
                    const whiteSpaceOffset = Whitespace.collapseWhitespaceOnInlines(line, whitespace);
                    // apply the collapsed left offset to ensure the starting offset is 0
                    line.forEach((inline) => {
                        inline.offsetX -= whiteSpaceOffset;
                    });
                    // compute its width: length from firstInline:LEFT to lastInline:RIGHT
                    line.width = this.computeLineWidth(line);
                    if (line.width > width) {
                        width = line.width;
                    }
                    // TODO: remove this style of loop when refactoring 8mesh, this is necessary for now
                    // to satisfy the linting rules
                    for (let j = 0; j < line.length; j++) {
                        const inline = line[j];
                        // subtracting the height of the inline aligns the tops of the inlines
                        // as anchor is the distance from the top of the line to the top of the inline
                        // (with an additional offset to place the baseline back at y=0)
                        inline.offsetY = (lineOffsetY - inline.height) - inline.anchor;
                        if (inline.lineHeight < line.lineHeight) {
                            inline.offsetY -= line.lineBase - inline.lineBase;
                        }
                    }
                }
                line.y = lineOffsetY;
                // line.x will be set by textAlign
                height += (line.lineHeight + interline);
                lineOffsetY += (line.lineHeight + interline);
            }
            lines.height = height;
            lines.width = width;
            return lines;
        }
        calculateHeight(fontMultiplier) {
            this.childrenInlines.forEach((inlineComponent) => {
                if (inlineComponent.isInlineBlock)
                    return;
                // Set font size and recalculate dimensions
                inlineComponent._fitFontSize = inlineComponent.getFontSize() * fontMultiplier;
                inlineComponent.calculateInlines(inlineComponent._fitFontSize);
            });
            const lines = this.computeLines();
            return Math.abs(lines.height);
        }
        /**
         * Compute the width of a line
         * @param line
         * @returns {number}
         */
        // eslint-disable-next-line class-methods-use-this
        computeLineWidth(line) {
            // only by the length of its extremities
            const firstInline = line[0];
            const lastInline = line[line.length - 1];
            // Right + Left ( left is negative )
            return (lastInline.offsetX + lastInline.width) + firstInline.offsetX;
        }
    };
}
