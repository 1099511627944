import { BoxGeometry, PlaneGeometry, SphereGeometry, CapsuleGeometry, ConeGeometry, CylinderGeometry, TetrahedronGeometry, CircleGeometry, FaceGeometry, RingGeometry, PolyhedronGeometry, TorusGeometry, } from './components';
import THREE from './three';
import { DEFAULT_CAP_SEGMENTS, DEFAULT_RADIAL_SEGMENTS, getPolyhedronGeometry, } from '../shared/geometry-constants';
import { FaceMeshPositions, FaceMeshNormals, FaceMeshUvs, FaceMeshIndices, } from '../shared/face-mesh-data';
import { makeSplatSystem } from './systems/splat-system';
import { makeGltfSystem } from './systems/gltf-system';
import { makeGeometrySystem } from './systems/geometry-system';
import { makeTransformSystem } from './systems/transform-system';
import { makeUiSystem } from './systems/ui-system';
import { makeHiddenSystem } from './systems/hidden-system';
import { makeCameraSystem } from './systems/camera-system';
import { makeHiderMaterialSystem, makeMaterialSystem, makeShadowMaterialSystem, makeUnlitMaterialSystem, } from './systems/material-systems';
import { makeLightSystem, makeShadowSystem } from './systems/light-systems';
import { makeAudioSystem } from './systems/audio-system';
// System responsible for keeping the scene in sync with the ECS state
const makeSceneSyncSystem = (world) => {
    const spheresSystem = makeGeometrySystem(world, SphereGeometry, c => new THREE.SphereGeometry(c.radius));
    const boxesSystem = makeGeometrySystem(world, BoxGeometry, c => new THREE.BoxGeometry(c.width, c.height, c.depth));
    const planesSystem = makeGeometrySystem(world, PlaneGeometry, c => new THREE.PlaneGeometry(c.width, c.height));
    const capsuleSystem = makeGeometrySystem(world, CapsuleGeometry, c => new THREE.CapsuleGeometry(c.radius, c.height, DEFAULT_CAP_SEGMENTS, DEFAULT_RADIAL_SEGMENTS));
    const coneSystem = makeGeometrySystem(world, ConeGeometry, c => new THREE.ConeGeometry(c.radius, c.height, DEFAULT_RADIAL_SEGMENTS));
    const cylinderSystem = makeGeometrySystem(world, CylinderGeometry, c => new THREE.CylinderGeometry(c.radius, c.radius, c.height, DEFAULT_RADIAL_SEGMENTS));
    const tetrahedronSystem = makeGeometrySystem(world, TetrahedronGeometry, c => new THREE.TetrahedronGeometry(c.radius));
    const polyhedronSystem = makeGeometrySystem(world, PolyhedronGeometry, (c) => {
        const polyhedron = getPolyhedronGeometry(c.faces);
        return new THREE.PolyhedronGeometry(polyhedron?.vertices, polyhedron?.indices, c.radius);
    });
    const circleSystem = makeGeometrySystem(world, CircleGeometry, c => new THREE.CircleGeometry(c.radius, DEFAULT_RADIAL_SEGMENTS));
    const ringSystem = makeGeometrySystem(world, RingGeometry, c => new THREE.RingGeometry(c.innerRadius, c.outerRadius, DEFAULT_RADIAL_SEGMENTS));
    const torusSystem = makeGeometrySystem(world, TorusGeometry, c => new THREE.TorusGeometry(c.radius, c.tubeRadius, DEFAULT_RADIAL_SEGMENTS, DEFAULT_RADIAL_SEGMENTS));
    const faceSystem = makeGeometrySystem(world, FaceGeometry, () => {
        const geometry = new THREE.BufferGeometry();
        const indices = new Array(FaceMeshIndices.length);
        for (let i = 0; i < FaceMeshIndices.length; i++) {
            indices[i] = FaceMeshIndices[i];
        }
        geometry.setIndex(indices);
        geometry.setAttribute('position', new THREE.BufferAttribute(FaceMeshPositions, 3));
        geometry.setAttribute('normal', new THREE.BufferAttribute(FaceMeshNormals, 3));
        geometry.setAttribute('uv', new THREE.BufferAttribute(FaceMeshUvs, 2));
        return geometry;
    });
    const materialSystem = makeMaterialSystem(world);
    const unlitMaterialSystem = makeUnlitMaterialSystem(world);
    const shadowMaterialSystem = makeShadowMaterialSystem(world);
    const hiderMaterialSystem = makeHiderMaterialSystem(world);
    const transformSystem = makeTransformSystem(world);
    const gltfSystem = makeGltfSystem(world);
    const splatSystem = makeSplatSystem(world);
    const hiddenSystem = makeHiddenSystem(world);
    const shadowSystem = makeShadowSystem(world);
    const audioSystem = makeAudioSystem(world);
    const uiSystem = makeUiSystem(world);
    const lightSystem = makeLightSystem(world);
    const cameraSystem = makeCameraSystem(world);
    return () => {
        transformSystem();
        spheresSystem();
        boxesSystem();
        planesSystem();
        capsuleSystem();
        coneSystem();
        cylinderSystem();
        tetrahedronSystem();
        polyhedronSystem();
        circleSystem();
        ringSystem();
        torusSystem();
        faceSystem();
        gltfSystem();
        splatSystem();
        materialSystem();
        unlitMaterialSystem();
        shadowMaterialSystem();
        hiderMaterialSystem();
        hiddenSystem();
        shadowSystem();
        audioSystem();
        uiSystem();
        cameraSystem();
        lightSystem();
    };
};
export { makeSceneSyncSystem, };
