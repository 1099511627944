import { Camera } from '../components';
import { makeSystemHelper } from './system-helper';
import { createCameraObject, editCameraObject, editXrCameraObject, sameCameraType, } from '../camera';
import { CameraEvents } from '../camera-events';
const xrCameraChanged = (curr, change) => {
    if (!curr || !change) {
        return false;
    }
    return !Object.is(curr, change);
};
const makeCameraSystem = (world) => {
    const { enter, changed, exit } = makeSystemHelper(Camera);
    const addCamera = (eid) => {
        const object = world.three.entityToObject.get(eid);
        if (!object) {
            return;
        }
        const camera = createCameraObject(Camera.get(world, eid));
        object.add(camera);
        object.userData.camera = camera;
        world.camera.notifyCameraAdded(eid);
    };
    const editCamera = (eid) => {
        const object = world.three.entityToObject.get(eid);
        if (!object) {
            return;
        }
        const { camera } = object.userData;
        if (!camera) {
            return;
        }
        const cameraAttributes = Camera.get(world, eid);
        if (!sameCameraType(camera, cameraAttributes.type)) {
            object.remove(camera);
            const newCamera = createCameraObject(cameraAttributes);
            object.add(newCamera);
            object.userData.camera = newCamera;
            world.camera.notifyCameraAdded(eid);
        }
        else {
            editCameraObject(camera, cameraAttributes);
        }
        const xrCamera = object.userData.camera;
        const cameraChanged = xrCameraChanged(xrCamera.userData.xr, cameraAttributes);
        if (cameraChanged) {
            editXrCameraObject(xrCamera, cameraAttributes);
        }
        if (world.camera.getActiveEid() === eid && cameraChanged) {
            // note(alancastillo): dispatch event to trigger xr pipeline restart
            world.events.dispatch(world.events.globalId, CameraEvents.XR_CAMERA_EDIT, { camera: xrCamera });
        }
    };
    const removeCamera = (eid) => {
        world.camera.notifyCameraRemoved(eid);
        const object = world.three.entityToObject.get(eid);
        if (!object) {
            return;
        }
        object.remove(object.userData.camera);
        object.userData.camera = null;
    };
    return () => {
        exit(world).forEach(removeCamera);
        enter(world).forEach(addCamera);
        changed(world).forEach(editCamera);
    };
};
export { makeCameraSystem, };
