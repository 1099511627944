import { defineQuery, lifecycleQueries } from './query';
import { createAttribute } from './attribute';
import { defineSystem } from './system';
import { createStateMachine, deleteStateMachine, tickStateMachine, } from './state-machine';
import { createInstanced } from '../shared/instanced';
import { Collider } from './physics';
import { Disabled } from './disabled';
const behaviors = [];
const attributes = {
    collider: Collider,
    disabled: Disabled,
};
const registerBehavior = (callback) => {
    behaviors.push(callback);
};
const unregisterBehavior = (callback) => {
    const index = behaviors.indexOf(callback);
    if (index !== -1) {
        behaviors.splice(index, 1);
    }
};
const getBehaviors = () => behaviors;
const runBehaviors = (w) => {
    getBehaviors().forEach(c => c(w));
    return w;
};
const registerAttribute = (name, schema, customDefaults) => {
    if (attributes[name]) {
        throw new Error(`Already defined attribute with name: ${name}`);
    }
    const attribute = createAttribute(schema, customDefaults);
    attributes[name] = attribute;
    return attribute;
};
const isStateMachineDefiner = (machineDef) => typeof machineDef === 'function';
const registerComponent = ({ name, schema, schemaDefaults, stateMachine: machineDef, data, tick, add, remove, }) => {
    const schemaComponent = registerAttribute(name, schema, schemaDefaults);
    const isPlainAttribute = !(tick || add || remove || machineDef);
    if (isPlainAttribute) {
        if (data) {
            throw new Error('Can only specify data when defining component lifecycle.');
        }
        return schemaComponent;
    }
    const dataComponent = data && createAttribute(data);
    const schemaQuery = defineQuery([schemaComponent]);
    const { enter, exit } = lifecycleQueries(schemaQuery);
    const machineMap = machineDef && createInstanced(() => new Map());
    const machineDefinition = machineDef && (isStateMachineDefiner(machineDef)
        ? (props) => machineDef({
            ...props,
            schemaAttribute: schemaComponent.forWorld(props.world),
            dataAttribute: dataComponent?.forWorld(props.world),
        })
        : machineDef);
    const componentCursor = {
        eid: 0n,
        schema: null,
        data: null,
        schemaAttribute: null,
        dataAttribute: null,
    };
    const setCursorEid = (world, eid) => {
        componentCursor.schemaAttribute = schemaComponent.forWorld(world);
        componentCursor.eid = eid;
        componentCursor.schema = schemaComponent.cursor(world, eid);
        if (dataComponent) {
            componentCursor.data = dataComponent.cursor(world, eid);
            componentCursor.dataAttribute = dataComponent.forWorld(world);
        }
    };
    registerBehavior((world) => {
        for (const eid of exit(world)) {
            if (dataComponent) {
                dataComponent.remove(world, eid);
            }
            if (machineMap) {
                const id = machineMap(world).get(eid);
                if (id) {
                    deleteStateMachine(world, id);
                    machineMap(world).delete(eid);
                }
            }
            if (remove) {
                componentCursor.eid = eid;
                componentCursor.schema = null;
                componentCursor.data = null;
                remove(world, componentCursor);
            }
        }
        for (const eid of enter(world)) {
            if (dataComponent) {
                if (!dataComponent.has(world, eid)) {
                    dataComponent.reset(world, eid);
                }
            }
            if (add) {
                setCursorEid(world, eid);
                add(world, componentCursor);
            }
            if (machineMap && machineDefinition) {
                const machine = createStateMachine(world, eid, machineDefinition);
                machineMap(world).set(eid, machine);
            }
        }
    });
    if (tick) {
        const systemTerms = dataComponent
            ? [schemaComponent, dataComponent]
            : [schemaComponent];
        const tickSystem = defineSystem(systemTerms, (world, eid, [schemaCursor, dataCursor]) => {
            componentCursor.eid = eid;
            componentCursor.schema = schemaCursor;
            componentCursor.data = dataCursor;
            tick(world, componentCursor);
        });
        registerBehavior(tickSystem);
    }
    if (machineMap) {
        const tickStateMachineSystem = defineSystem([schemaComponent], (world, eid) => {
            const machineId = machineMap(world).get(eid);
            if (machineId) {
                tickStateMachine(world, machineId);
            }
        });
        registerBehavior(tickStateMachineSystem);
    }
    return schemaComponent;
};
const getAttribute = (name) => {
    if (!attributes[name]) {
        throw new Error(`No attribute registered with name: ${name}`);
    }
    return attributes[name];
};
const listAttributes = () => Object.keys(attributes);
export { registerBehavior, unregisterBehavior, registerComponent, getBehaviors, runBehaviors, registerAttribute, getAttribute, listAttributes, };
