import THREE from './three';
import { DRACO_DECODER_PATH } from '../shared/draco-constants';
let _gltfLoader = null;
const getGltfLoader = () => {
    if (_gltfLoader) {
        return _gltfLoader;
    }
    const newLoader = new THREE.GLTFLoader();
    const dracoLoader = new THREE.DRACOLoader();
    dracoLoader.setDecoderPath(DRACO_DECODER_PATH);
    newLoader.setDRACOLoader(dracoLoader);
    _gltfLoader = newLoader;
    return newLoader;
};
export { getGltfLoader, };
