const fill4x1 = (v, a, b, c, d) => {
    v[0] = a;
    v[1] = b;
    v[2] = c;
    v[3] = d;
    return v;
};
const fill4x4 = (v, a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p) => {
    v[0] = a;
    v[1] = b;
    v[2] = c;
    v[3] = d;
    v[4] = e;
    v[5] = f;
    v[6] = g;
    v[7] = h;
    v[8] = i;
    v[9] = j;
    v[10] = k;
    v[11] = l;
    v[12] = m;
    v[13] = n;
    v[14] = o;
    v[15] = p;
    return v;
};
const rotation4x4ToQuat = (m, q) => {
    const trace = m[0] + m[5] + m[10];
    if (trace >= 0.0) {
        let t = Math.sqrt(trace + 1.0);
        q[3] = 0.5 * t;
        t = 0.5 / t;
        q[0] = (m[2 + 1 * 4] - m[1 + 2 * 4]) * t;
        q[1] = (m[0 + 2 * 4] - m[2 + 0 * 4]) * t;
        q[2] = (m[1 + 0 * 4] - m[0 + 1 * 4]) * t;
    }
    else {
        // Find the largest diagonal element and jump to the appropriate case (0, 1 or 2)
        // eslint-disable-next-line no-nested-ternary
        const i = m[0] > m[5] ? (m[0] > m[10] ? 0 : 2) : (m[5] > m[10] ? 1 : 2);
        const j = (i + 1) % 3;
        const k = (j + 1) % 3;
        let t = Math.sqrt(1.0 + m[i + i * 4] - m[j + j * 4] - m[k + k * 4]);
        q[i] = 0.5 * t;
        t = 0.5 / t;
        q[j] = (m[j + i * 4] + m[i + j * 4]) * t;
        q[k] = (m[k + i * 4] + m[i + k * 4]) * t;
        q[3] = (m[k + j * 4] - m[j + k * 4]) * t;
    }
};
const sqlen3 = (x, y, z) => x * x + y * y + z * z;
export { fill4x1, fill4x4, rotation4x4ToQuat, sqlen3, };
