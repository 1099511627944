import { createInstanced } from '../shared/instanced';
import { asm } from './asm';
import { createCursor } from './cursor';
import { getParent, setThreeParent } from './parent';
const disableObject = (world, eid) => {
    asm.entitySetEnabled(world._id, eid, false);
    const object = world.three.entityToObject.get(eid);
    if (object && object.parent) {
        object.removeFromParent();
    }
};
const disabledForWorld = createInstanced((world) => {
    const cursor = createCursor(world, []);
    return ({
        set: (eid) => {
            disableObject(world, eid);
        },
        reset: (eid) => {
            disableObject(world, eid);
        },
        has: (eid) => asm.entityIsSelfDisabled(world._id, eid),
        remove: (eid) => {
            asm.entitySetEnabled(world._id, eid, true);
            setThreeParent(world, eid, getParent(world, eid));
        },
        dirty: () => {
            throw new Error('Invalid method for ecs.Disabled');
        },
        acquire: () => {
            throw new Error('Invalid method for ecs.Disabled');
        },
        commit: () => {
            throw new Error('Invalid method for ecs.Disabled');
        },
        get: (eid) => {
            if (!asm.entityIsSelfDisabled(world._id, eid)) {
                throw new Error('Entity is not disabled, cannot call ecs.Disabled.get');
            }
            return cursor;
        },
        mutate: () => {
            throw new Error('Invalid method for ecs.Disabled');
        },
        cursor: () => {
            throw new Error('Invalid method for ecs.Disabled');
        },
        id: asm.getDisabledComponentId(world._id),
    });
});
const Disabled = {
    set: (world, eid) => disabledForWorld(world).set(eid),
    reset: (world, eid) => disabledForWorld(world).reset(eid),
    dirty: (world, eid) => disabledForWorld(world).dirty(eid),
    get: (world, eid) => disabledForWorld(world).get(eid),
    cursor: (world, eid) => disabledForWorld(world).cursor(eid),
    acquire: (world, eid) => disabledForWorld(world).acquire(eid),
    commit: (world, eid) => disabledForWorld(world).commit(eid),
    mutate: (world, eid, fn) => disabledForWorld(world).mutate(eid, fn),
    has: (world, eid) => disabledForWorld(world).has(eid),
    remove: (world, eid) => disabledForWorld(world).remove(eid),
    forWorld: disabledForWorld,
    schema: {},
    orderedSchema: [],
    defaults: {},
};
export { Disabled, };
