/// /////////////////// Type interfaces //////////////////////
/// /////////////////// Unexported Internal Factories //////////////////////
const SCRATCH_VEC = [];
class Vec2Impl {
    constructor() {
        this._data = [0, 0, 1];
        Object.defineProperties(this, {
            _data: { enumerable: false },
            x: { enumerable: true, get: () => this._data[0] },
            y: { enumerable: true, get: () => this._data[1] },
        });
    }
    setXy(x, y) {
        this._data[0] = x;
        this._data[1] = y;
        return this;
    }
    setX(v) {
        this._data[0] = v;
        return this;
    }
    setY(v) {
        this._data[1] = v;
        return this;
    }
    setFrom(v) {
        return this.setXy(v.x, v.y);
    }
    clone() {
        return new Vec2Impl().setFrom(this);
    }
    dot(v) {
        return this.x * v.x + this.y * v.y;
    }
    length() {
        return Math.sqrt(this.dot(this));
    }
    distanceTo(v) {
        return SCRATCH_VEC[0].setFrom(v).setMinus(this).length();
    }
    equals(v, tolerance) {
        return (Math.abs(this.x - v.x) <= tolerance &&
            Math.abs(this.y - v.y) <= tolerance);
    }
    setPlus(v) {
        return this.setXy(this.x + v.x, this.y + v.y);
    }
    plus(v) {
        return this.clone().setPlus(v);
    }
    setMinus(v) {
        return this.setXy(this.x - v.x, this.y - v.y);
    }
    minus(v) {
        return this.clone().setMinus(v);
    }
    setMix(v, t) {
        return this.setPlus(SCRATCH_VEC[0].setFrom(v).setMinus(this).setScale(t));
    }
    mix(v, t) {
        return this.clone().setMix(v, t);
    }
    setTimes(v) {
        return this.setXy(this.x * v.x, this.y * v.y);
    }
    times(v) {
        return this.clone().setTimes(v);
    }
    setDivide(v) {
        return this.setXy(this.x / v.x, this.y / v.y);
    }
    divide(v) {
        return this.clone().setDivide(v);
    }
    setScale(s) {
        return this.setXy(this.x * s, this.y * s);
    }
    scale(s) {
        return this.clone().setScale(s);
    }
    setNormalize() {
        return this.setScale(1 / (this.length() || 1));
    }
    normalize() {
        return this.clone().setNormalize();
    }
    cross(v) {
        return this.x * v.y - this.y * v.x;
    }
    makeOne() {
        return this.setXy(1, 1);
    }
    makeScale(s) {
        return this.setXy(s, s);
    }
    makeZero() {
        return this.setXy(0, 0);
    }
}
const newVec2 = () => new Vec2Impl();
/// /////////////////// Exported External Factories //////////////////////
const vec2 = {
    from: (source) => newVec2().setXy(source.x, source.y),
    one: () => newVec2().makeOne(),
    scale: (s) => newVec2().makeScale(s),
    xy: (x, y) => newVec2().setXy(x, y),
    zero: () => newVec2().makeZero(),
};
SCRATCH_VEC.push(vec2.zero());
export { vec2, };
