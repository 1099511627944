import { asm } from './asm';
import { globalStringMap } from './string-storage';
const bind = (fn) => asm.dataView[fn].bind(asm.dataView);
const makeStringAccessor = (cursor, propertyOffset) => {
    const get = bind('getUint32');
    const set = bind('setUint32');
    return {
        get() {
            const ptr = cursor._index * cursor._stride + cursor._ptr + propertyOffset;
            const stringId = get(ptr, true);
            return globalStringMap(cursor._world).get(stringId);
        },
        set(value) {
            const ptr = cursor._index * cursor._stride + cursor._ptr + propertyOffset;
            const stringId = globalStringMap(cursor._world).store(value);
            set(ptr, stringId, true);
        },
        enumerable: true,
    };
};
const makeEidAccessor = (cursor, propertyOffset) => {
    const get = bind('getBigUint64');
    const set = bind('setBigUint64');
    return {
        get() {
            const ptr = cursor._index * cursor._stride + cursor._ptr + propertyOffset;
            return get(ptr, true);
        },
        set(value) {
            const ptr = cursor._index * cursor._stride + cursor._ptr + propertyOffset;
            set(ptr, value, true);
        },
        enumerable: true,
    };
};
const makeBooleanAccessor = (cursor, propertyOffset) => {
    const get = bind('getUint8');
    const set = bind('setUint8');
    return {
        get() {
            const ptr = cursor._index * cursor._stride + cursor._ptr + propertyOffset;
            return !!get(ptr);
        },
        set(value) {
            const ptr = cursor._index * cursor._stride + cursor._ptr + propertyOffset;
            set(ptr, +!!value);
        },
        enumerable: true,
    };
};
const getAccessors = (type) => {
    switch (type) {
        case 'ui8':
            return [bind('getUint8'), bind('setUint8')];
        case 'ui32':
            return [bind('getUint32'), bind('setUint32')];
        case 'i32':
            return [bind('getInt32'), bind('setInt32')];
        case 'f32':
            return [bind('getFloat32'), bind('setFloat32')];
        case 'f64':
            return [bind('getFloat64'), bind('setFloat64')];
        default:
            throw new Error(`Unknown type: ${type}`);
    }
};
const makePropertyAccessor = (cursor, type, propertyOffset) => {
    if (type === 'string') {
        return makeStringAccessor(cursor, propertyOffset);
    }
    if (type === 'eid') {
        return makeEidAccessor(cursor, propertyOffset);
    }
    if (type === 'boolean') {
        return makeBooleanAccessor(cursor, propertyOffset);
    }
    const [get, set] = getAccessors(type);
    return {
        get() {
            const byteIndex = cursor._index * cursor._stride + cursor._ptr + propertyOffset;
            return get(byteIndex, true);
        },
        set(value) {
            const byteIndex = cursor._index * cursor._stride + cursor._ptr + propertyOffset;
            return set(byteIndex, value, true);
        },
        enumerable: true,
    };
};
const createCursor = (world, orderedSchema) => {
    const cursor = {};
    Object.defineProperties(cursor, {
        _world: {
            value: world,
            writable: false,
        },
        _ptr: {
            value: 0,
            writable: true,
        },
        _stride: {
            value: 0,
            writable: true,
        },
        _index: {
            value: 0,
            writable: true,
        },
    });
    orderedSchema.forEach(([key, type, offset]) => {
        Object.defineProperty(cursor, key, makePropertyAccessor(cursor, type, offset));
    });
    return cursor;
};
export { createCursor, };
