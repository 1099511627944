const createAssetManager = () => {
    const assets = new Map();
    const pendingLoads = new Map();
    // file path to CDN url path.
    // e.g. 'assets/some/path/skyview2.png' => 'assets/skyview2-abcdefg12j.png'
    const pathToUrl = new Map();
    const setAssetManifest = (newManifest) => {
        Object.entries(newManifest).forEach(([filePath, url]) => {
            pathToUrl.set(filePath, url);
        });
    };
    // @param assetUrl e.g. assets/my-model.glb
    // @returns use the manifest to map assetUrl to current location on CDN. Return original assetUrl
    //          if does not exist.
    const resolveAsset = (assetUrl) => pathToUrl.get(assetUrl) ?? assetUrl;
    // TODO(Dale): Implement defineAsset
    const fetchAsset = async (request) => {
        const remoteUrl = resolveAsset(request.url);
        const response = await fetch(remoteUrl);
        const data = await response.blob();
        const localUrl = URL.createObjectURL(data);
        const asset = { data, remoteUrl, localUrl };
        assets.set(request.url, asset);
        return asset;
    };
    const load = async (request) => {
        const asset = assets.get(request.url);
        if (asset) {
            return asset;
        }
        const pending = pendingLoads.get(request.url);
        if (pending) {
            return pending;
        }
        const promise = fetchAsset(request);
        pendingLoads.set(request.url, promise);
        return promise;
    };
    const loadSync = (request) => {
        const asset = assets.get(request.url);
        if (asset) {
            return asset;
        }
        throw new Error(`Asset not found: ${request.url}`);
    };
    const clear = (request) => {
        const asset = assets.get(request.url);
        if (asset) {
            URL.revokeObjectURL(asset.localUrl);
        }
        assets.delete(request.url);
    };
    const getStatistics = () => ({
        pending: pendingLoads.size - assets.size,
        complete: assets.size, // TODO(christoph): Calling ecs.assets.clear will mess with these stats
        total: pendingLoads.size,
    });
    return {
        load,
        clear,
        loadSync,
        setAssetManifest,
        resolveAsset,
        getStatistics,
    };
};
export { createAssetManager, };
