import { fill4x4, rotation4x4ToQuat, sqlen3 } from './algorithms';
import { quat } from './quat';
import { vec3 } from './vec3';
/// /////////////////// Unexported Internal Factories //////////////////////
const SCRATCH_TRS = {
    t: vec3.zero(),
    r: quat.zero(),
    s: vec3.zero(),
};
const SCRATCH_MAT = [];
const SCRATCH_4X4 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
const unrolled4x4Mul4x1 = (A, B, Outs) => {
    Outs[0] = A[0] * B[0] + A[4] * B[1] + A[8] * B[2] + A[12] * B[3];
    Outs[1] = A[1] * B[0] + A[5] * B[1] + A[9] * B[2] + A[13] * B[3];
    Outs[2] = A[2] * B[0] + A[6] * B[1] + A[10] * B[2] + A[14] * B[3];
    Outs[3] = A[3] * B[0] + A[7] * B[1] + A[11] * B[2] + A[15] * B[3];
};
const unrolled4x4Mul4x4 = (A, B, Outs) => {
    Outs[0] = A[0] * B[0] + A[4] * B[1] + A[8] * B[2] + A[12] * B[3];
    Outs[1] = A[1] * B[0] + A[5] * B[1] + A[9] * B[2] + A[13] * B[3];
    Outs[2] = A[2] * B[0] + A[6] * B[1] + A[10] * B[2] + A[14] * B[3];
    Outs[3] = A[3] * B[0] + A[7] * B[1] + A[11] * B[2] + A[15] * B[3];
    Outs[4] = A[0] * B[4] + A[4] * B[5] + A[8] * B[6] + A[12] * B[7];
    Outs[5] = A[1] * B[4] + A[5] * B[5] + A[9] * B[6] + A[13] * B[7];
    Outs[6] = A[2] * B[4] + A[6] * B[5] + A[10] * B[6] + A[14] * B[7];
    Outs[7] = A[3] * B[4] + A[7] * B[5] + A[11] * B[6] + A[15] * B[7];
    Outs[8] = A[0] * B[8] + A[4] * B[9] + A[8] * B[10] + A[12] * B[11];
    Outs[9] = A[1] * B[8] + A[5] * B[9] + A[9] * B[10] + A[13] * B[11];
    Outs[10] = A[2] * B[8] + A[6] * B[9] + A[10] * B[10] + A[14] * B[11];
    Outs[11] = A[3] * B[8] + A[7] * B[9] + A[11] * B[10] + A[15] * B[11];
    Outs[12] = A[0] * B[12] + A[4] * B[13] + A[8] * B[14] + A[12] * B[15];
    Outs[13] = A[1] * B[12] + A[5] * B[13] + A[9] * B[14] + A[13] * B[15];
    Outs[14] = A[2] * B[12] + A[6] * B[13] + A[10] * B[14] + A[14] * B[15];
    Outs[15] = A[3] * B[12] + A[7] * B[13] + A[11] * B[14] + A[15] * B[15];
};
const det2 = (m00, m01, m10, m11) => (m00 * m11 - m01 * m10);
const det3 = (m00, m01, m02, m10, m11, m12, m20, m21, m22) => (m00 * det2(m11, m12, m21, m22) - // Col 0
    m01 * det2(m10, m12, m20, m22) + // Col 1
    m02 * det2(m10, m11, m20, m21) // Col 2
);
const invert4x4 = (m, inv) => {
    inv[0] = m[5] * m[10] * m[15] - m[5] * m[11] * m[14] -
        m[9] * m[6] * m[15] + m[9] * m[7] * m[14] +
        m[13] * m[6] * m[11] - m[13] * m[7] * m[10];
    inv[4] = -m[4] * m[10] * m[15] + m[4] * m[11] * m[14] +
        m[8] * m[6] * m[15] - m[8] * m[7] * m[14] -
        m[12] * m[6] * m[11] + m[12] * m[7] * m[10];
    inv[8] = m[4] * m[9] * m[15] - m[4] * m[11] * m[13] -
        m[8] * m[5] * m[15] + m[8] * m[7] * m[13] +
        m[12] * m[5] * m[11] - m[12] * m[7] * m[9];
    inv[12] = -m[4] * m[9] * m[14] + m[4] * m[10] * m[13] +
        m[8] * m[5] * m[14] - m[8] * m[6] * m[13] -
        m[12] * m[5] * m[10] + m[12] * m[6] * m[9];
    const det = m[0] * inv[0] + m[1] * inv[4] + m[2] * inv[8] + m[3] * inv[12];
    if (det === 0) {
        inv.fill(0);
        return false;
    }
    const idet = 1.0 / det;
    inv[0] *= idet;
    inv[4] *= idet;
    inv[8] *= idet;
    inv[12] *= idet;
    inv[1] = (-m[1] * m[10] * m[15] + m[1] * m[11] * m[14] +
        m[9] * m[2] * m[15] - m[9] * m[3] * m[14] -
        m[13] * m[2] * m[11] + m[13] * m[3] * m[10]) * idet;
    inv[5] = (m[0] * m[10] * m[15] - m[0] * m[11] * m[14] -
        m[8] * m[2] * m[15] + m[8] * m[3] * m[14] +
        m[12] * m[2] * m[11] - m[12] * m[3] * m[10]) * idet;
    inv[9] = (-m[0] * m[9] * m[15] + m[0] * m[11] * m[13] +
        m[8] * m[1] * m[15] - m[8] * m[3] * m[13] -
        m[12] * m[1] * m[11] + m[12] * m[3] * m[9]) * idet;
    inv[13] = (m[0] * m[9] * m[14] - m[0] * m[10] * m[13] -
        m[8] * m[1] * m[14] + m[8] * m[2] * m[13] +
        m[12] * m[1] * m[10] - m[12] * m[2] * m[9]) * idet;
    inv[2] = (m[1] * m[6] * m[15] - m[1] * m[7] * m[14] -
        m[5] * m[2] * m[15] + m[5] * m[3] * m[14] +
        m[13] * m[2] * m[7] - m[13] * m[3] * m[6]) * idet;
    inv[6] = (-m[0] * m[6] * m[15] + m[0] * m[7] * m[14] +
        m[4] * m[2] * m[15] - m[4] * m[3] * m[14] -
        m[12] * m[2] * m[7] + m[12] * m[3] * m[6]) * idet;
    inv[10] = (m[0] * m[5] * m[15] - m[0] * m[7] * m[13] -
        m[4] * m[1] * m[15] + m[4] * m[3] * m[13] +
        m[12] * m[1] * m[7] - m[12] * m[3] * m[5]) * idet;
    inv[14] = (-m[0] * m[5] * m[14] + m[0] * m[6] * m[13] +
        m[4] * m[1] * m[14] - m[4] * m[2] * m[13] -
        m[12] * m[1] * m[6] + m[12] * m[2] * m[5]) * idet;
    inv[3] = (-m[1] * m[6] * m[11] + m[1] * m[7] * m[10] +
        m[5] * m[2] * m[11] - m[5] * m[3] * m[10] -
        m[9] * m[2] * m[7] + m[9] * m[3] * m[6]) * idet;
    inv[7] = (m[0] * m[6] * m[11] - m[0] * m[7] * m[10] -
        m[4] * m[2] * m[11] + m[4] * m[3] * m[10] +
        m[8] * m[2] * m[7] - m[8] * m[3] * m[6]) * idet;
    inv[11] = (-m[0] * m[5] * m[11] + m[0] * m[7] * m[9] +
        m[4] * m[1] * m[11] - m[4] * m[3] * m[9] -
        m[8] * m[1] * m[7] + m[8] * m[3] * m[5]) * idet;
    inv[15] = (m[0] * m[5] * m[10] - m[0] * m[6] * m[9] -
        m[4] * m[1] * m[10] + m[4] * m[2] * m[9] +
        m[8] * m[1] * m[6] - m[8] * m[2] * m[5]) * idet;
    return true;
};
const computedInverse = (m) => {
    const inv = new Array(16);
    if (!invert4x4(m, inv)) {
        return null;
    }
    return inv;
};
const transpose4x4 = (m, o) => {
    [o[0], o[4], o[8], o[12],
        o[1], o[5], o[9], o[13],
        o[2], o[6], o[10], o[14],
        o[3], o[7], o[11], o[15]] = m;
};
// Identity Matrix
const EYE = [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1];
const SCRATCH_MAT1 = new Array(16);
const SCRATCH_MAT2 = new Array(16);
const SCRATCH_VEC1 = new Array(4);
const SCRATCH_VEC2 = new Array(4);
const copyMat4x4 = (src, dst) => {
    for (let i = 0; i < 16; i++) {
        dst[i] = src[i];
    }
};
const newMat4 = () => {
    let data_ = [...EYE];
    let inverseData_ = [...EYE];
    const set = (data, inverseData = null) => {
        copyMat4x4(data, data_);
        const cinv = inverseData || computedInverse(data);
        if (cinv) {
            if (!inverseData_) {
                inverseData_ = new Array(16);
            }
            copyMat4x4(cinv, inverseData_);
        }
        else {
            inverseData_ = null;
        }
        return out_; // eslint-disable-line @typescript-eslint/no-use-before-define
    };
    const clone = () => newMat4().set(data_, inverseData_ || undefined);
    const setInv = () => {
        if (!inverseData_) {
            throw new Error('Mat4 is not invertible');
        }
        const temp = data_;
        data_ = inverseData_;
        inverseData_ = temp;
        return out_; // eslint-disable-line @typescript-eslint/no-use-before-define
    };
    const inv = () => clone().setInv();
    const setTranspose = () => {
        transpose4x4(data_, SCRATCH_MAT1);
        if (!inverseData_) {
            return set(SCRATCH_MAT1, null);
        }
        transpose4x4(inverseData_, SCRATCH_MAT2);
        return set(SCRATCH_MAT1, SCRATCH_MAT2);
    };
    const transpose = () => clone().setTranspose();
    const setScale = (s) => {
        if (s === 0) {
            throw new Error('Cannot scale matrix by zero');
        }
        for (let i = 0; i < 16; i++) {
            data_[i] *= s;
        }
        if (inverseData_) {
            const is = 1 / s;
            for (let i = 0; i < 16; i++) {
                inverseData_[i] *= is;
            }
        }
        return out_; // eslint-disable-line @typescript-eslint/no-use-before-define
    };
    const scale = (s) => clone().setScale(s);
    const setTimes = (m) => {
        unrolled4x4Mul4x4(data_, m.data(), SCRATCH_MAT1);
        if (!(inverseData_ && m.inverseData())) {
            return set(SCRATCH_MAT1, null);
        }
        unrolled4x4Mul4x4(m.inverseData(), inverseData_, SCRATCH_MAT2);
        return set(SCRATCH_MAT1, SCRATCH_MAT2);
    };
    const setPremultiply = (m) => {
        unrolled4x4Mul4x4(m.data(), data_, SCRATCH_MAT1);
        if (!(inverseData_ && m.inverseData())) {
            return set(SCRATCH_MAT1, null);
        }
        unrolled4x4Mul4x4(inverseData_, m.inverseData(), SCRATCH_MAT2);
        return set(SCRATCH_MAT1, SCRATCH_MAT2);
    };
    const times = (m) => clone().setTimes(m);
    const timesVec = (v, target = vec3.zero()) => {
        const o = SCRATCH_VEC1;
        SCRATCH_VEC2[0] = v.x;
        SCRATCH_VEC2[1] = v.y;
        SCRATCH_VEC2[2] = v.z;
        SCRATCH_VEC2[3] = 1;
        unrolled4x4Mul4x1(data_, SCRATCH_VEC2, o);
        return target.setXyz(o[0] / o[3], o[1] / o[3], o[2] / o[3]);
    };
    const determinant = () => {
        const m = data_;
        const m0 = m[0];
        const m1 = m[4];
        const m2 = m[8];
        const m3 = m[12];
        return m0 * det3(m[5], m[9], m[13], m[6], m[10], m[14], m[7], m[11], m[15]) -
            m1 * det3(m[1], m[9], m[13], m[2], m[10], m[14], m[3], m[11], m[15]) +
            m2 * det3(m[1], m[5], m[13], m[2], m[6], m[14], m[3], m[7], m[15]) -
            m3 * det3(m[1], m[5], m[9], m[2], m[6], m[10], m[3], m[7], m[11]);
    };
    const decomposeTrs = (target = { t: vec3.zero(), r: quat.zero(), s: vec3.zero() }) => {
        // First compute the scale.
        const m = data_;
        const sx = Math.sqrt(sqlen3(m[0], m[1], m[2]));
        const sy = Math.sqrt(sqlen3(m[4], m[5], m[6]));
        const sz = Math.sqrt(sqlen3(m[8], m[9], m[10]));
        // Detect a scale inversion, and negate the scale of the x-axis arbitrarily.
        const detSign = det3(m[0], m[4], m[8], m[1], m[5], m[9], m[2], m[6], m[10]) < 0 ? -1 : 1;
        const { t, r, s } = target;
        s.setXyz(sx * detSign, sy, sz);
        const isx = 1 / s.x;
        const isy = 1 / s.y;
        const isz = 1 / s.z;
        // Normalize the matrix to remove scale.
        unrolled4x4Mul4x4(m, fill4x4(SCRATCH_4X4, isx, 0, 0, 0, 0, isy, 0, 0, 0, 0, isz, 0, 0, 0, 0, 1), SCRATCH_MAT1);
        t.setXyz(SCRATCH_MAT1[12], SCRATCH_MAT1[13], SCRATCH_MAT1[14]);
        rotation4x4ToQuat(SCRATCH_MAT1, SCRATCH_VEC1);
        r.setXyzw(SCRATCH_VEC1[0], SCRATCH_VEC1[1], SCRATCH_VEC1[2], SCRATCH_VEC1[3]);
        return target;
    };
    const equals = (m, tolerance) => (!data_.some((v, i) => Math.abs(v - m.data()[i]) > tolerance));
    const makeI = () => set(EYE, EYE);
    const makeR = (q) => {
        const wx = q.w * q.x;
        const wy = q.w * q.y;
        const wz = q.w * q.z;
        const xx = q.x * q.x;
        const xy = q.x * q.y;
        const xz = q.x * q.z;
        const yy = q.y * q.y;
        const yz = q.y * q.z;
        const zz = q.z * q.z;
        copyMat4x4(EYE, data_);
        data_[0] = 1.0 - 2.0 * (yy + zz);
        data_[4] = 2.0000000 * (xy - wz);
        data_[8] = 2.0000000 * (xz + wy);
        data_[1] = 2.0000000 * (xy + wz);
        data_[5] = 1.0 - 2.0 * (xx + zz);
        data_[9] = 2.0000000 * (yz - wx);
        data_[2] = 2.0000000 * (xz - wy);
        data_[6] = 2.0000000 * (yz + wx);
        data_[10] = 1.0 - 2.0 * (xx + yy);
        if (!inverseData_) {
            inverseData_ = new Array(16);
        }
        transpose4x4(data_, inverseData_);
        return out_; // eslint-disable-line @typescript-eslint/no-use-before-define
    };
    const makeS = (v) => {
        copyMat4x4(EYE, data_);
        data_[0] = v.x;
        data_[5] = v.y;
        data_[10] = v.z;
        if (!inverseData_) {
            inverseData_ = new Array(16);
        }
        copyMat4x4(EYE, inverseData_);
        inverseData_[0] = 1 / v.x;
        inverseData_[5] = 1 / v.y;
        inverseData_[10] = 1 / v.z;
        return out_; // eslint-disable-line @typescript-eslint/no-use-before-define
    };
    const makeT = (v) => {
        copyMat4x4(EYE, data_);
        data_[12] = v.x;
        data_[13] = v.y;
        data_[14] = v.z;
        if (!inverseData_) {
            inverseData_ = new Array(16);
        }
        copyMat4x4(EYE, inverseData_);
        inverseData_[12] = -v.x;
        inverseData_[13] = -v.y;
        inverseData_[14] = -v.z;
        return out_; // eslint-disable-line @typescript-eslint/no-use-before-define
    };
    const makeTr = (t, r) => makeT(t).setTimes(SCRATCH_MAT[0].makeR(r));
    const makeTrs = (t, r, s) => (makeTr(t, r).setTimes(SCRATCH_MAT[0].makeS(s)));
    const makeRows = (rowData, inverseRowData) => {
        [[data_[0], data_[4], data_[8], data_[12]],
            [data_[1], data_[5], data_[9], data_[13]],
            [data_[2], data_[6], data_[10], data_[14]],
            [data_[3], data_[7], data_[11], data_[15]]] = rowData;
        if (inverseRowData) {
            if (!inverseData_) {
                inverseData_ = new Array(16);
            }
            [[inverseData_[0], inverseData_[4], inverseData_[8], inverseData_[12]],
                [inverseData_[1], inverseData_[5], inverseData_[9], inverseData_[13]],
                [inverseData_[2], inverseData_[6], inverseData_[10], inverseData_[14]],
                [inverseData_[3], inverseData_[7], inverseData_[11], inverseData_[15]]] = rowData;
        }
        else {
            const cinv = computedInverse(data_);
            if (cinv) {
                if (!inverseData_) {
                    inverseData_ = new Array(16);
                }
                copyMat4x4(cinv, inverseData_);
            }
            else {
                inverseData_ = null;
            }
        }
        return out_; // eslint-disable-line @typescript-eslint/no-use-before-define
    };
    const setLookAt = (target, up) => {
        const { t, r, s } = decomposeTrs(SCRATCH_TRS);
        r.makeLookAt(SCRATCH_TRS.t, target, up);
        return makeTrs(t, r, s);
    };
    const lookAt = (target, up) => clone().setLookAt(target, up);
    const out_ = {
        clone,
        data: () => data_,
        decomposeTrs,
        determinant,
        equals,
        inverseData: () => inverseData_,
        inv,
        lookAt,
        scale,
        times,
        timesVec,
        transpose,
        set,
        setInv,
        setLookAt,
        setPremultiply,
        setScale,
        setTimes,
        setTranspose,
        makeI,
        makeR,
        makeRows,
        makeS,
        makeT,
        makeTr,
        makeTrs,
    };
    return out_;
};
/// /////////////////// Exported External Factories //////////////////////
const mat4 = {
    i: () => newMat4().makeI(),
    of: (data, inverseData) => (newMat4().set(data, inverseData)),
    r: (q) => newMat4().makeR(q),
    rows: (dataRows, inverseDataRows) => (newMat4().makeRows(dataRows, inverseDataRows)),
    s: (v) => newMat4().makeS(v),
    t: (v) => newMat4().makeT(v),
    tr: (t, r) => newMat4().makeTr(t, r),
    trs: (t, r, s) => newMat4().makeTrs(t, r, s),
    // TODO: Camera projection
};
SCRATCH_MAT.push(newMat4());
export { mat4, };
