import { MeshoptSimplifier } from 'meshoptimizer';
import { TARGET_ERROR, TARGET_INDEX_RATIO, TRIANGLE_VERTEX_COUNT, VERTEX_POSITION_STRIDE, VERTEX_ROUNDING_FACTOR, } from '../shared/mesh-optimizer-constants';
import { mergeVertices } from './three-merge-vertices';
const getGltfVertices = (scene) => {
    const vertexPositions = [];
    scene.traverse((object) => {
        object.traverseVisible((ob) => {
            if (!ob.isMesh) {
                return;
            }
            const { geometry } = ob;
            const deduplicatedGeometry = mergeVertices(geometry);
            if (!deduplicatedGeometry.index) {
                return;
            }
            const positions = deduplicatedGeometry.attributes.position.array;
            const indices = new Uint32Array(deduplicatedGeometry.index.array);
            const targetIndexCount = Math.floor(indices.length * (TARGET_INDEX_RATIO / TRIANGLE_VERTEX_COUNT)) * TRIANGLE_VERTEX_COUNT;
            const [simplifiedIndices] = MeshoptSimplifier.simplify(indices, positions, VERTEX_POSITION_STRIDE, targetIndexCount, TARGET_ERROR);
            const simplifiedVertices = new Float32Array(simplifiedIndices.length * VERTEX_POSITION_STRIDE);
            for (let i = 0; i < simplifiedIndices.length; i++) {
                const index = simplifiedIndices[i] * VERTEX_POSITION_STRIDE;
                for (let j = 0; j < VERTEX_POSITION_STRIDE; j++) {
                    simplifiedVertices[i * VERTEX_POSITION_STRIDE + j] =
                        Math.round(positions[index + j] * VERTEX_ROUNDING_FACTOR) / VERTEX_ROUNDING_FACTOR;
                }
            }
            vertexPositions.push(...simplifiedVertices);
        });
    });
    return new Float32Array(vertexPositions);
};
export { getGltfVertices, };
