import { asm } from './asm';
import { WASM_POINTER_SIZE } from './constants';
import { extractEids } from './list-data';
const COLLISION_START_EVENT = 'physics-collision-start';
const COLLISION_END_EVENT = 'physics-collision-end';
const dispatchCollisionPairs = (world, event, listPtr, count) => {
    const eids = extractEids(listPtr, count);
    // NOTE(christoph): The eid list contains a pair of eids for each collision, in index 2n and 2n+1.
    // [1, 2, 3, 4] -> (1, 2), (3, 4)
    let prev = 0n;
    for (const eid of eids) {
        if (prev !== 0n) {
            world.events.dispatch(eid, event, { other: prev });
            world.events.dispatch(prev, event, { other: eid });
            prev = 0n;
        }
        else {
            prev = eid;
        }
    }
};
const dispatchCollisionStart = (world) => {
    const listPtr = asm._malloc(WASM_POINTER_SIZE);
    const count = asm.getCollisionStartEvents(world._id, listPtr);
    dispatchCollisionPairs(world, COLLISION_START_EVENT, listPtr, count);
    asm._free(listPtr);
};
const dispatchCollisionEnd = (world) => {
    const listPtr = asm._malloc(WASM_POINTER_SIZE);
    const count = asm.getCollisionEndEvents(world._id, listPtr);
    dispatchCollisionPairs(world, COLLISION_END_EVENT, listPtr, count);
    asm._free(listPtr);
};
const dispatchPhysicsEvents = (world) => {
    dispatchCollisionEnd(world);
    dispatchCollisionStart(world);
};
export { dispatchPhysicsEvents, COLLISION_END_EVENT, COLLISION_START_EVENT, };
