import { asm } from './asm';
import { createCursor } from './cursor';
import { getDefaults, getSchemaAlignment, getSchemaSize, toOrderedSchema } from './memory';
const createWorldAttribute = (world, id, orderedSchema, defaults) => {
    const worldId = world._id;
    const cursor = createCursor(world, orderedSchema);
    const getCursor = (eid) => {
        const mutIndex = asm.entityGetMutComponent(worldId, eid, id);
        if (mutIndex) {
            cursor._ptr = mutIndex;
            return cursor;
        }
        throw new Error('Failed to establish cursor');
    };
    const acquireExistingComponent = (eid) => {
        const mutIndex = asm.entityStartMutExistingComponent(worldId, eid, id);
        if (mutIndex) {
            cursor._ptr = mutIndex;
            return cursor;
        }
        throw new Error('Failed to establish cursor');
    };
    const acquire = (eid) => {
        const mutIndex = asm.entityStartMutComponent(worldId, eid, id);
        if (mutIndex) {
            cursor._ptr = mutIndex;
            return cursor;
        }
        throw new Error('Failed to establish cursor');
    };
    const commit = (eid, modified = true) => {
        asm.entityEndMutComponent(worldId, eid, id, modified);
    };
    const has = (eid) => asm.entityHasComponent(worldId, eid, id);
    let isMutating = false;
    const mutate = (eid, fn) => {
        if (isMutating) {
            throw new Error('Cannot nest mutate calls.');
        }
        isMutating = true;
        const deferredCursor = acquireExistingComponent(eid);
        const modified = !fn(deferredCursor);
        commit(eid, modified);
        isMutating = false;
    };
    return {
        id,
        set: (eid, data) => {
            const didHave = has(eid);
            const entityCursor = acquire(eid);
            if (didHave) {
                Object.assign(entityCursor, data);
            }
            else {
                Object.assign(entityCursor, defaults, data);
            }
            commit(eid);
        },
        reset: (eid) => {
            const entityCursor = acquire(eid);
            Object.assign(entityCursor, defaults);
            commit(eid);
        },
        mutate,
        dirty: (eid) => {
            asm.entityGetMutComponent(worldId, eid, id);
        },
        cursor: getCursor,
        acquire: acquireExistingComponent,
        commit,
        get: (eid) => {
            const ptr = asm.entityGetComponent(worldId, eid, id);
            if (!ptr) {
                throw new Error('Cannot get component data if not attached.');
            }
            cursor._ptr = ptr;
            return cursor;
        },
        remove: eid => asm.entityRemoveComponent(worldId, eid, id),
        has,
    };
};
const createAttribute = (schema, customDefaults) => {
    const handleMap = new WeakMap();
    const orderedSchema = toOrderedSchema(schema);
    const sizing = getSchemaSize(orderedSchema);
    const alignment = getSchemaAlignment(orderedSchema);
    const defaults = getDefaults(schema, customDefaults);
    const forWorld = (world) => {
        if (handleMap.has(world)) {
            return handleMap.get(world);
        }
        else {
            const id = asm.createComponent(world._id, sizing, alignment);
            const handle = createWorldAttribute(world, id, orderedSchema, defaults);
            handleMap.set(world, handle);
            return handle;
        }
    };
    const root = {
        set: (world, eid, data) => forWorld(world).set(eid, data),
        reset: (world, eid) => forWorld(world).reset(eid),
        dirty: (world, eid) => forWorld(world).dirty(eid),
        get: (world, eid) => forWorld(world).get(eid),
        cursor: (world, eid) => forWorld(world).cursor(eid),
        acquire: (world, eid) => forWorld(world).acquire(eid),
        commit: (world, eid) => forWorld(world).commit(eid),
        mutate: (world, eid, fn) => forWorld(world).mutate(eid, fn),
        has: (world, eid) => forWorld(world).has(eid),
        remove: (world, eid) => forWorld(world).remove(eid),
        forWorld,
        schema,
        orderedSchema,
        defaults,
    };
    return root;
};
export { createWorldAttribute, createAttribute, };
