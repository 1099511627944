const createInstanced = (create) => {
    const instanceMap = new WeakMap();
    return (key) => {
        if (instanceMap.has(key)) {
            return instanceMap.get(key);
        }
        const instance = create(key);
        instanceMap.set(key, instance);
        return instance;
    };
};
export { createInstanced, };
