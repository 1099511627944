import { HiderMaterial, Material, ShadowMaterial, UnlitMaterial } from '../components';
import { makeSystemHelper } from './system-helper';
import THREE from '../three';
import { sides } from '../three-side';
import { assets } from '../assets';
import { events } from '../event-ids';
const setMaterialColor = (color, r, g, b) => {
    color.setRGB(r / 255, g / 255, b / 255);
    color.convertSRGBToLinear();
};
const invisibleMaterial = new THREE.MeshBasicMaterial();
const removeMaterial = (eid, world) => {
    const object = world.three.entityToObject.get(eid);
    if (object instanceof THREE.Mesh) {
        // Clear out the userData to stop any texture loading
        object.material.userData = {};
        // TODO(christoph): Technically switching from one material to another would
        // be clobbered here
        object.material = invisibleMaterial;
    }
};
const textureLoader = new THREE.TextureLoader();
const MATERIAL_BLENDING = {
    'no': THREE.NoBlending,
    'normal': THREE.NormalBlending,
    'additive': THREE.AdditiveBlending,
    'subtractive': THREE.SubtractiveBlending,
    'multiply': THREE.MultiplyBlending,
};
const loadTexture = (material, src, property, repeatX, repeatY, offsetX, offsetY, colorSpace) => {
    if (material.userData[property] === src) {
        return;
    }
    material.userData[property] = src;
    if (src) {
        assets.load({ url: src }).then((asset) => {
            // If the material has changed since the texture was requested or is being removed,
            // don't apply it
            if (material.userData[property] !== src) {
                return;
            }
            const texture = textureLoader.load(asset.localUrl);
            texture.userData.src = src;
            texture.repeat.set(repeatX, repeatY);
            texture.offset.set(offsetX, offsetY);
            texture.wrapS = repeatX > 1 ? THREE.RepeatWrapping : THREE.ClampToEdgeWrapping;
            texture.wrapT = repeatY > 1 ? THREE.RepeatWrapping : THREE.ClampToEdgeWrapping;
            // ts couldn't resolve the type of material[property] although it always is a texture
            material[property] = texture;
            material.needsUpdate = true;
            if (colorSpace) {
                texture.colorSpace = colorSpace;
            }
        });
    }
    else {
        material[property] = null;
        material.needsUpdate = true;
    }
};
const makeUnlitMaterialSystem = (world) => {
    const { enter, changed, exit } = makeSystemHelper(UnlitMaterial);
    const applyMaterial = (eid) => {
        const object = world.three.entityToObject.get(eid);
        if (object instanceof THREE.Mesh) {
            let material;
            if (object.material?.userData.unlit) {
                material = object.material;
            }
            else {
                material = new THREE.MeshBasicMaterial();
                material.userData.unlit = true;
                object.material = material;
            }
            const { r, g, b, textureSrc, opacity, side, blending, opacityMap, repeatX, repeatY, offsetX, offsetY, depthTest, depthWrite, wireframe, forceTransparent, } = UnlitMaterial.get(world, eid);
            const threeSide = sides[side];
            setMaterialColor(material.color, r, g, b);
            material.side = threeSide;
            material.transparent = opacity < 1 || !!opacityMap || forceTransparent;
            material.opacity = opacity;
            material.blending = MATERIAL_BLENDING[blending] ?? THREE.NormalBlending;
            material.wireframe = wireframe;
            material.depthTest = depthTest;
            material.depthWrite = depthWrite;
            material.visible = true;
            loadTexture(material, textureSrc, 'map', repeatX, repeatY, offsetX, offsetY, THREE.SRGBColorSpace);
            loadTexture(material, opacityMap, 'alphaMap', repeatX, repeatY, offsetX, offsetY);
        }
    };
    return () => {
        exit(world).forEach(eid => removeMaterial(eid, world));
        enter(world).forEach(applyMaterial);
        changed(world).forEach(applyMaterial);
    };
};
const makeShadowMaterialSystem = (world) => {
    const { enter, changed, exit } = makeSystemHelper(ShadowMaterial);
    const applyShadowGroupMaterial = (model, eid) => {
        if (!model) {
            return;
        }
        const { r, g, b, side, opacity, depthTest, depthWrite, } = ShadowMaterial.get(world, eid);
        const shadowMat = new THREE.ShadowMaterial({ color: new THREE.Color(r, g, b) });
        shadowMat.opacity = opacity;
        shadowMat.side = sides[side];
        shadowMat.depthTest = depthTest;
        shadowMat.depthWrite = depthWrite;
        shadowMat.needsUpdate = true;
        model.traverse((node) => {
            if (node.material) {
                node.material = shadowMat;
            }
            node.receiveShadow = true;
        });
    };
    const applyShadowOnModelLoad = (event) => {
        const data = event.data;
        // Make sure that the event target matches the model (events bubble up the hierarchy)
        if (event.target === event.currentTarget) {
            applyShadowGroupMaterial(data.model, event.currentTarget);
        }
    };
    const applyMaterial = (eid) => {
        const object = world.three.entityToObject.get(eid);
        if (object instanceof THREE.Mesh) {
            let material;
            if (object.material instanceof THREE.ShadowMaterial) {
                material = object.material;
            }
            else {
                material = new THREE.ShadowMaterial();
                object.material = material;
            }
            const { r, g, b, side, opacity, depthTest, depthWrite, } = ShadowMaterial.get(world, eid);
            setMaterialColor(material.color, r, g, b);
            material.side = sides[side];
            material.transparent = true;
            material.opacity = opacity;
            material.depthTest = depthTest;
            material.depthWrite = depthWrite;
            material.needsUpdate = true;
        }
        else if (object?.userData.gltf) {
            applyShadowGroupMaterial(object.userData.gltf.scene, eid);
        }
    };
    return () => {
        exit(world).forEach((eid) => {
            removeMaterial(eid, world);
            world.events.removeListener(eid, events.GLTF_MODEL_LOADED, applyShadowOnModelLoad);
        });
        enter(world).forEach((eid) => {
            applyMaterial(eid);
            world.events.addListener(eid, events.GLTF_MODEL_LOADED, applyShadowOnModelLoad);
        });
        changed(world).forEach(applyMaterial);
    };
};
const makeHiderMaterial = () => {
    const material = new THREE.MeshBasicMaterial();
    material.userData.hider = true;
    material.colorWrite = false;
    material.visible = true;
    return material;
};
const applyGroupHiderMaterial = (model) => {
    if (!model) {
        return;
    }
    const hiderMaterial = makeHiderMaterial();
    // Set renderOrder for the whole group
    // (this makes it relative to all other objects in the scene without applying to individual nodes)
    model.renderOrder = -1;
    model.traverse((node) => {
        if (node instanceof THREE.Mesh) {
            node.material = hiderMaterial;
        }
    });
};
const applyHiderOnModelLoad = (event) => {
    const data = event.data;
    // Make sure that the event target matches the model (events bubble up the hierarchy)
    if (event.target === event.currentTarget) {
        applyGroupHiderMaterial(data.model);
    }
};
const makeHiderMaterialSystem = (world) => {
    const { enter, changed, exit } = makeSystemHelper(HiderMaterial);
    const applyMaterial = (eid) => {
        const object = world.three.entityToObject.get(eid);
        if (object?.userData.gltf) {
            applyGroupHiderMaterial(object.userData.gltf.scene);
        }
        else if (object instanceof THREE.Mesh) {
            if (!object.material?.userData.hider) {
                object.material = makeHiderMaterial();
            }
            // renderOrder is 0 by default. For now, we always want to render this material first
            // TODO: Make renderOrder configurable with layers
            object.renderOrder = -1;
        }
        // Have a listener waiting for a GLTF model to load (in case it hasn't loaded yet, or changes)
        world.events.addListener(eid, events.GLTF_MODEL_LOADED, applyHiderOnModelLoad);
    };
    return () => {
        exit(world).forEach((eid) => {
            removeMaterial(eid, world);
            const object = world.three.entityToObject.get(eid);
            // Reset renderOrder back to 0
            // TODO: Make renderOrder configurable with layers
            if (object?.userData.gltf) {
                object.userData.gltf.scene.renderOrder = 0;
            }
            else if (object instanceof THREE.Mesh) {
                object.renderOrder = 0;
            }
            world.events.removeListener(eid, events.GLTF_MODEL_LOADED, applyHiderOnModelLoad);
        });
        enter(world).forEach(applyMaterial);
        changed(world).forEach(applyMaterial);
    };
};
const makeMaterialSystem = (world) => {
    const { enter, changed, exit } = makeSystemHelper(Material);
    const applyMaterial = (eid) => {
        const object = world.three.entityToObject.get(eid);
        if (object instanceof THREE.Mesh) {
            let material;
            if (object.material instanceof THREE.MeshPhysicalMaterial) {
                material = object.material;
            }
            else {
                material = new THREE.MeshPhysicalMaterial();
                object.material = material;
            }
            const { r, g, b, textureSrc, roughness, metalness, opacity, roughnessMap, metalnessMap, side, normalScale, emissiveIntensity, emissiveR, emissiveG, emissiveB, opacityMap, normalMap, emissiveMap, blending, repeatX, repeatY, offsetX, offsetY, depthTest, depthWrite, wireframe, forceTransparent, } = Material.get(world, eid);
            const threeSide = sides[side];
            setMaterialColor(material.color, r, g, b);
            material.side = threeSide;
            material.transparent = opacity < 1 || !!opacityMap || forceTransparent;
            material.opacity = opacity;
            material.roughness = roughness;
            material.metalness = metalness;
            material.normalScale.set(normalScale, normalScale);
            material.emissiveIntensity = emissiveIntensity;
            setMaterialColor(material.emissive, emissiveR, emissiveG, emissiveB);
            material.blending = MATERIAL_BLENDING[blending] ?? THREE.NormalBlending;
            material.wireframe = wireframe;
            material.depthTest = depthTest;
            material.depthWrite = depthWrite;
            loadTexture(material, textureSrc, 'map', repeatX, repeatY, offsetX, offsetY, THREE.SRGBColorSpace);
            loadTexture(material, roughnessMap, 'roughnessMap', repeatX, repeatY, offsetX, offsetY);
            loadTexture(material, metalnessMap, 'metalnessMap', repeatX, repeatY, offsetX, offsetY);
            loadTexture(material, normalMap, 'normalMap', repeatX, repeatY, offsetX, offsetY);
            loadTexture(material, opacityMap, 'alphaMap', repeatX, repeatY, offsetX, offsetY);
            loadTexture(material, emissiveMap, 'emissiveMap', repeatX, repeatY, offsetX, offsetY, THREE.SRGBColorSpace);
        }
    };
    return () => {
        exit(world).forEach(eid => removeMaterial(eid, world));
        enter(world).forEach(applyMaterial);
        changed(world).forEach(applyMaterial);
    };
};
export { makeMaterialSystem, makeUnlitMaterialSystem, makeShadowMaterialSystem, makeHiderMaterialSystem, };
