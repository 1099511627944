import { asm } from './asm';
import { getDefaults } from './memory';
import { COLLISION_END_EVENT, COLLISION_START_EVENT } from './physics-events';
import { DEFAULT_ANGULAR_DAMPING, DEFAULT_FRICTION, DEFAULT_LINEAR_DAMPING, DEFAULT_MASS, DEFAULT_RESTITUTION, DEFAULT_ROLLING_FRICTION, DEFAULT_SPINNING_FRICTION, DEFAULT_GRAVITY_FACTOR, } from '../shared/physic-constants';
import { createWorldAttribute } from './attribute';
import { createInstanced } from '../shared/instanced';
import { FLOAT_SIZE } from './constants';
const colliderSchema = {
    width: 'f32',
    height: 'f32',
    depth: 'f32',
    radius: 'f32',
    mass: 'f32',
    type: 'ui8',
    shape: 'ui8',
    linearDamping: 'f32',
    angularDamping: 'f32',
    friction: 'f32',
    rollingFriction: 'f32',
    spinningFriction: 'f32',
    restitution: 'f32',
    gravityFactor: 'f32',
    eventOnly: 'boolean',
    lockXAxis: 'boolean',
    lockYAxis: 'boolean',
    lockZAxis: 'boolean',
};
const ColliderShape = {
    Box: 0,
    Sphere: 1,
    Plane: 2,
    Capsule: 3,
    Cone: 4,
    Cylinder: 5,
};
// NOTE(christoph): This corresponds with PhysicsCollider in physics.h.
// The first 12 bytes are internal pointers.
const colliderMemoryLayout = [
    ['width', 'f32', 12],
    ['height', 'f32', 16],
    ['depth', 'f32', 20],
    ['radius', 'f32', 24],
    ['mass', 'f32', 28],
    ['linearDamping', 'f32', 32],
    ['angularDamping', 'f32', 36],
    ['friction', 'f32', 40],
    ['rollingFriction', 'f32', 44],
    ['spinningFriction', 'f32', 48],
    ['restitution', 'f32', 52],
    ['gravityFactor', 'f32', 56],
    ['type', 'ui8', 60],
    ['shape', 'ui8', 61],
    ['eventOnly', 'ui8', 62],
    ['lockXAxis', 'ui8', 63],
    ['lockYAxis', 'ui8', 64],
    ['lockZAxis', 'ui8', 65],
];
const colliderDefaults = getDefaults(colliderSchema, {
    mass: DEFAULT_MASS,
    linearDamping: DEFAULT_LINEAR_DAMPING,
    angularDamping: DEFAULT_ANGULAR_DAMPING,
    friction: DEFAULT_FRICTION,
    rollingFriction: DEFAULT_ROLLING_FRICTION,
    spinningFriction: DEFAULT_SPINNING_FRICTION,
    restitution: DEFAULT_RESTITUTION,
    gravityFactor: DEFAULT_GRAVITY_FACTOR,
});
const colliderForWorld = createInstanced((world) => {
    const id = asm.getColliderComponentId(world._id);
    return createWorldAttribute(world, id, colliderMemoryLayout, colliderDefaults);
});
const Collider = {
    set: (world, eid, data) => colliderForWorld(world).set(eid, data),
    reset: (world, eid) => colliderForWorld(world).reset(eid),
    dirty: (world, eid) => colliderForWorld(world).dirty(eid),
    get: (world, eid) => colliderForWorld(world).get(eid),
    cursor: (world, eid) => colliderForWorld(world).cursor(eid),
    acquire: (world, eid) => colliderForWorld(world).acquire(eid),
    commit: (world, eid) => colliderForWorld(world).commit(eid),
    mutate: (world, eid, fn) => colliderForWorld(world).mutate(eid, fn),
    has: (world, eid) => colliderForWorld(world).has(eid),
    remove: (world, eid) => colliderForWorld(world).remove(eid),
    forWorld: colliderForWorld,
    schema: colliderSchema,
    orderedSchema: colliderMemoryLayout,
    defaults: colliderDefaults,
};
const setWorldGravity = (world, gravity) => {
    if (!asm.setWorldGravity(world._id, gravity)) {
        throw new Error('Failed to set world gravity');
    }
};
const getWorldGravity = (world) => asm.getWorldGravity(world._id);
const setLinearVelocity = (world, eid, velocityX, velocityY, velocityZ) => {
    if (!asm.setLinearVelocityToEntity(world._id, eid, velocityX, velocityY, velocityZ)) {
        throw new Error(`Entity ${eid} fail to set linear velocity, there may not be a collider on the entity`);
    }
};
const getLinearVelocity = (world, eid) => {
    const velocityPtr = asm.getLinearVelocityFromEntity(world._id, eid);
    if (!velocityPtr) {
        throw new Error(`Entity ${eid} does not have a collider`);
    }
    const dataView = new DataView(asm.HEAPF32.buffer);
    const x = dataView.getFloat32(velocityPtr, true);
    const y = dataView.getFloat32(velocityPtr + FLOAT_SIZE, true);
    const z = dataView.getFloat32(velocityPtr + (2 * FLOAT_SIZE), true);
    asm._free(velocityPtr);
    return { x, y, z };
};
const applyForce = (world, eid, forceX, forceY, forceZ) => {
    if (!asm.applyForceToEntity(world._id, eid, forceX, forceY, forceZ)) {
        throw new Error(`Entity ${eid} fail to apply force, there may not be a collider on the entity`);
    }
};
const applyImpulse = (world, eid, impulseX, impulseY, impulseZ) => {
    if (!asm.applyImpulseToEntity(world._id, eid, impulseX, impulseY, impulseZ)) {
        throw new Error(`Entity ${eid} fail to apply impulse, there may not be a collider on the entity`);
    }
};
const applyTorque = (world, eid, torqueX, torqueY, torqueZ) => {
    if (!asm.applyTorqueToEntity(world._id, eid, torqueX, torqueY, torqueZ)) {
        throw new Error(`Entity ${eid} fail to apply torque, there may not be a collider on the entity`);
    }
};
const registerConvexShape = (world, vertices) => {
    const verticesPtr = asm._malloc(FLOAT_SIZE * vertices.length);
    asm.HEAPF32.set(vertices, verticesPtr / FLOAT_SIZE);
    const id = asm.registerConvexShape(world._id, verticesPtr, vertices.length);
    asm._free(verticesPtr);
    return id;
};
const unregisterConvexShape = (world, id) => {
    asm.unregisterConvexShape(world._id, id);
};
const physics = {
    setWorldGravity,
    getWorldGravity,
    applyForce,
    applyImpulse,
    applyTorque,
    setLinearVelocity,
    getLinearVelocity,
    registerConvexShape,
    unregisterConvexShape,
    COLLISION_START_EVENT,
    COLLISION_END_EVENT,
    ColliderShape,
};
export { physics, ColliderShape, Collider, };
