import { fill4x1, fill4x4, rotation4x4ToQuat } from './algorithms';
import { vec3 } from './vec3';
/// /////////////////// Unexported Internal Factories //////////////////////
const DEG_TO_RAD = Math.PI / 180;
const RAD_TO_DEG = 180 / Math.PI;
const clip = (value, min, max) => (Math.min(Math.max(value, min), max));
const SCRATCH_Q = []; // These are allocated after the definition of factory methods.
const SCRATCH_V = [vec3.zero(), vec3.zero(), vec3.zero()];
const SCRATCH_4 = [0, 0, 0, 0];
const SCRATCH_4X4 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
class QuatImpl {
    constructor() {
        this._data = [0, 0, 0, 1];
        Object.defineProperties(this, {
            _data: { enumerable: false },
            x: { enumerable: true, get: () => this._data[0] },
            y: { enumerable: true, get: () => this._data[1] },
            z: { enumerable: true, get: () => this._data[2] },
            w: { enumerable: true, get: () => this._data[3] },
        });
    }
    setXyzw(x, y, z, w) {
        this._data[0] = x;
        this._data[1] = y;
        this._data[2] = z;
        this._data[3] = w;
        return this;
    }
    setFrom(q) {
        return this.setXyzw(q.x, q.y, q.z, q.w);
    }
    clone() {
        return new QuatImpl().setFrom(this);
    }
    makeXRadians(radians) {
        const hr = radians * 0.5;
        return this.setXyzw(Math.sin(hr), 0, 0, Math.cos(hr));
    }
    makeXDegrees(degrees) {
        return this.makeXRadians(degrees * DEG_TO_RAD);
    }
    makeYRadians(radians) {
        const hr = radians * 0.5;
        return this.setXyzw(0, Math.sin(hr), 0, Math.cos(hr));
    }
    makeYDegrees(degrees) {
        return this.makeYRadians(degrees * DEG_TO_RAD);
    }
    makeZRadians(radians) {
        const hr = radians * 0.5;
        return this.setXyzw(0, 0, Math.sin(hr), Math.cos(hr));
    }
    makeZDegrees(degrees) {
        return this.makeZRadians(degrees * DEG_TO_RAD);
    }
    pitchYawRollRadians(target = vec3.zero()) {
        // Convert quaternion to y-x-z euler angles.
        const wx = this.w * this.x;
        const wy = this.w * this.y;
        const wz = this.w * this.z;
        const xx = this.x * this.x;
        const xy = this.x * this.y;
        const xz = this.x * this.z;
        const yy = this.y * this.y;
        const yz = this.y * this.z;
        const zz = this.z * this.z;
        const m00 = 1.0 - 2.0 * (yy + zz);
        const m02 = 2.0 * (xz + wy);
        const m10 = 2.0 * (xy + wz);
        const m11 = 1.0 - 2.0 * (xx + zz);
        const m12 = 2.0 * (yz - wx);
        const m20 = 2.0 * (xz - wy);
        const m22 = 1.0 - 2.0 * (xx + yy);
        const vx = Math.asin(Math.max(-1.0, Math.min(-m12, 1.0)));
        // If pitch is 1 or -1, the camera is facing straight up or straight down. In this plane, yaw
        // and roll are interchangeable and ambiguous. In this case, we resolve ties in favor of
        // yaw-only motion (no roll).
        const fullPitch = Math.abs(m12) < (1.0 - 1e-7);
        const vy = fullPitch ? Math.atan2(m02, m22) : Math.atan2(-m20, m00);
        const vz = fullPitch ? Math.atan2(m10, m11) : 0.0;
        return target.setXyz(vx, vy, vz);
    }
    pitchYawRollDegrees(out = vec3.zero()) {
        return this.pitchYawRollRadians(out).setScale(RAD_TO_DEG);
    }
    axisAngle(target = vec3.zero()) {
        // For quaternions representing non-zero rotation, the conversion is numerically stable.
        const sinSquared = this.x * this.x + this.y * this.y + this.z * this.z;
        if (sinSquared > 0) {
            const sinTheta = Math.sqrt(sinSquared);
            const k = (2 * Math.atan2(sinTheta, this.w)) / sinTheta;
            return target.setXyz(this.x * k, this.y * k, this.z * k);
        }
        // If sinSquared is 0, then we will get NaNs when dividing by sinTheta.  By approximating with a
        // Taylor series, and truncating at one term, the value will be computed correctly.
        return target.setXyz(this.x * 2.0, this.y * 2.0, this.z * 2.0);
    }
    setConjugate() {
        this._data[0] = -this._data[0];
        this._data[1] = -this._data[1];
        this._data[2] = -this._data[2];
        return this;
    }
    conjugate() {
        return this.clone().setConjugate();
    }
    squaredNorm() {
        return this.x * this.x + this.y * this.y + this.z * this.z + this.w * this.w;
    }
    setNegate() {
        this._data[0] = -this._data[0];
        this._data[1] = -this._data[1];
        this._data[2] = -this._data[2];
        this._data[3] = -this._data[3];
        return this;
    }
    negate() {
        return this.clone().setNegate();
    }
    norm() {
        return Math.sqrt(this.squaredNorm());
    }
    setNormalize() {
        const n = this.norm();
        const invN = n > 0 ? 1 / n : 1;
        this._data[0] *= invN;
        this._data[1] *= invN;
        this._data[2] *= invN;
        this._data[3] *= invN;
        return this;
    }
    normalize() {
        return this.clone().setNormalize();
    }
    setInv() {
        return this.setConjugate().setNormalize();
    }
    inv() {
        return this.clone().setInv();
    }
    setPlus(q) {
        this._data[0] += q.x;
        this._data[1] += q.y;
        this._data[2] += q.z;
        this._data[3] += q.w;
        return this.setNormalize();
    }
    plus(q) {
        return this.clone().setPlus(q);
    }
    setTimes(q) {
        return this.setXyzw(this.w * q.x + this.x * q.w + this.y * q.z - this.z * q.y, this.w * q.y - this.x * q.z + this.y * q.w + this.z * q.x, this.w * q.z + this.x * q.y - this.y * q.x + this.z * q.w, this.w * q.w - this.x * q.x - this.y * q.y - this.z * q.z).setNormalize();
    }
    times(q) {
        return this.clone().setTimes(q);
    }
    timesVec(v, target = vec3.zero()) {
        const { x, y, z, w } = this;
        const x2 = x + x;
        const y2 = y + y;
        const z2 = z + z;
        const wx2 = w * x2;
        const wy2 = w * y2;
        const wz2 = w * z2;
        const xx2 = x * x2;
        const xy2 = x * y2;
        const xz2 = x * z2;
        const yy2 = y * y2;
        const yz2 = y * z2;
        const zz2 = z * z2;
        return target.setXyz(v.x * (1 - (yy2 + zz2)) + v.y * (xy2 - wz2) + v.z * (xz2 + wy2), v.x * (xy2 + wz2) + v.y * (1 - (xx2 + zz2)) + v.z * (yz2 - wx2), v.x * (xz2 - wy2) + v.y * (yz2 + wx2) + v.z * (1 - (xx2 + yy2)));
    }
    setPremultiply(q) {
        return this.setXyzw(q.w * this.x + q.x * this.w + q.y * this.z - q.z * this.y, q.w * this.y - q.x * this.z + q.y * this.w + q.z * this.x, q.w * this.z + q.x * this.y - q.y * this.x + q.z * this.w, q.w * this.w - q.x * this.x - q.y * this.y - q.z * this.z).setNormalize();
    }
    dot(q) {
        return this.x * q.x + this.y * q.y + this.z * q.z + this.w * q.w;
    }
    radiansTo(q) {
        return Math.acos(2 * (clip(this.dot(q), -1, 1) ** 2) - 1);
    }
    degreesTo(q) {
        return this.radiansTo(q) * RAD_TO_DEG;
    }
    setDelta(target) {
        return this.setInv().setPremultiply(target);
    }
    delta(target) {
        return this.clone().setDelta(target);
    }
    setSlerp(target, t) {
        this.setNormalize();
        const a = this;
        const b = SCRATCH_Q[0].setFrom(target).setNormalize();
        let dp = a.dot(b);
        if (dp < 0.0) {
            b.setNegate();
            dp *= -1.0;
        }
        if (dp > 0.995) {
            return this.setXyzw(a.x + t * (b.x - a.x), a.y + t * (b.y - a.y), a.z + t * (b.z - a.z), a.w + t * (b.w - a.w)).setNormalize();
        }
        const sqrSinHalfTheta = 1.0 - dp * dp;
        const sinHalfTheta = Math.sqrt(sqrSinHalfTheta);
        const r0 = Math.atan2(sinHalfTheta, dp);
        const sr0 = Math.sin(r0);
        const r = t * r0;
        const sr = Math.sin(r);
        const ta = Math.cos(r) - (dp * sr) / sr0;
        const tb = sr / sr0;
        return this.setXyzw(ta * a.x + tb * b.x, ta * a.y + tb * b.y, ta * a.z + tb * b.z, ta * a.w + tb * b.w).setNormalize();
    }
    slerp(target, t) {
        return this.clone().setSlerp(target, t);
    }
    setRotateToward(target, rads) {
        const total = this.radiansTo(target);
        const deltaRads = clip(0, rads, total);
        return this.setSlerp(target, deltaRads / total);
    }
    rotateToward(target, rads) {
        return this.clone().setRotateToward(target, rads);
    }
    equals(q, tolerance) {
        return (Math.abs(this.x - q.x) <= tolerance &&
            Math.abs(this.y - q.y) <= tolerance &&
            Math.abs(this.z - q.z) <= tolerance &&
            Math.abs(this.w - q.w) <= tolerance);
    }
    // Create a Quat from an axis-angle representation.
    makeAxisAngle(aa) {
        const a0 = aa.x;
        const a1 = aa.y;
        const a2 = aa.z;
        const thetaSquared = a0 * a0 + a1 * a1 + a2 * a2;
        // For points not at the origin, the full conversion is numerically stable.
        if (thetaSquared > 0.0) {
            const theta = Math.sqrt(thetaSquared);
            const halfTheta = theta * 0.5;
            const k = Math.sin(halfTheta) / theta;
            return this.setXyzw(a0 * k, a1 * k, a2 * k, Math.cos(halfTheta));
        }
        // If thetaSquared is 0, then we will get NaNs when dividing by theta.  By approximating with a
        // Taylor series, and truncating at one term, the value will be computed correctly.
        const k = 0.5;
        return this.setXyzw(a0 * k, a1 * k, a2 * k, 1.0);
    }
    makeLookAt(eye, target, up) {
        const dir = SCRATCH_V[0].setFrom(target).setMinus(eye);
        if (dir.length() === 0) {
            dir.setXyz(0, 0, 1); // eye and target are in the same position
        }
        dir.setNormalize();
        const cross = SCRATCH_V[1].setFrom(up).setCross(dir);
        if (cross.length() === 0) {
            // up and z are parallel
            if (Math.abs(up.z) === 1) {
                dir.setPlus(SCRATCH_V[2].setXyz(0.0001, 0, 0)).setNormalize();
            }
            else {
                dir.setPlus(SCRATCH_V[2].setXyz(0, 0, 0.0001)).setNormalize();
            }
            cross.setFrom(up).setCross(dir);
        }
        cross.setNormalize();
        const cross2 = SCRATCH_V[2].setFrom(dir).setCross(cross);
        const q = fill4x1(SCRATCH_4, 0, 0, 0, 1);
        rotation4x4ToQuat(fill4x4(SCRATCH_4X4, cross.x, cross.y, cross.z, 0, cross2.x, cross2.y, cross2.z, 0, dir.x, dir.y, dir.z, 0, 0, 0, 0, 1), q);
        return this.setXyzw(q[0], q[1], q[2], q[3]);
    }
    // Construct a quaternion from a pitch / yaw / roll representation, also known as YXZ euler
    // angles.
    makePitchYawRollRadians(v) {
        return this.makeYRadians(v.y)
            .setTimes(SCRATCH_Q[0].makeXRadians(v.x))
            .setTimes(SCRATCH_Q[0].makeZRadians(v.z));
    }
    makePitchYawRollDegrees(v) {
        return this.makePitchYawRollRadians(SCRATCH_V[0].setFrom(v).setScale(DEG_TO_RAD));
    }
    // Create a Quat which represents a zero rotation.
    makeZero() {
        return this.setXyzw(0, 0, 0, 1);
    }
    data() {
        return this._data;
    }
}
const newQuat = () => new QuatImpl();
/// /////////////////// Exported External Factories //////////////////////
const quat = {
    axisAngle: (aa, target = newQuat()) => target.makeAxisAngle(aa),
    lookAt: (eye, target, up) => (newQuat().makeLookAt(eye, target, up)),
    from: (source) => (newQuat().setXyzw(source.x, source.y, source.z, source.w)),
    pitchYawRollDegrees: (v) => newQuat().makePitchYawRollDegrees(v),
    pitchYawRollRadians: (v) => newQuat().makePitchYawRollRadians(v),
    xDegrees: (degrees) => newQuat().makeXDegrees(degrees),
    xRadians: (radians) => newQuat().makeXRadians(radians),
    xyzw: (x, y, z, w) => (newQuat().setXyzw(x, y, z, w)),
    yDegrees: (degrees) => newQuat().makeYDegrees(degrees),
    yRadians: (radians) => newQuat().makeYRadians(radians),
    zDegrees: (degrees) => newQuat().makeZDegrees(degrees),
    zRadians: (radians) => newQuat().makeZRadians(radians),
    zero: () => newQuat().makeZero(),
};
SCRATCH_Q.push(new QuatImpl());
export { quat, };
