import { asm } from './asm';
import { ENTITY_ID_SIZE } from './constants';
function* extractListData(listPtr, count, alignment) {
    // NOTE(christoph): Wasm pointers are 32 bit.
    const ptr = asm.dataView.getUint32(listPtr, true);
    for (let i = 0; i < count; i++) {
        yield asm.dataView.getUint32(ptr + alignment * i, true);
    }
}
function* extractEids(listPtr, count) {
    // NOTE(christoph): Wasm pointers are 32 bit.
    const ptr = asm.dataView.getUint32(listPtr, true);
    for (let i = 0; i < count; i++) {
        yield asm.dataView.getBigInt64(ptr + i * ENTITY_ID_SIZE, true);
    }
}
export { extractListData, extractEids, };
