import THREE from '../three';
import { assets } from '../assets';
import { getGltfLoader } from '../loaders';
import { makeSystemHelper } from './system-helper';
import { GltfModel, Shadow } from '../components';
import { events } from '../event-ids';
import { getGltfVertices } from '../get-gltf-vertices';
import { Collider, physics } from '../physics';
import { PLAY_ALL_ANIMATIONS_KEY } from '../../shared/gltf-constants';
const playAction = (action, loop, repetitions, reverse, paused, timeScale) => {
    if (loop) {
        const animReps = repetitions > 0 ? repetitions : Infinity;
        if (reverse) {
            // Double the internal reps so that full ping-pong cycle is completed in one repetition
            action.setLoop(THREE.LoopPingPong, animReps * 2);
            action.clampWhenFinished = false;
        }
        else {
            action.setLoop(THREE.LoopRepeat, animReps);
            action.clampWhenFinished = true;
        }
    }
    else {
        action.setLoop(THREE.LoopOnce, 1);
        action.clampWhenFinished = true;
    }
    action.timeScale = timeScale;
    action.paused = paused;
    if (!action.isRunning()) {
        action.play();
    }
};
const makeGltfSystem = (world) => {
    const { enter, changed, exit } = makeSystemHelper(GltfModel);
    const mixers = new Map();
    const loadingPromises = new Map();
    const clearModel = (eid) => {
        const object = world.three.entityToObject.get(eid);
        if (!object) {
            return;
        }
        object.children.forEach((child) => {
            if (child.userData.gltf) {
                object.remove(child);
            }
        });
        if (object.userData) {
            object.userData.model = null;
            object.userData.gltf = null;
        }
        loadingPromises.delete(eid);
    };
    const animationFinishedEvent = (event) => {
        const { eid } = event.action.getRoot().userData;
        world.events.dispatch(eid, events.GLTF_ANIMATION_FINISHED, { name: event.action.getClip().name });
    };
    const animationLoopEvent = (event) => {
        const { eid } = event.action.getRoot().userData;
        world.events.dispatch(eid, events.GLTF_ANIMATION_LOOP, { name: event.action.getClip().name });
    };
    const updateAnimation = (eid, gltf, model, object) => {
        // note(alan): if changed gltf-model.tsx needs to be updated as well
        const { animationClip, loop, paused, time, timeScale, reverse, repetitions, crossFadeDuration, } = model;
        let mixer = mixers.get(eid);
        if (!animationClip) {
            if (mixer) {
                mixer.stopAllAction();
                mixers.delete(eid);
            }
            return;
        }
        if (!mixer) {
            mixer = new THREE.AnimationMixer(gltf.scene);
            mixers.set(eid, mixer);
            mixer.addEventListener('finished', animationFinishedEvent);
            mixer.addEventListener('loop', animationLoopEvent);
        }
        if (animationClip === PLAY_ALL_ANIMATIONS_KEY) {
            if (object.userData.animationClip !== PLAY_ALL_ANIMATIONS_KEY) {
                mixer.setTime(0);
                mixer.stopAllAction();
            }
            gltf.animations.forEach((clip) => {
                const action = mixer.clipAction(clip);
                playAction(action, loop, repetitions, reverse, paused, timeScale);
            });
            if (object.userData.time !== time) {
                mixer.setTime(time);
                object.userData.time = time;
            }
            return;
        }
        const clip = THREE.AnimationClip.findByName(gltf.animations, animationClip);
        if (!clip) {
            return;
        }
        const clipChanged = object.userData.animationClip !== clip;
        const previousAction = mixer.existingAction(object.userData.animationClip);
        const action = mixer.clipAction(clip);
        const crossFade = crossFadeDuration && previousAction && previousAction !== action;
        if (clipChanged) {
            action.reset();
            object.userData.animationClip = clip;
        }
        if (clipChanged && !crossFade) {
            mixer.setTime(0);
            mixer.stopAllAction();
        }
        playAction(action, loop, repetitions, reverse, paused, timeScale);
        if (crossFade) {
            // 'warp' (transitioning timescale as part of crossfade) is generally the best option when
            // transitioning between two looped animations
            const warp = previousAction.isRunning() &&
                previousAction.loop !== THREE.LoopOnce && action.loop !== THREE.LoopOnce;
            action.crossFadeFrom(previousAction, crossFadeDuration, warp);
        }
        // Changes to time within the mixer make no sense if we're starting a crossfade
        if (!crossFade && (clipChanged || object.userData.time !== time)) {
            mixer.setTime(time);
            object.userData.time = time;
        }
    };
    const applyModel = (eid) => {
        const object = world.three.entityToObject.get(eid);
        if (!object) {
            return;
        }
        const { url } = GltfModel.get(world, eid);
        if (!url) {
            return;
        }
        const promise = new Promise((resolve, reject) => {
            assets.load({ url }).then(async (asset) => {
                getGltfLoader().parse(
                // NOTE(christoph): We load the main asset using the already loaded blob, but for
                // secondary relative assets like gltf -> png, we specify the remoteUrl to load from.
                await asset.data.arrayBuffer(), asset.remoteUrl?.replace(/\/[^/]*$/, '/') ?? '/', (gltf) => {
                    if (loadingPromises.get(eid) !== promise) {
                        return resolve();
                    }
                    let shadowConfig;
                    if (Shadow.has(world, eid)) {
                        shadowConfig = Shadow.get(world, eid);
                    }
                    gltf.scene.traverse((node) => {
                        if (shadowConfig && node.isObject3D) {
                            node.castShadow = !!shadowConfig.castShadow;
                            node.receiveShadow = !!shadowConfig.receiveShadow;
                        }
                        if (node instanceof THREE.Mesh) {
                            node.geometry.computeBoundsTree();
                        }
                    });
                    const model = GltfModel.get(world, eid);
                    object.userData.url = url;
                    object.userData.gltf = gltf;
                    gltf.scene.userData.gltf = true;
                    gltf.scene.userData.eid = eid;
                    object.userData.collider = model.collider;
                    object.add(gltf.scene);
                    world.events.dispatch(eid, events.GLTF_MODEL_LOADED, { model: gltf.scene });
                    updateAnimation(eid, gltf, model, object);
                    if (model.collider) {
                        const shapeId = physics.registerConvexShape(world, getGltfVertices(gltf.scene));
                        Collider.set(world, eid, { shape: shapeId });
                    }
                    return resolve();
                }, reject);
            }).catch(reject);
        });
        loadingPromises.set(eid, promise);
    };
    const updateMixers = (deltaTime) => {
        const deltaTimeInSeconds = deltaTime / 1000;
        mixers.forEach((mixer) => {
            mixer.update(deltaTimeInSeconds);
        });
    };
    const handleChanged = (eid) => {
        const newModel = GltfModel.get(world, eid);
        const object = world.three.entityToObject.get(eid);
        const oldModelUrl = object?.userData.url;
        const oldCollider = object?.userData.collider;
        if (oldModelUrl && newModel && newModel.url === oldModelUrl &&
            newModel.collider === oldCollider) {
            updateAnimation(eid, object.userData.gltf, newModel, object);
        }
        else {
            clearModel(eid);
            applyModel(eid);
        }
    };
    return () => {
        exit(world).forEach((eid) => {
            clearModel(eid);
            loadingPromises.delete(eid);
        });
        enter(world).forEach(applyModel);
        changed(world).forEach(handleChanged);
        updateMixers(world.time.delta);
    };
};
export { makeGltfSystem, };
