import { FONTS } from './fonts';
const extractResourceUrl = (resource) => {
    if (!resource) {
        return '';
    }
    if (typeof resource === 'string') {
        return resource;
    }
    if (resource.type === 'url') {
        return resource.url;
    }
    if (resource.type === 'asset') {
        return resource.asset;
    }
    throw new Error('Unexpected resource type');
};
const inferFontResourceObject = (resource) => {
    if (typeof resource === 'string') {
        if (resource.startsWith('assets/')) {
            return { type: 'asset', asset: resource };
        }
        else if (FONTS.has(resource)) {
            return { type: 'font', font: resource };
        }
        else {
            return { type: 'url', url: resource };
        }
    }
    return resource;
};
const extractFontResourceUrl = (resource) => {
    if (resource.type === 'font') {
        return resource.font;
    }
    return extractResourceUrl(resource);
};
// Upgrade a string resource into a Resource object type url or asset
const inferResourceObject = (resource) => {
    if (typeof resource === 'string') {
        if (resource.startsWith('assets/')) {
            return { type: 'asset', asset: resource };
        }
        else {
            return { type: 'url', url: resource };
        }
    }
    return resource;
};
export { extractResourceUrl, inferResourceObject, inferFontResourceObject, extractFontResourceUrl, };
