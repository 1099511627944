import THREE from '../three';
import { makeSystemHelper } from './system-helper';
const makeGeometrySystem = (world, component, geometryProvider) => {
    const worldComponent = component.forWorld(world);
    const { enter, changed, exit } = makeSystemHelper(component);
    const emptyGeometry = new THREE.BufferGeometry();
    const applyGeometry = (eid) => {
        const object = world.three.entityToObject.get(eid);
        if (object instanceof THREE.Mesh) {
            const data = worldComponent.get(eid);
            object.geometry = geometryProvider(data);
            object.geometry.computeBoundsTree();
        }
    };
    const removeGeometry = (eid) => {
        const object = world.three.entityToObject.get(eid);
        if (object instanceof THREE.Mesh) {
            // TODO(christoph): Technically switching from one geometry to another would
            // be clobbered here
            object.geometry = emptyGeometry;
        }
    };
    return () => {
        exit(world).forEach(removeGeometry);
        enter(world).forEach(applyGeometry);
        changed(world).forEach(applyGeometry);
    };
};
export { makeGeometrySystem, };
