/* eslint-disable camelcase */
// @TODO: Get rid of non camelcase uniforms
import Defaults from '../../utils/Defaults';
import { backgroundFragment } from '../shaders/background-fragment';
import { backgroundVertex } from '../shaders/background-vertex';
import { textFragment } from '../shaders/text-fragment';
import { textVertex } from '../shaders/text-vertex';
import FontLibrary from './FontLibrary';
import * as BackgroundSize from '../../utils/background-size';
// @ts-ignore
const { ShaderMaterial, Vector2 } = window.THREE;
// NOTE (tri) switch this to true to have named shaders
const DEBUG_SHADERS = false;
/**

Job:
- Host the materials of a given component.
- Update a component's materials clipping planes.
- Update a material uniforms and such.

Knows:
- Its component materials.
- Its component ancestors clipping planes.

 */
function MaterialManager(Base) {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    return class MaterialManager extends Base {
        constructor(options) {
            super(options);
            this.backgroundUniforms = {
                u_texture: { value: this.getBackgroundTexture() },
                u_color: { value: this.getBackgroundColor() },
                u_opacity: { value: this.getBackgroundOpacity() },
                u_backgroundMapping: { value: this.getBackgroundSize() },
                u_borderWidth: { value: this.getBorderWidth() },
                u_borderColor: { value: this.getBorderColor() },
                u_borderRadiusTopLeft: { value: this.getBorderRadius() },
                u_borderRadiusTopRight: { value: this.getBorderRadius() },
                u_borderRadiusBottomRight: { value: this.getBorderRadius() },
                u_borderRadiusBottomLeft: { value: this.getBorderRadius() },
                u_borderOpacity: { value: this.getBorderOpacity() },
                u_size: { value: new Vector2(1, 1) },
                u_tSize: { value: new Vector2(1, 1) },
            };
        }
        /**
         * Update backgroundMaterial uniforms.
         * Used within MaterialManager and in Block and InlineBlock innerUpdates.
         */
        updateBackgroundMaterial() {
            this.backgroundUniforms.u_texture.value = this.getBackgroundTexture();
            this.backgroundUniforms.u_tSize.value.set(this.backgroundUniforms.u_texture.value.image.width, this.backgroundUniforms.u_texture.value.image.height);
            if (this.size)
                this.backgroundUniforms.u_size.value.copy(this.size);
            // @ts-ignore
            if (this.backgroundUniforms.u_texture.value.isDefault) {
                this.backgroundUniforms.u_color.value = this.getBackgroundColor();
                this.backgroundUniforms.u_opacity.value = this.getBackgroundOpacity();
            }
            else {
                this.backgroundUniforms.u_color.value =
                    this.backgroundColor || Defaults.backgroundWhiteColor;
                this.backgroundUniforms.u_opacity.value =
                    (!this.backgroundOpacity && this.backgroundOpacity !== 0)
                        ? Defaults.backgroundOpaqueOpacity
                        : this.backgroundOpacity;
            }
            this.backgroundUniforms.u_backgroundMapping.value =
                BackgroundSize.convertBackgroundSizeToUniform(this.getBackgroundSize());
            const borderRadius = this.getBorderRadius();
            this.backgroundUniforms.u_borderWidth.value = this.getBorderWidth();
            this.backgroundUniforms.u_borderColor.value = this.getBorderColor();
            this.backgroundUniforms.u_borderOpacity.value = this.getBorderOpacity();
            //
            if (Array.isArray(borderRadius)) {
                [this.backgroundUniforms.u_borderRadiusTopLeft.value,
                    this.backgroundUniforms.u_borderRadiusTopRight.value,
                    this.backgroundUniforms.u_borderRadiusBottomRight.value,
                    this.backgroundUniforms.u_borderRadiusBottomLeft.value] = borderRadius;
            }
            else {
                this.backgroundUniforms.u_borderRadiusTopLeft.value = borderRadius;
                this.backgroundUniforms.u_borderRadiusTopRight.value = borderRadius;
                this.backgroundUniforms.u_borderRadiusBottomRight.value = borderRadius;
                this.backgroundUniforms.u_borderRadiusBottomLeft.value = borderRadius;
            }
        }
        /**
         * Update fontMaterials uniforms.
         * Used within MaterialManager and in Text innerUpdates.
         */
        updateTextMaterials() {
            // Remake the materials if the number of textures changed
            // This is necessary for some applications that recycle meshes and pass new parameters
            const urls = FontLibrary.getFontTextureUrls(this);
            const textUniformsKeys = Object.keys(this.textUniforms ?? {});
            const { textUniforms } = this;
            if (textUniforms === undefined ||
                urls.size !== textUniformsKeys.length ||
                textUniformsKeys.some(url => !urls.has(url))) {
                this.fontMaterials = this._makeTextMaterials();
            }
            else {
                urls.forEach((url) => {
                    textUniforms[url].u_texture.value = FontLibrary.getFontTextureFromUrl(url);
                    textUniforms[url].u_color.value = this.getFontColor();
                    textUniforms[url].u_opacity.value = this.getFontOpacity();
                    textUniforms[url].u_pxRange.value = this.getFontPXRange();
                    textUniforms[url].u_useRGSS.value = this.getFontSupersampling();
                });
            }
        }
        /** Called by Block, which needs the background material to create a mesh */
        getBackgroundMaterial() {
            if (!this.backgroundMaterial || !this.backgroundUniforms) {
                this.backgroundMaterial = this._makeBackgroundMaterial();
            }
            return this.backgroundMaterial;
        }
        /** Called by Text to get the font material */
        getFontMaterials() {
            const urls = FontLibrary.getFontTextureUrls(this);
            const textUniformsKeys = Object.keys(this.textUniforms ?? {});
            if (!this.fontMaterials || !this.textUniforms || urls.size !== textUniformsKeys.length ||
                textUniformsKeys.some(url => !urls.has(url))) {
                this.fontMaterials = this._makeTextMaterials();
            }
            return this.fontMaterials;
        }
        /** @private */
        _makeTextMaterials() {
            const fontTextureUrls = Array.from(FontLibrary.getFontTextureUrls(this));
            this.textUniforms = fontTextureUrls.reduce((acc, url) => {
                acc[url] = {
                    u_texture: { value: FontLibrary.getFontTextureFromUrl(url) },
                    u_color: { value: this.getFontColor() },
                    u_opacity: { value: this.getFontOpacity() },
                    u_pxRange: { value: this.getFontPXRange() },
                    u_useRGSS: { value: this.getFontSupersampling() },
                };
                return acc;
            }, {});
            return fontTextureUrls.reduce((acc, url) => {
                acc[url] = new ShaderMaterial({
                    uniforms: this.textUniforms[url],
                    ...(DEBUG_SHADERS ? { name: url } : {}),
                    transparent: true,
                    clipping: true,
                    vertexShader: textVertex,
                    fragmentShader: textFragment,
                    extensions: {
                        derivatives: true,
                    },
                });
                return acc;
            }, {});
        }
        /** @private */
        _makeBackgroundMaterial() {
            return new ShaderMaterial({
                uniforms: this.backgroundUniforms,
                transparent: true,
                clipping: true,
                vertexShader: backgroundVertex,
                fragmentShader: backgroundFragment,
                extensions: {
                    derivatives: true,
                },
                // Turning off z-buffer write prevents z-fighting with objects rendered before this
                // This is sometimes necessary because the z-buffer doesn't work as expected, maybe because
                // the material is transparent
                depthWrite: this.getBackgroundDepthWrite(),
            });
        }
        /**
         * Update a component's materials clipping planes.
         * Called every frame.
         */
        updateClippingPlanes(value) {
            const newClippingPlanes = value !== undefined ? value : this.getClippingPlanes();
            if (JSON.stringify(newClippingPlanes) !== JSON.stringify(this.clippingPlanes)) {
                this.clippingPlanes = newClippingPlanes;
                if (this.fontMaterials) {
                    Object.values(this.fontMaterials).forEach((material) => {
                        material.clippingPlanes = this.clippingPlanes;
                    });
                }
                if (this.backgroundMaterial)
                    this.backgroundMaterial.clippingPlanes = this.clippingPlanes;
            }
        }
    };
}
export default MaterialManager;
