const STRETCH = 'stretch';
const CONTAIN = 'contain';
const COVER = 'cover';
const convertBackgroundSizeToUniform = (size) => {
    switch (size) {
        case STRETCH:
            return 0;
        case CONTAIN:
            return 1;
        case COVER:
            return 2;
        default:
            throw new Error(`Unknown background size: ${size}`);
    }
};
export { STRETCH, CONTAIN, COVER, convertBackgroundSizeToUniform, };
