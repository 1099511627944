import * as Types from './types';
import { registerAttribute } from './registry';
import { LIGHT_DEFAULTS } from '../shared/light-constants';
import { DEFAULT_SHADOW_OPACITY, MATERIAL_DEFAULTS } from '../shared/material-constants';
import { UI_DEFAULTS } from '../shared/ui-constants';
const Vector3 = { x: Types.f32, y: Types.f32, z: Types.f32 };
const Vector4 = { x: Types.f32, y: Types.f32, z: Types.f32, w: Types.f32 };
const Position = registerAttribute('position', Vector3);
const Scale = registerAttribute('scale', Vector3);
const Quaternion = registerAttribute('quaternion', Vector4);
const ThreeObject = registerAttribute('three-object', { order: Types.i32 });
const Material = registerAttribute('material', {
    r: Types.ui8,
    g: Types.ui8,
    b: Types.ui8,
    textureSrc: Types.string,
    roughness: Types.f32,
    metalness: Types.f32,
    opacity: Types.f32,
    roughnessMap: Types.string,
    metalnessMap: Types.string,
    side: Types.string,
    normalScale: Types.f32,
    emissiveIntensity: Types.f32,
    emissiveR: Types.ui8,
    emissiveG: Types.ui8,
    emissiveB: Types.ui8,
    opacityMap: Types.string,
    normalMap: Types.string,
    emissiveMap: Types.string,
    blending: Types.string,
    repeatX: Types.f32,
    repeatY: Types.f32,
    offsetX: Types.f32,
    offsetY: Types.f32,
    depthTest: Types.boolean,
    depthWrite: Types.boolean,
    wireframe: Types.boolean,
    forceTransparent: Types.boolean,
}, MATERIAL_DEFAULTS);
const UnlitMaterial = registerAttribute('unlit-material', {
    r: Types.ui8,
    g: Types.ui8,
    b: Types.ui8,
    textureSrc: Types.string,
    opacity: Types.f32,
    side: Types.string,
    opacityMap: Types.string,
    blending: Types.string,
    repeatX: Types.f32,
    repeatY: Types.f32,
    offsetX: Types.f32,
    offsetY: Types.f32,
    depthTest: Types.boolean,
    depthWrite: Types.boolean,
    wireframe: Types.boolean,
    forceTransparent: Types.boolean,
}, {
    opacity: MATERIAL_DEFAULTS.opacity,
    side: MATERIAL_DEFAULTS.side,
    blending: MATERIAL_DEFAULTS.blending,
    repeatX: MATERIAL_DEFAULTS.repeatX,
    repeatY: MATERIAL_DEFAULTS.repeatY,
    offsetX: MATERIAL_DEFAULTS.offsetX,
    offsetY: MATERIAL_DEFAULTS.offsetY,
    depthTest: MATERIAL_DEFAULTS.depthTest,
    depthWrite: MATERIAL_DEFAULTS.depthWrite,
    wireframe: MATERIAL_DEFAULTS.wireframe,
});
const ShadowMaterial = registerAttribute('shadow-material', {
    r: Types.ui8,
    g: Types.ui8,
    b: Types.ui8,
    opacity: Types.f32,
    side: Types.string,
    depthTest: Types.boolean,
    depthWrite: Types.boolean,
}, {
    opacity: DEFAULT_SHADOW_OPACITY,
    side: MATERIAL_DEFAULTS.side,
    depthTest: MATERIAL_DEFAULTS.depthTest,
    depthWrite: MATERIAL_DEFAULTS.depthWrite,
});
const HiderMaterial = registerAttribute('hider-material', {});
const GltfModel = registerAttribute('gltf-model', {
    url: Types.string,
    animationClip: Types.string,
    loop: Types.boolean,
    paused: Types.boolean,
    time: Types.f32,
    timeScale: Types.f32,
    collider: Types.boolean,
    reverse: Types.boolean,
    repetitions: Types.ui32,
    crossFadeDuration: Types.f32,
}, {
    timeScale: 1,
});
const Splat = registerAttribute('splat', {
    url: Types.string,
});
const SphereGeometry = registerAttribute('sphere-geometry', { radius: Types.f32 });
const BoxGeometry = registerAttribute('box-geometry', {
    width: Types.f32,
    height: Types.f32,
    depth: Types.f32,
});
const PlaneGeometry = registerAttribute('plane-geometry', {
    width: Types.f32,
    height: Types.f32,
});
const CapsuleGeometry = registerAttribute('capsule-geometry', {
    radius: Types.f32,
    height: Types.f32,
});
const ConeGeometry = registerAttribute('cone-geometry', {
    radius: Types.f32,
    height: Types.f32,
});
const CylinderGeometry = registerAttribute('cylinder-geometry', {
    radius: Types.f32,
    height: Types.f32,
});
const TetrahedronGeometry = registerAttribute('tetrahedron-geometry', {
    radius: Types.f32,
});
const PolyhedronGeometry = registerAttribute('polyhedron-geometry', {
    faces: Types.ui8,
    radius: Types.f32,
});
const CircleGeometry = registerAttribute('circle-geometry', {
    radius: Types.f32,
});
const RingGeometry = registerAttribute('ring-geometry', {
    innerRadius: Types.f32,
    outerRadius: Types.f32,
});
const TorusGeometry = registerAttribute('torus-geometry', {
    radius: Types.f32,
    tubeRadius: Types.f32,
});
const FaceGeometry = registerAttribute('face-geometry', {});
const Hidden = registerAttribute('hidden');
const Shadow = registerAttribute('shadow', {
    castShadow: Types.boolean,
    receiveShadow: Types.boolean,
});
const Ui = registerAttribute('ui', {
    type: Types.string,
    font: Types.string,
    fontSize: Types.f32,
    position: Types.string,
    opacity: Types.f32,
    backgroundOpacity: Types.f32,
    backgroundSize: Types.string,
    background: Types.string,
    color: Types.string,
    text: Types.string,
    image: Types.string,
    fixedSize: Types.boolean,
    width: Types.string,
    height: Types.string,
    top: Types.string,
    left: Types.string,
    bottom: Types.string,
    right: Types.string,
    borderColor: Types.string,
    borderRadius: Types.f32,
    // FlexStyles
    alignContent: Types.string,
    alignItems: Types.string,
    alignSelf: Types.string,
    borderWidth: Types.f32,
    columnGap: Types.string,
    direction: Types.string,
    display: Types.string,
    flex: Types.f32,
    flexBasis: Types.string,
    flexDirection: Types.string,
    flexGrow: Types.f32,
    flexShrink: Types.f32,
    flexWrap: Types.string,
    gap: Types.string,
    justifyContent: Types.string,
    margin: Types.string,
    marginBottom: Types.string,
    marginLeft: Types.string,
    marginRight: Types.string,
    marginTop: Types.string,
    maxHeight: Types.string,
    maxWidth: Types.string,
    minHeight: Types.string,
    minWidth: Types.string,
    overflow: Types.string,
    padding: Types.string,
    paddingBottom: Types.string,
    paddingLeft: Types.string,
    paddingRight: Types.string,
    paddingTop: Types.string,
    rowGap: Types.string,
    textAlign: Types.string,
}, {
    ...UI_DEFAULTS,
    font: UI_DEFAULTS.font.font,
});
// TODO(Julie): Add more configurable properties/options
const Audio = registerAttribute('audio', {
    url: Types.string,
    volume: Types.f32,
    loop: Types.boolean,
    paused: Types.boolean,
    pitch: Types.f32,
    positional: Types.boolean,
    refDistance: Types.f32,
    rolloffFactor: Types.f32,
    distanceModel: Types.string,
    maxDistance: Types.f32,
}, {
    volume: 1,
    pitch: 1,
    maxDistance: 10000,
});
const Light = registerAttribute('light', {
    type: Types.string,
    r: Types.ui8,
    g: Types.ui8,
    b: Types.ui8,
    intensity: Types.f32,
    castShadow: Types.boolean,
    targetX: Types.f32,
    targetY: Types.f32,
    targetZ: Types.f32,
    shadowNormalBias: Types.f32,
    shadowBias: Types.f32,
    shadowAutoUpdate: Types.boolean,
    shadowBlurSamples: Types.ui32,
    shadowRadius: Types.f32,
    shadowMapSizeHeight: Types.i32,
    shadowMapSizeWidth: Types.i32,
    shadowCameraNear: Types.f32,
    shadowCameraFar: Types.f32,
    shadowCameraLeft: Types.f32,
    shadowCameraRight: Types.f32,
    shadowCameraTop: Types.f32,
    shadowCameraBottom: Types.f32,
    distance: Types.f32,
    decay: Types.f32,
    followCamera: Types.boolean,
}, LIGHT_DEFAULTS);
const Camera = registerAttribute('camera', {
    type: Types.string,
    fov: Types.f32,
    zoom: Types.f32,
    left: Types.i32,
    right: Types.i32,
    top: Types.i32,
    bottom: Types.i32,
    xrCameraType: Types.string,
    phone: Types.string,
    desktop: Types.string,
    headset: Types.string,
    nearClip: Types.f32,
    farClip: Types.f32,
    leftHandedAxes: Types.boolean,
    uvType: Types.string,
    direction: Types.string,
    // World
    disableWorldTracking: Types.boolean,
    enableLighting: Types.boolean,
    enableWorldPoints: Types.boolean,
    scale: Types.string,
    enableVps: Types.boolean,
    // Face
    mirroredDisplay: Types.boolean,
    meshGeometryFace: Types.boolean,
    meshGeometryEyes: Types.boolean,
    meshGeometryIris: Types.boolean,
    meshGeometryMouth: Types.boolean,
    enableEars: Types.boolean,
    maxDetections: Types.i32,
}, {
    type: 'perspective',
    fov: 80,
    zoom: 1,
    left: -1,
    right: 1,
    top: 1,
    bottom: -1,
    xrCameraType: '3dOnly',
    phone: '3D',
    desktop: '3D',
    headset: '3D',
    nearClip: 0.1,
    farClip: 2000,
    direction: 'front',
    disableWorldTracking: false,
    enableLighting: false,
    enableWorldPoints: false,
    leftHandedAxes: false,
    mirroredDisplay: false,
    scale: 'responsive',
    enableVps: false,
    meshGeometryFace: false,
    meshGeometryEyes: false,
    meshGeometryIris: false,
    meshGeometryMouth: false,
    uvType: 'standard',
    maxDetections: 1,
    enableEars: false,
});
const Face = registerAttribute('face', {
    id: Types.i32,
    addAttachmentState: Types.boolean,
}, {
    id: 1,
    addAttachmentState: false,
});
const Location = registerAttribute('location', {
    name: Types.string,
    poiId: Types.string,
    lat: Types.f32,
    lng: Types.f32,
    title: Types.string,
    anchorNodeId: Types.string,
    anchorSpaceId: Types.string,
    imageUrl: Types.string,
    anchorPayload: Types.string,
});
export { Position, Scale, Quaternion, Material, UnlitMaterial, ShadowMaterial, HiderMaterial, GltfModel, Splat, SphereGeometry, BoxGeometry, PlaneGeometry, CapsuleGeometry, ConeGeometry, CylinderGeometry, TetrahedronGeometry, PolyhedronGeometry, CircleGeometry, RingGeometry, TorusGeometry, FaceGeometry, ThreeObject, Hidden, Shadow, Audio, Light, Camera, Face, Ui, Location, };
