import { Camera } from '../components';
import { constructFaceCameraPipeline, constructWorldCameraPipeline } from './xr-camera-pipeline';
import { getAllowedXrDevices } from '../../shared/xr-config';
import { quat } from '../math/quat';
import { CameraEvents } from '../camera-events';
const CAMERA_TRANSFORM_UPDATE = 'cameraupdate';
const Z_FLIP = quat.yDegrees(180);
// XrManager manages the running CameraPipeline for XR. Only one camera pipeline can run at a time
// so XrManager can switch between the active one. If users
// XrManager should not have async methods since we use it in the ECS system.
const createXrManager = (world, events) => {
    let activeHandle_ = null;
    const handleToCameraPipeline_ = {};
    let currentCameraEid_ = 0n;
    let ecsRenderOverride_;
    let pipelineIsRunning_ = false;
    const setEcsRenderOverride = (renderOverride) => {
        ecsRenderOverride_ = renderOverride;
    };
    const getRandomInt = () => Math.floor(Math.random() * 1000000);
    const getNewHandle = () => {
        let handle = getRandomInt();
        while (handleToCameraPipeline_[handle]) {
            handle = getRandomInt();
        }
        return handle;
    };
    const createWorldEffect = (config, eid) => {
        const handle = getNewHandle();
        handleToCameraPipeline_[handle] =
            constructWorldCameraPipeline(world, eid, config, ecsRenderOverride_);
        return handle;
    };
    const startCameraPipeline = (handle) => {
        if (activeHandle_ !== null && activeHandle_ === handle) {
            // already running this handle
            return;
        }
        // stop the current camera pipeline and start the new one
        if (activeHandle_ !== null) {
            handleToCameraPipeline_[activeHandle_].then(pipeline => pipeline.stop());
        }
        if (!handleToCameraPipeline_[handle]) {
            // eslint-disable-next-line no-console
            console.log('[ecs.xr] Handle not found. Did you call create{World,Face}Effect?');
            return;
        }
        handleToCameraPipeline_[handle].then((pipeline) => {
            pipeline.start();
            activeHandle_ = handle;
            pipelineIsRunning_ = true;
        });
    };
    const stopCameraPipeline = (handle) => {
        if (handleToCameraPipeline_[handle] === null) {
            // We have already cleaned up this pipeline
            return;
        }
        // Stop this pipeline
        handleToCameraPipeline_[handle].then((pipeline) => {
            // NOTE(dat): Consider killing pipelines to save memory. Perhaps we need deleteWorldEffect()
            pipeline.stop();
            pipelineIsRunning_ = false;
        });
        if (activeHandle_ === handle) {
            activeHandle_ = null;
        }
    };
    const createFaceEffect = (config, eid) => {
        const handle = getNewHandle();
        handleToCameraPipeline_[handle] =
            constructFaceCameraPipeline(world, eid, config, ecsRenderOverride_);
        currentCameraEid_ = eid;
        return handle;
    };
    const tempQuat = quat.zero();
    const updateCameraTransform = (e) => {
        const { position, rotation, cameraFov } = e.data;
        if (cameraFov) {
            Camera.set(world, currentCameraEid_, { fov: cameraFov });
        }
        if (position) {
            world.setPosition(currentCameraEid_, position.x, position.y, position.z);
        }
        if (rotation) {
            tempQuat.setXyzw(rotation.x, rotation.y, rotation.z, rotation.w);
            // Convert rotation from the engine back to ecs world
            tempQuat.setTimes(Z_FLIP);
            world.setQuaternion(currentCameraEid_, tempQuat.x, tempQuat.y, tempQuat.z, tempQuat.w);
        }
    };
    const handleCameraChange = async (e) => {
        const { camera } = e.data;
        let handle;
        if (currentCameraEid_) {
            events.removeListener(currentCameraEid_, CAMERA_TRANSFORM_UPDATE, updateCameraTransform);
        }
        currentCameraEid_ = world.camera?.getActiveEid() ?? 0n;
        if (currentCameraEid_) {
            events.addListener(currentCameraEid_, CAMERA_TRANSFORM_UPDATE, updateCameraTransform);
        }
        if (camera.userData?.xr?.xrCameraType === 'world') {
            handle = createWorldEffect({
                disableWorldTracking: camera.userData.xr.disableWorldTracking,
                enableLighting: camera.userData.xr.enableLighting,
                enableWorldPoints: camera.userData.xr.enableWorldPoints,
                leftHandedAxes: camera.userData.xr.leftHandedAxes,
                mirroredDisplay: camera.userData.xr.mirroredDisplay,
                scale: camera.userData.xr.scale,
                enableVps: camera.userData.xr.enableVps,
                direction: camera.userData.xr.direction,
                allowedDevices: getAllowedXrDevices({
                    camera: camera.userData.xr.xrCameraType,
                    phone: camera.userData.xr.phone,
                    desktop: camera.userData.xr.desktop,
                    headset: camera.userData.xr.headset,
                }),
            }, world.camera.getActiveEid());
        }
        else if (camera.userData?.xr?.xrCameraType === 'face') {
            handle = createFaceEffect({
                nearClip: camera.userData.xr.nearClip,
                farClip: camera.userData.xr.farClip,
                direction: camera.userData.xr.direction,
                meshGeometryFace: camera.userData.xr.meshGeometryFace,
                meshGeometryEyes: camera.userData.xr.meshGeometryEyes,
                meshGeometryIris: camera.userData.xr.meshGeometryIris,
                meshGeometryMouth: camera.userData.xr.meshGeometryMouth,
                uvType: camera.userData.xr.uvType,
                maxDetections: camera.userData.xr.maxDetections,
                enableEars: camera.userData.xr.enableEars,
                mirroredDisplay: camera.userData.xr.mirroredDisplay,
                allowedDevices: getAllowedXrDevices({
                    camera: camera.userData.xr.xrCameraType,
                    phone: camera.userData.xr.phone,
                    desktop: camera.userData.xr.desktop,
                    headset: camera.userData.xr.headset,
                }),
            }, world.camera.getActiveEid());
        }
        if (handle) {
            startCameraPipeline(handle);
        }
    };
    const drawPausedBackground = () => {
        if (pipelineIsRunning_) {
            XR8?.GlTextureRenderer?.pipelineModule()?.onRender();
        }
    };
    const tick = () => {
        if (pipelineIsRunning_) {
            XR8.runPreRender(Date.now());
            XR8.runRender();
        }
    };
    const tock = () => {
        if (pipelineIsRunning_) {
            XR8.runPostRender();
        }
    };
    events.addListener(events.globalId, CameraEvents.ACTIVE_CAMERA_CHANGE, handleCameraChange);
    events.addListener(events.globalId, CameraEvents.XR_CAMERA_EDIT, handleCameraChange);
    return {
        createWorldEffect,
        createFaceEffect,
        startCameraPipeline,
        stopCameraPipeline,
        drawPausedBackground: () => drawPausedBackground(),
        setEcsRenderOverride,
        attach: () => { },
        detach: () => {
            Object.values(handleToCameraPipeline_).forEach((handle) => {
                handle.then(p => p.stop());
            });
        },
        tick,
        tock,
    };
};
export { createXrManager, };
