const GAMEPAD_CONNECTED = 'input-gamepad-connected';
const GAMEPAD_DISCONNECTED = 'input-gamepad-disconnected';
var BUTTON_STATUS;
(function (BUTTON_STATUS) {
    BUTTON_STATUS[BUTTON_STATUS["FIRST_FRAME_BUTTON_DOWN"] = 0] = "FIRST_FRAME_BUTTON_DOWN";
    BUTTON_STATUS[BUTTON_STATUS["FIRST_FRAME_BUTTON_UP"] = 1] = "FIRST_FRAME_BUTTON_UP";
})(BUTTON_STATUS || (BUTTON_STATUS = {}));
var KEY_STATUS;
(function (KEY_STATUS) {
    KEY_STATUS[KEY_STATUS["KEY_DOWN"] = 0] = "KEY_DOWN";
    KEY_STATUS[KEY_STATUS["FIRST_FRAME_DOWN"] = 1] = "FIRST_FRAME_DOWN";
    KEY_STATUS[KEY_STATUS["KEY_PRESSED"] = 2] = "KEY_PRESSED";
    KEY_STATUS[KEY_STATUS["KEY_UP"] = 3] = "KEY_UP";
    KEY_STATUS[KEY_STATUS["FIRST_FRAME_UP"] = 4] = "FIRST_FRAME_UP";
    KEY_STATUS[KEY_STATUS["DELETE"] = 5] = "DELETE";
})(KEY_STATUS || (KEY_STATUS = {}));
const MOUSE_BUTTON_KEYS = [
    'primaryButton', 'auxillaryButton', 'secondaryButton', 'fourthButton', 'fifthButton',
];
// NOTE(johnny): The cooldown in Chrome is ~1 second before another pointer lock request
// can be made.
const POINTER_LOCK_COOLDOWN = 1600;
const createInputListener = (events) => {
    const { globalId } = events;
    let attached_ = false;
    let pointerLock_ = false;
    const gpdIndexToGamepadInput_ = new Map();
    const codeToKeyStatus_ = new Map();
    let mouseMoving_ = false;
    let mouseScrolling_ = false;
    const mouseButtonsToKeyStatus_ = {
        primaryButton: KEY_STATUS.DELETE,
        auxillaryButton: KEY_STATUS.DELETE,
        secondaryButton: KEY_STATUS.DELETE,
        fourthButton: KEY_STATUS.DELETE,
        fifthButton: KEY_STATUS.DELETE,
    };
    const mousePositionsToValue_ = {
        screenX: 0,
        screenY: 0,
        movementX: 0,
        movementY: 0,
        scrollX: 0,
        scrollY: 0,
    };
    let _lastExitTime = 0;
    const getGamepadInputFromIndex = (index) => (index !== undefined
        ? gpdIndexToGamepadInput_.get(index) : gpdIndexToGamepadInput_.values().next().value);
    const handleKeyDown = (e) => {
        if (!codeToKeyStatus_.has(e.code)) {
            codeToKeyStatus_.set(e.code, KEY_STATUS.KEY_DOWN);
        }
    };
    const handleKeyUp = (e) => {
        codeToKeyStatus_.set(e.code, KEY_STATUS.KEY_UP);
    };
    const handleGamepadLoop = () => {
        if (!attached_) {
            return;
        }
        if (mouseMoving_) {
            mouseMoving_ = false;
        }
        else if (mousePositionsToValue_.movementX || mousePositionsToValue_.movementY) {
            mousePositionsToValue_.movementX = 0;
            mousePositionsToValue_.movementY = 0;
        }
        if (mouseScrolling_) {
            mouseScrolling_ = false;
        }
        else if (mousePositionsToValue_.scrollX || mousePositionsToValue_.scrollY) {
            mousePositionsToValue_.scrollX = 0;
            mousePositionsToValue_.scrollY = 0;
        }
        const gamepads = navigator?.getGamepads?.();
        // Delete gamepads in gamepads_ that are not in gamepads
        const gamepadIndices = new Set(gamepads?.map(gamepad => gamepad?.index));
        Array.from(gpdIndexToGamepadInput_.keys()).forEach((index) => {
            if (!gamepadIndices.has(index)) {
                gpdIndexToGamepadInput_.delete(index);
            }
        });
        if (gamepads) {
            gamepads.forEach((gamepad) => {
                if (!gamepad) {
                    return;
                }
                const oldGamepad = gpdIndexToGamepadInput_.get(gamepad.index);
                if (oldGamepad) {
                    const oldButtons = oldGamepad.gamepad.buttons;
                    gamepad.buttons.forEach((button, idx) => {
                        if (button.pressed && !oldButtons[idx].pressed) {
                            oldGamepad.btnIndexToButtonStatus.set(idx, BUTTON_STATUS.FIRST_FRAME_BUTTON_DOWN);
                        }
                        else if (!button.pressed && oldButtons[idx].pressed) {
                            oldGamepad.btnIndexToButtonStatus.set(idx, BUTTON_STATUS.FIRST_FRAME_BUTTON_UP);
                        }
                        else if (oldGamepad.btnIndexToButtonStatus.has(idx)) {
                            oldGamepad.btnIndexToButtonStatus.delete(idx);
                        }
                    });
                }
                gpdIndexToGamepadInput_.set(gamepad.index, {
                    gamepad, btnIndexToButtonStatus: oldGamepad?.btnIndexToButtonStatus || new Map(),
                });
            });
        }
        codeToKeyStatus_.forEach((keyStatus, code) => {
            if (keyStatus === KEY_STATUS.KEY_PRESSED) {
                return;
            }
            const newKeyStatus = keyStatus + 1;
            if (newKeyStatus < KEY_STATUS.DELETE) {
                codeToKeyStatus_.set(code, newKeyStatus);
            }
            else {
                codeToKeyStatus_.delete(code);
            }
        });
        Object.keys(mouseButtonsToKeyStatus_).forEach((button) => {
            const keyStatus = mouseButtonsToKeyStatus_[button];
            if (keyStatus === KEY_STATUS.KEY_PRESSED) {
                return;
            }
            // NOTE(johnny): Move keyStatus up one level. Look at enum KEY_STATUS for more info.
            const newKeyStatus = keyStatus + 1;
            if (newKeyStatus <= KEY_STATUS.DELETE) {
                mouseButtonsToKeyStatus_[button] = newKeyStatus;
            }
        });
    };
    const handleGamepadConnected = (event) => {
        gpdIndexToGamepadInput_.set(event.gamepad.index, {
            gamepad: event.gamepad,
            btnIndexToButtonStatus: new Map(),
        });
        events.dispatch(globalId, GAMEPAD_CONNECTED, {
            gamepad: event.gamepad,
        });
    };
    const handleGamepadDisconnected = (event) => {
        gpdIndexToGamepadInput_.delete(event.gamepad.index);
        events.dispatch(globalId, GAMEPAD_DISCONNECTED, {
            gamepad: event.gamepad,
        });
    };
    const handleMouseMovement = (event) => {
        mouseMoving_ = true;
        mousePositionsToValue_.screenX = event.screenX;
        mousePositionsToValue_.screenY = event.screenY;
        mousePositionsToValue_.movementX = event.movementX;
        mousePositionsToValue_.movementY = event.movementY;
    };
    const handleMouseDown = (event) => {
        mouseButtonsToKeyStatus_[MOUSE_BUTTON_KEYS[event.button]] = KEY_STATUS.KEY_DOWN;
    };
    const handleMouseUp = (event) => {
        mouseButtonsToKeyStatus_[MOUSE_BUTTON_KEYS[event.button]] = KEY_STATUS.KEY_UP;
    };
    const handleMouseScroll = (event) => {
        mouseScrolling_ = true;
        mousePositionsToValue_.scrollX = event.deltaX;
        mousePositionsToValue_.scrollY = event.deltaY;
    };
    const getButton = (input, gamepadIdx) => {
        const gamepadInput = getGamepadInputFromIndex(gamepadIdx);
        if (!gamepadInput) {
            return false;
        }
        return gamepadInput.gamepad.buttons[input]?.pressed;
    };
    const getButtonDown = (input, gamepadIdx) => {
        const gamepadInput = getGamepadInputFromIndex(gamepadIdx);
        if (!gamepadInput) {
            return false;
        }
        return gamepadInput.btnIndexToButtonStatus.get(input) === BUTTON_STATUS.FIRST_FRAME_BUTTON_DOWN;
    };
    const getButtonUp = (input, gamepadIdx) => {
        const gamepadInput = getGamepadInputFromIndex(gamepadIdx);
        if (!gamepadInput) {
            return false;
        }
        return gamepadInput.btnIndexToButtonStatus.get(input) === BUTTON_STATUS.FIRST_FRAME_BUTTON_UP;
    };
    const getAxis = (gamepadIdx) => {
        const gamepadInput = getGamepadInputFromIndex(gamepadIdx);
        if (!gamepadInput) {
            return undefined;
        }
        return gamepadInput.gamepad.axes;
    };
    const pointerLockChanged = () => {
        if (!document.pointerLockElement) {
            _lastExitTime = performance.now();
        }
    };
    const handlePointerLock = () => {
        if (!window.document.pointerLockElement && pointerLock_ &&
            performance.now() - _lastExitTime > POINTER_LOCK_COOLDOWN) {
            document.body.requestPointerLock();
        }
    };
    const exitPointerLock = () => {
        if (document.pointerLockElement) {
            document.exitPointerLock();
        }
    };
    const enablePointerLockRequest = () => {
        pointerLock_ = true;
    };
    const disablePointerLockRequest = () => {
        pointerLock_ = false;
    };
    const isPointerLockActive = () => !!document.pointerLockElement;
    const handleBlur = () => {
        codeToKeyStatus_.clear();
        gpdIndexToGamepadInput_.clear();
    };
    const getKey = (code) => {
        const keyStatus = codeToKeyStatus_.get(code);
        return !!(keyStatus && keyStatus <= KEY_STATUS.KEY_PRESSED);
    };
    const getKeyDown = (code) => codeToKeyStatus_.get(code) === KEY_STATUS.FIRST_FRAME_DOWN;
    const getKeyUp = (code) => codeToKeyStatus_.get(code) === KEY_STATUS.FIRST_FRAME_UP;
    const getGamepads = () => Array.from(gpdIndexToGamepadInput_.values())
        .map(gamepadInput => gamepadInput.gamepad);
    const getMousePosition = () => [
        mousePositionsToValue_.screenX,
        mousePositionsToValue_.screenY,
    ];
    const getMouseVelocity = () => [
        mousePositionsToValue_.movementX,
        mousePositionsToValue_.movementY,
    ];
    const getMouseScroll = () => [
        mousePositionsToValue_.scrollX,
        mousePositionsToValue_.scrollY,
    ];
    const getMouseButton = (button) => {
        const buttonStatus = mouseButtonsToKeyStatus_[MOUSE_BUTTON_KEYS[button]];
        return !!(buttonStatus && buttonStatus <= KEY_STATUS.KEY_PRESSED);
    };
    const getMouseDown = (button) => (mouseButtonsToKeyStatus_[MOUSE_BUTTON_KEYS[button]] === KEY_STATUS.FIRST_FRAME_DOWN);
    const getMouseUp = (button) => (mouseButtonsToKeyStatus_[MOUSE_BUTTON_KEYS[button]] === KEY_STATUS.FIRST_FRAME_UP);
    const attach = () => {
        attached_ = true;
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
        window.addEventListener('blur', handleBlur);
        window.addEventListener('gamepadconnected', handleGamepadConnected);
        window.addEventListener('gamepaddisconnected', handleGamepadDisconnected);
        window.addEventListener('click', handlePointerLock);
        window.addEventListener('mousemove', handleMouseMovement);
        window.addEventListener('mousedown', handleMouseDown);
        window.addEventListener('mouseup', handleMouseUp);
        window.addEventListener('wheel', handleMouseScroll);
        document.addEventListener('pointerlockchange', pointerLockChanged);
    };
    const detach = () => {
        attached_ = false;
        codeToKeyStatus_.clear();
        gpdIndexToGamepadInput_.clear();
        window.removeEventListener('keydown', handleKeyDown);
        window.removeEventListener('keyup', handleKeyUp);
        window.removeEventListener('blur', handleBlur);
        window.removeEventListener('gamepadconnected', handleGamepadConnected);
        window.removeEventListener('gamepaddisconnected', handleGamepadDisconnected);
        window.removeEventListener('click', handlePointerLock);
        window.removeEventListener('mousemove', handleMouseMovement);
        window.removeEventListener('mousedown', handleMouseDown);
        window.removeEventListener('mouseup', handleMouseUp);
        window.removeEventListener('wheel', handleMouseScroll);
        document.removeEventListener('pointerlockchange', pointerLockChanged);
    };
    const api = {
        getAxis,
        getGamepads,
        getKey,
        getKeyDown,
        getKeyUp,
        getButton,
        getButtonDown,
        getButtonUp,
        enablePointerLockRequest,
        disablePointerLockRequest,
        getMouseButton,
        getMouseDown,
        getMouseUp,
        getMousePosition,
        getMouseVelocity,
        getMouseScroll,
        isPointerLockActive,
        exitPointerLock,
    };
    return {
        api,
        handleGamepadLoop,
        attach,
        detach,
    };
};
export { createInputListener, GAMEPAD_CONNECTED, GAMEPAD_DISCONNECTED, };
