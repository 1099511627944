import { Hidden, ThreeObject, Ui } from '../components';
import { makeSystemHelper } from './system-helper';
import { createLayoutNode, getNodeId, metricsFromLayoutNode, updateLayoutNode, } from '../ui';
import { createOverlayUi, removeOverlayUi, updateOverlayUi } from '../2d-ui';
import { create3dUi, remove3dUi, update3dUi } from '../3d-ui';
import ThreeMeshUI from '../8mesh';
import { input } from '../input';
import { findParentComponent } from '../../shared/find-parent-component';
import { findRootComponent } from '../../shared/find-root-component';
import { validateNumber } from '../../shared/validate-number';
const isEntityHidden = (world, eid) => {
    let cursor = eid;
    while (cursor) {
        if (Hidden.has(world, cursor)) {
            return true;
        }
        cursor = world.getParent(cursor);
    }
    return false;
};
const makeUiSystem = (world) => {
    const { enter, changed, exit } = makeSystemHelper(Ui);
    const nodes = new Map();
    const parentAddingQueue = new Map();
    const eidToLoadingPromise = new Map();
    const overlayRootNode = createLayoutNode();
    const rootElement = document.createElement('div');
    let needsLayout = false;
    overlayRootNode.setWidth('100%');
    overlayRootNode.setHeight('100%');
    // Column = 0, ColumnReverse = 1, Row = 2, RowReverse = 3
    overlayRootNode.setFlexDirection(2);
    Object.assign(rootElement.style, {
        display: 'flex',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        overflow: 'hidden',
    });
    document.body.appendChild(rootElement);
    let windowWidth;
    let windowHeight;
    const rootNodes = new Map([[0n, {
                rootNode: overlayRootNode,
                resolveWidth: () => windowWidth ?? rootElement.offsetWidth,
                resolveHeight: () => windowHeight ?? rootElement.offsetHeight,
            }]]);
    const orderMap = new Map();
    const applyLayoutNode = (eid, node) => {
        if (!node.node.hasNewLayout()) {
            return;
        }
        const ui = Ui.get(world, eid);
        const metrics = metricsFromLayoutNode(node.node);
        nodes.set(eid, { ...node, metrics });
        switch (node.currentType) {
            case ('overlay'):
                updateOverlayUi(node.el, ui, isEntityHidden(world, eid), metrics);
                break;
            case ('3d'):
                update3dUi(eid, eidToLoadingPromise, node.object, ui, metrics);
                break;
            default:
                throw new Error('Unknown Ui type');
        }
        node.node.markLayoutSeen();
    };
    const updateTreeLayout = () => {
        for (const { rootNode, resolveWidth, resolveHeight } of rootNodes.values()) {
            rootNode.calculateLayout(resolveWidth(), resolveHeight(), 1 // Inherit = 0, LTR = 1, RTL = 2
            );
        }
        for (const [eid, node] of nodes.entries()) {
            applyLayoutNode(eid, node);
        }
    };
    const makeHandleUiClick = (eid) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        world.events.dispatch(eid, 'click', { x: e.clientX, y: e.clientY });
    };
    const makeHandle3dUiClick = (eid) => () => {
        // TODO (tri) implement getting clientX and clientY from click event
        world.events.dispatch(eid, 'click', {});
    };
    const handleUiRemove = (eid) => {
        if (!nodes.has(eid)) {
            return;
        }
        const { el, currentType, node, listener } = nodes.get(eid);
        if (currentType === 'overlay' && el) {
            el.removeEventListener('click', listener);
            removeOverlayUi(el);
        }
        if (currentType === '3d') {
            world.events.removeListener(eid, input.SCREEN_TOUCH_START, listener);
            const entityObject = world.three.entityToObject.get(eid);
            if (entityObject) {
                entityObject.userData.ui3d = null;
            }
            remove3dUi(eid, eidToLoadingPromise, nodes.get(eid).object);
        }
        rootNodes.delete(eid);
        orderMap.delete(getNodeId(node));
        node.getParent()?.removeChild(node);
        node.free();
        nodes.delete(eid);
    };
    const handleUiDeepRemove = (eid) => {
        for (const child of world.getChildren(eid)) {
            handleUiDeepRemove(child);
        }
        if (Ui.has(world, eid)) {
            handleUiRemove(eid);
        }
    };
    const findNextSiblingIndex = (parent, order) => {
        const childCount = parent.getChildCount();
        for (let i = 0; i < childCount; i++) {
            const child = parent.getChild(i);
            if ((orderMap.get(getNodeId(child)) ?? 0) > order) {
                return i;
            }
        }
        return childCount;
    };
    const insertToParent = (child, parent) => {
        const afterChild = findNextSiblingIndex(parent, orderMap.get(getNodeId(child)) ?? 0);
        parent.insertChild(child, afterChild);
    };
    const handleCreate3dUi = (eid) => {
        // Create layout node
        const entityObject = world.three.entityToObject.get(eid);
        if (!entityObject) {
            // eslint-disable-next-line no-console
            console.error('entity has no object which should not happen');
        }
        const ui = Ui.get(world, eid);
        const { order } = ThreeObject.get(world, eid);
        const object = create3dUi(eid, eidToLoadingPromise, ui);
        entityObject?.add(object);
        if (entityObject) {
            entityObject.userData.ui3d = object;
        }
        const node = createLayoutNode(ui);
        const listener = makeHandle3dUiClick(eid);
        world.events.addListener(eid, input.SCREEN_TOUCH_START, listener);
        if (parentAddingQueue.has(eid)) {
            // add this ui's children in the queue to self
            const children = parentAddingQueue.get(eid) ?? [];
            for (const childId of children) {
                const { node: childNode, object: childObj } = nodes.get(childId) || {};
                if (childNode) {
                    insertToParent(childNode, node);
                    object.add(childObj);
                }
            }
            parentAddingQueue.delete(eid);
        }
        orderMap.set(getNodeId(node), order);
        const parentId = findParentComponent(world, eid, Ui);
        const { node: parentNode, object: parentObj } = nodes.get(parentId) ?? {};
        if (parentId && !parentNode) {
            // Parent ui hasn't been created yet. Queue this ui to be added later
            parentAddingQueue.set(parentId, [...(parentAddingQueue.get(parentId) ?? []), eid]);
        }
        else if (parentNode) {
            insertToParent(node, parentNode);
            parentObj.add(object);
        }
        else {
            // This is a root Ui add to the list of root nodes
            rootNodes.set(eid, {
                rootNode: node,
                // NOTE (jeffha): Need to get the most up-to-date UI cursor, otherwise
                //                the cursor may be stale and point to another element
                resolveHeight: () => validateNumber(Ui.get(world, eid).height) / 100,
                resolveWidth: () => validateNumber(Ui.get(world, eid).width) / 100,
            });
        }
        nodes.set(eid, {
            eid,
            node,
            object,
            listener,
            isImage: !!ui.image,
            currentType: '3d',
        });
    };
    const handleCreateOverlayUi = (eid) => {
        // Create layout node
        const options = Ui.get(world, eid);
        const { order } = ThreeObject.get(world, eid);
        const node = createLayoutNode(options);
        const el = createOverlayUi(options, isEntityHidden(world, eid), order);
        const listener = makeHandleUiClick(eid);
        el.addEventListener('click', listener);
        if (parentAddingQueue.has(eid)) {
            // add this ui's children in the queue to self
            const children = parentAddingQueue.get(eid) ?? [];
            for (const childId of children) {
                const { node: childNode, el: childEl } = nodes.get(childId) || {};
                if (childNode) {
                    insertToParent(childNode, node);
                    el.appendChild(childEl);
                }
            }
            parentAddingQueue.delete(eid);
        }
        orderMap.set(getNodeId(node), order);
        const parentId = findParentComponent(world, eid, Ui);
        const { node: parentNode, el: parentEl } = nodes.get(parentId) ?? {};
        if (parentId && !parentNode) {
            // Parent ui hasn't been created yet. Queue this ui to be added later
            parentAddingQueue.set(parentId, [...(parentAddingQueue.get(parentId) ?? []), eid]);
        }
        else if (parentNode) {
            insertToParent(node, parentNode);
            parentEl.appendChild(el);
        }
        else {
            // This is a root ui
            // Add to the root element
            insertToParent(node, overlayRootNode);
            rootElement.appendChild(el);
        }
        nodes.set(eid, {
            eid,
            node,
            el,
            listener,
            isImage: !!options.image,
            currentType: 'overlay',
        });
    };
    const handleCreateUi = (eid) => {
        // Check root component type
        const root = findRootComponent(world, eid, Ui);
        const rootOptions = Ui.get(world, root);
        switch (rootOptions.type) {
            case 'overlay':
                handleCreateOverlayUi(eid);
                break;
            case '3d':
                handleCreate3dUi(eid);
                break;
            default:
                throw new Error('Unknown Ui type');
        }
    };
    const handleUiDeepCreate = (eid) => {
        if (Ui.has(world, eid)) {
            handleCreateUi(eid);
        }
        for (const child of world.getChildren(eid)) {
            handleUiDeepCreate(child);
        }
    };
    const handleUiApply = (eid) => {
        const root = findRootComponent(world, eid, Ui);
        const rootOptions = Ui.get(world, root);
        const currentType = rootOptions.type;
        const ui = Ui.get(world, eid);
        // Ui component is not created yet, create it
        if (!nodes.has(eid)) {
            handleCreateUi(eid);
            needsLayout = true;
            return;
        }
        // Ui component is created, update it
        const { node, isImage: wasImage, currentType: wasType } = nodes.get(eid);
        const isImage = !!ui.image;
        const assetChange = wasImage !== isImage;
        const typeChange = currentType !== wasType;
        if (typeChange) {
            // type of ui changed, delete it and all its children and recreate it
            handleUiDeepRemove(eid);
            handleUiDeepCreate(eid);
            needsLayout = true;
        }
        else if (assetChange) {
            // type of ui asset changed, recreate it
            handleUiRemove(eid);
            handleUiApply(eid);
        }
        else {
            if (currentType === 'overlay') {
                updateOverlayUi(nodes.get(eid).el, ui, isEntityHidden(world, eid));
            }
            else {
                update3dUi(eid, eidToLoadingPromise, nodes.get(eid).object, ui);
                if (rootNodes.has(eid)) {
                    rootNodes.set(eid, {
                        rootNode: rootNodes.get(eid).rootNode,
                        resolveHeight: () => validateNumber(Ui.get(world, eid).height) / 100,
                        resolveWidth: () => validateNumber(Ui.get(world, eid).width) / 100,
                    });
                }
            }
            updateLayoutNode(node, ui);
            needsLayout = true;
        }
    };
    return () => {
        const newWindowWidth = window.innerWidth;
        const newWindowHeight = window.innerHeight;
        if (newWindowWidth !== windowWidth || newWindowHeight !== windowHeight) {
            windowWidth = newWindowWidth;
            windowHeight = newWindowHeight;
            updateTreeLayout();
        }
        exit(world).forEach(handleUiRemove);
        enter(world).forEach(handleUiApply);
        changed(world).forEach(handleUiApply);
        if (needsLayout) {
            updateTreeLayout();
            needsLayout = false;
        }
        ThreeMeshUI.update();
        if (parentAddingQueue.size > 0) {
            throw new Error('potentially there are still ui elements that have not been added to the scene');
        }
    };
};
export { makeUiSystem, };
