import isEqual from 'lodash-es/isEqual';
import THREE from './three';
import { BoxGeometry, Material, PlaneGeometry, SphereGeometry, ThreeObject, GltfModel, Hidden, Shadow, Audio, Light, CapsuleGeometry, ConeGeometry, CylinderGeometry, TetrahedronGeometry, CircleGeometry, FaceGeometry, Camera, Ui, Face, ShadowMaterial, UnlitMaterial, RingGeometry, Splat, HiderMaterial, Location, PolyhedronGeometry, TorusGeometry, } from './components';
import { asm } from './asm';
import { getAttribute } from './registry';
import { Collider, ColliderShape } from './physics';
import { DEFAULT_VOLUME, DEFAULT_PITCH, DEFAULT_REF_DISTANCE, DEFAULT_DISTANCE_MODEL, DEFAULT_ROLLOFF_FACTOR, DEFAULT_MAX_DISTANCE, } from '../shared/audio-constants';
import { setGlobalDeleteCallback } from './global-callbacks';
import { DEFAULT_ANGULAR_DAMPING, DEFAULT_FRICTION, DEFAULT_GRAVITY_FACTOR, DEFAULT_LINEAR_DAMPING, DEFAULT_RESTITUTION, DEFAULT_ROLLING_FRICTION, DEFAULT_SPINNING_FRICTION, } from '../shared/physic-constants';
import { DEFAULT_EMISSIVE_COLOR, MATERIAL_DEFAULTS } from '../shared/material-constants';
import { extractResourceUrl } from '../shared/resource';
import { getUiAttributes } from '../shared/get-ui-attributes';
import { resolveSpaceForObject } from '../shared/object-hierarchy';
import { Disabled } from './disabled';
const GEOMETRY_TYPES = {
    'plane': PlaneGeometry,
    'sphere': SphereGeometry,
    'box': BoxGeometry,
    'capsule': CapsuleGeometry,
    'cone': ConeGeometry,
    'cylinder': CylinderGeometry,
    'tetrahedron': TetrahedronGeometry,
    'polyhedron': PolyhedronGeometry,
    'circle': CircleGeometry,
    'ring': RingGeometry,
    'torus': TorusGeometry,
    'face': FaceGeometry,
};
const MATERIAL_TYPES = {
    'basic': Material,
    'unlit': UnlitMaterial,
    'shadow': ShadowMaterial,
    'hider': HiderMaterial,
};
const createEntity = (world) => {
    const eid = asm.createEntity(world._id);
    world.allEntities.add(eid);
    const object = new THREE.Mesh(undefined, 
    // NOTE(christoph): It seems like different versions of THREE behave differently,
    // where passing undefined gives you a default material.
    new THREE.MeshBasicMaterial({ visible: false }));
    object.userData.entity = true;
    object.userData.eid = eid;
    object.matrixAutoUpdate = false;
    world.three.scene.add(object);
    world.setPosition(eid, 0, 0, 0);
    world.setScale(eid, 1, 1, 1);
    world.setQuaternion(eid, 0, 0, 0, 1);
    world.three.entityToObject.set(eid, object);
    ThreeObject.reset(world, eid);
    return eid;
};
// NOTE(christoph): _deleteEntity is responsible for removing the entity from the scene graph after
// it's been deleted from flecs. If a single world.deleteEntity call is made, it will call
// _deleteEntity multiple times for each child object.
const _deleteEntity = (world, eid) => {
    const object = world.three.entityToObject.get(eid);
    if (object && object.parent) {
        object.parent.remove(object);
    }
    world.allEntities.delete(eid);
    world.three.entityToObject.delete(eid);
};
const deleteEntity = (world, eid) => {
    const callbackToRestore = setGlobalDeleteCallback(_deleteEntity.bind(null, world));
    asm.deleteEntity(world._id, eid);
    // NOTE(christoph): Restoring the previous callback allows us to recursively call
    // deleteEntity in response to a separate world's deleteEntity theoretically and still
    // work after we're done with the inner delete.
    setGlobalDeleteCallback(callbackToRestore);
};
const applyGeometryComponent = (world, eid, geometry, prevGeometry) => {
    if (prevGeometry && (!geometry || prevGeometry.type !== geometry.type)) {
        if (prevGeometry) {
            GEOMETRY_TYPES[prevGeometry.type].remove(world, eid);
        }
    }
    if (!geometry) {
        return;
    }
    const geometryClass = GEOMETRY_TYPES[geometry.type];
    if (!geometryClass) {
        throw new Error('Unexpected geometry');
    }
    geometryClass.set(world, eid, geometry);
};
const applyMaterialComponent = (world, eid, material, prevMaterial) => {
    if (prevMaterial && (!material || prevMaterial.type !== material.type)) {
        MATERIAL_TYPES[prevMaterial.type].remove(world, eid);
    }
    if (!material) {
        return;
    }
    if (material.type === 'hider') {
        HiderMaterial.set(world, eid, {});
        return;
    }
    const [, r, g, b] = material.color.match(/^#(.{2})(.{2})(.{2})$/) || [];
    switch (material.type) {
        case 'basic': {
            const emissiveColor = material.emissiveColor ?? DEFAULT_EMISSIVE_COLOR;
            const [, emissiveR, emissiveG, emissiveB] = emissiveColor.match(/^#(.{2})(.{2})(.{2})$/) || [];
            Material.set(world, eid, {
                r: parseInt(r, 16),
                g: parseInt(g, 16),
                b: parseInt(b, 16),
                textureSrc: extractResourceUrl(material.textureSrc),
                roughness: material.roughness ?? MATERIAL_DEFAULTS.roughness,
                metalness: material.metalness ?? MATERIAL_DEFAULTS.metalness,
                opacity: material.opacity ?? MATERIAL_DEFAULTS.opacity,
                roughnessMap: extractResourceUrl(material.roughnessMap),
                metalnessMap: extractResourceUrl(material.metalnessMap),
                opacityMap: extractResourceUrl(material.opacityMap),
                side: material.side || MATERIAL_DEFAULTS.side,
                normalScale: material.normalScale ?? MATERIAL_DEFAULTS.normalScale,
                emissiveIntensity: material.emissiveIntensity ?? MATERIAL_DEFAULTS.emissiveIntensity,
                emissiveR: parseInt(emissiveR, 16),
                emissiveG: parseInt(emissiveG, 16),
                emissiveB: parseInt(emissiveB, 16),
                normalMap: extractResourceUrl(material.normalMap),
                emissiveMap: extractResourceUrl(material.emissiveMap),
                blending: material.blending ?? MATERIAL_DEFAULTS.blending,
                repeatX: material.repeatX ?? MATERIAL_DEFAULTS.repeatX,
                repeatY: material.repeatY ?? MATERIAL_DEFAULTS.repeatY,
                offsetX: material.offsetX ?? MATERIAL_DEFAULTS.offsetX,
                offsetY: material.offsetY ?? MATERIAL_DEFAULTS.offsetY,
                depthTest: material.depthTest ?? MATERIAL_DEFAULTS.depthTest,
                depthWrite: material.depthWrite ?? MATERIAL_DEFAULTS.depthWrite,
                wireframe: material.wireframe ?? MATERIAL_DEFAULTS.wireframe,
                forceTransparent: material.forceTransparent ?? MATERIAL_DEFAULTS.forceTransparent,
            });
            break;
        }
        case 'unlit': {
            UnlitMaterial.set(world, eid, {
                r: parseInt(r, 16),
                g: parseInt(g, 16),
                b: parseInt(b, 16),
                textureSrc: extractResourceUrl(material.textureSrc),
                opacity: material.opacity ?? MATERIAL_DEFAULTS.opacity,
                side: material.side || MATERIAL_DEFAULTS.side,
                opacityMap: extractResourceUrl(material.opacityMap),
                blending: material.blending ?? MATERIAL_DEFAULTS.blending,
                repeatX: material.repeatX ?? MATERIAL_DEFAULTS.repeatX,
                repeatY: material.repeatY ?? MATERIAL_DEFAULTS.repeatY,
                offsetX: material.offsetX ?? MATERIAL_DEFAULTS.offsetX,
                offsetY: material.offsetY ?? MATERIAL_DEFAULTS.offsetY,
                depthTest: material.depthTest ?? MATERIAL_DEFAULTS.depthTest,
                depthWrite: material.depthWrite ?? MATERIAL_DEFAULTS.depthWrite,
                wireframe: material.wireframe ?? MATERIAL_DEFAULTS.wireframe,
                forceTransparent: material.forceTransparent ?? MATERIAL_DEFAULTS.forceTransparent,
            });
            break;
        }
        case 'shadow': {
            ShadowMaterial.set(world, eid, {
                r: parseInt(r, 16),
                g: parseInt(g, 16),
                b: parseInt(b, 16),
                opacity: material.opacity ?? MATERIAL_DEFAULTS.opacity,
                side: material.side || MATERIAL_DEFAULTS.side,
                depthTest: material.depthTest ?? MATERIAL_DEFAULTS.depthTest,
                depthWrite: material.depthWrite ?? MATERIAL_DEFAULTS.depthWrite,
            });
            break;
        }
        default:
            throw new Error('Unexpected material');
    }
};
// Set up the attributes of the component in the scene when the entity for the component has
// been created.
const setComponentAttributes = (world, eid, sceneHandle, component) => {
    const attribute = getAttribute(component.name);
    attribute.reset(world, eid);
    const cursor = attribute.cursor(world, eid);
    if (component.parameters) {
        for (const [k, v] of Object.entries(component.parameters)) {
            if (typeof v === 'object' && v?.type === 'entity') {
                if (v.id === undefined) {
                    // No entity is set
                    cursor[k] = BigInt(0);
                }
                else {
                    const parameterEid = sceneHandle.graphIdToEid.get(v.id);
                    cursor[k] = parameterEid || BigInt(0);
                }
            }
            else if (v !== undefined) {
                cursor[k] = v;
            }
        }
    }
};
// NOTE(christoph): This exposes a way to apply GraphObject to an entity that already exists.
// We're using this currently to replace components that already exist on an ephemeral entity but
// we may remove this from the public API once we stabilize on our final scene
// storage/transfer/diff format.
const spawnIntoObject = (world, eid, e, sceneHandle, prevObject) => {
    world.setPosition(eid, ...e.position);
    world.setScale(eid, ...e.scale);
    world.setQuaternion(eid, ...e.rotation);
    applyGeometryComponent(world, eid, e.geometry, prevObject?.geometry);
    applyMaterialComponent(world, eid, e.material, prevObject?.material);
    ThreeObject.set(world, eid, { order: e.order });
    if (e.shadow) {
        Shadow.set(world, eid, {
            castShadow: !!e.shadow.castShadow,
            receiveShadow: !!e.shadow.receiveShadow,
        });
    }
    else {
        Shadow.remove(world, eid);
    }
    if (e.camera) {
        const cameraData = {
            type: e.camera.type,
            fov: e.camera.fov,
            zoom: e.camera.zoom,
            left: e.camera.left,
            right: e.camera.right,
            top: e.camera.top,
            bottom: e.camera.bottom,
            // XR
            xrCameraType: e.camera.xr?.xrCameraType,
            phone: e.camera.xr?.phone,
            desktop: e.camera.xr?.desktop,
            headset: e.camera.xr?.headset,
            nearClip: e.camera.nearClip,
            farClip: e.camera.farClip,
            leftHandedAxes: e.camera.xr?.leftHandedAxes,
            uvType: e.camera.xr?.uvType,
            direction: e.camera.xr?.direction,
            // World
            disableWorldTracking: e.camera.xr?.world?.disableWorldTracking,
            enableLighting: e.camera.xr?.world?.enableLighting,
            enableWorldPoints: e.camera.xr?.world?.enableWorldPoints,
            scale: e.camera.xr?.world?.scale,
            enableVps: e.camera.xr?.world?.enableVps,
            // Face
            mirroredDisplay: e.camera.xr?.face?.mirroredDisplay,
            meshGeometryFace: e.camera.xr?.face?.meshGeometryFace,
            meshGeometryEyes: e.camera.xr?.face?.meshGeometryEyes,
            meshGeometryIris: e.camera.xr?.face?.meshGeometryIris,
            meshGeometryMouth: e.camera.xr?.face?.meshGeometryMouth,
            enableEars: e.camera.xr?.face?.enableEars,
            maxDetections: e.camera.xr?.face?.maxDetections,
        };
        Camera.set(world, eid, Object.fromEntries(Object.entries(cameraData).filter(([, v]) => v !== undefined)));
    }
    else {
        Camera.remove(world, eid);
    }
    if (e.light) {
        const color = e.light.color?.match(/^#(.{2})(.{2})(.{2})$/);
        const lightData = {
            type: e.light.type,
            castShadow: e.light.castShadow,
            intensity: e.light.intensity,
            r: color && parseInt(color[1], 16),
            g: color && parseInt(color[2], 16),
            b: color && parseInt(color[3], 16),
            targetX: e.light.target?.[0],
            targetY: e.light.target?.[1],
            targetZ: e.light.target?.[2],
            shadowNormalBias: e.light.shadowNormalBias,
            shadowBias: e.light.shadowBias,
            shadowAutoUpdate: e.light.shadowAutoUpdate,
            shadowBlurSamples: e.light.shadowBlurSamples,
            shadowRadius: e.light.shadowRadius,
            shadowMapSizeWidth: e.light.shadowMapSize?.[0],
            shadowMapSizeHeight: e.light.shadowMapSize?.[1],
            shadowCameraLeft: e.light.shadowCamera?.[0],
            shadowCameraRight: e.light.shadowCamera?.[1],
            shadowCameraTop: e.light.shadowCamera?.[2],
            shadowCameraBottom: e.light.shadowCamera?.[3],
            shadowCameraNear: e.light.shadowCamera?.[4],
            shadowCameraFar: e.light.shadowCamera?.[5],
            distance: e.light.distance,
            decay: e.light.decay,
            followCamera: e.light.followCamera,
        };
        Light.set(world, eid, Object.fromEntries(Object.entries(lightData).filter(([, v]) => v !== undefined)));
    }
    else {
        Light.remove(world, eid);
    }
    if (e.gltfModel) {
        GltfModel.set(world, eid, {
            url: extractResourceUrl(e.gltfModel.src),
            animationClip: e.gltfModel.animationClip,
            loop: !!e.gltfModel.loop,
            paused: !!e.gltfModel.paused,
            timeScale: e.gltfModel.timeScale ?? 1,
            reverse: !!e.gltfModel.reverse,
            repetitions: e.gltfModel.repetitions ?? 0,
            crossFadeDuration: e.gltfModel.crossFadeDuration ?? 0,
        });
    }
    else {
        GltfModel.remove(world, eid);
    }
    if (e.splat) {
        Splat.set(world, eid, {
            url: extractResourceUrl(e.splat.src),
        });
    }
    else {
        Splat.remove(world, eid);
    }
    if (e.collider) {
        const geometry = e.collider.geometry?.type === 'auto' ? e.geometry : e.collider.geometry;
        const rigidBodyInfo = {
            mass: e.collider.mass || 0,
            linearDamping: e.collider.linearDamping ?? DEFAULT_LINEAR_DAMPING,
            angularDamping: e.collider.angularDamping ?? DEFAULT_ANGULAR_DAMPING,
            friction: e.collider.friction ?? DEFAULT_FRICTION,
            rollingFriction: e.collider.rollingFriction ?? DEFAULT_ROLLING_FRICTION,
            spinningFriction: e.collider.spinningFriction ?? DEFAULT_SPINNING_FRICTION,
            restitution: e.collider.restitution ?? DEFAULT_RESTITUTION,
            eventOnly: !!e.collider.eventOnly,
            lockXAxis: !!e.collider.lockXAxis,
            lockYAxis: !!e.collider.lockYAxis,
            lockZAxis: !!e.collider.lockZAxis,
            gravityFactor: e.collider.gravityFactor ?? DEFAULT_GRAVITY_FACTOR,
        };
        Collider.set(world, eid, rigidBodyInfo);
        if (geometry) {
            switch (geometry.type) {
                case 'box':
                    Collider.set(world, eid, {
                        shape: ColliderShape.Box,
                        width: geometry.width,
                        height: geometry.height,
                        depth: geometry.depth,
                    });
                    break;
                case 'sphere':
                    Collider.set(world, eid, {
                        shape: ColliderShape.Sphere,
                        radius: geometry.radius,
                    });
                    break;
                case 'plane':
                    Collider.set(world, eid, {
                        shape: ColliderShape.Plane,
                        width: geometry.width,
                        height: geometry.height,
                    });
                    break;
                case 'capsule':
                    Collider.set(world, eid, {
                        shape: ColliderShape.Capsule,
                        radius: geometry.radius,
                        height: geometry.height,
                    });
                    break;
                case 'cone':
                    Collider.set(world, eid, {
                        shape: ColliderShape.Cone,
                        radius: geometry.radius,
                        height: geometry.height,
                    });
                    break;
                case 'cylinder':
                    Collider.set(world, eid, {
                        shape: ColliderShape.Cylinder,
                        radius: geometry.radius,
                        height: geometry.height,
                    });
                    break;
                default:
                    throw new Error('Unexpected collider geometry');
            }
        }
        else if (e.gltfModel) {
            GltfModel.set(world, eid, { collider: true });
        }
    }
    else {
        Collider.remove(world, eid);
    }
    if (e.audio) {
        Audio.set(world, eid, {
            url: extractResourceUrl(e.audio.src),
            volume: e.audio.volume ?? DEFAULT_VOLUME,
            loop: !!e.audio.loop,
            paused: !!e.audio.paused,
            pitch: e.audio.pitch ?? DEFAULT_PITCH,
            positional: !!e.audio.positional,
            refDistance: e.audio.refDistance ?? DEFAULT_REF_DISTANCE,
            rolloffFactor: e.audio.rolloffFactor ?? DEFAULT_ROLLOFF_FACTOR,
            distanceModel: e.audio.distanceModel ?? DEFAULT_DISTANCE_MODEL,
            maxDistance: e.audio.maxDistance ?? DEFAULT_MAX_DISTANCE,
        });
    }
    else {
        Audio.remove(world, eid);
    }
    if (e.ui) {
        Ui.set(world, eid, getUiAttributes(e.ui));
    }
    else {
        Ui.remove(world, eid);
    }
    if (e.face) {
        Face.set(world, eid, {
            id: e.face.id,
            addAttachmentState: false,
        });
    }
    else {
        Face.remove(world, eid);
    }
    if (e.location) {
        Location.set(world, eid, {
            name: e.location.name,
            poiId: e.location.poiId,
            lat: e.location.lat,
            lng: e.location.lng,
            title: e.location.title,
            anchorNodeId: e.location.anchorNodeId,
            anchorSpaceId: e.location.anchorSpaceId,
            imageUrl: e.location.imageUrl,
            anchorPayload: e.location.anchorPayload,
        });
    }
    else {
        Location.remove(world, eid);
    }
    if (e.hidden) {
        Hidden.reset(world, eid);
    }
    else {
        Hidden.remove(world, eid);
    }
    if (e.disabled) {
        Disabled.set(world, eid);
    }
    else {
        Disabled.remove(world, eid);
    }
    if (prevObject?.components) {
        Object.values(prevObject.components).forEach((component) => {
            if (!e.components[component.id]) {
                const attribute = getAttribute(component.name);
                attribute.remove(world, eid);
            }
        });
    }
    Object.values(e.components).forEach((component) => {
        setComponentAttributes(world, eid, sceneHandle, component);
    });
};
const setParentEntities = (world, graphIdToEid, objects) => {
    graphIdToEid.forEach((eid, graphId) => {
        const { parentId } = objects[graphId];
        if (!parentId) {
            world.setParent(eid, 0n);
            return;
        }
        const parentEid = graphIdToEid.get(parentId);
        if (!parentEid) {
            return;
        }
        world.setParent(eid, parentEid);
    });
};
const setActiveCamera = (world, graphIdToEid, activeCamera) => {
    if (activeCamera && graphIdToEid.has(activeCamera)) {
        world.camera.setActiveEid(graphIdToEid.get(activeCamera));
    }
};
const readInputMap = (world, inputs, activeMap) => {
    if (inputs) {
        world.input.readInputMap(inputs);
        if (activeMap) {
            world.input.setActiveMap(activeMap);
        }
    }
};
const getObjectsInSpace = (world, graph, spaceId) => Object.values(graph.objects)
    .filter(e => !e.ephemeral || (e.ephemeral && !world.allEntities.has(BigInt(e.id))))
    .filter(e => spaceId === resolveSpaceForObject(graph, e.id)?.id);
const updateEntities = (world, oldGraph, newGraph, sceneHandle, spaceId) => {
    const oldObjects = oldGraph.objects;
    const newObjects = newGraph.objects;
    const objects = getObjectsInSpace(world, newGraph, spaceId);
    const addedObjects = objects.filter(e => !oldObjects[e.id]);
    const removedObjects = Object.values(oldGraph.objects).filter(e => !newObjects[e.id]);
    const updatedObjects = objects
        .filter(e => oldObjects[e.id] && !isEqual(e, oldObjects[e.id]));
    removedObjects.forEach((e) => {
        const eid = sceneHandle.graphIdToEid.get(e.id);
        if (eid) {
            deleteEntity(world, eid);
            sceneHandle.graphIdToEid.delete(e.id);
            sceneHandle.eidToObject.delete(eid);
        }
        else {
            // delete ephemeral objects
            deleteEntity(world, BigInt(e.id));
        }
    });
    addedObjects.forEach((e) => {
        const eid = createEntity(world);
        sceneHandle.graphIdToEid.set(e.id, eid);
    });
    const objectsToSpawn = addedObjects.concat(updatedObjects);
    objectsToSpawn.forEach((e) => {
        const eid = sceneHandle.graphIdToEid.get(e.id);
        if (!eid) {
            return;
        }
        sceneHandle.eidToObject.set(eid, e);
        spawnIntoObject(world, eid, e, sceneHandle, oldGraph.objects[e.id]);
    });
    setParentEntities(world, sceneHandle.graphIdToEid, newObjects);
    setActiveCamera(world, sceneHandle.graphIdToEid, newGraph.activeCamera);
    readInputMap(world, newGraph.inputs, newGraph.activeMap);
};
const loadSpace = (world, graph, sceneHandle, spaceId) => {
    if (spaceId && !graph.spaces?.[spaceId]) {
        throw new Error(`Space with id ${spaceId} not found in scene graph`);
    }
    sceneHandle?.remove();
    sceneHandle.graphIdToEid.clear();
    sceneHandle.eidToObject.clear();
    const spaceObjects = getObjectsInSpace(world, graph, spaceId);
    spaceObjects.forEach((e) => {
        const eid = createEntity(world);
        sceneHandle.graphIdToEid.set(e.id, eid);
        sceneHandle.eidToObject.set(eid, e);
    });
    spaceObjects.forEach((e) => {
        const eid = sceneHandle.graphIdToEid.get(e.id);
        if (!eid) {
            return;
        }
        spawnIntoObject(world, eid, e, sceneHandle);
    });
    const activeCamera = spaceId ? graph.spaces?.[spaceId]?.activeCamera : graph.activeCamera;
    setParentEntities(world, sceneHandle.graphIdToEid, graph.objects);
    setActiveCamera(world, sceneHandle.graphIdToEid, activeCamera);
};
const resolveSpaceId = (sceneGraph, spaceIdOrName) => {
    if (!sceneGraph.spaces || !spaceIdOrName) {
        return undefined;
    }
    if (sceneGraph.spaces[spaceIdOrName]) {
        return spaceIdOrName;
    }
    const nameMatches = Object.values(sceneGraph.spaces).filter(space => space.name === spaceIdOrName);
    if (nameMatches.length === 0) {
        throw new Error(`No space found with id or name: ${spaceIdOrName}`);
    }
    else if (nameMatches.length > 1) {
        throw new Error(`Multiple spaces found with name: ${spaceIdOrName}`);
    }
    else {
        return nameMatches[0].id;
    }
};
const spawnScene = (world, originalGraph) => {
    const graphIdToEid = new Map();
    const eidToObject = new Map();
    const spaceId = originalGraph.entrySpaceId;
    let currentGraph = originalGraph;
    let activeSpace = spaceId;
    const sceneHandle = {
        graphIdToEid,
        eidToObject,
        remove: () => {
            eidToObject.forEach((_, eid) => deleteEntity(world, eid));
        },
        update: (oldGraph, newGraph, partialUpdate) => {
            if (partialUpdate) {
                const updatedGraph = { ...currentGraph, objects: { ...currentGraph.objects } };
                const spaceObjects = getObjectsInSpace(world, newGraph, activeSpace);
                spaceObjects.forEach((e) => {
                    updatedGraph.objects[e.id] = newGraph.objects[e.id];
                });
                currentGraph = updatedGraph;
            }
            else {
                currentGraph = newGraph;
            }
            updateEntities(world, oldGraph, currentGraph, sceneHandle, activeSpace);
        },
        loadSpace: (idOrName) => {
            const id = resolveSpaceId(currentGraph, idOrName);
            if (id === activeSpace || !id) {
                return;
            }
            loadSpace(world, currentGraph, sceneHandle, id);
            activeSpace = id;
        },
        listSpaces: () => {
            if (!currentGraph.spaces) {
                return undefined;
            }
            return Object.values(currentGraph.spaces).map(s => ({
                id: s.id,
                name: s.name,
            }));
        },
        getActiveSpace: () => {
            if (!activeSpace || !currentGraph.spaces) {
                return undefined;
            }
            const { name } = currentGraph.spaces[activeSpace];
            return { id: activeSpace, name };
        },
    };
    loadSpace(world, originalGraph, sceneHandle, spaceId);
    // Note(Dale): Input map is not scoped to spaces
    readInputMap(world, originalGraph.inputs, originalGraph.activeMap);
    return sceneHandle;
};
export { createEntity, deleteEntity, spawnScene, spawnIntoObject, };
