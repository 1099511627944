// @ts-nocheck
/**

Contains key maps for the Keyboard component.
Most languages need a specific keyboard. Therefore, Keyboard takes a language attribute
and if not passed tries to detect the language. If not found, it uses the basic QZERTY layout.

 */
export default {
    fr: [
        [
            [
                { width: 0.1, chars: [{ lowerCase: 'a', upperCase: 'A' }] },
                { width: 0.1, chars: [{ lowerCase: 'z', upperCase: 'Z' }] },
                { width: 0.1, chars: [{ lowerCase: 'e', upperCase: 'E' }] },
                { width: 0.1, chars: [{ lowerCase: 'r', upperCase: 'R' }] },
                { width: 0.1, chars: [{ lowerCase: 't', upperCase: 'T' }] },
                { width: 0.1, chars: [{ lowerCase: 'y', upperCase: 'Y' }] },
                { width: 0.1, chars: [{ lowerCase: 'u', upperCase: 'U' }] },
                { width: 0.1, chars: [{ lowerCase: 'i', upperCase: 'I' }] },
                { width: 0.1, chars: [{ lowerCase: 'o', upperCase: 'O' }] },
                { width: 0.1, chars: [{ lowerCase: 'p', upperCase: 'P' }] },
            ],
            [
                { width: 0.1, chars: [{ lowerCase: 'q', upperCase: 'Q' }] },
                { width: 0.1, chars: [{ lowerCase: 's', upperCase: 'S' }] },
                { width: 0.1, chars: [{ lowerCase: 'd', upperCase: 'D' }] },
                { width: 0.1, chars: [{ lowerCase: 'f', upperCase: 'F' }] },
                { width: 0.1, chars: [{ lowerCase: 'g', upperCase: 'G' }] },
                { width: 0.1, chars: [{ lowerCase: 'h', upperCase: 'H' }] },
                { width: 0.1, chars: [{ lowerCase: 'j', upperCase: 'J' }] },
                { width: 0.1, chars: [{ lowerCase: 'k', upperCase: 'K' }] },
                { width: 0.1, chars: [{ lowerCase: 'l', upperCase: 'L' }] },
                { width: 0.1, chars: [{ lowerCase: 'm', upperCase: 'M' }] },
            ],
            [
                { width: 0.2, command: 'shift', chars: [{ icon: 'shift' }] },
                { width: 0.1, chars: [{ lowerCase: 'w', upperCase: 'W' }] },
                { width: 0.1, chars: [{ lowerCase: 'x', upperCase: 'X' }] },
                { width: 0.1, chars: [{ lowerCase: 'c', upperCase: 'C' }] },
                { width: 0.1, chars: [{ lowerCase: 'v', upperCase: 'V' }] },
                { width: 0.1, chars: [{ lowerCase: 'b', upperCase: 'B' }] },
                { width: 0.1, chars: [{ lowerCase: 'n', upperCase: 'N' }] },
                { width: 0.2, command: 'backspace', chars: [{ icon: 'backspace' }] },
            ],
            [
                { width: 0.2, command: 'switch', chars: [{ lowerCase: '.?12' }] },
                { width: 0.1, chars: [{ lowerCase: ',' }] },
                { width: 0.4, command: 'space', chars: [{ icon: 'space' }] },
                { width: 0.1, chars: [{ lowerCase: '.' }] },
                { width: 0.2, command: 'enter', chars: [{ icon: 'enter' }] },
            ],
        ],
        [
            [
                { width: 0.1, chars: [{ lowerCase: '1' }] },
                { width: 0.1, chars: [{ lowerCase: '2' }] },
                { width: 0.1, chars: [{ lowerCase: '3' }] },
                { width: 0.1, chars: [{ lowerCase: '4' }] },
                { width: 0.1, chars: [{ lowerCase: '5' }] },
                { width: 0.1, chars: [{ lowerCase: '6' }] },
                { width: 0.1, chars: [{ lowerCase: '7' }] },
                { width: 0.1, chars: [{ lowerCase: '8' }] },
                { width: 0.1, chars: [{ lowerCase: '9' }] },
                { width: 0.1, chars: [{ lowerCase: '0' }] },
            ],
            [
                { width: 0.1, chars: [{ lowerCase: '@' }] },
                { width: 0.1, chars: [{ lowerCase: '#' }] },
                { width: 0.1, chars: [{ lowerCase: '|' }] },
                { width: 0.1, chars: [{ lowerCase: '_' }] },
                { width: 0.1, chars: [{ lowerCase: '&' }] },
                { width: 0.1, chars: [{ lowerCase: '-' }] },
                { width: 0.1, chars: [{ lowerCase: '+' }] },
                { width: 0.1, chars: [{ lowerCase: '(' }] },
                { width: 0.1, chars: [{ lowerCase: ')' }] },
                { width: 0.1, chars: [{ lowerCase: '/' }] },
            ],
            [
                { width: 0.1, chars: [{ lowerCase: '=' }] },
                { width: 0.1, chars: [{ lowerCase: '*' }] },
                { width: 0.1, chars: [{ lowerCase: '"' }] },
                { width: 0.1, chars: [{ lowerCase: '\'' }] },
                { width: 0.1, chars: [{ lowerCase: ':' }] },
                { width: 0.1, chars: [{ lowerCase: ';' }] },
                { width: 0.1, chars: [{ lowerCase: '!' }] },
                { width: 0.1, chars: [{ lowerCase: '?' }] },
                { width: 0.2, command: 'backspace', chars: [{ icon: 'backspace' }] },
            ],
            [
                { width: 0.2, command: 'switch', chars: [{ lowerCase: '.?12' }] },
                { width: 0.1, chars: [{ lowerCase: ',' }] },
                { width: 0.4, command: 'space', chars: [{ icon: 'space' }] },
                { width: 0.1, chars: [{ lowerCase: '.' }] },
                { width: 0.2, command: 'enter', chars: [{ icon: 'enter' }] },
            ],
        ],
    ],
    /// ////////////////////////////////////////////////////////
    eng: [
        [
            [
                { width: 0.1, chars: [{ lowerCase: 'q', upperCase: 'Q' }] },
                { width: 0.1, chars: [{ lowerCase: 'w', upperCase: 'W' }] },
                { width: 0.1, chars: [{ lowerCase: 'e', upperCase: 'E' }] },
                { width: 0.1, chars: [{ lowerCase: 'r', upperCase: 'R' }] },
                { width: 0.1, chars: [{ lowerCase: 't', upperCase: 'T' }] },
                { width: 0.1, chars: [{ lowerCase: 'y', upperCase: 'Y' }] },
                { width: 0.1, chars: [{ lowerCase: 'u', upperCase: 'U' }] },
                { width: 0.1, chars: [{ lowerCase: 'i', upperCase: 'I' }] },
                { width: 0.1, chars: [{ lowerCase: 'o', upperCase: 'O' }] },
                { width: 0.1, chars: [{ lowerCase: 'p', upperCase: 'P' }] },
            ],
            [
                { width: 0.1, chars: [{ lowerCase: 'a', upperCase: 'A' }] },
                { width: 0.1, chars: [{ lowerCase: 's', upperCase: 'S' }] },
                { width: 0.1, chars: [{ lowerCase: 'd', upperCase: 'D' }] },
                { width: 0.1, chars: [{ lowerCase: 'f', upperCase: 'F' }] },
                { width: 0.1, chars: [{ lowerCase: 'g', upperCase: 'G' }] },
                { width: 0.1, chars: [{ lowerCase: 'h', upperCase: 'H' }] },
                { width: 0.1, chars: [{ lowerCase: 'j', upperCase: 'J' }] },
                { width: 0.1, chars: [{ lowerCase: 'k', upperCase: 'K' }] },
                { width: 0.1, chars: [{ lowerCase: 'l', upperCase: 'L' }] },
            ],
            [
                { width: 0.15, command: 'shift', chars: [{ icon: 'shift' }] },
                { width: 0.1, chars: [{ lowerCase: 'z', upperCase: 'Z' }] },
                { width: 0.1, chars: [{ lowerCase: 'x', upperCase: 'X' }] },
                { width: 0.1, chars: [{ lowerCase: 'c', upperCase: 'C' }] },
                { width: 0.1, chars: [{ lowerCase: 'v', upperCase: 'V' }] },
                { width: 0.1, chars: [{ lowerCase: 'b', upperCase: 'B' }] },
                { width: 0.1, chars: [{ lowerCase: 'n', upperCase: 'N' }] },
                { width: 0.1, chars: [{ lowerCase: 'm', upperCase: 'M' }] },
                { width: 0.15, command: 'backspace', chars: [{ icon: 'backspace' }] },
            ],
            [
                { width: 0.2, command: 'switch', chars: [{ lowerCase: '.?12' }] },
                { width: 0.1, chars: [{ lowerCase: ',' }] },
                { width: 0.4, command: 'space', chars: [{ icon: 'space' }] },
                { width: 0.1, chars: [{ lowerCase: '.' }] },
                { width: 0.2, command: 'enter', chars: [{ icon: 'enter' }] },
            ],
        ],
        [
            [
                { width: 0.1, chars: [{ lowerCase: '1' }] },
                { width: 0.1, chars: [{ lowerCase: '2' }] },
                { width: 0.1, chars: [{ lowerCase: '3' }] },
                { width: 0.1, chars: [{ lowerCase: '4' }] },
                { width: 0.1, chars: [{ lowerCase: '5' }] },
                { width: 0.1, chars: [{ lowerCase: '6' }] },
                { width: 0.1, chars: [{ lowerCase: '7' }] },
                { width: 0.1, chars: [{ lowerCase: '8' }] },
                { width: 0.1, chars: [{ lowerCase: '9' }] },
                { width: 0.1, chars: [{ lowerCase: '0' }] },
            ],
            [
                { width: 0.1, chars: [{ lowerCase: '@' }] },
                { width: 0.1, chars: [{ lowerCase: '#' }] },
                { width: 0.1, chars: [{ lowerCase: '|' }] },
                { width: 0.1, chars: [{ lowerCase: '_' }] },
                { width: 0.1, chars: [{ lowerCase: '&' }] },
                { width: 0.1, chars: [{ lowerCase: '-' }] },
                { width: 0.1, chars: [{ lowerCase: '+' }] },
                { width: 0.1, chars: [{ lowerCase: '(' }] },
                { width: 0.1, chars: [{ lowerCase: ')' }] },
                { width: 0.1, chars: [{ lowerCase: '/' }] },
            ],
            [
                { width: 0.1, chars: [{ lowerCase: '=' }] },
                { width: 0.1, chars: [{ lowerCase: '*' }] },
                { width: 0.1, chars: [{ lowerCase: '"' }] },
                { width: 0.1, chars: [{ lowerCase: '\'' }] },
                { width: 0.1, chars: [{ lowerCase: ':' }] },
                { width: 0.1, chars: [{ lowerCase: ';' }] },
                { width: 0.1, chars: [{ lowerCase: '!' }] },
                { width: 0.1, chars: [{ lowerCase: '?' }] },
                { width: 0.2, command: 'backspace', chars: [{ icon: 'backspace' }] },
            ],
            [
                { width: 0.2, command: 'switch', chars: [{ lowerCase: '.?12' }] },
                { width: 0.1, chars: [{ lowerCase: ',' }] },
                { width: 0.4, command: 'space', chars: [{ icon: 'space' }] },
                { width: 0.1, chars: [{ lowerCase: '.' }] },
                { width: 0.2, command: 'enter', chars: [{ icon: 'enter' }] },
            ],
        ],
    ],
    /// /////////////////////////////////////////////////////////
    ru: [
        [
            [
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'й', upperCase: 'Й' }, { lowerCase: 'q', upperCase: 'Q' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'ц', upperCase: 'Ц' }, { lowerCase: 'w', upperCase: 'W' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'у', upperCase: 'У' }, { lowerCase: 'e', upperCase: 'E' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'к', upperCase: 'К' }, { lowerCase: 'r', upperCase: 'R' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'е', upperCase: 'Е' }, { lowerCase: 't', upperCase: 'T' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'н', upperCase: 'Н' }, { lowerCase: 'y', upperCase: 'Y' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'г', upperCase: 'Г' }, { lowerCase: 'u', upperCase: 'U' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'ш', upperCase: 'Ш' }, { lowerCase: 'i', upperCase: 'I' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'щ', upperCase: 'Щ' }, { lowerCase: 'o', upperCase: 'O' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'з', upperCase: 'З' }, { lowerCase: 'p', upperCase: 'P' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'х', upperCase: 'Х' }, { lowerCase: '{', upperCase: '[' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'ъ', upperCase: 'Ъ' }, { lowerCase: '}', upperCase: ']' }],
                },
            ],
            [
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'ф', upperCase: 'Ф' }, { lowerCase: 'a', upperCase: 'A' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'ы', upperCase: 'Ы' }, { lowerCase: 's', upperCase: 'S' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'в', upperCase: 'В' }, { lowerCase: 'd', upperCase: 'D' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'а', upperCase: 'А' }, { lowerCase: 'f', upperCase: 'F' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'п', upperCase: 'П' }, { lowerCase: 'g', upperCase: 'G' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'р', upperCase: 'Р' }, { lowerCase: 'h', upperCase: 'H' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'о', upperCase: 'О' }, { lowerCase: 'j', upperCase: 'J' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'л', upperCase: 'Л' }, { lowerCase: 'k', upperCase: 'K' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'д', upperCase: 'Д' }, { lowerCase: 'l', upperCase: 'L' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'ж', upperCase: 'Ж' }, { lowerCase: ':', upperCase: ';' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'э', upperCase: 'Э' }, { lowerCase: '"', upperCase: '\'' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'ё', upperCase: 'Ё' }, { lowerCase: '|', upperCase: '\\' }],
                },
            ],
            [
                { width: 1.5 / 12, command: 'shift', chars: [{ icon: 'shift' }] },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'я', upperCase: 'Я' }, { lowerCase: 'z', upperCase: 'Z' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'ч', upperCase: 'Ч' }, { lowerCase: 'x', upperCase: 'X' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'с', upperCase: 'С' }, { lowerCase: 'c', upperCase: 'C' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'м', upperCase: 'М' }, { lowerCase: 'v', upperCase: 'V' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'и', upperCase: 'И' }, { lowerCase: 'b', upperCase: 'B' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'т', upperCase: 'Т' }, { lowerCase: 'n', upperCase: 'N' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'ь', upperCase: 'Ь' }, { lowerCase: 'm', upperCase: 'M' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'б', upperCase: 'Б' }, { lowerCase: ',', upperCase: '' }],
                },
                {
                    width: 1 / 12,
                    chars: [{ lowerCase: 'ю', upperCase: 'Ю' }, { lowerCase: '.', upperCase: '' }],
                },
                { width: 1.5 / 12, command: 'backspace', chars: [{ icon: 'backspace' }] },
            ],
            [
                { width: 0.15, command: 'switch-set', chars: [{ lowerCase: 'eng' }] },
                { width: 0.15, command: 'switch', chars: [{ lowerCase: '.?12' }] },
                { width: 0.4, command: 'space', chars: [{ icon: 'space' }] },
                { width: 0.1, chars: [{ lowerCase: '?' }] },
                { width: 0.2, command: 'enter', chars: [{ icon: 'enter' }] },
            ],
        ],
        [
            [
                { width: 0.1, chars: [{ lowerCase: '1' }] },
                { width: 0.1, chars: [{ lowerCase: '2' }] },
                { width: 0.1, chars: [{ lowerCase: '3' }] },
                { width: 0.1, chars: [{ lowerCase: '4' }] },
                { width: 0.1, chars: [{ lowerCase: '5' }] },
                { width: 0.1, chars: [{ lowerCase: '6' }] },
                { width: 0.1, chars: [{ lowerCase: '7' }] },
                { width: 0.1, chars: [{ lowerCase: '8' }] },
                { width: 0.1, chars: [{ lowerCase: '9' }] },
                { width: 0.1, chars: [{ lowerCase: '0' }] },
            ],
            [
                { width: 0.1, chars: [{ lowerCase: '@' }] },
                { width: 0.1, chars: [{ lowerCase: '#' }] },
                { width: 0.1, chars: [{ lowerCase: '|' }] },
                { width: 0.1, chars: [{ lowerCase: '_' }] },
                { width: 0.1, chars: [{ lowerCase: '&' }] },
                { width: 0.1, chars: [{ lowerCase: '-' }] },
                { width: 0.1, chars: [{ lowerCase: '+' }] },
                { width: 0.1, chars: [{ lowerCase: '(' }] },
                { width: 0.1, chars: [{ lowerCase: ')' }] },
                { width: 0.1, chars: [{ lowerCase: '/' }] },
            ],
            [
                { width: 0.1, chars: [{ lowerCase: '=' }] },
                { width: 0.1, chars: [{ lowerCase: '*' }] },
                { width: 0.1, chars: [{ lowerCase: '"' }] },
                { width: 0.1, chars: [{ lowerCase: '\'' }] },
                { width: 0.1, chars: [{ lowerCase: ':' }] },
                { width: 0.1, chars: [{ lowerCase: ';' }] },
                { width: 0.1, chars: [{ lowerCase: '!' }] },
                { width: 0.1, chars: [{ lowerCase: '?' }] },
                { width: 0.2, command: 'backspace', chars: [{ icon: 'backspace' }] },
            ],
            [
                { width: 0.3, command: 'switch', chars: [{ lowerCase: 'АБВ' }] },
                { width: 0.4, command: 'space', chars: [{ icon: 'space' }] },
                { width: 0.1, chars: [{ lowerCase: '.' }] },
                { width: 0.2, command: 'enter', chars: [{ icon: 'enter' }] },
            ],
        ],
    ],
    /// //////////////////////////////////////////////////////
    de: [
        [
            [
                { width: 1 / 11, chars: [{ lowerCase: 'q', upperCase: 'Q' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'w', upperCase: 'W' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'e', upperCase: 'E' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'r', upperCase: 'R' }] },
                { width: 1 / 11, chars: [{ lowerCase: 't', upperCase: 'T' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'z', upperCase: 'Z' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'u', upperCase: 'U' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'i', upperCase: 'I' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'o', upperCase: 'O' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'p', upperCase: 'P' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'ü', upperCase: 'Ü' }] },
            ],
            [
                { width: 1 / 11, chars: [{ lowerCase: 'a', upperCase: 'A' }] },
                { width: 1 / 11, chars: [{ lowerCase: 's', upperCase: 'S' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'd', upperCase: 'D' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'f', upperCase: 'F' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'g', upperCase: 'G' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'h', upperCase: 'H' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'j', upperCase: 'J' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'k', upperCase: 'K' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'l', upperCase: 'L' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'ö', upperCase: 'Ö' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'ä', upperCase: 'Ä' }] },
            ],
            [
                { width: 2 / 11, command: 'shift', chars: [{ icon: 'shift' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'y', upperCase: 'Y' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'x', upperCase: 'X' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'c', upperCase: 'C' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'v', upperCase: 'V' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'b', upperCase: 'B' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'n', upperCase: 'N' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'm', upperCase: 'M' }] },
                { width: 2 / 11, command: 'backspace', chars: [{ icon: 'backspace' }] },
            ],
            [
                { width: 0.2, command: 'switch', chars: [{ lowerCase: '.?12' }] },
                { width: 0.1, chars: [{ lowerCase: ',' }] },
                { width: 0.4, command: 'space', chars: [{ icon: 'space' }] },
                { width: 0.1, chars: [{ lowerCase: '.' }] },
                { width: 0.2, command: 'enter', chars: [{ icon: 'enter' }] },
            ],
        ],
        [
            [
                { width: 0.1, chars: [{ lowerCase: '1' }] },
                { width: 0.1, chars: [{ lowerCase: '2' }] },
                { width: 0.1, chars: [{ lowerCase: '3' }] },
                { width: 0.1, chars: [{ lowerCase: '4' }] },
                { width: 0.1, chars: [{ lowerCase: '5' }] },
                { width: 0.1, chars: [{ lowerCase: '6' }] },
                { width: 0.1, chars: [{ lowerCase: '7' }] },
                { width: 0.1, chars: [{ lowerCase: '8' }] },
                { width: 0.1, chars: [{ lowerCase: '9' }] },
                { width: 0.1, chars: [{ lowerCase: '0' }] },
            ],
            [
                { width: 0.1, chars: [{ lowerCase: '@' }] },
                { width: 0.1, chars: [{ lowerCase: '#' }] },
                { width: 0.1, chars: [{ lowerCase: '|' }] },
                { width: 0.1, chars: [{ lowerCase: '_' }] },
                { width: 0.1, chars: [{ lowerCase: '&' }] },
                { width: 0.1, chars: [{ lowerCase: '-' }] },
                { width: 0.1, chars: [{ lowerCase: '+' }] },
                { width: 0.1, chars: [{ lowerCase: '(' }] },
                { width: 0.1, chars: [{ lowerCase: ')' }] },
                { width: 0.1, chars: [{ lowerCase: '/' }] },
            ],
            [
                { width: 0.1, chars: [{ lowerCase: '=' }] },
                { width: 0.1, chars: [{ lowerCase: '*' }] },
                { width: 0.1, chars: [{ lowerCase: '"' }] },
                { width: 0.1, chars: [{ lowerCase: '\'' }] },
                { width: 0.1, chars: [{ lowerCase: ':' }] },
                { width: 0.1, chars: [{ lowerCase: ';' }] },
                { width: 0.1, chars: [{ lowerCase: '!' }] },
                { width: 0.1, chars: [{ lowerCase: '?' }] },
                { width: 0.2, command: 'backspace', chars: [{ icon: 'backspace' }] },
            ],
            [
                { width: 0.2, command: 'switch', chars: [{ lowerCase: '.?12' }] },
                { width: 0.1, chars: [{ lowerCase: ',' }] },
                { width: 0.4, command: 'space', chars: [{ icon: 'space' }] },
                { width: 0.1, chars: [{ lowerCase: '.' }] },
                { width: 0.2, command: 'enter', chars: [{ icon: 'enter' }] },
            ],
        ],
    ],
    /// ////////////////////////////////////////////////////////
    es: [
        [
            [
                { width: 0.1, chars: [{ lowerCase: 'q', upperCase: 'Q' }] },
                { width: 0.1, chars: [{ lowerCase: 'w', upperCase: 'W' }] },
                { width: 0.1, chars: [{ lowerCase: 'e', upperCase: 'E' }] },
                { width: 0.1, chars: [{ lowerCase: 'r', upperCase: 'R' }] },
                { width: 0.1, chars: [{ lowerCase: 't', upperCase: 'T' }] },
                { width: 0.1, chars: [{ lowerCase: 'y', upperCase: 'Y' }] },
                { width: 0.1, chars: [{ lowerCase: 'u', upperCase: 'U' }] },
                { width: 0.1, chars: [{ lowerCase: 'i', upperCase: 'I' }] },
                { width: 0.1, chars: [{ lowerCase: 'o', upperCase: 'O' }] },
                { width: 0.1, chars: [{ lowerCase: 'p', upperCase: 'P' }] },
            ],
            [
                { width: 0.1, chars: [{ lowerCase: 'a', upperCase: 'A' }] },
                { width: 0.1, chars: [{ lowerCase: 's', upperCase: 'S' }] },
                { width: 0.1, chars: [{ lowerCase: 'd', upperCase: 'D' }] },
                { width: 0.1, chars: [{ lowerCase: 'f', upperCase: 'F' }] },
                { width: 0.1, chars: [{ lowerCase: 'g', upperCase: 'G' }] },
                { width: 0.1, chars: [{ lowerCase: 'h', upperCase: 'H' }] },
                { width: 0.1, chars: [{ lowerCase: 'j', upperCase: 'J' }] },
                { width: 0.1, chars: [{ lowerCase: 'k', upperCase: 'K' }] },
                { width: 0.1, chars: [{ lowerCase: 'l', upperCase: 'L' }] },
                { width: 0.1, chars: [{ lowerCase: 'ñ', upperCase: 'Ñ' }] },
            ],
            [
                { width: 0.15, command: 'shift', chars: [{ icon: 'shift' }] },
                { width: 0.1, chars: [{ lowerCase: 'z', upperCase: 'Z' }] },
                { width: 0.1, chars: [{ lowerCase: 'x', upperCase: 'X' }] },
                { width: 0.1, chars: [{ lowerCase: 'c', upperCase: 'C' }] },
                { width: 0.1, chars: [{ lowerCase: 'v', upperCase: 'V' }] },
                { width: 0.1, chars: [{ lowerCase: 'b', upperCase: 'B' }] },
                { width: 0.1, chars: [{ lowerCase: 'n', upperCase: 'N' }] },
                { width: 0.1, chars: [{ lowerCase: 'm', upperCase: 'M' }] },
                { width: 0.15, command: 'backspace', chars: [{ icon: 'backspace' }] },
            ],
            [
                { width: 0.2, command: 'switch', chars: [{ lowerCase: '.?12' }] },
                { width: 0.1, chars: [{ lowerCase: ',' }] },
                { width: 0.4, command: 'space', chars: [{ icon: 'space' }] },
                { width: 0.1, chars: [{ lowerCase: '.' }] },
                { width: 0.2, command: 'enter', chars: [{ icon: 'enter' }] },
            ],
        ],
        [
            [
                { width: 0.1, chars: [{ lowerCase: '1' }] },
                { width: 0.1, chars: [{ lowerCase: '2' }] },
                { width: 0.1, chars: [{ lowerCase: '3' }] },
                { width: 0.1, chars: [{ lowerCase: '4' }] },
                { width: 0.1, chars: [{ lowerCase: '5' }] },
                { width: 0.1, chars: [{ lowerCase: '6' }] },
                { width: 0.1, chars: [{ lowerCase: '7' }] },
                { width: 0.1, chars: [{ lowerCase: '8' }] },
                { width: 0.1, chars: [{ lowerCase: '9' }] },
                { width: 0.1, chars: [{ lowerCase: '0' }] },
            ],
            [
                { width: 0.1, chars: [{ lowerCase: '@' }] },
                { width: 0.1, chars: [{ lowerCase: '#' }] },
                { width: 0.1, chars: [{ lowerCase: '|' }] },
                { width: 0.1, chars: [{ lowerCase: '_' }] },
                { width: 0.1, chars: [{ lowerCase: '&' }] },
                { width: 0.1, chars: [{ lowerCase: '-' }] },
                { width: 0.1, chars: [{ lowerCase: '+' }] },
                { width: 0.1, chars: [{ lowerCase: '(' }] },
                { width: 0.1, chars: [{ lowerCase: ')' }] },
                { width: 0.1, chars: [{ lowerCase: '/' }] },
            ],
            [
                { width: 0.1, chars: [{ lowerCase: '=' }] },
                { width: 0.1, chars: [{ lowerCase: '*' }] },
                { width: 0.1, chars: [{ lowerCase: '"' }] },
                { width: 0.1, chars: [{ lowerCase: '\'' }] },
                { width: 0.1, chars: [{ lowerCase: ':' }] },
                { width: 0.1, chars: [{ lowerCase: ';' }] },
                { width: 0.1, chars: [{ lowerCase: '!' }] },
                { width: 0.1, chars: [{ lowerCase: '?' }] },
                { width: 0.2, command: 'backspace', chars: [{ icon: 'backspace' }] },
            ],
            [
                { width: 0.2, command: 'switch', chars: [{ lowerCase: '.?12' }] },
                { width: 0.1, chars: [{ lowerCase: ',' }] },
                { width: 0.4, command: 'space', chars: [{ icon: 'space' }] },
                { width: 0.1, chars: [{ lowerCase: '.' }] },
                { width: 0.2, command: 'enter', chars: [{ icon: 'enter' }] },
            ],
        ],
    ],
    /// ///////////////////////////////////////////////////////////////////
    el: [
        [
            [
                { width: 0.1, chars: [{ lowerCase: ';', upperCase: ':' }, { lowerCase: 'q', upperCase: 'Q' }] },
                { width: 0.1, chars: [{ lowerCase: 'ς', upperCase: 'ς' }, { lowerCase: 'w', upperCase: 'W' }] },
                { width: 0.1, chars: [{ lowerCase: 'ε', upperCase: 'Ε' }, { lowerCase: 'e', upperCase: 'E' }] },
                { width: 0.1, chars: [{ lowerCase: 'ρ', upperCase: 'Ρ' }, { lowerCase: 'r', upperCase: 'R' }] },
                { width: 0.1, chars: [{ lowerCase: 'τ', upperCase: 'Τ' }, { lowerCase: 't', upperCase: 'T' }] },
                { width: 0.1, chars: [{ lowerCase: 'υ', upperCase: 'Υ' }, { lowerCase: 'y', upperCase: 'Y' }] },
                { width: 0.1, chars: [{ lowerCase: 'θ', upperCase: 'Θ' }, { lowerCase: 'u', upperCase: 'U' }] },
                { width: 0.1, chars: [{ lowerCase: 'ι', upperCase: 'Ι' }, { lowerCase: 'i', upperCase: 'I' }] },
                { width: 0.1, chars: [{ lowerCase: 'ο', upperCase: 'Ο' }, { lowerCase: 'o', upperCase: 'O' }] },
                { width: 0.1, chars: [{ lowerCase: 'π', upperCase: 'Π' }, { lowerCase: 'p', upperCase: 'P' }] },
            ],
            [
                { width: 0.1, chars: [{ lowerCase: 'α', upperCase: 'Α' }, { lowerCase: 'a', upperCase: 'A' }] },
                { width: 0.1, chars: [{ lowerCase: 'σ', upperCase: 'Σ' }, { lowerCase: 's', upperCase: 'S' }] },
                { width: 0.1, chars: [{ lowerCase: 'δ', upperCase: 'Δ' }, { lowerCase: 'd', upperCase: 'D' }] },
                { width: 0.1, chars: [{ lowerCase: 'φ', upperCase: 'Φ' }, { lowerCase: 'f', upperCase: 'F' }] },
                { width: 0.1, chars: [{ lowerCase: 'γ', upperCase: 'Γ' }, { lowerCase: 'g', upperCase: 'G' }] },
                { width: 0.1, chars: [{ lowerCase: 'η', upperCase: 'Η' }, { lowerCase: 'h', upperCase: 'H' }] },
                { width: 0.1, chars: [{ lowerCase: 'ξ', upperCase: 'Ξ' }, { lowerCase: 'j', upperCase: 'J' }] },
                { width: 0.1, chars: [{ lowerCase: 'κ', upperCase: 'Κ' }, { lowerCase: 'k', upperCase: 'K' }] },
                { width: 0.1, chars: [{ lowerCase: 'λ', upperCase: 'Λ' }, { lowerCase: 'l', upperCase: 'L' }] },
            ],
            [
                { width: 0.15, command: 'shift', chars: [{ icon: 'shift' }] },
                { width: 0.1, chars: [{ lowerCase: 'ζ', upperCase: 'Ζ' }, { lowerCase: 'z', upperCase: 'Z' }] },
                { width: 0.1, chars: [{ lowerCase: 'χ', upperCase: 'Χ' }, { lowerCase: 'x', upperCase: 'X' }] },
                { width: 0.1, chars: [{ lowerCase: 'ψ', upperCase: 'Ψ' }, { lowerCase: 'c', upperCase: 'C' }] },
                { width: 0.1, chars: [{ lowerCase: 'ω', upperCase: 'Ω' }, { lowerCase: 'v', upperCase: 'V' }] },
                { width: 0.1, chars: [{ lowerCase: 'β', upperCase: 'Β' }, { lowerCase: 'b', upperCase: 'B' }] },
                { width: 0.1, chars: [{ lowerCase: 'ν', upperCase: 'Ν' }, { lowerCase: 'n', upperCase: 'N' }] },
                { width: 0.1, chars: [{ lowerCase: 'μ', upperCase: 'Μ' }, { lowerCase: 'm', upperCase: 'M' }] },
                { width: 0.15, command: 'backspace', chars: [{ icon: 'backspace' }] },
            ],
            [
                { width: 0.15, command: 'switch-set', chars: [{ lowerCase: 'eng' }] },
                { width: 0.15, command: 'switch', chars: [{ lowerCase: '.?12' }] },
                { width: 0.4, command: 'space', chars: [{ icon: 'space' }] },
                { width: 0.1, chars: [{ lowerCase: '?' }] },
                { width: 0.2, command: 'enter', chars: [{ icon: 'enter' }] },
            ],
        ],
        [
            [
                { width: 0.1, chars: [{ lowerCase: '1' }] },
                { width: 0.1, chars: [{ lowerCase: '2' }] },
                { width: 0.1, chars: [{ lowerCase: '3' }] },
                { width: 0.1, chars: [{ lowerCase: '4' }] },
                { width: 0.1, chars: [{ lowerCase: '5' }] },
                { width: 0.1, chars: [{ lowerCase: '6' }] },
                { width: 0.1, chars: [{ lowerCase: '7' }] },
                { width: 0.1, chars: [{ lowerCase: '8' }] },
                { width: 0.1, chars: [{ lowerCase: '9' }] },
                { width: 0.1, chars: [{ lowerCase: '0' }] },
            ],
            [
                { width: 0.1, chars: [{ lowerCase: '@' }] },
                { width: 0.1, chars: [{ lowerCase: '#' }] },
                { width: 0.1, chars: [{ lowerCase: '|' }] },
                { width: 0.1, chars: [{ lowerCase: '_' }] },
                { width: 0.1, chars: [{ lowerCase: '&' }] },
                { width: 0.1, chars: [{ lowerCase: '-' }] },
                { width: 0.1, chars: [{ lowerCase: '+' }] },
                { width: 0.1, chars: [{ lowerCase: '(' }] },
                { width: 0.1, chars: [{ lowerCase: ')' }] },
                { width: 0.1, chars: [{ lowerCase: '/' }] },
            ],
            [
                { width: 0.1, chars: [{ lowerCase: '=' }] },
                { width: 0.1, chars: [{ lowerCase: '*' }] },
                { width: 0.1, chars: [{ lowerCase: '"' }] },
                { width: 0.1, chars: [{ lowerCase: '\'' }] },
                { width: 0.1, chars: [{ lowerCase: ':' }] },
                { width: 0.1, chars: [{ lowerCase: ';' }] },
                { width: 0.1, chars: [{ lowerCase: '!' }] },
                { width: 0.1, chars: [{ lowerCase: '?' }] },
                { width: 0.2, command: 'backspace', chars: [{ icon: 'backspace' }] },
            ],
            [
                { width: 0.2, command: 'switch', chars: [{ lowerCase: '.?12' }] },
                { width: 0.1, chars: [{ lowerCase: ',' }] },
                { width: 0.4, command: 'space', chars: [{ icon: 'space' }] },
                { width: 0.1, chars: [{ lowerCase: '.' }] },
                { width: 0.2, command: 'enter', chars: [{ icon: 'enter' }] },
            ],
        ],
    ],
    /// /////////////////////////////////////////////////////////////////////////////
    nord: [
        [
            [
                { width: 1 / 11, chars: [{ lowerCase: 'q', upperCase: 'Q' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'w', upperCase: 'W' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'e', upperCase: 'E' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'r', upperCase: 'R' }] },
                { width: 1 / 11, chars: [{ lowerCase: 't', upperCase: 'T' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'y', upperCase: 'Y' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'u', upperCase: 'U' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'i', upperCase: 'I' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'o', upperCase: 'O' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'p', upperCase: 'P' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'å', upperCase: 'Å' }] },
            ],
            [
                { width: 1 / 11, chars: [{ lowerCase: 'a', upperCase: 'A' }] },
                { width: 1 / 11, chars: [{ lowerCase: 's', upperCase: 'S' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'd', upperCase: 'D' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'f', upperCase: 'F' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'g', upperCase: 'G' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'h', upperCase: 'H' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'j', upperCase: 'J' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'k', upperCase: 'K' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'l', upperCase: 'L' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'æ', upperCase: 'Æ' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'ø', upperCase: 'Ø' }] },
            ],
            [
                { width: 2 / 11, command: 'shift', chars: [{ icon: 'shift' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'z', upperCase: 'Z' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'x', upperCase: 'X' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'c', upperCase: 'C' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'v', upperCase: 'V' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'b', upperCase: 'B' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'n', upperCase: 'N' }] },
                { width: 1 / 11, chars: [{ lowerCase: 'm', upperCase: 'M' }] },
                { width: 2 / 11, command: 'backspace', chars: [{ icon: 'backspace' }] },
            ],
            [
                { width: 0.2, command: 'switch', chars: [{ lowerCase: '.?12' }] },
                { width: 0.1, chars: [{ lowerCase: ',' }] },
                { width: 0.4, command: 'space', chars: [{ icon: 'space' }] },
                { width: 0.1, chars: [{ lowerCase: '.' }] },
                { width: 0.2, command: 'enter', chars: [{ icon: 'enter' }] },
            ],
        ],
        [
            [
                { width: 0.1, chars: [{ lowerCase: '1' }] },
                { width: 0.1, chars: [{ lowerCase: '2' }] },
                { width: 0.1, chars: [{ lowerCase: '3' }] },
                { width: 0.1, chars: [{ lowerCase: '4' }] },
                { width: 0.1, chars: [{ lowerCase: '5' }] },
                { width: 0.1, chars: [{ lowerCase: '6' }] },
                { width: 0.1, chars: [{ lowerCase: '7' }] },
                { width: 0.1, chars: [{ lowerCase: '8' }] },
                { width: 0.1, chars: [{ lowerCase: '9' }] },
                { width: 0.1, chars: [{ lowerCase: '0' }] },
            ],
            [
                { width: 0.1, chars: [{ lowerCase: '@' }] },
                { width: 0.1, chars: [{ lowerCase: '#' }] },
                { width: 0.1, chars: [{ lowerCase: '|' }] },
                { width: 0.1, chars: [{ lowerCase: '_' }] },
                { width: 0.1, chars: [{ lowerCase: '&' }] },
                { width: 0.1, chars: [{ lowerCase: '-' }] },
                { width: 0.1, chars: [{ lowerCase: '+' }] },
                { width: 0.1, chars: [{ lowerCase: '(' }] },
                { width: 0.1, chars: [{ lowerCase: ')' }] },
                { width: 0.1, chars: [{ lowerCase: '/' }] },
            ],
            [
                { width: 0.1, chars: [{ lowerCase: '=' }] },
                { width: 0.1, chars: [{ lowerCase: '*' }] },
                { width: 0.1, chars: [{ lowerCase: '"' }] },
                { width: 0.1, chars: [{ lowerCase: '\'' }] },
                { width: 0.1, chars: [{ lowerCase: ':' }] },
                { width: 0.1, chars: [{ lowerCase: ';' }] },
                { width: 0.1, chars: [{ lowerCase: '!' }] },
                { width: 0.1, chars: [{ lowerCase: '?' }] },
                { width: 0.2, command: 'backspace', chars: [{ icon: 'backspace' }] },
            ],
            [
                { width: 0.2, command: 'switch', chars: [{ lowerCase: '.?12' }] },
                { width: 0.1, chars: [{ lowerCase: ',' }] },
                { width: 0.4, command: 'space', chars: [{ icon: 'space' }] },
                { width: 0.1, chars: [{ lowerCase: '.' }] },
                { width: 0.2, command: 'enter', chars: [{ icon: 'enter' }] },
            ],
        ],
    ],
};
