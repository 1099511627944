import THREE from './three';
const sameLightType = (object, type) => {
    switch (type) {
        case 'directional':
            return object instanceof THREE.DirectionalLight;
        case 'ambient':
            return object instanceof THREE.AmbientLight;
        case 'point':
            return object instanceof THREE.PointLight;
        default:
            throw new Error('Unexpected light type');
    }
};
const editOrthographicShadowCamera = (object, config) => {
    object.shadow.camera.left = config.shadowCameraLeft;
    object.shadow.camera.right = config.shadowCameraRight;
    object.shadow.camera.top = config.shadowCameraTop;
    object.shadow.camera.bottom = config.shadowCameraBottom;
};
const editLightShadow = (object, config) => {
    object.castShadow = config.castShadow;
    object.shadow.normalBias = config.shadowNormalBias;
    object.shadow.bias = config.shadowBias;
    object.shadow.camera.near = config.shadowCameraNear;
    object.shadow.camera.far = config.shadowCameraFar;
    object.shadow.mapSize.set(config.shadowMapSizeWidth, config.shadowMapSizeHeight);
    object.shadow.radius = config.shadowRadius;
    object.shadow.autoUpdate = config.shadowAutoUpdate;
    object.shadow.blurSamples = config.shadowBlurSamples;
    // force a shadow map update (needed for map size change)
    object.shadow.map?.dispose();
    object.shadow.map = null;
};
const editLightObject = (object, config) => {
    if (object instanceof THREE.DirectionalLight) {
        if (!config.followCamera) {
            object.target.position.set(config.targetX, config.targetY, config.targetZ);
            object.target.updateMatrixWorld();
        }
        object.castShadow = config.castShadow;
        editLightShadow(object, config);
        editOrthographicShadowCamera(object, config);
    }
    else if (object instanceof THREE.PointLight) {
        object.distance = config.distance;
        object.decay = config.decay;
        editLightShadow(object, config);
    }
    else if (object instanceof THREE.AmbientLight) {
        // Do nothing
    }
    else {
        throw new Error('Unexpected light type');
    }
    // NOTE(johnny): https://discourse.threejs.org/t/updates-to-lighting-in-three-js-r155/53733
    object.intensity = config.intensity * Math.PI;
    object.color.setRGB(config.r / 255, config.g / 255, config.b / 255);
    object.color.convertSRGBToLinear();
};
const createLightObject = (config) => {
    let lightObject;
    switch (config.type) {
        case 'directional':
            lightObject = new THREE.DirectionalLight();
            break;
        case 'ambient':
            lightObject = new THREE.AmbientLight();
            break;
        case 'point':
            lightObject = new THREE.PointLight();
            break;
        default:
            throw new Error('Unexpected light type');
    }
    // NOTE(christoph): Directional and spotlights default to y=1
    lightObject.position.set(0, 0, 0);
    editLightObject(lightObject, config);
    return lightObject;
};
export { createLightObject, editLightObject, sameLightType, };
