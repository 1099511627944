import THREE from './three';
const SCREEN_TOUCH_START = 'screen-touch-start';
const SCREEN_TOUCH_MOVE = 'screen-touch-move';
const SCREEN_TOUCH_END = 'screen-touch-end';
const GESTURE_START = 'gesture-start';
const GESTURE_MOVE = 'gesture-move';
const GESTURE_END = 'gesture-end';
const getProportionalPosition = (element, event) => {
    const rect = element.getBoundingClientRect();
    return ({
        x: (event.clientX - rect.left) / rect.width,
        y: (event.clientY - rect.top) / rect.height,
    });
};
// Recursively search for the first parent with an eid
// If I intersect a child object of a gltf for example, this will resolve to the entity that has
// the gltf attached.
const findEidForObject = (object) => {
    if (object.userData.eid) {
        return object.userData.eid;
    }
    if (object.parent) {
        return findEidForObject(object.parent);
    }
    return undefined;
};
const createPointerListener = (world, events, element, scene) => {
    const { globalId } = events;
    const pointers = new Map();
    const raycaster_ = new THREE.Raycaster();
    // Note/TODO(Dale): generate-ecs-definitions fails here. We extend the Raycaster in
    // node_modules/three-mesh-bvh/src/index.d.ts but it wasn't being carried over. Potentially could
    // be fixed by adding a type definition in the project and updating the tsconfig.
    // @ts-ignore
    raycaster_.firstHitOnly = true;
    const origin_ = new THREE.Vector2();
    const raycastIntersections_ = [];
    let gestureState_ = null;
    const dispatchMaybeTargeted = (event, data) => {
        if (data.target) {
            events.dispatch(data.target, event, data);
        }
        else {
            events.dispatch(globalId, event, data);
        }
    };
    const getGestureSnapshot = () => {
        const touchList = Array.from(pointers.values()).map(pointer => pointer.last);
        if (touchList.length === 0) {
            return null;
        }
        let averageX = 0;
        let averageY = 0;
        for (const touch of touchList) {
            averageX += touch.x;
            averageY += touch.y;
        }
        averageX /= touchList.length;
        averageY /= touchList.length;
        let spread = 0;
        // Calculate average spread of touches from the center point
        if (touchList.length >= 2) {
            for (const touch of touchList) {
                spread += Math.sqrt((averageX - touch.x) ** 2 +
                    (averageY - touch.y) ** 2);
            }
        }
        spread /= touchList.length;
        return {
            touchCount: touchList.length,
            position: { x: averageX, y: averageY },
            spread,
        };
    };
    const maybeDispatchMultiTouch = () => {
        const previousState = gestureState_;
        const currentState = getGestureSnapshot();
        const gestureContinues = (previousState &&
            currentState &&
            currentState.touchCount === previousState.touchCount);
        const gestureEnded = previousState && !gestureContinues;
        const gestureStarted = currentState && !gestureContinues;
        if (gestureEnded) {
            const event = {
                startPosition: previousState.startPosition,
                position: previousState.position,
                startSpread: previousState.startSpread,
                spread: previousState.spread,
                touchCount: previousState.touchCount,
                target: undefined,
                nextTouchCount: currentState?.touchCount,
            };
            gestureState_ = null;
            events.dispatch(globalId, GESTURE_END, event);
        }
        if (gestureStarted) {
            gestureState_ = {
                startPosition: currentState.position,
                position: currentState.position,
                touchCount: currentState.touchCount,
                startSpread: currentState.spread,
                spread: currentState.spread,
            };
            const startEvent = {
                startPosition: currentState.position,
                position: currentState.position,
                startSpread: currentState.spread,
                spread: currentState.spread,
                touchCount: currentState.touchCount,
            };
            events.dispatch(globalId, GESTURE_START, startEvent);
        }
        if (gestureContinues) {
            const event = {
                startPosition: previousState.startPosition,
                position: currentState.position,
                positionChange: {
                    x: currentState.position.x - previousState.position.x,
                    y: currentState.position.y - previousState.position.y,
                },
                startSpread: previousState.startSpread,
                spread: currentState.spread,
                touchCount: currentState.touchCount,
                spreadChange: currentState.spread - previousState.spread,
            };
            if (gestureState_) {
                gestureState_.position = currentState.position;
                gestureState_.spread = currentState.spread;
            }
            events.dispatch(globalId, GESTURE_MOVE, event);
        }
    };
    const handlePointerDown = (event) => {
        const position = getProportionalPosition(element, event);
        const pointer = {
            start: position,
            last: { ...position },
            target: undefined,
        };
        // Screen positions are in the range [0, 1] - we need to convert them to
        // [-1, 1] for raycasting
        // https://threejs.org/docs/#api/en/core/Raycaster.setFromCamera
        origin_.set(position.x * 2 - 1, -position.y * 2 + 1);
        raycaster_.setFromCamera(origin_, world.three.activeCamera);
        raycastIntersections_.length = 0;
        raycaster_.intersectObject(scene, true, raycastIntersections_);
        if (raycastIntersections_.length > 0) {
            const intersection = raycastIntersections_[0];
            pointer.target = findEidForObject(intersection.object);
        }
        pointers.set(event.pointerId, pointer);
        const startEvent = {
            pointerId: event.pointerId,
            position,
            target: pointer.target,
        };
        dispatchMaybeTargeted(SCREEN_TOUCH_START, startEvent);
        maybeDispatchMultiTouch();
    };
    const handlePointerMove = (event) => {
        const pointer = pointers.get(event.pointerId);
        if (!pointer) {
            return;
        }
        const position = getProportionalPosition(element, event);
        const changeX = position.x - pointer.last.x;
        const changeY = position.y - pointer.last.y;
        pointer.last = position;
        const moveEvent = {
            pointerId: event.pointerId,
            position,
            start: pointer.start,
            change: {
                x: changeX,
                y: changeY,
            },
            target: pointer.target,
        };
        dispatchMaybeTargeted(SCREEN_TOUCH_MOVE, moveEvent);
        maybeDispatchMultiTouch();
    };
    const handlePointerUp = (event) => {
        const pointer = pointers.get(event.pointerId);
        if (!pointer) {
            return;
        }
        const position = getProportionalPosition(element, event);
        const endEvent = {
            pointerId: event.pointerId,
            position,
            start: pointer.start,
            target: pointer.target,
        };
        dispatchMaybeTargeted(SCREEN_TOUCH_END, endEvent);
        pointers.delete(event.pointerId);
        maybeDispatchMultiTouch();
    };
    const attach = () => {
        element.addEventListener('pointerdown', handlePointerDown);
        element.addEventListener('pointermove', handlePointerMove);
        element.addEventListener('pointerup', handlePointerUp);
        element.addEventListener('pointerleave', handlePointerUp);
    };
    const detach = () => {
        element.removeEventListener('pointerdown', handlePointerDown);
        element.removeEventListener('pointermove', handlePointerMove);
        element.removeEventListener('pointerup', handlePointerUp);
        element.addEventListener('pointerleave', handlePointerUp);
    };
    return {
        attach,
        detach,
    };
};
export { createPointerListener, SCREEN_TOUCH_START, SCREEN_TOUCH_MOVE, SCREEN_TOUCH_END, GESTURE_START, GESTURE_MOVE, GESTURE_END, };
