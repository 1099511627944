import Yoga, { Overflow } from './yoga';
import { applyStyle } from '../shared/flex-styles';
const overflowToString = (overflow) => {
    switch (overflow) {
        case Overflow.Hidden:
            return 'hidden';
        case Overflow.Scroll:
            return 'scroll';
        case Overflow.Visible:
            return 'visible';
        default:
            return undefined;
    }
};
const metricsFromLayoutNode = (node) => {
    const parent = node.getParent();
    const parentRelatedMetrics = parent ? {
        parentWidth: parent.getComputedWidth(),
        parentHeight: parent.getComputedHeight(),
    } : null;
    return {
        top: node.getComputedTop(),
        left: node.getComputedLeft(),
        width: node.getComputedWidth(),
        height: node.getComputedHeight(),
        ...parentRelatedMetrics,
        overflow: overflowToString(node.getOverflow()),
    };
};
const updateLayoutNode = (node, settings) => {
    applyStyle(node, settings);
};
const getNodeId = (node) => (node.M.O);
const createLayoutNode = (settings) => {
    const node = Yoga.Node.createDefault();
    node.getId = () => node.M.O;
    if (settings !== undefined) {
        updateLayoutNode(node, settings);
    }
    return node;
};
export { updateLayoutNode, createLayoutNode, metricsFromLayoutNode, getNodeId };
