import { Audio } from '../components';
import { makeSystemHelper } from './system-helper';
import { getAudioManager } from '../audio-manager';
const makeAudioSystem = (world) => {
    const { enter, changed, exit } = makeSystemHelper(Audio);
    const { internalAudio } = getAudioManager(world);
    const clearAudio = (eid) => {
        internalAudio.removeSound(eid);
    };
    const applyAudio = (eid) => {
        const object = world.three.entityToObject.get(eid);
        if (!object) {
            return;
        }
        const audio = Audio.get(world, eid);
        if (audio.url) {
            const sound = internalAudio.addSound(eid, audio);
            if (sound) {
                object.add(sound.audioNode);
            }
        }
    };
    const handleAudioChanged = (eid) => {
        const object = world.three.entityToObject.get(eid);
        if (!object) {
            return;
        }
        internalAudio.updateSound(eid, Audio.get(world, eid));
    };
    return () => {
        exit(world).forEach(clearAudio);
        enter(world).forEach(applyAudio);
        changed(world).forEach(handleAudioChanged);
    };
};
export { makeAudioSystem, };
