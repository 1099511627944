import { asm } from './asm';
import { createInstanced } from '../shared/instanced';
import { extractEids } from './list-data';
import { WASM_POINTER_SIZE } from './constants';
const makeFilter = (world, terms) => {
    const ptr = asm._malloc(terms.length * WASM_POINTER_SIZE);
    const view = new Uint32Array(asm.HEAPU32.buffer, ptr, terms.length);
    view.set(terms.map(e => e.forWorld(world).id));
    const res = asm.defineFilter(ptr, terms.length);
    asm._free(ptr);
    return res;
};
const defineQuery = (terms) => {
    const instanced = createInstanced((world) => {
        const filterPtr = makeFilter(world, terms);
        const newInstance = asm.createQuery(world._id, filterPtr);
        asm._free(filterPtr);
        return newInstance;
    });
    return Object.assign((world) => {
        const instance = instanced(world);
        const iterPtr = asm._malloc(WASM_POINTER_SIZE);
        asm.dataView.setUint32(iterPtr, 0);
        const listPtr = asm._malloc(WASM_POINTER_SIZE);
        asm.dataView.setUint32(listPtr, 0);
        const res = [];
        // eslint-disable-next-line no-constant-condition
        while (true) {
            const count = asm.executeQuery(world._id, instance, iterPtr, listPtr);
            if (count === -1) {
                break;
            }
            res.push(...extractEids(listPtr, count));
        }
        asm._free(iterPtr);
        asm._free(listPtr);
        return res;
    }, { terms });
};
const flushObserver = (observer) => {
    const listPtr = asm._malloc(WASM_POINTER_SIZE);
    asm.dataView.setUint32(listPtr, 0);
    const count = asm.flushObserver(observer, listPtr);
    const res = [...extractEids(listPtr, count)];
    asm._free(listPtr);
    return res;
};
const makeObserver = (observerProvider) => {
    const instanced = createInstanced(observerProvider);
    return (world) => {
        const observer = instanced(world);
        return flushObserver(observer);
    };
};
const enterQuery = (t) => makeObserver((world) => {
    const filterPtr = makeFilter(world, t.terms);
    const observer = asm.createAddObserver(world._id, filterPtr);
    asm._free(filterPtr);
    return observer;
});
const exitQuery = (t) => makeObserver((world) => {
    const filterPtr = makeFilter(world, t.terms);
    const observer = asm.createRemoveObserver(world._id, filterPtr);
    asm._free(filterPtr);
    return observer;
});
const changedQuery = (t) => makeObserver((world) => {
    const filterPtr = makeFilter(world, t.terms);
    const observer = asm.createChangeObserver(world._id, filterPtr);
    asm._free(filterPtr);
    return observer;
});
const lifecycleQueries = (t) => {
    const observerProvider = (world) => {
        const filterPtr = makeFilter(world, t.terms);
        const lifecycleObserver = asm.createLifecycleObserver(world._id, filterPtr);
        asm._free(filterPtr);
        return lifecycleObserver;
    };
    const instanced = createInstanced(observerProvider);
    return {
        enter: (world) => {
            const lifecycle = instanced(world);
            const listPtr = asm._malloc(WASM_POINTER_SIZE);
            asm.dataView.setUint32(listPtr, 0);
            const count = asm.accessLifecycleAdded(lifecycle, listPtr);
            const res = [...extractEids(listPtr, count)];
            asm._free(listPtr);
            return res;
        },
        changed: (world) => {
            const lifecycle = instanced(world);
            const listPtr = asm._malloc(WASM_POINTER_SIZE);
            asm.dataView.setUint32(listPtr, 0);
            const count = asm.accessLifecycleChanged(lifecycle, listPtr);
            const res = [...extractEids(listPtr, count)];
            asm._free(listPtr);
            return res;
        },
        exit: (world) => {
            const lifecycle = instanced(world);
            const listPtr = asm._malloc(WASM_POINTER_SIZE);
            asm.dataView.setUint32(listPtr, 0);
            const count = asm.accessLifecycleRemoved(lifecycle, listPtr);
            const res = [...extractEids(listPtr, count)];
            asm._free(listPtr);
            return res;
        },
    };
};
export { makeFilter, defineQuery, enterQuery, changedQuery, exitQuery, lifecycleQueries, };
