import { asm } from './asm';
import { createInstanced } from '../shared/instanced';
import { makeFilter } from './query';
import { getSchemaSize } from './memory';
import { createCursor } from './cursor';
import { extractListData, extractEids } from './list-data';
import { WASM_POINTER_SIZE } from './constants';
const defineSystemQuery = (terms) => {
    const instanced = createInstanced((world) => {
        const filterPtr = makeFilter(world, terms);
        const newInstance = asm.createQuery(world._id, filterPtr);
        asm._free(filterPtr);
        return newInstance;
    });
    return function* query(world) {
        const instance = instanced(world);
        const iterPtr = asm._malloc(WASM_POINTER_SIZE);
        asm.dataView.setUint32(iterPtr, 0);
        const listPtr = asm._malloc(WASM_POINTER_SIZE);
        asm.dataView.setUint32(listPtr, 0);
        const dataPtr = asm._malloc(WASM_POINTER_SIZE);
        asm.dataView.setUint32(dataPtr, 0);
        // eslint-disable-next-line no-constant-condition
        while (true) {
            const count = asm.executeQuery(world._id, instance, iterPtr, listPtr, dataPtr);
            if (count === -1) {
                break;
            }
            yield {
                eids: extractEids(listPtr, count),
                ptrs: [...extractListData(dataPtr, terms.length, WASM_POINTER_SIZE)],
                count,
            };
        }
        asm._free(iterPtr);
        asm._free(listPtr);
        asm._free(dataPtr);
    };
};
const defineSystem = (terms, callback) => {
    const query = defineSystemQuery(terms);
    const instancedCursors = createInstanced(world => terms.map((t) => {
        const cursor = createCursor(world, t.orderedSchema);
        cursor._stride = getSchemaSize(t.orderedSchema);
        return cursor;
    }));
    return (world) => {
        const cursors = instancedCursors(world);
        for (const match of query(world)) {
            for (let i = 0; i < cursors.length; i++) {
                cursors[i]._ptr = match.ptrs[i];
                cursors[i]._index = 0;
            }
            for (const eid of match.eids) {
                callback(world, eid, cursors);
                for (const cursor of cursors) {
                    // NOTE(christoph): The TableMatch gives us contiguous memory for the component
                    // data so we can increment the cursors to progress to the next entity's data.
                    cursor._index++;
                }
            }
        }
    };
};
export { defineSystemQuery, defineSystem, };
