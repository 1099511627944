import { registerComponent } from '../registry';
import THREE from '../three';
import { Hidden, Face, Camera } from '../components';
import { createInstanced } from '../../shared/instanced';
import { mat4, vec3, quat } from '../math/math';
import { earAttachmentNames } from '../../shared/face-mesh-data';
import { CameraEvents } from '../camera-events';
const cleanups = createInstanced(() => new Map());
const scratch = {
    rotY180: quat.yDegrees(180),
    cameraTransform: mat4.i(),
    cameraPos: vec3.zero(),
    cameraRot: quat.zero(),
    cameraScale: vec3.zero(),
    engineRot: quat.zero(),
    meshPos: vec3.zero(),
    meshRot: quat.zero(),
    vertexPositionData: new Float32Array(1),
    tempPos: new THREE.Vector3(),
    tempQuat: new THREE.Quaternion(),
};
const FaceAnchor = registerComponent({
    name: 'face-anchor',
    add: (world, component) => {
        let camEid = world.camera.getActiveEid();
        Hidden.set(world, component.eid);
        const show = (eid) => (event) => {
            if (!Face.has(world, eid)) {
                return;
            }
            if (event.data.id && event.data.id !== Face.get(world, eid).id) {
                return;
            }
            if (Hidden.has(world, eid)) {
                Hidden.remove(world, eid);
            }
            world.getWorldTransform(world.camera.getActiveEid(), scratch.cameraTransform);
            scratch.cameraTransform.decomposeTrs({
                t: scratch.cameraPos, r: scratch.cameraRot, s: scratch.cameraScale,
            });
            const { position, rotation, scale } = event.data.transform;
            scratch.tempPos.set(position.x, position.y, position.z);
            scratch.cameraRot = scratch.cameraRot.times(scratch.rotY180);
            scratch.tempQuat.set(scratch.cameraRot.x, scratch.cameraRot.y, scratch.cameraRot.z, scratch.cameraRot.w);
            scratch.tempPos.applyQuaternion(scratch.tempQuat);
            // shift data from the face controller by the mesh's position
            world.setPosition(eid, scratch.cameraPos.x + scratch.tempPos.x, scratch.cameraPos.y + scratch.tempPos.y, scratch.cameraPos.z + scratch.tempPos.z);
            // local rotation
            scratch.engineRot.setXyzw(scratch.cameraRot.x, scratch.cameraRot.y, scratch.cameraRot.z, scratch.cameraRot.w);
            scratch.meshRot = scratch.engineRot.times(rotation).times(scratch.rotY180);
            world.setQuaternion(eid, scratch.meshRot.x, scratch.meshRot.y, scratch.meshRot.z, scratch.meshRot.w);
            // scale is left as is
            world.setScale(eid, scale, scale, scale);
        };
        const hide = (eid) => (event) => {
            if (!Face.has(world, eid)) {
                return;
            }
            if (event.data.id && event.data.id !== Face.get(world, eid).id) {
                return;
            }
            if (!Hidden.has(world, eid)) {
                Hidden.set(world, eid);
            }
        };
        // Set up face listeners.
        const setUpListeners = (componentEid) => {
            const showFunc = show(componentEid);
            const hideFunc = hide(componentEid);
            world.events.addListener(camEid, 'facecontroller.facefound', showFunc);
            world.events.addListener(camEid, 'facecontroller.faceupdated', showFunc);
            world.events.addListener(camEid, 'facecontroller.facelost', hideFunc);
            const cleanup = () => {
                world.events.removeListener(camEid, 'facecontroller.facefound', showFunc);
                world.events.removeListener(camEid, 'facecontroller.faceupdated', showFunc);
                world.events.removeListener(camEid, 'facecontroller.facelost', hideFunc);
            };
            cleanups(world).set(componentEid, cleanup);
        };
        // Handles camera changes to enable/disable face listeners.
        const cameraChange = () => (e) => {
            const { camera } = e.data;
            if (camera.userData?.xr?.xrCameraType !== 'face' || camEid !== world.camera.getActiveEid()) {
                Hidden.set(world, component.eid);
                cleanups(world).get(component.eid)?.();
                cleanups(world).delete(component.eid);
            }
            // Update the cam eid for listeners if active camera is changed.
            camEid = world.camera.getActiveEid();
            if (camera.userData?.xr?.xrCameraType === 'face' && !cleanups(world).get(component.eid)) {
                setUpListeners(component.eid);
            }
        };
        setUpListeners(component.eid);
        // Set up camera change listener to enable/disable face listeners if the camera changes.
        const cameraChangeFunc = cameraChange();
        world.events.addListener(world.events.globalId, CameraEvents.ACTIVE_CAMERA_CHANGE, cameraChangeFunc);
        world.events.addListener(world.events.globalId, CameraEvents.XR_CAMERA_EDIT, cameraChangeFunc);
        const worldCleanup = () => {
            world.events.removeListener(world.events.globalId, CameraEvents.ACTIVE_CAMERA_CHANGE, cameraChangeFunc);
            world.events.removeListener(world.events.globalId, CameraEvents.XR_CAMERA_EDIT, cameraChangeFunc);
        };
        cleanups(world).set(world.events.globalId, worldCleanup);
    },
    remove: (world, component) => {
        cleanups(world).get(component.eid)?.();
        cleanups(world).delete(component.eid);
        cleanups(world).get(world.events.globalId)?.();
        cleanups(world).delete(world.events.globalId);
    },
});
const FaceMeshAnchor = registerComponent({
    name: 'face-mesh-anchor',
    add: (world, component) => {
        const camEid = world.camera.getActiveEid();
        const show = (eid) => (event) => {
            if (Hidden.has(world, eid)) {
                Hidden.remove(world, eid);
            }
            const faceObj = world.three.entityToObject.get(component.eid);
            const { vertices } = event.data;
            const { mirroredDisplay } = Camera.get(world, camEid);
            if (faceObj instanceof THREE.Mesh) {
                // re-allocate vertex position data if needed
                if (scratch.vertexPositionData.length !== vertices.length * 3) {
                    scratch.vertexPositionData = new Float32Array(vertices.length * 3);
                }
                for (let i = 0; i < vertices.length; ++i) {
                    // TODO: (lynn) This is a temporary fix for the issue in which the mesh is mirrored
                    // incorrectly in the experience but not in the viewport.
                    if (mirroredDisplay) {
                        scratch.vertexPositionData[i * 3] = -vertices[i].x;
                    }
                    else {
                        scratch.vertexPositionData[i * 3] = vertices[i].x;
                    }
                    scratch.vertexPositionData[i * 3 + 1] = vertices[i].y;
                    scratch.vertexPositionData[i * 3 + 2] = -vertices[i].z;
                }
                faceObj.geometry.setAttribute('position', new THREE.BufferAttribute(scratch.vertexPositionData, 3));
                faceObj.geometry.attributes.position.needsUpdate = true;
            }
        };
        const hide = (eid) => () => {
            if (!Hidden.has(world, eid)) {
                Hidden.set(world, eid);
            }
        };
        const showFunc = show(component.eid);
        const hideFunc = hide(component.eid);
        world.events.addListener(camEid, 'facecontroller.facefound', showFunc);
        world.events.addListener(camEid, 'facecontroller.faceupdated', showFunc);
        world.events.addListener(camEid, 'facecontroller.facelost', hideFunc);
        const cleanup = () => {
            world.events.removeListener(camEid, 'facecontroller.facefound', showFunc);
            world.events.removeListener(camEid, 'facecontroller.faceupdated', showFunc);
            world.events.removeListener(camEid, 'facecontroller.facelost', hideFunc);
        };
        cleanups(world).set(component.eid, cleanup);
    },
    remove: (world, component) => {
        cleanups(world).get(component.eid)?.();
        cleanups(world).delete(component.eid);
    },
});
const FaceAttachment = registerComponent({
    name: 'face-attachment',
    schema: {
        // eslint-disable-next-line max-len
        // @enum forehead, rightEyebrowInner, rightEyebrowMiddle, rightEyebrowOuter, leftEyebrowInner, leftEyebrowMiddle, leftEyebrowOuter, leftCheek, rightCheek, noseBridge, noseTip, leftEye, rightEye, leftEyeOuterCorner, rightEyeOuterCorner, upperLip, lowerLip, mouth, mouthRightCorner, mouthLeftCorner, chin, leftIris, rightIris, leftUpperEyelid, rightUpperEyelid, leftLowerEyelid, rightLowerEyelid, leftHelix, leftCanal, leftLobe, rightHelix, rightCanal, rightLobe
        point: 'string',
    },
    schemaDefaults: {
        point: 'forehead',
    },
    add: (world, component) => {
        const { schema } = component;
        const camEid = world.camera.getActiveEid();
        const parentEid = world.getParent(component.eid);
        if (!Camera.get(world, camEid).enableEars && earAttachmentNames.includes(schema.point)) {
            Hidden.set(world, component.eid);
            return;
        }
        const show = (point, eid) => (event) => {
            if (!Face.has(world, parentEid)) {
                return;
            }
            if (event.data.id && event.data.id !== Face.get(world, parentEid).id) {
                return;
            }
            if (Hidden.has(world, eid)) {
                Hidden.remove(world, eid);
            }
            const position = event.data.attachmentPoints[point]?.position;
            if (!position) {
                return;
            }
            world.setPosition(eid, -position.x, position.y, -position.z);
        };
        const hide = (point, eid) => (event) => {
            if (!Face.has(world, parentEid)) {
                return;
            }
            if (event.data.id && event.data.id !== Face.get(world, parentEid).id) {
                return;
            }
            if (!Hidden.has(world, eid)) {
                Hidden.set(world, eid);
            }
        };
        const showFunc = show(schema.point, component.eid);
        const hideFunc = hide(schema.point, component.eid);
        world.events.addListener(camEid, 'facecontroller.facefound', showFunc);
        world.events.addListener(camEid, 'facecontroller.faceupdated', showFunc);
        world.events.addListener(camEid, 'facecontroller.facelost', hideFunc);
        const cleanup = () => {
            world.events.removeListener(camEid, 'facecontroller.facefound', showFunc);
            world.events.removeListener(camEid, 'facecontroller.faceupdated', showFunc);
            world.events.removeListener(camEid, 'facecontroller.facelost', hideFunc);
        };
        cleanups(world).set(component.eid, cleanup);
    },
    tick: (world, component) => {
    },
    remove: (world, component) => {
        cleanups(world).get(component.eid)?.();
        cleanups(world).delete(component.eid);
    },
});
export { FaceAnchor, FaceMeshAnchor, FaceAttachment, };
