import THREE from './three';
import { createWorld } from './world';
import { ACTIVE_CAMERA_CHANGE } from './camera-manager';
import { createSceneBackground, updateSkyBoxMaterial } from './create-scene-background';
import { ready } from './init-ready';
import { getSkyFromSceneGraph } from '../shared/get-sky-from-scene-graph';
import { ACTIVE_SPACE_CHANGE } from '../shared/space-constants';
const getCameraTypeFromWorld = (world) => {
    const activeCamera = world.camera.getActiveEid() ?? null;
    if (activeCamera) {
        const cameraObject = world.three.entityToObject.get(activeCamera);
        return cameraObject?.userData?.camera?.userData?.xr?.xrCameraType ?? '3dOnly';
    }
    return '3dOnly';
};
const getCameraTypeFromSceneGraph = (sceneGraph) => {
    if (!sceneGraph.activeCamera) {
        return '3dOnly';
    }
    const sceneCameraObject = sceneGraph.objects[sceneGraph.activeCamera];
    return sceneCameraObject?.camera?.xr?.xrCameraType ?? '3dOnly';
};
const makeApplication = () => {
    let world;
    let sceneHandle;
    let renderer;
    let skyBox;
    return {
        getWorld: () => world,
        getScene: () => sceneHandle,
        init: async (sceneGraph) => {
            await ready();
            const scene = new THREE.Scene();
            // Read the current cameraType and update the skybox
            const updateSkybox = async (cameraType) => {
                if (cameraType !== '3dOnly') {
                    if (skyBox) {
                        scene.remove(skyBox);
                        skyBox = undefined;
                    }
                    return;
                }
                const spaceId = sceneHandle?.getActiveSpace()?.id ?? sceneGraph.entrySpaceId;
                const graphSky = getSkyFromSceneGraph(sceneGraph, spaceId);
                if (!skyBox) {
                    skyBox = await createSceneBackground(graphSky);
                    if (skyBox) {
                        scene.add(skyBox);
                    }
                    return;
                }
                const material = await updateSkyBoxMaterial(skyBox, graphSky);
                if (!material) {
                    scene.remove(skyBox);
                    skyBox = undefined;
                }
            };
            // On initialization, the world is not yet constructed, we need to inspect the sceneGraph
            // for the camera type
            updateSkybox(getCameraTypeFromSceneGraph(sceneGraph));
            const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 2000);
            camera.position.set(0, 5, 10);
            camera.lookAt(scene.position);
            let currentCamera = camera;
            renderer = new THREE.WebGLRenderer({ antialias: true });
            renderer.shadowMap.enabled = true;
            document.body.appendChild(renderer.domElement);
            document.body.style.margin = '0';
            // disable default touch and selection behavior
            renderer.domElement.style.touchAction = 'none';
            renderer.domElement.style.userSelect = 'none';
            renderer.domElement.style.webkitUserSelect = 'none';
            world = createWorld(scene, renderer, camera);
            const updateCameraAspectRatio = () => {
                currentCamera.aspect = (window.innerWidth / window.innerHeight);
                currentCamera.updateProjectionMatrix();
            };
            const handleCameraChange = (e) => {
                currentCamera = e.data.camera;
                updateCameraAspectRatio();
                updateSkybox(getCameraTypeFromWorld(world));
            };
            const handleSpaceChange = () => {
                updateSkybox(getCameraTypeFromWorld(world));
            };
            world.events.addListener(world.events.globalId, ACTIVE_CAMERA_CHANGE, handleCameraChange);
            world.events.addListener(world.events.globalId, ACTIVE_SPACE_CHANGE, handleSpaceChange);
            // Start the world
            sceneHandle = world.loadScene(sceneGraph);
            world.pointer.attach();
            world.input.attach();
            world.camera.attach();
            world.xr.attach();
            const render = (dt) => {
                world.tick(dt);
                if (currentCamera) {
                    renderer?.render(scene, currentCamera);
                }
                world.tock();
            };
            world.xr.setEcsRenderOverride({
                engage: () => {
                    // User wants us to stop our loop and give them a function to call so rendering happens
                    renderer?.setAnimationLoop(null);
                },
                disengage: () => {
                    // User no longer wants loop themselves, we should set up our loop
                    renderer?.setAnimationLoop(render);
                },
                render: (dt) => {
                    render(dt);
                },
            });
            world.setSpaceHook(sceneHandle);
            const onWindowResize = () => {
                updateCameraAspectRatio();
                renderer?.setSize(window.innerWidth, window.innerHeight);
                renderer?.setPixelRatio(window.devicePixelRatio);
            };
            onWindowResize();
            // NOTE(yuyansong): our renderer will loop the render() function at the start.
            // XR might null this when XR mode is engaged.
            renderer.setAnimationLoop(render);
            window.addEventListener('resize', onWindowResize);
            window.dispatchEvent(new Event('ecsInit'));
        },
    };
};
const application = makeApplication();
export { 
// TODO(christoph): Remove ecs.Application after migration period
application as Application, application, };
