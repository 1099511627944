import THREE from '../runtime/three';
const FaceMeshPositions = new Float32Array([
    0.001636, -0.477721, 0.618629,
    -0.000282, -0.314289, 0.74258,
    -0.000207, -0.362521, 0.62311,
    0.035762, -0.152887, 0.688606,
    -0.000215, -0.265291, 0.760141,
    -0.00017, -0.200541, 0.744176,
    -0.000216, -0.036883, 0.629908,
    0.327206, -0.009323, 0.454067,
    -2e-06, 0.083147, 0.605057,
    8.1e-05, 0.148398, 0.618753,
    0.000316, 0.419438, 0.58457,
    0.00164, -0.496268, 0.611095,
    0.001694, -0.515598, 0.595093,
    0.001488, -0.529951, 0.57141,
    0.001664, -0.530269, 0.572488,
    0.001441, -0.552146, 0.576207,
    0.00161, -0.577379, 0.58509,
    0.00173, -0.605841, 0.578526,
    0.000291, -0.661474, 0.534155,
    -0.000343, -0.338355, 0.717771,
    0.040124, -0.337541, 0.655393,
    0.516085, 0.229195, 0.248564,
    0.194176, -0.058146, 0.498157,
    0.24039, -0.062872, 0.49523,
    0.285091, -0.059225, 0.483204,
    0.345429, -0.026943, 0.446,
    0.154744, -0.045543, 0.493606,
    0.262589, 0.086059, 0.518338,
    0.208953, 0.080045, 0.518036,
    0.312387, 0.078288, 0.503614,
    0.344752, 0.059716, 0.485067,
    0.384338, -0.057519, 0.422117,
    0.163954, -0.754345, 0.475611,
    0.34439, 0.006486, 0.439643,
    0.538839, -0.013674, 0.21984,
    0.440502, -0.017773, 0.378314,
    0.223135, -0.267623, 0.530861,
    0.05515, -0.466528, 0.613736,
    0.049803, -0.513014, 0.588991,
    0.10691, -0.482691, 0.586714,
    0.143152, -0.500586, 0.553454,
    0.091643, -0.514466, 0.571765,
    0.126025, -0.519732, 0.541637,
    0.202747, -0.579285, 0.48094,
    0.034137, -0.312758, 0.739001,
    0.039433, -0.265678, 0.755053,
    0.40918, 0.102438, 0.474603,
    0.132727, -0.141294, 0.54267,
    0.136816, -0.29686, 0.627156,
    0.14001, -0.269838, 0.614103,
    0.361826, -0.261445, 0.473552,
    0.037865, -0.205532, 0.73019,
    0.296577, 0.144024, 0.555794,
    0.362444, 0.132715, 0.519594,
    0.461261, 0.312986, 0.342972,
    0.095899, 0.092366, 0.591662,
    0.163146, 0.058863, 0.504319,
    0.238717, -0.53355, 0.47241,
    0.498638, -0.544004, 0.067729,
    0.105005, -0.325352, 0.608368,
    0.069251, -0.340431, 0.611447,
    0.192169, -0.531989, 0.477138,
    0.180705, -0.530948, 0.489729,
    0.387866, 0.167994, 0.499384,
    0.139739, -0.317332, 0.600915,
    0.209286, 0.133588, 0.581049,
    0.216537, 0.17207, 0.593494,
    0.261452, 0.404112, 0.525571,
    0.418365, 0.240436, 0.433282,
    0.230819, 0.291409, 0.563783,
    0.438613, 0.130443, 0.437013,
    0.473377, 0.176687, 0.34983,
    0.053537, -0.491548, 0.606228,
    0.099387, -0.499591, 0.580991,
    0.134851, -0.510052, 0.551152,
    0.094975, -0.334179, 0.598744,
    0.18628, -0.531482, 0.483898,
    0.163228, -0.540149, 0.510974,
    0.174732, -0.53102, 0.491234,
    0.082068, -0.310119, 0.673014,
    0.119131, -0.527025, 0.532215,
    0.085271, -0.525959, 0.551361,
    0.045815, -0.527365, 0.566652,
    0.057714, -0.659049, 0.532564,
    0.056181, -0.601718, 0.575877,
    0.054018, -0.5731, 0.581261,
    0.050492, -0.548284, 0.571484,
    0.045882, -0.527901, 0.567471,
    0.119394, -0.527524, 0.532423,
    0.127635, -0.536723, 0.537454,
    0.136577, -0.549225, 0.543272,
    0.144263, -0.565671, 0.535285,
    0.185048, -0.446566, 0.541708,
    0.565072, -0.257123, 0.014074,
    -0.000408, -0.350561, 0.656407,
    0.145589, -0.529656, 0.508796,
    0.154173, -0.534203, 0.511833,
    0.055099, -0.359729, 0.611622,
    0.1255, -0.346744, 0.566188,
    0.061808, -0.350298, 0.612868,
    0.180631, -0.169884, 0.525726,
    0.2546, -0.203444, 0.511279,
    0.148517, -0.290969, 0.587323,
    0.378846, 0.371319, 0.441197,
    0.341077, 0.281009, 0.5028,
    0.311794, 0.184188, 0.550487,
    0.163698, -0.610402, 0.502398,
    0.110485, 0.154655, 0.614108,
    0.12171, 0.289368, 0.597996,
    0.14016, 0.417057, 0.573883,
    0.323231, -0.047727, 0.463957,
    0.434872, -0.092112, 0.394941,
    0.128773, -0.032845, 0.488019,
    0.391127, 0.040742, 0.446099,
    0.100727, -0.109562, 0.560242,
    0.108799, -0.280235, 0.672959,
    0.485831, -0.126354, 0.341838,
    0.400455, -0.131167, 0.433983,
    0.338753, -0.152542, 0.474879,
    0.250771, -0.146338, 0.495468,
    0.185973, -0.126346, 0.506148,
    0.137557, -0.103719, 0.519423,
    0.041493, -0.048071, 0.612113,
    0.476775, -0.239669, 0.348771,
    0.427649, 0.049382, 0.425677,
    0.020792, -0.33664, 0.715339,
    0.128053, -0.191926, 0.559248,
    0.573494, 0.005373, 0.064211,
    0.101763, -0.077899, 0.52743,
    0.154252, -0.293717, 0.541389,
    0.365124, 0.004899, 0.430727,
    0.111162, -0.247939, 0.656585,
    0.54123, -0.399675, 0.027919,
    0.127982, -0.01414, 0.47797,
    0.074595, -0.221441, 0.702113,
    0.370062, -0.655288, 0.308775,
    0.371931, -0.725296, 0.222351,
    0.53716, -0.250845, 0.183019,
    0.434303, -0.587198, 0.254152,
    0.509008, 0.093054, 0.272427,
    0.173169, -0.811719, 0.445188,
    0.016729, -0.348801, 0.653478,
    0.168831, -0.227966, 0.540942,
    0.482868, -0.022844, 0.332397,
    0.282072, -0.029735, 0.480405,
    0.2423, -0.034287, 0.491173,
    0.171609, -0.548403, 0.50308,
    0.468839, -0.348314, 0.328126,
    0.091777, -0.884338, 0.428385,
    0.230573, -0.83007, 0.340976,
    0.294176, -0.788965, 0.289212,
    0.000204, 0.286515, 0.607264,
    -0.000534, -0.890986, 0.440477,
    0.202842, -0.030428, 0.493037,
    0.16541, -0.022463, 0.486949,
    0.140538, -0.018209, 0.478321,
    0.463159, 0.065283, 0.380484,
    0.171066, 0.022348, 0.495546,
    0.211506, 0.036981, 0.503776,
    0.252168, 0.040181, 0.502008,
    0.292505, 0.033356, 0.490817,
    0.317651, 0.022077, 0.475789,
    0.553071, 0.134283, 0.147011,
    0.308951, -0.020097, 0.46635,
    -0.000152, -0.400994, 0.610928,
    0.141266, -0.418059, 0.566824,
    0.104411, -0.317469, 0.626594,
    0.059, -0.40261, 0.607622,
    -5.1e-05, 0.02626, 0.59972,
    0.30284, -0.720556, 0.351208,
    0.239439, -0.76796, 0.395439,
    0.09342, -0.838599, 0.488114,
    0.438274, -0.651642, 0.140848,
    0.141716, 0.000678, 0.485676,
    0.069505, -0.127023, 0.622265,
    -0.000409, -0.845306, 0.498026,
    0.164813, -0.862549, 0.394089,
    0.518229, -0.374845, 0.183504,
    0.08539, -0.526618, 0.551964,
    0.093349, -0.542264, 0.557239,
    0.099734, -0.561822, 0.564336,
    0.10447, -0.587457, 0.558319,
    0.116131, -0.638276, 0.523557,
    0.154501, -0.525154, 0.51459,
    0.163594, -0.521179, 0.514989,
    0.171075, -0.516495, 0.515637,
    0.22019, -0.486034, 0.508582,
    0.40029, -0.374371, 0.420186,
    0.070302, -0.074701, 0.582851,
    0.09568, 0.022021, 0.511256,
    0.123235, 0.017835, 0.494449,
    0.145351, -0.529398, 0.508842,
    0.409871, -0.504311, 0.345485,
    0.057082, 0.018298, 0.568586,
    0.141891, -0.695678, 0.496784,
    -0.000248, -0.145953, 0.704116,
    0.0389, -0.103653, 0.655275,
    -0.000298, -0.093821, 0.665854,
    0.100137, -0.204477, 0.618103,
    -8.9e-05, -0.788623, 0.526617,
    0.000187, -0.72249, 0.529745,
    0.072533, -0.719551, 0.52144,
    0.246179, -0.607302, 0.453499,
    0.191351, -0.333567, 0.52952,
    0.197674, -0.659254, 0.473045,
    0.295756, -0.327119, 0.510408,
    0.233297, -0.383959, 0.519574,
    0.336158, -0.410547, 0.470645,
    0.087185, -0.783536, 0.515344,
    0.127217, -0.23122, 0.584069,
    0.286751, -0.654194, 0.407507,
    0.225113, -0.709412, 0.435183,
    0.284597, -0.544317, 0.451252,
    0.457002, -0.440948, 0.307177,
    0.344517, -0.556994, 0.40488,
    0.489462, -0.4883, 0.197321,
    0.26943, -0.449991, 0.495533,
    0.097844, -0.158254, 0.588211,
    0.094959, -0.301801, 0.682585,
    0.123145, -0.313269, 0.631637,
    0.075545, -0.271073, 0.71629,
    0.132681, 0.066372, 0.526875,
    0.209142, 0.099224, 0.535957,
    0.276686, 0.106303, 0.532959,
    0.332516, 0.097739, 0.514227,
    0.371667, 0.077023, 0.485304,
    0.396911, -0.006442, 0.41199,
    0.545203, -0.130579, 0.199451,
    0.357843, -0.081504, 0.444238,
    0.308834, -0.098791, 0.471107,
    0.247005, -0.100448, 0.489298,
    0.188949, -0.089242, 0.4976,
    0.143594, -0.072806, 0.500224,
    0.112516, -0.056856, 0.503542,
    0.574278, -0.123635, 0.023809,
    0.123227, -0.325681, 0.608491,
    0.06913, -0.173584, 0.655617,
    0.063967, -0.305472, 0.717731,
    0.042358, -0.328711, 0.697357,
    0.0646, -0.312882, 0.695055,
    0.111651, -0.340033, 0.592051,
    0.034081, -0.333305, 0.70916,
    0.030398, -0.345089, 0.65435,
    0.112265, -0.016541, 0.484472,
    0.08865, -0.029764, 0.510799,
    0.075206, -0.040365, 0.541494,
    0.33229, 0.013616, 0.460127,
    0.365396, 0.037079, 0.458344,
    -0.036385, -0.152476, 0.687443,
    -0.328513, -0.007315, 0.445825,
    -0.040808, -0.337036, 0.654406,
    -0.514732, 0.232664, 0.236935,
    -0.195485, -0.057125, 0.493424,
    -0.241022, -0.061258, 0.48943,
    -0.285345, -0.057645, 0.476285,
    -0.346594, -0.024267, 0.437319,
    -0.156708, -0.044806, 0.489824,
    -0.262715, 0.088291, 0.512544,
    -0.209518, 0.081783, 0.513465,
    -0.31256, 0.081469, 0.496457,
    -0.344949, 0.063325, 0.477011,
    -0.384788, -0.054936, 0.412413,
    -0.164668, -0.753389, 0.471766,
    -0.346374, 0.009332, 0.43097,
    -0.539958, -0.010452, 0.207586,
    -0.440519, -0.013112, 0.367391,
    -0.223813, -0.266595, 0.525217,
    -0.052104, -0.466688, 0.612289,
    -0.046968, -0.513289, 0.587497,
    -0.104385, -0.483029, 0.584104,
    -0.14028, -0.50091, 0.549801,
    -0.089106, -0.514846, 0.569218,
    -0.123493, -0.520024, 0.538102,
    -0.202283, -0.578832, 0.476171,
    -0.034667, -0.312457, 0.738025,
    -0.039749, -0.265311, 0.754018,
    -0.407568, 0.108307, 0.464652,
    -0.133497, -0.140258, 0.538862,
    -0.137623, -0.296142, 0.623634,
    -0.140909, -0.268974, 0.610535,
    -0.362394, -0.25989, 0.464824,
    -0.038271, -0.204956, 0.729078,
    -0.294656, 0.148205, 0.548136,
    -0.360487, 0.137999, 0.510713,
    -0.459716, 0.315797, 0.333058,
    -0.095624, 0.093532, 0.58941,
    -0.164453, 0.059804, 0.50051,
    -0.238534, -0.5329, 0.466325,
    -0.502047, -0.541878, 0.055287,
    -0.105555, -0.325068, 0.605804,
    -0.070193, -0.340244, 0.609783,
    -0.189819, -0.531676, 0.472223,
    -0.178234, -0.530617, 0.485215,
    -0.38591, 0.174558, 0.489743,
    -0.140239, -0.31687, 0.597285,
    -0.208171, 0.136376, 0.575699,
    -0.214599, 0.175252, 0.587377,
    -0.2605, 0.405352, 0.519546,
    -0.41809, 0.244229, 0.42378,
    -0.230829, 0.293226, 0.55817,
    -0.436946, 0.137077, 0.42611,
    -0.473403, 0.180351, 0.33955,
    -0.050504, -0.491772, 0.604837,
    -0.096849, -0.500035, 0.578511,
    -0.132119, -0.510272, 0.547646,
    -0.095418, -0.333949, 0.596364,
    -0.183791, -0.53117, 0.479219,
    -0.160146, -0.539639, 0.506984,
    -0.172138, -0.530664, 0.486818,
    -0.082707, -0.309571, 0.670664,
    -0.116458, -0.52707, 0.528848,
    -0.082693, -0.526157, 0.548763,
    -0.043374, -0.527543, 0.565154,
    -0.057335, -0.659142, 0.531089,
    -0.052819, -0.601769, 0.574717,
    -0.050471, -0.572997, 0.580171,
    -0.047412, -0.548178, 0.570179,
    -0.042957, -0.527844, 0.566013,
    -0.116134, -0.52719, 0.528946,
    -0.124744, -0.536354, 0.533987,
    -0.133406, -0.548813, 0.539959,
    -0.140985, -0.565228, 0.531898,
    -0.184619, -0.446186, 0.537233,
    -0.566983, -0.255392, 0.000839,
    -0.142409, -0.529229, 0.504973,
    -0.151105, -0.533741, 0.507989,
    -0.056131, -0.359989, 0.610163,
    -0.126134, -0.346802, 0.563141,
    -0.062781, -0.350465, 0.611305,
    -0.181087, -0.168761, 0.520684,
    -0.255321, -0.202273, 0.504834,
    -0.149418, -0.290376, 0.583496,
    -0.377785, 0.373664, 0.432694,
    -0.340791, 0.2838, 0.494848,
    -0.30971, 0.18943, 0.54234,
    -0.16301, -0.609911, 0.498354,
    -0.108905, 0.15591, 0.611085,
    -0.121577, 0.290239, 0.594925,
    -0.139396, 0.417752, 0.570741,
    -0.323692, -0.046068, 0.455954,
    -0.435068, -0.089178, 0.384349,
    -0.131155, -0.032562, 0.484769,
    -0.39119, 0.045262, 0.436461,
    -0.101694, -0.108569, 0.557331,
    -0.10949, -0.279589, 0.669974,
    -0.486583, -0.12386, 0.330269,
    -0.400212, -0.129471, 0.42407,
    -0.338437, -0.151541, 0.466576,
    -0.251102, -0.145741, 0.489241,
    -0.187003, -0.125802, 0.501372,
    -0.138948, -0.103704, 0.515644,
    -0.042043, -0.047513, 0.610789,
    -0.477803, -0.237887, 0.337345,
    -0.427065, 0.054382, 0.4151,
    -0.021362, -0.336411, 0.714712,
    -0.128804, -0.190914, 0.555556,
    -0.574145, 0.008837, 0.051466,
    -0.103502, -0.078325, 0.524423,
    -0.15512, -0.293178, 0.537435,
    -0.366619, 0.008652, 0.421457,
    -0.111829, -0.247054, 0.653542,
    -0.54437, -0.398034, 0.014893,
    -0.130793, -0.013742, 0.474576,
    -0.075036, -0.22063, 0.699986,
    -0.371824, -0.652906, 0.300357,
    -0.37441, -0.722968, 0.213042,
    -0.539295, -0.248445, 0.170293,
    -0.436229, -0.584779, 0.243816,
    -0.509458, 0.0966, 0.261143,
    -0.174119, -0.810559, 0.441221,
    -0.017599, -0.348594, 0.653081,
    -0.169374, -0.22705, 0.536436,
    -0.483882, -0.017622, 0.320859,
    -0.282425, -0.028452, 0.47351,
    -0.242876, -0.033066, 0.485339,
    -0.168713, -0.547881, 0.498954,
    -0.470199, -0.346573, 0.316888,
    -0.092558, -0.883641, 0.426066,
    -0.232147, -0.827539, 0.33542,
    -0.296627, -0.786444, 0.282062,
    -0.204114, -0.029421, 0.488073,
    -0.167313, -0.021643, 0.482887,
    -0.143026, -0.017854, 0.474741,
    -0.462518, 0.071467, 0.368936,
    -0.173581, 0.022774, 0.491435,
    -0.213322, 0.03776, 0.498941,
    -0.253746, 0.041222, 0.496234,
    -0.2938, 0.035039, 0.484005,
    -0.318894, 0.024294, 0.468185,
    -0.552422, 0.137981, 0.134207,
    -0.30966, -0.018521, 0.458613,
    -0.141812, -0.417883, 0.563154,
    -0.105018, -0.317157, 0.62393,
    -0.059927, -0.402788, 0.606135,
    -0.304263, -0.718521, 0.34439,
    -0.24059, -0.766474, 0.389956,
    -0.094164, -0.838009, 0.486004,
    -0.441396, -0.649056, 0.129637,
    -0.144311, 0.000898, 0.481949,
    -0.070362, -0.126321, 0.62014,
    -0.165836, -0.860666, 0.39022,
    -0.520493, -0.372804, 0.170923,
    -0.082412, -0.52642, 0.549311,
    -0.090358, -0.541947, 0.554757,
    -0.096521, -0.561443, 0.56199,
    -0.101558, -0.587098, 0.55585,
    -0.115396, -0.637769, 0.520417,
    -0.151941, -0.524936, 0.51058,
    -0.160745, -0.520974, 0.510656,
    -0.16832, -0.516371, 0.511276,
    -0.219587, -0.485469, 0.503122,
    -0.401035, -0.372608, 0.410706,
    -0.070985, -0.074105, 0.580837,
    -0.098064, 0.022393, 0.508895,
    -0.125475, 0.018281, 0.491194,
    -0.142807, -0.529212, 0.504929,
    -0.41085, -0.501882, 0.335691,
    -0.057841, 0.017864, 0.567141,
    -0.141947, -0.694978, 0.493507,
    -0.039496, -0.103148, 0.654099,
    -0.100804, -0.203869, 0.615319,
    -0.072421, -0.719254, 0.519903,
    -0.245973, -0.606279, 0.447803,
    -0.191787, -0.332595, 0.524776,
    -0.19754, -0.658222, 0.468586,
    -0.296266, -0.325817, 0.503302,
    -0.23349, -0.383058, 0.513992,
    -0.336697, -0.409018, 0.462814,
    -0.087572, -0.783157, 0.513441,
    -0.127926, -0.230294, 0.580731,
    -0.287498, -0.652306, 0.400962,
    -0.22585, -0.707978, 0.429987,
    -0.284678, -0.543363, 0.444294,
    -0.458498, -0.438864, 0.296225,
    -0.345112, -0.554905, 0.396757,
    -0.491886, -0.486134, 0.185396,
    -0.2695, -0.44923, 0.489208,
    -0.098529, -0.157431, 0.585357,
    -0.095509, -0.301213, 0.679912,
    -0.123767, -0.312816, 0.628555,
    -0.076064, -0.270395, 0.714328,
    -0.133722, 0.067477, 0.523647,
    -0.208653, 0.101345, 0.531253,
    -0.275647, 0.109373, 0.526587,
    -0.331492, 0.101751, 0.506188,
    -0.370982, 0.08151, 0.476445,
    -0.397459, -0.002431, 0.402043,
    -0.546579, -0.127737, 0.186333,
    -0.358045, -0.079874, 0.43527,
    -0.308858, -0.097526, 0.463482,
    -0.247448, -0.099248, 0.483238,
    -0.18999, -0.088228, 0.492923,
    -0.145495, -0.072308, 0.496569,
    -0.114867, -0.05654, 0.50053,
    -0.575372, -0.120841, 0.010627,
    -0.123538, -0.325265, 0.605378,
    -0.069716, -0.172871, 0.653504,
    -0.064457, -0.304887, 0.716034,
    -0.042738, -0.328337, 0.696217,
    -0.065128, -0.312555, 0.693252,
    -0.11211, -0.339772, 0.58925,
    -0.034552, -0.332962, 0.708306,
    -0.031022, -0.344632, 0.653628,
    -0.114984, -0.016329, 0.48152,
    -0.09107, -0.029714, 0.50831,
    -0.076561, -0.041066, 0.539294,
    -0.333861, 0.016096, 0.451886,
    -0.365867, 0.040949, 0.449387,
    0.242283, 0.00767, 0.479961,
    0.192304, 0.008378, 0.479514,
    0.243031, 0.054718, 0.478538,
    0.292764, 0.007085, 0.480192,
    0.241835, -0.039333, 0.480922,
    -0.241978, 0.008745, 0.476712,
    -0.292148, 0.008746, 0.476299,
    -0.241732, 0.055759, 0.475661,
    -0.191533, 0.009015, 0.477123,
    -0.242312, -0.038204, 0.477666,
]);
const FaceMeshNormals = new Float32Array([
    -0.013488, 0.001504, 0.999908,
    -0.014468, -0.501564, 0.864999,
    -0.010782, -0.513822, 0.857829,
    0.574817, 0.481305, 0.661763,
    -0.013402, 0.039797, 0.999118,
    -0.01001, 0.42493, 0.905171,
    -0.009898, 0.522793, 0.852403,
    0.596508, 0.015906, 0.802449,
    -0.013418, -0.257411, 0.966209,
    -0.013176, -0.016251, 0.999781,
    -0.010659, 0.168284, 0.985681,
    -0.011163, -0.444194, 0.895861,
    -0.008391, -0.720698, 0.693198,
    -0.006366, -0.837685, 0.546117,
    -0.014009, 0.21374, 0.97679,
    -0.011235, 0.302436, 0.953103,
    -0.010376, 0.043124, 0.999016,
    -0.009587, -0.507826, 0.861406,
    -0.01116, -0.29826, 0.954419,
    -0.010828, -0.904601, 0.426122,
    0.477216, -0.823756, 0.306091,
    0.901323, 0.051939, 0.430023,
    0.022158, 0.062627, 0.997791,
    0.205296, -0.027533, 0.978313,
    0.389971, 0.00331, 0.920821,
    0.540534, 0.097499, 0.835653,
    -0.031603, 0.235148, 0.971446,
    0.12899, -0.473679, 0.8712,
    0.049221, -0.490083, 0.870285,
    0.199396, -0.485762, 0.851044,
    0.315552, -0.485497, 0.815303,
    0.542152, 0.144159, 0.827822,
    0.533616, -0.19546, 0.82283,
    0.524274, -0.323649, 0.787647,
    0.95518, 0.019738, 0.295365,
    0.646454, -0.17189, 0.743338,
    0.239797, 0.032392, 0.970282,
    0.211579, -0.016445, 0.977223,
    0.167884, -0.787075, 0.593572,
    0.484073, -0.086641, 0.870727,
    0.52675, -0.382653, 0.75902,
    0.278353, -0.747925, 0.602601,
    0.284129, -0.833149, 0.474483,
    0.520648, -0.071856, 0.850742,
    0.375498, -0.457959, 0.805776,
    0.537062, 0.088614, 0.838875,
    0.706227, -0.234996, 0.667847,
    0.615343, 0.330599, 0.715582,
    0.873483, -0.102777, 0.475883,
    0.929469, 0.282827, 0.236847,
    0.612619, -0.007927, 0.790338,
    0.51382, 0.452776, 0.728685,
    0.370848, -0.371581, 0.851116,
    0.457054, -0.301389, 0.836819,
    0.814471, 0.143416, 0.5622,
    0.312985, -0.500551, 0.807149,
    0.113735, -0.492319, 0.862952,
    0.321378, -0.356246, 0.877385,
    0.943443, -0.272787, 0.188424,
    0.083732, -0.871852, 0.482558,
    0.483112, -0.726519, 0.488644,
    0.3554, -0.28951, 0.888749,
    0.528472, -0.245444, 0.812696,
    0.73262, 0.157929, 0.662063,
    0.870954, -0.434614, 0.229238,
    0.283709, -0.613157, 0.737257,
    0.290509, 0.060523, 0.954956,
    0.450784, 0.191549, 0.871839,
    0.828828, 0.175308, 0.531329,
    0.35446, 0.189768, 0.915612,
    0.886545, 0.055686, 0.459278,
    0.904251, 0.084469, 0.418564,
    0.241984, -0.507239, 0.827135,
    0.389085, -0.537938, 0.74782,
    0.396794, -0.690379, 0.604923,
    0.290924, -0.753709, 0.58931,
    0.683714, -0.234982, 0.690883,
    0.698962, 0.2463, 0.671408,
    0.289819, 0.829427, 0.477552,
    0.443097, -0.853758, 0.273428,
    0.266073, -0.849056, 0.456409,
    0.218516, -0.849967, 0.479381,
    0.173014, -0.815285, 0.552609,
    0.18815, -0.445953, 0.875057,
    0.266229, -0.435227, 0.860058,
    0.159586, 0.111632, 0.980852,
    0.169874, 0.307844, 0.936149,
    0.214844, 0.287494, 0.933375,
    0.408179, 0.655461, 0.635422,
    0.386599, 0.581636, 0.71571,
    0.565518, 0.189868, 0.802583,
    0.62959, -0.20159, 0.750319,
    0.499088, -0.138925, 0.855343,
    0.980719, -0.106291, 0.16399,
    -0.008687, -0.968888, 0.247348,
    0.347825, 0.852429, 0.390363,
    0.434845, 0.731152, 0.525668,
    0.447278, -0.186869, 0.874655,
    0.605773, -0.086798, 0.790889,
    0.529769, -0.614194, 0.584902,
    0.373463, 0.244302, 0.894898,
    0.3305, 0.160521, 0.930055,
    0.975107, -0.064463, 0.212159,
    0.706252, 0.172932, 0.686515,
    0.63617, 0.223488, 0.738472,
    0.48148, 0.169773, 0.859857,
    0.532125, -0.212693, 0.819515,
    0.167978, -0.012628, 0.98571,
    0.19209, 0.134518, 0.972114,
    0.177083, 0.155491, 0.971835,
    0.484677, 0.089186, 0.870135,
    0.69797, 0.063943, 0.713267,
    0.272937, 0.3951, 0.877155,
    0.487511, -0.476934, 0.731346,
    0.779479, 0.358066, 0.514005,
    0.824893, -0.05467, 0.56264,
    0.893443, -0.024336, 0.448517,
    0.648008, 0.082686, 0.757132,
    0.373634, 0.159417, 0.913774,
    0.231582, 0.219324, 0.947769,
    0.276642, 0.282928, 0.918379,
    0.451609, 0.393314, 0.800846,
    0.679174, 0.353703, 0.643131,
    0.846336, -0.078702, 0.526803,
    0.698966, -0.299079, 0.649614,
    0.305177, -0.870694, 0.385694,
    0.663562, 0.151284, 0.732666,
    0.96704, 0.007575, 0.254512,
    0.677813, 0.436785, 0.591428,
    0.806164, 0.038803, 0.590418,
    0.38902, -0.28994, 0.874413,
    0.835955, 0.34876, 0.423728,
    0.969025, -0.177909, 0.171286,
    0.184195, -0.159076, 0.969931,
    0.768269, 0.382486, 0.513291,
    0.771332, -0.409018, 0.487597,
    0.810023, -0.465284, 0.356895,
    0.9583, -0.117268, 0.260596,
    0.851587, -0.366934, 0.374379,
    0.940305, 0.046015, 0.337208,
    0.610753, -0.484462, 0.626321,
    0.167354, -0.949384, 0.265825,
    0.296534, 0.0171, 0.954869,
    0.862909, -0.016591, 0.505087,
    0.365291, -0.068624, 0.92836,
    0.166278, -0.082197, 0.982647,
    0.704903, 0.18104, 0.685811,
    0.888851, -0.179956, 0.421379,
    0.309751, -0.746083, 0.589419,
    0.695581, -0.527215, 0.488069,
    0.741427, -0.502888, 0.444285,
    -0.01153, 0.142345, 0.98975,
    -0.010774, -0.783157, 0.621731,
    -0.028634, -0.077451, 0.996585,
    -0.177242, -0.000307, 0.984167,
    -0.17376, 0.071597, 0.982182,
    0.887337, -0.041664, 0.459235,
    -0.153522, -0.245068, 0.957274,
    -0.05628, -0.210082, 0.976063,
    0.105244, -0.238695, 0.965375,
    0.27211, -0.289937, 0.917547,
    0.450541, -0.376162, 0.809639,
    0.938827, -0.01209, 0.344176,
    0.543794, -0.12651, 0.829628,
    -0.012891, 0.002625, 0.999913,
    0.464588, 0.06018, 0.88348,
    0.354674, -0.873219, 0.334207,
    0.326112, -0.012118, 0.945253,
    -0.011216, 0.288434, 0.957434,
    0.709017, -0.412316, 0.572093,
    0.704843, -0.461673, 0.538567,
    0.342889, -0.542994, 0.766541,
    0.887858, -0.389005, 0.245731,
    -0.010823, -0.338587, 0.940873,
    0.815766, 0.321407, 0.480857,
    -0.010683, -0.70223, 0.71187,
    0.627688, -0.587279, 0.510991,
    0.949447, -0.180075, 0.257146,
    0.3385, 0.396251, 0.853465,
    0.300204, 0.442158, 0.845207,
    0.38682, 0.103676, 0.916309,
    0.452119, -0.391589, 0.801403,
    0.419546, -0.2862, 0.861435,
    0.270451, -0.852892, 0.446577,
    0.371994, -0.766288, 0.523853,
    0.368551, -0.652932, 0.661702,
    0.443679, -0.382117, 0.810639,
    0.739866, -0.185516, 0.64667,
    0.800847, 0.376918, 0.465378,
    0.673672, -0.447989, 0.587768,
    0.320472, -0.478354, 0.817604,
    0.196144, -0.9089, 0.368005,
    0.783688, -0.303535, 0.541941,
    0.654246, -0.1377, 0.74364,
    0.482243, -0.190583, 0.855056,
    -0.008469, 0.60495, 0.796218,
    0.647844, 0.427633, 0.630418,
    -0.009305, 0.555769, 0.831284,
    0.832726, 0.415705, 0.365728,
    -0.011229, -0.313666, 0.949467,
    -0.010817, -0.061131, 0.998071,
    0.26842, -0.117356, 0.956127,
    0.512133, -0.243189, 0.823759,
    0.321361, 0.048275, 0.945726,
    0.54075, -0.216104, 0.81295,
    0.446851, -0.061419, 0.892498,
    0.336137, -0.046749, 0.940652,
    0.5785, -0.240136, 0.779533,
    0.292058, -0.173915, 0.940455,
    0.883097, 0.357162, 0.304261,
    0.609629, -0.32731, 0.721956,
    0.635593, -0.275428, 0.721222,
    0.480446, -0.316781, 0.817815,
    0.848071, -0.25604, 0.463917,
    0.681541, -0.321509, 0.657369,
    0.919448, -0.276731, 0.279347,
    0.439022, -0.227261, 0.86926,
    0.815354, 0.279567, 0.506991,
    0.63961, -0.563974, 0.522333,
    0.56355, -0.66094, 0.49555,
    0.762961, -0.005446, 0.646422,
    0.350256, -0.725652, 0.592242,
    0.198859, -0.709002, 0.676588,
    0.192013, -0.55998, 0.805949,
    0.265497, -0.479396, 0.836475,
    0.432694, -0.474811, 0.766375,
    0.528648, -0.122426, 0.839966,
    0.962363, -0.041766, 0.26854,
    0.491954, 0.113935, 0.863134,
    0.380751, 0.129983, 0.915496,
    0.211745, 0.091712, 0.973012,
    0.10747, 0.121765, 0.986724,
    0.27739, 0.358323, 0.891437,
    0.56415, 0.406921, 0.718436,
    0.983285, -0.040115, 0.177599,
    0.390983, -0.763145, 0.51453,
    0.790599, 0.39588, 0.467154,
    0.615882, -0.575379, 0.538171,
    0.546448, -0.819314, 0.173548,
    0.57594, -0.771784, 0.269523,
    0.405962, -0.711886, 0.573074,
    0.465727, -0.853086, 0.235248,
    0.470819, -0.8187, 0.328724,
    0.596549, -0.045765, 0.801271,
    0.792717, 0.093557, 0.602368,
    0.850308, 0.251689, 0.4622,
    0.58707, -0.466959, 0.661285,
    0.386053, -0.568896, 0.726168,
    -0.58425, 0.476947, 0.656638,
    -0.602355, 0.005089, 0.798212,
    -0.490817, -0.812691, 0.314058,
    -0.905003, 0.062758, 0.42075,
    -0.045742, 0.061238, 0.997074,
    -0.230492, -0.029172, 0.972637,
    -0.411451, 0.00082, 0.911432,
    -0.556853, 0.086668, 0.826077,
    0.013529, 0.234376, 0.972052,
    -0.15875, -0.458738, 0.874275,
    -0.070239, -0.480705, 0.874065,
    -0.236783, -0.466103, 0.852456,
    -0.364172, -0.473911, 0.80174,
    -0.554871, 0.136551, 0.820653,
    -0.546774, -0.190529, 0.815314,
    -0.5509, -0.314578, 0.773013,
    -0.955343, 0.023149, 0.294592,
    -0.656305, -0.162978, 0.736683,
    -0.259488, 0.034351, 0.965135,
    -0.232494, -0.026116, 0.972247,
    -0.179775, -0.78667, 0.590619,
    -0.495756, -0.104271, 0.862179,
    -0.533676, -0.393146, 0.74875,
    -0.287669, -0.750056, 0.595535,
    -0.295097, -0.834586, 0.46517,
    -0.527717, -0.05701, 0.847505,
    -0.397417, -0.453067, 0.797991,
    -0.551701, 0.088911, 0.829289,
    -0.719938, -0.216769, 0.659318,
    -0.628102, 0.324741, 0.707129,
    -0.875287, -0.104148, 0.472256,
    -0.930173, 0.281107, 0.236128,
    -0.623341, -0.004401, 0.781937,
    -0.525272, 0.448917, 0.722885,
    -0.395611, -0.348819, 0.849598,
    -0.480931, -0.280639, 0.830631,
    -0.819491, 0.148295, 0.553574,
    -0.33835, -0.487527, 0.804883,
    -0.130411, -0.485113, 0.864672,
    -0.33558, -0.354229, 0.872873,
    -0.943193, -0.27004, 0.19356,
    -0.236079, -0.867121, 0.438597,
    -0.492549, -0.720995, 0.487403,
    -0.364688, -0.275696, 0.889379,
    -0.539879, -0.246905, 0.804716,
    -0.734908, 0.148475, 0.661714,
    -0.870526, -0.433609, 0.232741,
    -0.310715, -0.595067, 0.741182,
    -0.312903, 0.056937, 0.948077,
    -0.46519, 0.192071, 0.864122,
    -0.831985, 0.176839, 0.52586,
    -0.371718, 0.183947, 0.909939,
    -0.883975, 0.049788, 0.464876,
    -0.905403, 0.085893, 0.415774,
    -0.25763, -0.506073, 0.823115,
    -0.402319, -0.538627, 0.740284,
    -0.409771, -0.691835, 0.594519,
    -0.246726, -0.673948, 0.696362,
    -0.692845, -0.217241, 0.687584,
    -0.703118, 0.245639, 0.667298,
    -0.287444, 0.838903, 0.462189,
    -0.424298, -0.854733, 0.299004,
    -0.25384, -0.871936, 0.41868,
    -0.219877, -0.860633, 0.459309,
    -0.181798, -0.825162, 0.534843,
    -0.21116, -0.43951, 0.873065,
    -0.285129, -0.426771, 0.858235,
    -0.181501, 0.113954, 0.976766,
    -0.192846, 0.315362, 0.92917,
    -0.240224, 0.295307, 0.924709,
    -0.411413, 0.664826, 0.623495,
    -0.394424, 0.588783, 0.705524,
    -0.576994, 0.192676, 0.793697,
    -0.633766, -0.182173, 0.751767,
    -0.510703, -0.149586, 0.846644,
    -0.981731, -0.098816, 0.162603,
    -0.340824, 0.859435, 0.381065,
    -0.435991, 0.735309, 0.518876,
    -0.464042, -0.187428, 0.865758,
    -0.61883, -0.086762, 0.780719,
    -0.544607, -0.597537, 0.588518,
    -0.38921, 0.241533, 0.888919,
    -0.348726, 0.160919, 0.923307,
    -0.974243, -0.072678, 0.21347,
    -0.713667, 0.175279, 0.678201,
    -0.64457, 0.22133, 0.731808,
    -0.493799, 0.159958, 0.854737,
    -0.539006, -0.201322, 0.817889,
    -0.194595, -0.013508, 0.980791,
    -0.214325, 0.132619, 0.967718,
    -0.198421, 0.154131, 0.967922,
    -0.499245, 0.082851, 0.862491,
    -0.701431, 0.062987, 0.709948,
    -0.287865, 0.391328, 0.874069,
    -0.516101, -0.458673, 0.723366,
    -0.783947, 0.353515, 0.510347,
    -0.827862, -0.054652, 0.558263,
    -0.89325, -0.021808, 0.449031,
    -0.65537, 0.084367, 0.750582,
    -0.392515, 0.160139, 0.905697,
    -0.253484, 0.21696, 0.942695,
    -0.293705, 0.276084, 0.915158,
    -0.463308, 0.384037, 0.798662,
    -0.684889, 0.351594, 0.638208,
    -0.847526, -0.075191, 0.525401,
    -0.711299, -0.281264, 0.644162,
    -0.322875, -0.865164, 0.383722,
    -0.67513, 0.151647, 0.721943,
    -0.968039, 0.015345, 0.250328,
    -0.681424, 0.429902, 0.592323,
    -0.813401, 0.039954, 0.58033,
    -0.422069, -0.284065, 0.860909,
    -0.837161, 0.347573, 0.422321,
    -0.96939, -0.173229, 0.173994,
    -0.206334, -0.155929, 0.965978,
    -0.771854, 0.380131, 0.509649,
    -0.775399, -0.402359, 0.486687,
    -0.812667, -0.460285, 0.357365,
    -0.958487, -0.112125, 0.262167,
    -0.852386, -0.362729, 0.37665,
    -0.940199, 0.05012, 0.336919,
    -0.621014, -0.48039, 0.619328,
    -0.181613, -0.945739, 0.269433,
    -0.317986, 0.020718, 0.947869,
    -0.862881, -0.012368, 0.505256,
    -0.38901, -0.074545, 0.918212,
    -0.192093, -0.087609, 0.977458,
    -0.697684, 0.204862, 0.68649,
    -0.889179, -0.177, 0.421938,
    -0.327065, -0.743494, 0.583306,
    -0.698793, -0.527785, 0.482837,
    -0.745135, -0.498856, 0.442625,
    0.005496, -0.081297, 0.996675,
    0.159103, 0.000254, 0.987262,
    0.160112, 0.077365, 0.984062,
    -0.886113, -0.034006, 0.46222,
    0.135484, -0.247944, 0.959254,
    0.033697, -0.213181, 0.976431,
    -0.130904, -0.240184, 0.961861,
    -0.301054, -0.287549, 0.909221,
    -0.48344, -0.337638, 0.807642,
    -0.941739, 0.000626, 0.336343,
    -0.556695, -0.133523, 0.819916,
    -0.481439, 0.052266, 0.87492,
    -0.361641, -0.869131, 0.337382,
    -0.344614, -0.023413, 0.938452,
    -0.714574, -0.405373, 0.570137,
    -0.710049, -0.458599, 0.534338,
    -0.360799, -0.539616, 0.760683,
    -0.88772, -0.386166, 0.250655,
    -0.005494, -0.337757, 0.941217,
    -0.818606, 0.317827, 0.478404,
    -0.635834, -0.586169, 0.502117,
    -0.948935, -0.17725, 0.260969,
    -0.354754, 0.405411, 0.842491,
    -0.315161, 0.450977, 0.835041,
    -0.404051, 0.107994, 0.908339,
    -0.469503, -0.378085, 0.797884,
    -0.436037, -0.27362, 0.857324,
    -0.282562, -0.851857, 0.441019,
    -0.384341, -0.764283, 0.517836,
    -0.373501, -0.657177, 0.654688,
    -0.45208, -0.387884, 0.803225,
    -0.745376, -0.183276, 0.640956,
    -0.802203, 0.376545, 0.46334,
    -0.682186, -0.431525, 0.590262,
    -0.340976, -0.468346, 0.8151,
    -0.190004, -0.919384, 0.34443,
    -0.786518, -0.299119, 0.540294,
    -0.659707, -0.133106, 0.739642,
    -0.495576, -0.182659, 0.849141,
    -0.655039, 0.424157, 0.625312,
    -0.834006, 0.414119, 0.364608,
    -0.289185, -0.112484, 0.950642,
    -0.525157, -0.236776, 0.817403,
    -0.341449, 0.048146, 0.938666,
    -0.552055, -0.20957, 0.807041,
    -0.462445, -0.059828, 0.884627,
    -0.354578, -0.047085, 0.93384,
    -0.589702, -0.238437, 0.771621,
    -0.311463, -0.171545, 0.934646,
    -0.883801, 0.356843, 0.302588,
    -0.618972, -0.319819, 0.717349,
    -0.644356, -0.268957, 0.715869,
    -0.493011, -0.315246, 0.810901,
    -0.848161, -0.254174, 0.464779,
    -0.688502, -0.316383, 0.652585,
    -0.918934, -0.27419, 0.283514,
    -0.453211, -0.227378, 0.861916,
    -0.819396, 0.27511, 0.502896,
    -0.647165, -0.557346, 0.520137,
    -0.574277, -0.653073, 0.493661,
    -0.769104, -0.004042, 0.639111,
    -0.371024, -0.709076, 0.599627,
    -0.223399, -0.689458, 0.689014,
    -0.224954, -0.535402, 0.814088,
    -0.301113, -0.456912, 0.836996,
    -0.465514, -0.451682, 0.761104,
    -0.549072, -0.117, 0.827545,
    -0.96262, -0.038043, 0.268169,
    -0.508332, 0.106742, 0.85452,
    -0.401231, 0.126318, 0.907225,
    -0.235329, 0.088102, 0.967914,
    -0.129996, 0.116611, 0.984633,
    -0.291789, 0.347054, 0.891298,
    -0.568407, 0.392698, 0.722981,
    -0.983782, -0.036182, 0.175679,
    -0.407933, -0.757172, 0.510178,
    -0.794408, 0.391605, 0.464286,
    -0.626928, -0.568161, 0.533061,
    -0.595684, -0.792782, 0.129066,
    -0.545575, -0.809678, 0.216261,
    -0.423466, -0.696461, 0.579326,
    -0.478672, -0.8461, 0.234495,
    -0.485182, -0.807689, 0.335019,
    -0.60819, -0.045213, 0.792503,
    -0.789043, 0.08604, 0.608283,
    -0.84767, 0.246556, 0.46975,
    -0.563091, -0.462204, 0.685052,
    -0.414252, -0.557791, 0.719211,
    -0.006436, 0.025388, 0.999657,
    -0.0086, 0.025415, 0.99964,
    -0.006372, 0.03029, 0.999521,
    -0.004293, 0.025361, 0.999669,
    -0.0065, 0.020479, 0.999769,
    -0.008233, 0.02136, 0.999738,
    -0.008224, 0.02136, 0.999738,
    -0.008235, 0.022364, 0.999716,
    -0.008241, 0.02136, 0.999738,
    -0.00823, 0.020355, 0.999759,
]);
// TODO(yuyan): figure out why we need to flip x from
// 'FACEMESH_UVS_ORIGINAL' in 'reality/engine/faces/facemesh-data.h'
const FaceMeshUvs = new Float32Array([
    0.499634, 0.347046,
    0.500122, 0.452515,
    0.500122, 0.397339,
    0.518677, 0.527466,
    0.500122, 0.472778,
    0.500122, 0.501587,
    0.500366, 0.598877,
    0.710327, 0.619507,
    0.500122, 0.687378,
    0.500122, 0.729858,
    0.499878, 0.893188,
    0.500122, 0.333618,
    0.500122, 0.320679,
    0.499878, 0.307983,
    0.500122, 0.30481,
    0.500122, 0.294067,
    0.500122, 0.28064,
    0.501099, 0.264038,
    0.500122, 0.218384,
    0.501099, 0.437622,
    0.526245, 0.425903,
    0.895386, 0.745728,
    0.634399, 0.589966,
    0.661255, 0.587036,
    0.68811, 0.590454,
    0.725464, 0.610718,
    0.608032, 0.596802,
    0.654175, 0.656616,
    0.630005, 0.653442,
    0.68103, 0.652466,
    0.702271, 0.646118,
    0.752563, 0.589233,
    0.603394, 0.158325,
    0.720093, 0.624146,
    0.893921, 0.599731,
    0.790405, 0.608765,
    0.644409, 0.465698,
    0.528198, 0.349243,
    0.526245, 0.319214,
    0.560669, 0.342407,
    0.585083, 0.333618,
    0.549927, 0.319214,
    0.571411, 0.317261,
    0.625366, 0.272095,
    0.51355, 0.452271,
    0.515015, 0.472534,
    0.742554, 0.685425,
    0.598877, 0.544678,
    0.570435, 0.451294,
    0.578735, 0.466187,
    0.722534, 0.467896,
    0.516968, 0.500122,
    0.66272, 0.716919,
    0.703491, 0.707642,
    0.830933, 0.80603,
    0.552979, 0.69751,
    0.607544, 0.645874,
    0.646118, 0.302856,
    0.933472, 0.269897,
    0.557739, 0.42688,
    0.543335, 0.414917,
    0.61853, 0.305054,
    0.607788, 0.305542,
    0.723511, 0.727661,
    0.578003, 0.436401,
    0.614136, 0.718384,
    0.616821, 0.744019,
    0.668579, 0.880249,
    0.770142, 0.767944,
    0.635864, 0.810913,
    0.770386, 0.700562,
    0.826782, 0.721558,
    0.527222, 0.333862,
    0.552856, 0.331177,
    0.577026, 0.325562,
    0.55481, 0.4198,
    0.612183, 0.30603,
    0.59729, 0.293579,
    0.596313, 0.30603,
    0.540405, 0.442261,
    0.568726, 0.307251,
    0.547974, 0.307495,
    0.524536, 0.307251,
    0.534302, 0.220581,
    0.52771, 0.26355,
    0.527222, 0.28186,
    0.527222, 0.295044,
    0.526978, 0.305786,
    0.572144, 0.304565,
    0.573608, 0.296509,
    0.577026, 0.28894,
    0.581665, 0.279663,
    0.609985, 0.360229,
    0.98645, 0.440063,
    0.500122, 0.419556,
    0.586792, 0.304321,
    0.590698, 0.297974,
    0.532349, 0.398315,
    0.577515, 0.413696,
    0.537231, 0.405884,
    0.628052, 0.526489,
    0.665649, 0.504028,
    0.588501, 0.453003,
    0.758179, 0.852661,
    0.709839, 0.798706,
    0.672729, 0.74353,
    0.600708, 0.251099,
    0.558228, 0.738403,
    0.570435, 0.812134,
    0.587769, 0.891235,
    0.711304, 0.600952,
    0.781372, 0.564331,
    0.58728, 0.600952,
    0.742798, 0.644653,
    0.572388, 0.562134,
    0.551636, 0.463257,
    0.821655, 0.542358,
    0.752808, 0.542847,
    0.713989, 0.532349,
    0.667114, 0.539185,
    0.63147, 0.552856,
    0.601196, 0.567261,
    0.523315, 0.594116,
    0.810669, 0.476196,
    0.771362, 0.651245,
    0.509399, 0.437622,
    0.596069, 0.515503,
    0.981079, 0.598511,
    0.573853, 0.579224,
    0.603149, 0.451294,
    0.733765, 0.622925,
    0.561157, 0.481323,
    0.96814, 0.355591,
    0.580933, 0.612915,
    0.537476, 0.494019,
    0.761353, 0.220337,
    0.802124, 0.168091,
    0.892456, 0.459351,
    0.816528, 0.259888,
    0.865845, 0.666382,
    0.614136, 0.117065,
    0.508911, 0.420532,
    0.617798, 0.491333,
    0.825806, 0.602417,
    0.680298, 0.604126,
    0.65686, 0.599243,
    0.604126, 0.289673,
    0.812134, 0.411743,
    0.569214, 0.055786,
    0.681274, 0.10144,
    0.734009, 0.130005,
    0.500122, 0.809448,
    0.500366, 0.045288,
    0.633911, 0.601196,
    0.606812, 0.603882,
    0.589722, 0.609253,
    0.805298, 0.657837,
    0.611694, 0.637817,
    0.634399, 0.643921,
    0.65686, 0.644653,
    0.681274, 0.641724,
    0.698608, 0.636841,
    0.942505, 0.680786,
    0.698364, 0.612671,
    0.500122, 0.38147,
    0.584106, 0.375854,
    0.554321, 0.434448,
    0.534302, 0.379272,
    0.500854, 0.649048,
    0.711548, 0.180054,
    0.664917, 0.147339,
    0.559692, 0.097534,
    0.871948, 0.20813,
    0.590942, 0.625854,
    0.544312, 0.548462,
    0.500122, 0.090942,
    0.624634, 0.075562,
    0.885864, 0.384888,
    0.551392, 0.30481,
    0.552612, 0.296021,
    0.552856, 0.284302,
    0.555298, 0.268921,
    0.570435, 0.233032,
    0.593384, 0.314331,
    0.599243, 0.31897,
    0.607544, 0.322388,
    0.632446, 0.33606,
    0.752075, 0.39856,
    0.547729, 0.579468,
    0.563599, 0.640015,
    0.584106, 0.631226,
    0.586548, 0.307739,
    0.772095, 0.316528,
    0.53186, 0.647339,
    0.588989, 0.195679,
    0.500366, 0.530396,
    0.521118, 0.557251,
    0.49939, 0.560181,
    0.567993, 0.50647,
    0.500122, 0.133179,
    0.500122, 0.178101,
    0.543335, 0.180298,
    0.655396, 0.254272,
    0.621216, 0.425659,
    0.62561, 0.21936,
    0.680298, 0.429077,
    0.643188, 0.395142,
    0.704712, 0.378296,
    0.552612, 0.137329,
    0.588989, 0.491089,
    0.686157, 0.224976,
    0.646118, 0.187134,
    0.675659, 0.295776,
    0.811157, 0.353149,
    0.720337, 0.285034,
    0.866333, 0.317261,
    0.663452, 0.355103,
    0.569946, 0.533325,
    0.544556, 0.45105,
    0.56311, 0.440796,
    0.532593, 0.470093,
    0.585571, 0.664429,
    0.622925, 0.67688,
    0.655884, 0.679565,
    0.687378, 0.677124,
    0.716431, 0.667114,
    0.758911, 0.617065,
    0.897583, 0.530884,
    0.732544, 0.575317,
    0.702271, 0.566528,
    0.666626, 0.565552,
    0.633911, 0.573608,
    0.604126, 0.58313,
    0.579956, 0.589233,
    0.993042, 0.518921,
    0.567505, 0.430542,
    0.541626, 0.52063,
    0.526489, 0.453979,
    0.524048, 0.435913,
    0.53186, 0.444458,
    0.565552, 0.418335,
    0.516479, 0.43689,
    0.517456, 0.421997,
    0.573608, 0.610229,
    0.560669, 0.602905,
    0.549927, 0.599487,
    0.710571, 0.631958,
    0.723267, 0.636353,
    0.481567, 0.528687,
    0.289673, 0.619019,
    0.474243, 0.425903,
    0.104858, 0.745728,
    0.365845, 0.59021,
    0.338989, 0.586792,
    0.311157, 0.59021,
    0.27478, 0.610718,
    0.393433, 0.595825,
    0.345581, 0.655396,
    0.370239, 0.653687,
    0.319214, 0.652466,
    0.297974, 0.646118,
    0.247681, 0.589478,
    0.397339, 0.15686,
    0.280151, 0.624146,
    0.106567, 0.599487,
    0.210083, 0.608276,
    0.356079, 0.46521,
    0.472046, 0.349243,
    0.474487, 0.319458,
    0.439819, 0.342407,
    0.414917, 0.33313,
    0.450317, 0.31897,
    0.428833, 0.317017,
    0.375122, 0.271606,
    0.486938, 0.452515,
    0.484985, 0.472534,
    0.257812, 0.685303,
    0.401733, 0.5448,
    0.429565, 0.451782,
    0.421509, 0.466187,
    0.276978, 0.467651,
    0.483765, 0.500122,
    0.33728, 0.717163,
    0.296509, 0.706421,
    0.169556, 0.80603,
    0.447632, 0.696899,
    0.3927, 0.64563,
    0.354614, 0.303101,
    0.066772, 0.269897,
    0.442505, 0.42688,
    0.457153, 0.414917,
    0.381714, 0.305054,
    0.392456, 0.305298,
    0.276978, 0.727661,
    0.422241, 0.436646,
    0.386108, 0.718384,
    0.383423, 0.743774,
    0.331665, 0.879761,
    0.229858, 0.767944,
    0.36438, 0.810425,
    0.229858, 0.700317,
    0.172485, 0.721313,
    0.472778, 0.333618,
    0.446899, 0.331177,
    0.422729, 0.325806,
    0.444946, 0.4198,
    0.387817, 0.305298,
    0.402954, 0.293335,
    0.403687, 0.305786,
    0.460083, 0.442749,
    0.431519, 0.307251,
    0.452515, 0.308716,
    0.47522, 0.307739,
    0.465942, 0.220581,
    0.47229, 0.26355,
    0.473267, 0.28186,
    0.473267, 0.295044,
    0.473267, 0.304321,
    0.427856, 0.305786,
    0.426636, 0.296021,
    0.423462, 0.28772,
    0.418335, 0.279663,
    0.390259, 0.359741,
    0.014038, 0.439819,
    0.413452, 0.304321,
    0.409546, 0.297974,
    0.467896, 0.398315,
    0.422729, 0.413696,
    0.463013, 0.405884,
    0.372437, 0.526733,
    0.334595, 0.503784,
    0.411743, 0.454224,
    0.24231, 0.852417,
    0.291138, 0.798218,
    0.327515, 0.743286,
    0.39978, 0.250854,
    0.442261, 0.737671,
    0.430054, 0.81189,
    0.411743, 0.890503,
    0.28894, 0.601196,
    0.218872, 0.564087,
    0.41272, 0.600708,
    0.257202, 0.644409,
    0.428101, 0.562378,
    0.448364, 0.463257,
    0.178589, 0.542114,
    0.247437, 0.542603,
    0.286255, 0.532104,
    0.332886, 0.539673,
    0.369263, 0.552612,
    0.399048, 0.567261,
    0.476685, 0.594116,
    0.189331, 0.475708,
    0.228882, 0.650757,
    0.490845, 0.437134,
    0.404907, 0.514771,
    0.018921, 0.598267,
    0.42688, 0.579956,
    0.397095, 0.45105,
    0.266479, 0.622681,
    0.439575, 0.480835,
    0.032593, 0.356567,
    0.419556, 0.612671,
    0.46228, 0.494263,
    0.239136, 0.220093,
    0.19812, 0.167847,
    0.107544, 0.459351,
    0.183716, 0.259644,
    0.134399, 0.666382,
    0.386108, 0.116577,
    0.491333, 0.420288,
    0.382202, 0.491333,
    0.174438, 0.602417,
    0.318726, 0.603638,
    0.342651, 0.599487,
    0.396362, 0.289429,
    0.187866, 0.411743,
    0.432007, 0.055298,
    0.31897, 0.101196,
    0.266235, 0.130005,
    0.365845, 0.600708,
    0.393433, 0.603882,
    0.410767, 0.608765,
    0.194946, 0.657593,
    0.38855, 0.637817,
    0.365845, 0.643921,
    0.343384, 0.644409,
    0.318726, 0.641479,
    0.301636, 0.636353,
    0.057983, 0.68103,
    0.302124, 0.612183,
    0.415894, 0.37561,
    0.445679, 0.434204,
    0.467163, 0.379761,
    0.288696, 0.17981,
    0.335571, 0.146851,
    0.440796, 0.097046,
    0.128296, 0.207642,
    0.408813, 0.626099,
    0.454956, 0.548706,
    0.376099, 0.075562,
    0.114868, 0.384644,
    0.448853, 0.30481,
    0.448364, 0.295288,
    0.447388, 0.284058,
    0.444946, 0.268433,
    0.430054, 0.232788,
    0.407349, 0.314575,
    0.401001, 0.31897,
    0.392456, 0.322632,
    0.368286, 0.336548,
    0.248169, 0.398804,
    0.453003, 0.579712,
    0.436646, 0.640259,
    0.416382, 0.631226,
    0.413696, 0.307739,
    0.228394, 0.316528,
    0.468628, 0.647583,
    0.411987, 0.195435,
    0.47937, 0.557495,
    0.432251, 0.50647,
    0.456909, 0.180786,
    0.344849, 0.254517,
    0.379517, 0.426147,
    0.374634, 0.219604,
    0.319946, 0.429077,
    0.357544, 0.39563,
    0.295532, 0.378296,
    0.44812, 0.137329,
    0.411499, 0.491333,
    0.314331, 0.224731,
    0.35437, 0.187378,
    0.324829, 0.296021,
    0.189331, 0.353638,
    0.280151, 0.285278,
    0.134399, 0.316772,
    0.336792, 0.355347,
    0.429565, 0.532837,
    0.455444, 0.451782,
    0.437378, 0.441284,
    0.46814, 0.469849,
    0.414917, 0.664673,
    0.377319, 0.677124,
    0.34436, 0.680054,
    0.312866, 0.677856,
    0.284058, 0.66687,
    0.241333, 0.61731,
    0.103394, 0.530884,
    0.2677, 0.575806,
    0.297974, 0.566772,
    0.333618, 0.56604,
    0.366821, 0.573853,
    0.396362, 0.583374,
    0.420532, 0.589722,
    0.007202, 0.519165,
    0.432739, 0.430786,
    0.458862, 0.521118,
    0.473511, 0.453979,
    0.47644, 0.436157,
    0.468872, 0.444702,
    0.434204, 0.417358,
    0.483765, 0.436646,
    0.483032, 0.422241,
    0.426636, 0.610229,
    0.439819, 0.604614,
    0.450562, 0.60022,
    0.289917, 0.631714,
    0.276978, 0.636597,
    1, 0,
    1, 0,
    1, 0,
    1, 0,
    1, 0,
    1, 0,
    1, 0,
    1, 0,
    1, 0,
    1, 0,
]);
const FaceMeshIndices = new Uint32Array([
    127, 139, 34,
    11, 37, 0,
    232, 120, 231,
    72, 39, 37,
    128, 47, 121,
    232, 128, 121,
    104, 67, 69,
    175, 148, 171,
    118, 101, 50,
    73, 40, 39,
    9, 108, 151,
    48, 131, 115,
    194, 211, 204,
    74, 185, 40,
    80, 183, 42,
    40, 186, 92,
    230, 118, 229,
    202, 214, 212,
    83, 17, 18,
    76, 146, 61,
    106, 194, 204,
    135, 192, 214,
    203, 98, 165,
    21, 68, 71,
    51, 4, 45,
    77, 91, 146,
    205, 187, 50,
    201, 18, 200,
    91, 182, 106,
    90, 181, 91,
    85, 17, 84,
    206, 36, 203,
    148, 140, 171,
    92, 39, 40,
    193, 244, 189,
    236, 196, 3,
    54, 104, 68,
    193, 8, 168,
    117, 31, 228,
    189, 55, 193,
    98, 99, 97,
    126, 100, 47,
    166, 218, 79,
    209, 131, 49,
    135, 150, 136,
    47, 217, 126,
    223, 53, 52,
    45, 134, 51,
    211, 140, 170,
    67, 108, 69,
    43, 91, 106,
    230, 120, 119,
    226, 247, 130,
    63, 52, 53,
    238, 242, 20,
    46, 156, 70,
    78, 96, 62,
    46, 63, 53,
    143, 227, 34,
    123, 111, 117,
    44, 19, 125,
    236, 51, 134,
    216, 205, 206,
    39, 167, 37,
    200, 208, 201,
    36, 100, 142,
    57, 202, 212,
    20, 99, 60,
    35, 113, 226,
    204, 210, 202,
    113, 46, 225,
    43, 204, 202,
    62, 77, 76,
    137, 116, 123,
    41, 72, 38,
    203, 142, 129,
    64, 240, 98,
    49, 64, 102,
    41, 74, 73,
    212, 207, 216,
    42, 184, 74,
    169, 211, 170,
    170, 176, 149,
    105, 69, 66,
    122, 168, 6,
    123, 187, 147,
    96, 90, 77,
    65, 107, 55,
    89, 180, 90,
    101, 120, 100,
    63, 104, 105,
    93, 227, 137,
    15, 85, 86,
    129, 49, 102,
    14, 86, 87,
    55, 9, 8,
    100, 121, 47,
    88, 179, 89,
    6, 196, 122,
    88, 96, 95,
    138, 136, 172,
    215, 172, 58,
    115, 219, 48,
    42, 81, 80,
    195, 51, 3,
    43, 61, 146,
    171, 199, 175,
    81, 38, 82,
    53, 225, 46,
    52, 66, 65,
    229, 117, 228,
    34, 234, 127,
    107, 69, 108,
    109, 151, 108,
    48, 235, 64,
    62, 191, 78,
    129, 126, 209,
    111, 143, 35,
    117, 50, 123,
    222, 52, 65,
    19, 141, 125,
    221, 65, 55,
    3, 197, 195,
    220, 44, 237,
    70, 139, 71,
    122, 245, 193,
    71, 162, 21,
    170, 150, 169,
    188, 196, 174,
    216, 92, 186,
    2, 167, 97,
    141, 241, 125,
    164, 37, 167,
    72, 12, 38,
    38, 13, 82,
    63, 71, 68,
    226, 111, 35,
    101, 205, 50,
    206, 165, 92,
    209, 217, 198,
    165, 97, 167,
    220, 218, 115,
    239, 241, 238,
    214, 169, 135,
    171, 32, 208,
    125, 237, 44,
    86, 178, 87,
    85, 179, 86,
    84, 180, 85,
    83, 181, 84,
    201, 182, 83,
    137, 132, 93,
    76, 183, 62,
    61, 184, 76,
    57, 185, 61,
    212, 186, 57,
    214, 187, 207,
    34, 156, 143,
    79, 237, 239,
    123, 177, 137,
    44, 4, 1,
    201, 32, 194,
    64, 129, 102,
    213, 138, 215,
    59, 219, 166,
    242, 97, 99,
    2, 141, 94,
    75, 235, 59,
    24, 228, 110,
    25, 226, 130,
    23, 229, 24,
    22, 230, 23,
    26, 231, 22,
    112, 232, 26,
    189, 243, 190,
    221, 190, 56,
    28, 221, 56,
    27, 222, 28,
    29, 223, 27,
    30, 224, 29,
    247, 225, 30,
    238, 20, 79,
    166, 75, 59,
    60, 240, 75,
    147, 215, 177,
    20, 166, 79,
    187, 213, 147,
    112, 244, 233,
    233, 245, 128,
    128, 188, 114,
    114, 174, 217,
    131, 220, 115,
    217, 236, 198,
    198, 134, 131,
    177, 58, 132,
    143, 124, 35,
    228, 25, 110,
    356, 368, 389,
    11, 267, 302,
    452, 349, 350,
    302, 269, 303,
    357, 277, 343,
    452, 357, 453,
    333, 297, 332,
    175, 377, 152,
    347, 330, 348,
    303, 270, 304,
    9, 337, 336,
    278, 360, 279,
    418, 431, 262,
    304, 409, 408,
    310, 407, 415,
    270, 410, 409,
    450, 347, 348,
    422, 434, 430,
    313, 17, 314,
    306, 375, 307,
    335, 418, 406,
    364, 416, 367,
    423, 327, 358,
    251, 298, 284,
    281, 4, 5,
    307, 321, 320,
    425, 411, 427,
    421, 18, 313,
    321, 406, 405,
    320, 405, 404,
    315, 17, 16,
    426, 266, 425,
    377, 369, 400,
    322, 269, 391,
    417, 464, 465,
    456, 419, 399,
    284, 333, 332,
    417, 8, 285,
    346, 261, 340,
    413, 285, 441,
    327, 328, 460,
    355, 329, 371,
    392, 438, 439,
    429, 360, 420,
    364, 379, 394,
    277, 437, 343,
    443, 283, 444,
    275, 363, 440,
    431, 369, 262,
    297, 337, 338,
    273, 321, 375,
    450, 349, 451,
    446, 467, 342,
    293, 282, 334,
    458, 462, 461,
    276, 383, 353,
    308, 325, 324,
    276, 293, 300,
    372, 447, 345,
    352, 340, 345,
    274, 19, 1,
    456, 281, 248,
    436, 425, 427,
    269, 393, 391,
    200, 428, 199,
    266, 329, 330,
    287, 422, 273,
    250, 328, 462,
    265, 342, 353,
    424, 430, 431,
    342, 276, 353,
    273, 424, 335,
    292, 307, 325,
    366, 345, 447,
    271, 302, 303,
    423, 371, 266,
    294, 460, 455,
    279, 294, 278,
    271, 304, 272,
    432, 427, 434,
    272, 408, 407,
    394, 431, 430,
    395, 400, 369,
    334, 299, 333,
    351, 168, 417,
    352, 411, 280,
    325, 320, 319,
    295, 336, 296,
    319, 404, 403,
    330, 349, 348,
    293, 333, 298,
    323, 447, 454,
    15, 315, 16,
    358, 279, 429,
    14, 316, 15,
    285, 9, 336,
    329, 350, 349,
    318, 403, 402,
    6, 419, 197,
    318, 325, 319,
    367, 365, 364,
    435, 397, 367,
    344, 439, 438,
    272, 311, 271,
    195, 281, 5,
    273, 291, 287,
    396, 199, 428,
    311, 268, 271,
    283, 445, 444,
    282, 296, 334,
    449, 346, 347,
    264, 454, 447,
    336, 299, 296,
    338, 151, 10,
    278, 455, 439,
    292, 415, 407,
    358, 355, 371,
    340, 372, 345,
    346, 280, 347,
    442, 282, 443,
    19, 370, 94,
    441, 295, 442,
    248, 197, 419,
    440, 274, 275,
    300, 368, 383,
    351, 465, 412,
    301, 389, 368,
    395, 379, 378,
    412, 419, 351,
    436, 322, 426,
    2, 393, 164,
    370, 461, 462,
    164, 267, 0,
    302, 12, 11,
    268, 13, 12,
    293, 301, 300,
    446, 340, 261,
    330, 425, 266,
    426, 391, 423,
    429, 437, 355,
    391, 326, 327,
    440, 438, 457,
    459, 461, 457,
    434, 394, 430,
    396, 262, 369,
    354, 457, 461,
    316, 402, 403,
    315, 403, 404,
    314, 404, 405,
    313, 405, 406,
    421, 406, 418,
    366, 361, 401,
    306, 407, 408,
    291, 408, 409,
    287, 409, 410,
    432, 410, 436,
    434, 411, 416,
    264, 383, 368,
    309, 457, 438,
    352, 401, 376,
    274, 4, 275,
    421, 262, 428,
    294, 358, 327,
    433, 367, 416,
    289, 439, 455,
    462, 326, 370,
    2, 370, 326,
    305, 455, 460,
    254, 448, 449,
    255, 446, 261,
    253, 449, 450,
    252, 450, 451,
    256, 451, 452,
    341, 452, 453,
    413, 463, 464,
    441, 414, 413,
    258, 441, 442,
    257, 442, 443,
    259, 443, 444,
    260, 444, 445,
    467, 445, 342,
    459, 250, 458,
    289, 290, 392,
    290, 460, 328,
    376, 435, 433,
    250, 392, 290,
    411, 433, 416,
    341, 464, 463,
    453, 465, 464,
    357, 412, 465,
    343, 399, 412,
    360, 440, 363,
    437, 456, 399,
    420, 363, 456,
    401, 288, 435,
    372, 353, 383,
    448, 255, 261,
    75, 166, 60,
    238, 79, 239,
    162, 139, 127,
    72, 37, 11,
    121, 120, 232,
    73, 39, 72,
    114, 47, 128,
    233, 128, 232,
    103, 67, 104,
    152, 148, 175,
    119, 101, 118,
    74, 40, 73,
    107, 108, 9,
    49, 131, 48,
    32, 211, 194,
    184, 185, 74,
    191, 183, 80,
    185, 186, 40,
    119, 118, 230,
    210, 214, 202,
    84, 17, 83,
    77, 146, 76,
    182, 194, 106,
    138, 192, 135,
    129, 98, 203,
    54, 68, 21,
    5, 4, 51,
    90, 91, 77,
    207, 187, 205,
    83, 18, 201,
    181, 182, 91,
    180, 181, 90,
    16, 17, 85,
    205, 36, 206,
    176, 140, 148,
    165, 39, 92,
    245, 244, 193,
    174, 196, 236,
    103, 104, 54,
    55, 8, 193,
    111, 31, 117,
    221, 55, 189,
    240, 99, 98,
    142, 100, 126,
    219, 218, 166,
    198, 131, 209,
    169, 150, 135,
    114, 217, 47,
    224, 53, 223,
    220, 134, 45,
    32, 140, 211,
    109, 108, 67,
    146, 91, 43,
    231, 120, 230,
    113, 247, 226,
    105, 52, 63,
    241, 242, 238,
    124, 156, 46,
    95, 96, 78,
    70, 63, 46,
    116, 227, 143,
    116, 111, 123,
    1, 19, 44,
    3, 51, 236,
    207, 205, 216,
    165, 167, 39,
    199, 208, 200,
    101, 100, 36,
    43, 202, 57,
    242, 99, 20,
    124, 113, 35,
    211, 210, 204,
    124, 46, 113,
    106, 204, 43,
    96, 77, 62,
    227, 116, 137,
    73, 72, 41,
    36, 142, 203,
    235, 240, 64,
    48, 64, 49,
    42, 74, 41,
    214, 207, 212,
    183, 184, 42,
    210, 211, 169,
    140, 176, 170,
    104, 69, 105,
    193, 168, 122,
    50, 187, 123,
    89, 90, 96,
    66, 107, 65,
    179, 180, 89,
    119, 120, 101,
    68, 104, 63,
    234, 227, 93,
    16, 85, 15,
    209, 49, 129,
    15, 86, 14,
    107, 9, 55,
    120, 121, 100,
    178, 179, 88,
    197, 196, 6,
    89, 96, 88,
    135, 136, 138,
    138, 172, 215,
    218, 219, 115,
    41, 81, 42,
    5, 51, 195,
    57, 61, 43,
    208, 199, 171,
    41, 38, 81,
    224, 225, 53,
    105, 66, 52,
    118, 117, 229,
    227, 234, 34,
    66, 69, 107,
    10, 151, 109,
    219, 235, 48,
    183, 191, 62,
    142, 126, 129,
    116, 143, 111,
    118, 50, 117,
    223, 52, 222,
    94, 141, 19,
    222, 65, 221,
    196, 197, 3,
    45, 44, 220,
    156, 139, 70,
    188, 245, 122,
    139, 162, 71,
    149, 150, 170,
    122, 196, 188,
    206, 92, 216,
    164, 167, 2,
    242, 241, 141,
    0, 37, 164,
    11, 12, 72,
    12, 13, 38,
    70, 71, 63,
    31, 111, 226,
    36, 205, 101,
    203, 165, 206,
    126, 217, 209,
    98, 97, 165,
    237, 218, 220,
    237, 241, 239,
    210, 169, 214,
    140, 32, 171,
    241, 237, 125,
    179, 178, 86,
    180, 179, 85,
    181, 180, 84,
    182, 181, 83,
    194, 182, 201,
    177, 132, 137,
    184, 183, 76,
    185, 184, 61,
    186, 185, 57,
    216, 186, 212,
    192, 187, 214,
    139, 156, 34,
    218, 237, 79,
    147, 177, 123,
    45, 4, 44,
    208, 32, 201,
    98, 129, 64,
    192, 138, 213,
    235, 219, 59,
    141, 97, 242,
    97, 141, 2,
    240, 235, 75,
    229, 228, 24,
    31, 226, 25,
    230, 229, 23,
    231, 230, 22,
    232, 231, 26,
    233, 232, 112,
    244, 243, 189,
    189, 190, 221,
    222, 221, 28,
    223, 222, 27,
    224, 223, 29,
    225, 224, 30,
    113, 225, 247,
    99, 240, 60,
    213, 215, 147,
    60, 166, 20,
    192, 213, 187,
    243, 244, 112,
    244, 245, 233,
    245, 188, 128,
    188, 174, 114,
    134, 220, 131,
    174, 236, 217,
    236, 134, 198,
    215, 58, 177,
    156, 124, 143,
    31, 25, 228,
    264, 368, 356,
    0, 267, 11,
    451, 349, 452,
    267, 269, 302,
    350, 277, 357,
    350, 357, 452,
    299, 297, 333,
    396, 377, 175,
    280, 330, 347,
    269, 270, 303,
    151, 337, 9,
    344, 360, 278,
    424, 431, 418,
    270, 409, 304,
    272, 407, 310,
    322, 410, 270,
    449, 347, 450,
    432, 434, 422,
    18, 17, 313,
    291, 375, 306,
    424, 418, 335,
    434, 416, 364,
    391, 327, 423,
    301, 298, 251,
    275, 4, 281,
    375, 321, 307,
    280, 411, 425,
    200, 18, 421,
    335, 406, 321,
    321, 405, 320,
    314, 17, 315,
    423, 266, 426,
    396, 369, 377,
    270, 269, 322,
    413, 464, 417,
    248, 419, 456,
    298, 333, 284,
    168, 8, 417,
    448, 261, 346,
    417, 285, 413,
    326, 328, 327,
    277, 329, 355,
    309, 438, 392,
    279, 360, 429,
    365, 379, 364,
    355, 437, 277,
    282, 283, 443,
    281, 363, 275,
    395, 369, 431,
    299, 337, 297,
    335, 321, 273,
    348, 349, 450,
    359, 467, 446,
    283, 282, 293,
    250, 462, 458,
    300, 383, 276,
    292, 325, 308,
    283, 293, 276,
    264, 447, 372,
    346, 340, 352,
    354, 19, 274,
    363, 281, 456,
    426, 425, 436,
    267, 393, 269,
    421, 428, 200,
    371, 329, 266,
    432, 422, 287,
    290, 328, 250,
    446, 342, 265,
    422, 430, 424,
    445, 276, 342,
    422, 424, 273,
    306, 307, 292,
    352, 345, 366,
    268, 302, 271,
    358, 371, 423,
    327, 460, 294,
    331, 294, 279,
    303, 304, 271,
    436, 427, 432,
    304, 408, 272,
    395, 431, 394,
    378, 400, 395,
    296, 299, 334,
    6, 168, 351,
    376, 411, 352,
    307, 320, 325,
    285, 336, 295,
    320, 404, 319,
    329, 349, 330,
    334, 333, 293,
    366, 447, 323,
    316, 315, 15,
    331, 279, 358,
    317, 316, 14,
    8, 9, 285,
    277, 350, 329,
    319, 403, 318,
    351, 419, 6,
    324, 325, 318,
    397, 365, 367,
    288, 397, 435,
    278, 439, 344,
    310, 311, 272,
    248, 281, 195,
    375, 291, 273,
    175, 199, 396,
    312, 268, 311,
    276, 445, 283,
    295, 296, 282,
    448, 346, 449,
    356, 454, 264,
    337, 299, 336,
    337, 151, 338,
    294, 455, 278,
    308, 415, 292,
    429, 355, 358,
    265, 372, 340,
    352, 280, 346,
    295, 282, 442,
    354, 370, 19,
    285, 295, 441,
    195, 197, 248,
    457, 274, 440,
    301, 368, 300,
    417, 465, 351,
    251, 389, 301,
    394, 379, 395,
    399, 419, 412,
    410, 322, 436,
    326, 393, 2,
    354, 461, 370,
    393, 267, 164,
    268, 12, 302,
    312, 13, 268,
    298, 301, 293,
    265, 340, 446,
    280, 425, 330,
    322, 391, 426,
    420, 437, 429,
    393, 326, 391,
    344, 438, 440,
    458, 461, 459,
    364, 394, 434,
    428, 262, 396,
    274, 457, 354,
    317, 402, 316,
    316, 403, 315,
    315, 404, 314,
    314, 405, 313,
    313, 406, 421,
    323, 361, 366,
    292, 407, 306,
    306, 408, 291,
    291, 409, 287,
    287, 410, 432,
    427, 411, 434,
    372, 383, 264,
    459, 457, 309,
    366, 401, 352,
    1, 4, 274,
    418, 262, 421,
    331, 358, 294,
    435, 367, 433,
    392, 439, 289,
    328, 326, 462,
    94, 370, 2,
    289, 455, 305,
    339, 448, 254,
    359, 446, 255,
    254, 449, 253,
    253, 450, 252,
    252, 451, 256,
    256, 452, 341,
    414, 463, 413,
    286, 414, 441,
    286, 441, 258,
    258, 442, 257,
    257, 443, 259,
    259, 444, 260,
    260, 445, 467,
    309, 250, 459,
    305, 290, 289,
    305, 460, 290,
    401, 435, 376,
    309, 392, 250,
    376, 433, 411,
    453, 464, 341,
    357, 465, 453,
    343, 412, 357,
    437, 399, 343,
    344, 440, 360,
    420, 456, 437,
    360, 363, 420,
    361, 288, 401,
    265, 353, 372,
    339, 255, 448,
]);
const FaceMeshEyeIndices = new Uint32Array([
    // Outer Left Eye
    341, 362, 382,
    414, 362, 463,
    362, 414, 398,
    362, 341, 463,
    258, 384, 286,
    398, 286, 384,
    385, 384, 258,
    385, 258, 386,
    286, 398, 414,
    386, 258, 257,
    386, 257, 387,
    387, 260, 388,
    387, 257, 259,
    259, 260, 387,
    466, 388, 260,
    263, 466, 467,
    466, 260, 467,
    263, 359, 255,
    263, 467, 359,
    263, 255, 249,
    339, 249, 255,
    390, 249, 339,
    390, 339, 373,
    373, 253, 374,
    373, 339, 254,
    254, 253, 373,
    374, 252, 380,
    253, 252, 374,
    380, 252, 381,
    381, 252, 256,
    381, 256, 382,
    382, 256, 341,
    // Outer Right Eye
    247, 33, 130,
    33, 247, 246,
    247, 161, 246,
    161, 30, 160,
    30, 161, 247,
    160, 30, 29,
    29, 27, 160,
    160, 27, 159,
    27, 28, 159,
    159, 28, 158,
    28, 157, 158,
    56, 157, 28,
    56, 173, 157,
    190, 133, 173,
    190, 173, 56,
    133, 243, 112,
    133, 190, 243,
    133, 112, 155,
    155, 26, 154,
    112, 26, 155,
    154, 22, 153,
    26, 22, 154,
    153, 22, 145,
    145, 22, 23,
    145, 23, 144,
    144, 23, 24,
    144, 110, 163,
    24, 110, 144,
    110, 7, 163,
    25, 7, 110,
    25, 33, 7,
    33, 25, 130,
    // Inner Left Eye
    157, 155, 154,
    173, 133, 155,
    246, 7, 33,
    163, 246, 161,
    153, 159, 158,
    144, 161, 160,
    145, 160, 159,
    158, 154, 153,
    173, 155, 157,
    7, 246, 163,
    145, 159, 153,
    163, 161, 144,
    144, 160, 145,
    157, 154, 158,
    // Inner Right Eye
    384, 382, 398,
    382, 362, 398,
    263, 249, 466,
    390, 466, 249,
    380, 386, 374,
    373, 388, 390,
    374, 387, 373,
    385, 381, 384,
    381, 382, 384,
    388, 466, 390,
    385, 386, 380,
    387, 388, 373,
    386, 387, 374,
    380, 381, 385,
]);
const FaceMeshMouthIndices = new Uint32Array([
    78, 191, 95,
    191, 80, 95,
    95, 80, 88,
    80, 81, 88,
    88, 81, 178,
    178, 81, 82,
    178, 82, 87,
    87, 82, 13,
    87, 13, 14,
    14, 13, 317,
    13, 312, 317,
    317, 312, 402,
    402, 312, 311,
    402, 311, 318,
    311, 310, 318,
    318, 310, 324,
    310, 415, 324,
    324, 415, 308,
]);
const FaceMeshIrisIndices = new Uint32Array([
    // Left Iris
    471, 470, 468,
    468, 470, 469,
    472, 468, 469,
    471, 468, 472,
    // Right Iris
    476, 475, 473,
    473, 475, 474,
    477, 473, 474,
    476, 473, 477,
]);
const EarLMeshPositions = new Float32Array([
    0.618723, -0.137429295, -0.314211634,
    0.626906, -0.12569247, -0.33475621,
    0.62272, -0.099439833, -0.34201522,
    0.632345, 0.01547457, -0.33762164,
    0.632566, 0.0339815, -0.32774912,
    0.621804, 0.0424663, -0.302328904,
    0.632566, 0.0339815, -0.32774912,
    0.632098, 0.05928808, -0.30749193,
    0.621804, 0.0424663, -0.302328904,
    0.621804, 0.0424663, -0.302328904,
    0.632098, 0.05928808, -0.30749193,
    0.628468, 0.08690066, -0.272833024,
    0.656246, 0.08908837, -0.217391946,
    0.67717, 0.08021923, -0.2040691905,
    0.668702, 0.08169743, -0.227840162,
    0.668702, 0.08169743, -0.227840162,
    0.67717, 0.08021923, -0.2040691905,
    0.670945, 0.07418824, -0.177218045,
    0.632987, 0.09038919, -0.188234433,
    0.644326, 0.11643487, -0.217888505,
    0.644322, 0.09937657, -0.170081236,
    0.645691, -0.33571357, -0.09547249,
    0.6635, -0.32240984, -0.0774755,
    0.663498, -0.34467139, -0.09465294,
    0.668799, -0.35584647, -0.113659655,
    0.67922, -0.338936, -0.098128234,
    0.663498, -0.34467139, -0.09465294,
    0.6635, -0.32240984, -0.0774755,
    0.67922, -0.338936, -0.098128234,
    0.67922, -0.338936, -0.098128234,
    0.6635, -0.32240984, -0.0774755,
    0.677799, -0.31838918, -0.08134983,
    0.677799, -0.31838918, -0.08134983,
    0.6635, -0.32240984, -0.0774755,
    0.662189, -0.30088743, -0.06714351,
    0.661306, -0.27980844, -0.05820554,
    0.662189, -0.30088743, -0.06714351,
    0.646365, -0.287022, -0.06484978,
    0.636259, -0.29479729, -0.081360136,
    0.637348, -0.31702924, -0.093227205,
    0.637743, -0.33621614, -0.11200535,
    0.648455, -0.35773856, -0.12533279,
    0.664566, -0.36217315, -0.15107484,
    0.679425, -0.34564698, -0.174941244,
    0.664566, -0.36217315, -0.15107484,
    0.679539, -0.35425005, -0.14981185,
    0.679539, -0.35425005, -0.14981185,
    0.686309, -0.34416879, -0.124278826,
    0.687575, -0.32314894, -0.10782411,
    0.67922, -0.338936, -0.098128234,
    0.68473, -0.30943137, -0.09147435,
    0.677799, -0.31838918, -0.08134983,
    0.68473, -0.30943137, -0.09147435,
    0.677799, -0.31838918, -0.08134983,
    0.672839, -0.24205551, -0.04951533,
    0.672839, -0.24205551, -0.04951533,
    0.661306, -0.27980844, -0.05820554,
    0.672839, -0.24205551, -0.04951533,
    0.685455, -0.14822008, -0.09000363,
    0.683808, -0.13145741, -0.09196734,
    0.685907, -0.17402926, -0.10615105,
    0.657547, -0.174827485, -0.10399269,
    0.656563, -0.185884325, -0.09626588,
    0.672079, -0.197709835, -0.08832675,
    0.672079, -0.197709835, -0.08832675,
    0.656563, -0.185884325, -0.09626588,
    0.675952, -0.21278737, -0.07979318,
    0.675952, -0.21278737, -0.07979318,
    0.656563, -0.185884325, -0.09626588,
    0.633145, -0.19235879, -0.09930343,
    0.656563, -0.185884325, -0.09626588,
    0.657547, -0.174827485, -0.10399269,
    0.633145, -0.19235879, -0.09930343,
    0.619152, -0.188308536, -0.10010358,
    0.617638, -0.189698054, -0.11495918,
    0.619152, -0.188308536, -0.10010358,
    0.607251, -0.17456138, -0.11869436,
    0.607251, -0.17456138, -0.11869436,
    0.619152, -0.188308536, -0.10010358,
    0.613195, -0.16749565, -0.107166734,
    0.613195, -0.16749565, -0.107166734,
    0.636607, -0.150112166, -0.11039697,
    0.636607, -0.150112166, -0.11039697,
    0.679227, -0.168175636, -0.11737536,
    0.672079, -0.197709835, -0.08832675,
    0.685907, -0.17402926, -0.10615105,
    0.679227, -0.168175636, -0.11737536,
    0.685907, -0.17402926, -0.10615105,
    0.684151, -0.154014567, -0.11296235,
    0.679227, -0.168175636, -0.11737536,
    0.673828, -0.114369554, -0.116284285,
    0.684151, -0.154014567, -0.11296235,
    0.673828, -0.114369554, -0.116284285,
    0.673006, -0.086904791, -0.121360955,
    0.678036, -0.06130257, -0.112514225,
    0.678733, -0.0363803, -0.096644875,
    0.657448, -0.013438804, -0.08512894,
    0.671512, -0.01400052, -0.088793886,
    0.663504, -0.075522747, -0.077091184,
    0.675635, -0.066830987, -0.084756856,
    0.671512, -0.01400052, -0.088793886,
    0.678733, -0.0363803, -0.096644875,
    0.675635, -0.066830987, -0.084756856,
    0.678733, -0.0363803, -0.096644875,
    0.678932, -0.143903768, -0.07193029,
    0.683808, -0.13145741, -0.09196734,
    0.683808, -0.13145741, -0.09196734,
    0.685455, -0.14822008, -0.09000363,
    0.678932, -0.143903768, -0.07193029,
    0.678932, -0.143903768, -0.07193029,
    0.685455, -0.14822008, -0.09000363,
    0.685387, -0.16382974, -0.08515421,
    0.685387, -0.16382974, -0.08515421,
    0.684826, -0.197680286, -0.07657441,
    0.685907, -0.17402926, -0.10615105,
    0.672079, -0.197709835, -0.08832675,
    0.684826, -0.197680286, -0.07657441,
    0.675952, -0.21278737, -0.07979318,
    0.693265, -0.22697797, -0.06684671,
    0.684826, -0.197680286, -0.07657441,
    0.684926, -0.23090995, -0.08399634,
    0.672869, -0.23546278, -0.10048636,
    0.684926, -0.23090995, -0.08399634,
    0.663556, -0.220917395, -0.089154404,
    0.663556, -0.220917395, -0.089154404,
    0.63397, -0.196675105, -0.1165782,
    0.633145, -0.19235879, -0.09930343,
    0.619152, -0.188308536, -0.10010358,
    0.63397, -0.196675105, -0.1165782,
    0.617638, -0.189698054, -0.11495918,
    0.63397, -0.196675105, -0.1165782,
    0.617638, -0.189698054, -0.11495918,
    0.627271, -0.183785295, -0.129605804,
    0.607251, -0.17456138, -0.11869436,
    0.627271, -0.183785295, -0.129605804,
    0.607251, -0.17456138, -0.11869436,
    0.611828, -0.16078467, -0.13050913,
    0.611828, -0.16078467, -0.13050913,
    0.607251, -0.17456138, -0.11869436,
    0.60761, -0.14215949, -0.12393118,
    0.607251, -0.17456138, -0.11869436,
    0.60761, -0.14215949, -0.12393118,
    0.61883, -0.14006047, -0.116893356,
    0.643758, -0.133408616, -0.11931158,
    0.643758, -0.133408616, -0.11931158,
    0.636607, -0.150112166, -0.11039697,
    0.65939, -0.126254185, -0.12287462,
    0.65939, -0.126254185, -0.12287462,
    0.65939, -0.126254185, -0.12287462,
    0.645048, -0.124982937, -0.13162425,
    0.661866, -0.104288292, -0.14063967,
    0.673828, -0.114369554, -0.116284285,
    0.661866, -0.104288292, -0.14063967,
    0.661866, -0.104288292, -0.14063967,
    0.665364, -0.114280849, -0.162406717,
    0.661866, -0.104288292, -0.14063967,
    0.676513, -0.0975477625, -0.159270783,
    0.686565, -0.0462546, -0.13666939,
    0.648379, -0.070703844, -0.192149754,
    0.6577, -0.06615102, -0.16874247,
    0.647181, -0.050955246, -0.182262425,
    0.647181, -0.050955246, -0.182262425,
    0.6577, -0.06615102, -0.16874247,
    0.649081, -0.034961244, -0.175896107,
    0.654288, -0.0476441, -0.16702167,
    0.654288, -0.0476441, -0.16702167,
    0.672211, -0.0585827, -0.155057204,
    0.665426, -0.080046002, -0.167758704,
    0.672211, -0.0585827, -0.155057204,
    0.673993, -0.0934088266, -0.171277613,
    0.653197, -0.107362925, -0.1979356895,
    0.645202, -0.084598824, -0.22744726,
    0.647181, -0.050955246, -0.182262425,
    0.645202, -0.084598824, -0.22744726,
    0.647181, -0.050955246, -0.182262425,
    0.646579, -0.0398097, -0.1946144773,
    0.647181, -0.050955246, -0.182262425,
    0.649081, -0.034961244, -0.175896107,
    0.663016, -0.02041586, -0.15227087,
    0.654288, -0.0476441, -0.16702167,
    0.654288, -0.0476441, -0.16702167,
    0.672211, -0.0585827, -0.155057204,
    0.663016, -0.02041586, -0.15227087,
    0.651066, 0.02588102, -0.15847324,
    0.661194, 0.001224825, -0.151157942,
    0.661203, 0.02877826, -0.133300555,
    0.663016, -0.02041586, -0.15227087,
    0.672692, 0.00574809, -0.13726386,
    0.667977, 0.04698955, -0.144280826,
    0.661203, 0.02877826, -0.133300555,
    0.681551, 0.02058909, -0.146787607,
    0.661203, 0.02877826, -0.133300555,
    0.672692, 0.00574809, -0.13726386,
    0.681551, 0.02058909, -0.146787607,
    0.681551, 0.02058909, -0.146787607,
    0.672692, 0.00574809, -0.13726386,
    0.686076, 0.0014909, -0.141039972,
    0.672692, 0.00574809, -0.13726386,
    0.663016, -0.02041586, -0.15227087,
    0.686076, 0.0014909, -0.141039972,
    0.686076, 0.0014909, -0.141039972,
    0.686076, 0.0014909, -0.141039972,
    0.691499, -0.00353494, -0.128412974,
    0.683458, 0.014469394, -0.1085203,
    0.683458, 0.014469394, -0.1085203,
    0.666648, 0.02472803, -0.09598669,
    0.657448, -0.013438804, -0.08512894,
    0.651675, 0.05476483, -0.11708805,
    0.662267, 0.0756073, -0.13337077,
    0.660762, 0.08595462, -0.144412537,
    0.646163, 0.08314606, -0.146655095,
    0.647461, 0.0706997, -0.12993461,
    0.632176, 0.03256243, -0.11854037,
    0.632176, 0.03256243, -0.11854037,
    0.642769, 0.021239506, -0.1007342,
    0.658064, 0.00447684, -0.08841712,
    0.658064, 0.00447684, -0.08841712,
    0.632466, -0.01199019, -0.093560655,
    0.642833, -0.105825614, -0.08042346,
    0.667336, -0.1411839, -0.06119537,
    0.667336, -0.1411839, -0.06119537,
    0.671427, -0.18544088, -0.04879105,
    0.685387, -0.16382974, -0.08515421,
    0.684826, -0.197680286, -0.07657441,
    0.671427, -0.18544088, -0.04879105,
    0.671427, -0.18544088, -0.04879105,
    0.672839, -0.24205551, -0.04951533,
    0.671427, -0.18544088, -0.04879105,
    0.645218, -0.200074954, -0.06430563,
    0.643803, -0.21831579, -0.06390652,
    0.641477, -0.2368227, -0.06586313,
    0.638373, -0.25577309, -0.06863948,
    0.635931, -0.27546257, -0.07316708,
    0.635931, -0.27546257, -0.07316708,
    0.628435, -0.2887367, -0.094523814,
    0.628435, -0.2887367, -0.094523814,
    0.629061, -0.30348902, -0.1053513,
    0.630644, -0.32004476, -0.115153936,
    0.637743, -0.33621614, -0.11200535,
    0.630644, -0.32004476, -0.115153936,
    0.637743, -0.33621614, -0.11200535,
    0.636887, -0.34363665, -0.12606954,
    0.636887, -0.34363665, -0.12606954,
    0.636966, -0.34585392, -0.149633993,
    0.630113, -0.33074682, -0.14908947,
    0.636966, -0.34585392, -0.149633993,
    0.635666, -0.34070983, -0.172297076,
    0.630113, -0.33074682, -0.14908947,
    0.628973, -0.32672616, -0.16767421,
    0.633567, -0.31865524, -0.211951939,
    0.627753, -0.31705881, -0.185400725,
    0.635306, -0.33009643, -0.192604741,
    0.627753, -0.31705881, -0.185400725,
    0.628973, -0.32672616, -0.16767421,
    0.635306, -0.33009643, -0.192604741,
    0.635666, -0.34070983, -0.172297076,
    0.644994, -0.3534814, -0.165150837,
    0.651391, -0.34118285, -0.198123818,
    0.651391, -0.34118285, -0.198123818,
    0.678354, -0.33565443, -0.1982571556,
    0.679425, -0.34564698, -0.174941244,
    0.678354, -0.33565443, -0.1982571556,
    0.684176, -0.32093168, -0.1984657025,
    0.687122, -0.32832261, -0.182412867,
    0.688954, -0.33905425, -0.14852046,
    0.688807, -0.31871439, -0.148430236,
    0.688222, -0.3151963, -0.130803165,
    0.688222, -0.3151963, -0.130803165,
    0.685224, -0.2979015, -0.10366043,
    0.684813, -0.28258744, -0.094845665,
    0.684265, -0.24412498, -0.09921563,
    0.684926, -0.23090995, -0.08399634,
    0.684265, -0.24412498, -0.09921563,
    0.672869, -0.23546278, -0.10048636,
    0.655166, -0.22541111, -0.1140201,
    0.652952, -0.217015, -0.153348532,
    0.635444, -0.16282459, -0.14255198,
    0.611828, -0.16078467, -0.13050913,
    0.62005, -0.146357565, -0.13310791,
    0.62005, -0.146357565, -0.13310791,
    0.629848, -0.13098438, -0.12626224,
    0.629848, -0.13098438, -0.12626224,
    0.643758, -0.133408616, -0.11931158,
    0.629848, -0.13098438, -0.12626224,
    0.629848, -0.13098438, -0.12626224,
    0.651214, -0.118242406, -0.15273911,
    0.651214, -0.118242406, -0.15273911,
    0.651214, -0.118242406, -0.15273911,
    0.665364, -0.114280849, -0.162406717,
    0.66371, -0.125928985, -0.176462588,
    0.66371, -0.125928985, -0.176462588,
    0.657702, -0.139025743, -0.20824186,
    0.658885, -0.129151443, -0.216709888,
    0.646121, -0.117444184, -0.25554397,
    0.65005, -0.0820859, -0.25017447,
    0.668858, -0.02825027, -0.184587626,
    0.660762, -0.00223414, -0.164221343,
    0.661194, 0.001224825, -0.151157942,
    0.651066, 0.02588102, -0.15847324,
    0.660762, -0.00223414, -0.164221343,
    0.651066, 0.02588102, -0.15847324,
    0.645034, 0.06177145, -0.167043426,
    0.651066, 0.02588102, -0.15847324,
    0.661203, 0.02877826, -0.133300555,
    0.645034, 0.06177145, -0.167043426,
    0.661203, 0.02877826, -0.133300555,
    0.667977, 0.04698955, -0.144280826,
    0.645034, 0.06177145, -0.167043426,
    0.663477, 0.0609141, -0.15623744,
    0.670945, 0.07418824, -0.177218045,
    0.663477, 0.0609141, -0.15623744,
    0.674604, 0.05432137, -0.15522999,
    0.663477, 0.0609141, -0.15623744,
    0.667977, 0.04698955, -0.144280826,
    0.674604, 0.05432137, -0.15522999,
    0.667977, 0.04698955, -0.144280826,
    0.681551, 0.02058909, -0.146787607,
    0.674604, 0.05432137, -0.15522999,
    0.681551, 0.02058909, -0.146787607,
    0.686076, 0.0014909, -0.141039972,
    0.674604, 0.05432137, -0.15522999,
    0.674604, 0.05432137, -0.15522999,
    0.673529, 0.08217045, -0.147709177,
    0.673529, 0.08217045, -0.147709177,
    0.673529, 0.08217045, -0.147709177,
    0.660762, 0.08595462, -0.144412537,
    0.673529, 0.08217045, -0.147709177,
    0.669905, 0.10307204, -0.169224539,
    0.655083, 0.11270983, -0.184296149,
    0.659933, 0.12217023, -0.22393478,
    0.64433, 0.10972389, -0.265695785,
    0.628468, 0.08690066, -0.272833024,
    0.632567, 0.09724797, -0.225630275,
    0.633091, 0.09538546, -0.206536502,
    0.633091, 0.09538546, -0.206536502,
    0.633091, 0.09538546, -0.206536502,
    0.632987, 0.09038919, -0.188234433,
    0.633046, 0.08302779, -0.171205391,
    0.633046, 0.08302779, -0.171205391,
    0.644322, 0.09937657, -0.170081236,
    0.646163, 0.08314606, -0.146655095,
    0.633046, 0.08302779, -0.171205391,
    0.646163, 0.08314606, -0.146655095,
    0.647461, 0.0706997, -0.12993461,
    0.633046, 0.08302779, -0.171205391,
    0.623753, 0.05296143, -0.140951794,
    0.623753, 0.05296143, -0.140951794,
    0.623753, 0.05296143, -0.140951794,
    0.632176, 0.03256243, -0.11854037,
    0.628903, 0.00113613, -0.1035166,
    0.632176, 0.03256243, -0.11854037,
    0.642769, 0.021239506, -0.1007342,
    0.628903, 0.00113613, -0.1035166,
    0.628903, 0.00113613, -0.1035166,
    0.628903, 0.00113613, -0.1035166,
    0.628903, 0.00113613, -0.1035166,
    0.617979, -0.060947797, -0.09642525,
    0.619152, -0.188308536, -0.10010358,
    0.645963, -0.182484506, -0.06658995,
    0.645218, -0.200074954, -0.06430563,
    0.631974, -0.20087317, -0.078388464,
    0.645963, -0.182484506, -0.06658995,
    0.632466, -0.184258325, -0.07939884,
    0.632466, -0.184258325, -0.07939884,
    0.631974, -0.20087317, -0.078388464,
    0.619152, -0.188308536, -0.10010358,
    0.617453, -0.21713323, -0.09296059,
    0.643803, -0.21831579, -0.06390652,
    0.641477, -0.2368227, -0.06586313,
    0.617453, -0.21713323, -0.09296059,
    0.618652, -0.23392547, -0.09418528,
    0.618652, -0.23392547, -0.09418528,
    0.62049, -0.2509542, -0.09659923,
    0.638373, -0.25577309, -0.06863948,
    0.635931, -0.27546257, -0.07316708,
    0.62049, -0.2509542, -0.09659923,
    0.620311, -0.26762818, -0.0995835,
    0.620311, -0.26762818, -0.0995835,
    0.622567, -0.27974931, -0.107264456,
    0.622567, -0.27974931, -0.107264456,
    0.622507, -0.29556594, -0.12999961,
    0.622507, -0.29556594, -0.12999961,
    0.622722, -0.30478985, -0.15193936,
    0.622722, -0.30478985, -0.15193936,
    0.622722, -0.30478985, -0.15193936,
    0.622592, -0.30348902, -0.175679021,
    0.622592, -0.30348902, -0.175679021,
    0.622592, -0.30348902, -0.175679021,
    0.621964, -0.2951225, -0.1961908448,
    0.621964, -0.2951225, -0.1961908448,
    0.633567, -0.31865524, -0.211951939,
    0.625406, -0.2819666, -0.231882588,
    0.626221, -0.213408186, -0.29554537,
    0.624294, -0.23770962, -0.270483394,
    0.628174, -0.26857423, -0.258490742,
    0.625406, -0.2819666, -0.231882588,
    0.628174, -0.26857423, -0.258490742,
    0.625406, -0.2819666, -0.231882588,
    0.637759, -0.29305302, -0.252293263,
    0.625406, -0.2819666, -0.231882588,
    0.633567, -0.31865524, -0.211951939,
    0.635306, -0.33009643, -0.192604741,
    0.65174, -0.28858889, -0.26391044,
    0.658796, -0.31977867, -0.23087078,
    0.651391, -0.34118285, -0.198123818,
    0.680741, -0.3126538, -0.213123888,
    0.678354, -0.33565443, -0.1982571556,
    0.680741, -0.3126538, -0.213123888,
    0.67698, -0.29929098, -0.230794324,
    0.680741, -0.3126538, -0.213123888,
    0.679019, -0.3004144, -0.2053597908,
    0.6886, -0.31903959, -0.168266446,
    0.684176, -0.32093168, -0.1984657025,
    0.687122, -0.32832261, -0.182412867,
    0.687122, -0.32832261, -0.182412867,
    0.688954, -0.33905425, -0.14852046,
    0.6886, -0.31903959, -0.168266446,
    0.688807, -0.31871439, -0.148430236,
    0.676939, -0.27602429, -0.149607342,
    0.688807, -0.31871439, -0.148430236,
    0.688222, -0.3151963, -0.130803165,
    0.676939, -0.27602429, -0.149607342,
    0.675636, -0.23986777, -0.132771064,
    0.68122, -0.217015, -0.192758321,
    0.676776, -0.206756344, -0.1822754,
    0.675513, -0.20424344, -0.1984936246,
    0.664274, -0.198567195, -0.210202581,
    0.648737, -0.20042971, -0.17201947,
    0.642102, -0.13716323, -0.147677083,
    0.62005, -0.146357565, -0.13310791,
    0.642102, -0.13716323, -0.147677083,
    0.648334, -0.139173566, -0.190759728,
    0.66371, -0.125928985, -0.176462588,
    0.650524, -0.15507888, -0.24441417,
    0.657712, -0.123652574, -0.26675187,
    0.659674, -0.138050145, -0.26573753,
    0.657712, -0.123652574, -0.26675187,
    0.676802, -0.11886324, -0.26485179,
    0.676802, -0.11886324, -0.26485179,
    0.657712, -0.123652574, -0.26675187,
    0.671222, -0.106269073, -0.26127532,
    0.654257, -0.108900249, -0.26358189,
    0.654257, -0.108900249, -0.26358189,
    0.658688, -0.0937340245, -0.25827677,
    0.66519, -0.08161288, -0.249977574,
    0.66519, -0.08161288, -0.249977574,
    0.674315, -0.044332954, -0.214121218,
    0.661637, 0.01018265, -0.1994132832,
    0.655042, 0.08001229, -0.185419203,
    0.645034, 0.06177145, -0.167043426,
    0.655042, 0.08001229, -0.185419203,
    0.670945, 0.07418824, -0.177218045,
    0.67717, 0.08021923, -0.2040691905,
    0.655042, 0.08001229, -0.185419203,
    0.655042, 0.08001229, -0.185419203,
    0.655042, 0.08001229, -0.185419203,
    0.656246, 0.08908837, -0.217391946,
    0.660223, 0.07847498, -0.24424923,
    0.680468, 0.05574044, -0.284698826,
    0.66149, 0.06925108, -0.26460342,
    0.678174, 0.07427693, -0.249492102,
    0.66149, 0.06925108, -0.26460342,
    0.660223, 0.07847498, -0.24424923,
    0.678174, 0.07427693, -0.249492102,
    0.678174, 0.07427693, -0.249492102,
    0.668702, 0.08169743, -0.227840162,
    0.690209, 0.08217045, -0.2011008017,
    0.674604, 0.05432137, -0.15522999,
    0.681977, 0.10593973, -0.208021961,
    0.670166, 0.11888867, -0.212274458,
    0.661807, 0.11188205, -0.27102241,
    0.64415, 0.08426949, -0.311372426,
    0.628468, 0.08690066, -0.272833024,
    0.632098, 0.05928808, -0.30749193,
    0.632098, 0.05928808, -0.30749193,
    0.645478, 0.04249586, -0.34556387,
    0.632566, 0.0339815, -0.32774912,
    0.632345, 0.01547457, -0.33762164,
    0.643959, -0.03726721, -0.36650987,
    0.631968, -0.00516095, -0.34545254,
    0.632914, -0.02635817, -0.35116851,
    0.626253, -0.05881921, -0.35476517,
    0.643959, -0.03726721, -0.36650987,
    0.626253, -0.05881921, -0.35476517,
    0.644049, -0.071856833, -0.36633344,
    0.644049, -0.071856833, -0.36633344,
    0.626253, -0.05881921, -0.35476517,
    0.644364, -0.0935862147, -0.36453323,
    0.626253, -0.05881921, -0.35476517,
    0.62272, -0.099439833, -0.34201522,
    0.644364, -0.0935862147, -0.36453323,
    0.644364, -0.0935862147, -0.36453323,
    0.62272, -0.099439833, -0.34201522,
    0.63485, -0.110585381, -0.34976957,
    0.641822, -0.131339146, -0.3502562,
    0.641822, -0.131339146, -0.3502562,
    0.641822, -0.131339146, -0.3502562,
    0.635612, -0.147480986, -0.33907485,
    0.635446, -0.165189674, -0.33230264,
    0.617808, -0.15436934, -0.30837771,
    0.617808, -0.15436934, -0.30837771,
    0.626221, -0.213408186, -0.29554537,
    0.635446, -0.165189674, -0.33230264,
    0.635446, -0.165189674, -0.33230264,
    0.626221, -0.213408186, -0.29554537,
    0.63651, -0.22003048, -0.306828384,
    0.626221, -0.213408186, -0.29554537,
    0.65778, -0.217783636, -0.314145875,
    0.669403, -0.27868502, -0.265513775,
    0.67698, -0.29929098, -0.230794324,
    0.67698, -0.29929098, -0.230794324,
    0.675942, -0.2891506, -0.222534754,
    0.675942, -0.2891506, -0.222534754,
    0.679019, -0.3004144, -0.2053597908,
    0.671823, -0.26987503, -0.186628987,
    0.679019, -0.3004144, -0.2053597908,
    0.672701, -0.25195937, -0.221466179,
    0.68122, -0.217015, -0.192758321,
    0.677486, -0.20864844, -0.21909171,
    0.68122, -0.217015, -0.192758321,
    0.676776, -0.206756344, -0.1822754,
    0.677486, -0.20864844, -0.21909171,
    0.675513, -0.20424344, -0.1984936246,
    0.664274, -0.198567195, -0.210202581,
    0.665153, -0.19401437, -0.225142256,
    0.665153, -0.19401437, -0.225142256,
    0.664274, -0.198567195, -0.210202581,
    0.648875, -0.18130195, -0.232452565,
    0.648737, -0.20042971, -0.17201947,
    0.648875, -0.18130195, -0.232452565,
    0.666325, -0.16850084, -0.254512914,
    0.659674, -0.138050145, -0.26573753,
    0.666325, -0.16850084, -0.254512914,
    0.659674, -0.138050145, -0.26573753,
    0.67334, -0.14958001, -0.262302627,
    0.659674, -0.138050145, -0.26573753,
    0.676802, -0.11886324, -0.26485179,
    0.67334, -0.14958001, -0.262302627,
    0.673917, -0.091871518, -0.2579921,
    0.671222, -0.106269073, -0.26127532,
    0.654257, -0.108900249, -0.26358189,
    0.658688, -0.0937340245, -0.25827677,
    0.658688, -0.0937340245, -0.25827677,
    0.676627, -0.04823538, -0.241907232,
    0.676893, 0.0471965, -0.233424743,
    0.655042, 0.08001229, -0.185419203,
    0.661428, 0.05695255, -0.28183939,
    0.66149, 0.06925108, -0.26460342,
    0.66149, 0.06925108, -0.26460342,
    0.680468, 0.05574044, -0.284698826,
    0.661428, 0.05695255, -0.28183939,
    0.661428, 0.05695255, -0.28183939,
    0.680656, 0.03205986, -0.306372215,
    0.68367, 0.09390728, -0.26400689,
    0.670088, 0.08154961, -0.31326901,
    0.665242, 0.03566664, -0.34987954,
    0.655381, -0.01308404, -0.36556463,
    0.658995, -0.072507235, -0.36770148,
    0.643959, -0.03726721, -0.36650987,
    0.644049, -0.071856833, -0.36633344,
    0.658995, -0.072507235, -0.36770148,
    0.644049, -0.071856833, -0.36633344,
    0.644364, -0.0935862147, -0.36453323,
    0.63485, -0.110585381, -0.34976957,
    0.657622, -0.151974677, -0.34909053,
    0.635612, -0.147480986, -0.33907485,
    0.6757, -0.192417926, -0.31944757,
    0.674677, -0.25929118, -0.247445185,
    0.674315, -0.24238071, -0.233959605,
    0.68458, -0.18260275, -0.250416306,
    0.68458, -0.18260275, -0.250416306,
    0.665153, -0.19401437, -0.225142256,
    0.68458, -0.18260275, -0.250416306,
    0.67334, -0.14958001, -0.262302627,
    0.68458, -0.18260275, -0.250416306,
    0.67334, -0.14958001, -0.262302627,
    0.685318, -0.155670146, -0.264704075,
    0.685318, -0.155670146, -0.264704075,
    0.683916, -0.04285477, -0.280063455,
    0.674973, 0.01828312, -0.281448466,
    0.663143, -0.018908104, -0.3158279,
    0.659841, 0.01124694, -0.30925952,
    0.659841, 0.01124694, -0.30925952,
    0.659841, 0.01124694, -0.30925952,
    0.683449, 0.0151198, -0.3191736,
    0.680493, 0.03194159, -0.33333414,
    0.671648, -0.048235378, -0.36493602,
    0.676887, -0.130629633, -0.3474288,
    0.67725, -0.126934152, -0.32963422,
    0.678708, -0.178109056, -0.30605625,
    0.677266, -0.19966103, -0.28268456,
    0.684738, -0.116202511, -0.286310335,
    0.663394, -0.057045376, -0.3286619,
    0.663143, -0.018908104, -0.3158279,
    0.659841, 0.01124694, -0.30925952,
    0.663394, -0.057045376, -0.3286619,
    0.663394, -0.057045376, -0.3286619,
    0.679868, -0.053941192, -0.3365207,
    0.665882, -0.126342873, -0.32250868,
    0.665882, -0.126342873, -0.32250868,
    0.665882, -0.126342873, -0.32250868,
    0.667574, -0.132019116, -0.310581905,
    0.667574, -0.132019116, -0.310581905,
    0.665241, -0.077858279, -0.32726203,
    0.663394, -0.057045376, -0.3286619,
    0.665241, -0.077858279, -0.32726203,
    0.665882, -0.126342873, -0.32250868,
]);
const EarLMeshIndices = new Uint32Array([
    0, 1, 2,
    3, 4, 5,
    6, 7, 8,
    9, 10, 11,
    12, 13, 14,
    15, 16, 17,
    18, 19, 20,
    21, 22, 23,
    24, 23, 25,
    26, 27, 28,
    29, 30, 31,
    32, 33, 34,
    35, 36, 37,
    36, 22, 37,
    22, 21, 37,
    38, 37, 39,
    37, 21, 39,
    39, 21, 40,
    40, 21, 41,
    21, 23, 41,
    23, 24, 41,
    41, 24, 42,
    43, 44, 45,
    46, 42, 47,
    42, 24, 47,
    24, 25, 47,
    47, 25, 48,
    48, 49, 50,
    49, 51, 50,
    52, 53, 54,
    32, 34, 55,
    34, 56, 55,
    35, 37, 57,
    58, 59, 60,
    61, 62, 63,
    64, 65, 66,
    67, 68, 69,
    70, 71, 72,
    72, 71, 73,
    74, 75, 76,
    77, 78, 79,
    80, 73, 81,
    73, 71, 81,
    82, 61, 83,
    61, 63, 83,
    84, 85, 86,
    87, 88, 89,
    89, 88, 90,
    91, 60, 92,
    92, 60, 93,
    60, 59, 93,
    93, 59, 94,
    94, 59, 95,
    96, 97, 98,
    98, 97, 99,
    100, 101, 102,
    99, 103, 104,
    103, 105, 104,
    106, 107, 108,
    109, 110, 111,
    58, 60, 112,
    112, 60, 113,
    114, 115, 116,
    115, 117, 116,
    118, 119, 120,
    119, 67, 120,
    121, 122, 123,
    120, 67, 124,
    67, 69, 124,
    124, 69, 125,
    126, 127, 128,
    127, 129, 128,
    130, 131, 132,
    131, 133, 132,
    134, 135, 136,
    137, 138, 139,
    140, 80, 141,
    80, 81, 141,
    141, 81, 142,
    142, 81, 143,
    144, 145, 146,
    82, 83, 147,
    89, 90, 148,
    149, 146, 150,
    146, 151, 150,
    92, 93, 152,
    93, 94, 153,
    154, 155, 156,
    153, 94, 156,
    156, 94, 157,
    94, 95, 157,
    158, 159, 160,
    161, 162, 163,
    163, 162, 164,
    165, 159, 166,
    159, 167, 166,
    157, 168, 169,
    168, 167, 169,
    169, 167, 170,
    167, 159, 170,
    159, 158, 170,
    170, 158, 171,
    158, 172, 171,
    173, 174, 175,
    176, 177, 175,
    175, 177, 178,
    177, 179, 178,
    180, 181, 182,
    183, 184, 185,
    184, 186, 185,
    186, 187, 185,
    188, 189, 190,
    191, 192, 193,
    194, 195, 196,
    197, 198, 199,
    182, 181, 200,
    201, 168, 202,
    168, 157, 202,
    157, 95, 202,
    202, 95, 203,
    101, 100, 204,
    204, 100, 205,
    100, 206, 205,
    205, 207, 208,
    20, 209, 210,
    209, 208, 210,
    210, 208, 211,
    208, 207, 211,
    211, 207, 212,
    213, 207, 214,
    207, 205, 214,
    214, 205, 215,
    205, 206, 215,
    216, 96, 217,
    96, 98, 217,
    217, 98, 218,
    218, 98, 219,
    98, 99, 219,
    99, 104, 219,
    220, 109, 221,
    109, 111, 221,
    222, 223, 224,
    119, 118, 225,
    118, 226, 225,
    57, 37, 227,
    228, 227, 229,
    229, 227, 230,
    230, 227, 231,
    227, 37, 231,
    231, 37, 232,
    37, 38, 233,
    233, 38, 234,
    38, 39, 235,
    236, 235, 237,
    235, 39, 237,
    39, 238, 237,
    239, 240, 241,
    40, 41, 242,
    41, 243, 242,
    242, 243, 244,
    245, 246, 247,
    247, 246, 248,
    249, 250, 251,
    252, 253, 254,
    253, 255, 254,
    254, 255, 256,
    255, 243, 256,
    243, 41, 256,
    41, 42, 256,
    256, 42, 257,
    44, 43, 258,
    258, 43, 259,
    260, 46, 261,
    262, 261, 263,
    263, 261, 264,
    261, 46, 264,
    46, 47, 264,
    264, 47, 265,
    265, 47, 266,
    47, 48, 267,
    267, 48, 268,
    48, 50, 268,
    268, 50, 269,
    50, 226, 269,
    226, 118, 269,
    269, 118, 270,
    118, 271, 270,
    122, 121, 272,
    270, 273, 274,
    273, 124, 274,
    124, 125, 274,
    130, 132, 274,
    274, 132, 275,
    275, 134, 276,
    134, 136, 276,
    276, 277, 278,
    137, 139, 279,
    279, 139, 280,
    141, 142, 281,
    142, 282, 281,
    144, 146, 283,
    146, 149, 283,
    284, 149, 285,
    149, 150, 285,
    155, 154, 286,
    287, 288, 289,
    154, 156, 290,
    156, 157, 290,
    157, 169, 290,
    291, 290, 292,
    290, 169, 292,
    169, 170, 292,
    292, 170, 293,
    170, 171, 293,
    293, 173, 294,
    173, 175, 294,
    294, 175, 295,
    175, 178, 295,
    295, 178, 296,
    178, 297, 296,
    297, 298, 296,
    299, 300, 301,
    302, 303, 304,
    305, 306, 307,
    307, 306, 308,
    309, 310, 311,
    312, 313, 314,
    315, 316, 317,
    318, 319, 320,
    201, 202, 321,
    202, 203, 321,
    321, 203, 322,
    204, 205, 323,
    205, 208, 324,
    208, 325, 324,
    326, 209, 327,
    327, 209, 328,
    209, 20, 328,
    20, 19, 328,
    328, 19, 329,
    329, 19, 330,
    330, 19, 331,
    331, 19, 332,
    332, 19, 333,
    19, 18, 334,
    335, 336, 337,
    18, 20, 338,
    339, 340, 341,
    342, 343, 344,
    344, 343, 345,
    211, 212, 346,
    347, 348, 349,
    350, 351, 352,
    214, 215, 353,
    216, 217, 354,
    355, 217, 356,
    217, 218, 356,
    218, 219, 356,
    356, 219, 357,
    357, 219, 358,
    219, 227, 358,
    227, 228, 358,
    359, 360, 361,
    361, 360, 362,
    363, 364, 365,
    365, 364, 366,
    364, 228, 366,
    228, 229, 366,
    367, 368, 369,
    369, 368, 370,
    230, 231, 371,
    371, 231, 372,
    373, 374, 375,
    375, 374, 376,
    233, 234, 377,
    377, 234, 378,
    379, 235, 380,
    235, 236, 380,
    236, 237, 380,
    381, 239, 382,
    239, 241, 382,
    242, 244, 383,
    384, 247, 385,
    247, 248, 385,
    253, 252, 386,
    250, 249, 387,
    387, 249, 388,
    389, 390, 391,
    392, 393, 394,
    393, 395, 394,
    396, 397, 398,
    399, 400, 398,
    400, 401, 398,
    401, 256, 398,
    256, 257, 398,
    398, 257, 402,
    402, 257, 403,
    403, 404, 405,
    404, 406, 405,
    261, 262, 407,
    408, 409, 410,
    410, 409, 411,
    409, 412, 411,
    412, 413, 411,
    414, 415, 416,
    415, 417, 416,
    416, 417, 418,
    419, 420, 421,
    267, 268, 418,
    268, 269, 418,
    418, 269, 422,
    269, 270, 422,
    270, 274, 422,
    274, 275, 422,
    423, 422, 424,
    422, 275, 424,
    425, 424, 426,
    426, 424, 427,
    424, 275, 427,
    275, 276, 427,
    427, 276, 428,
    276, 278, 428,
    429, 284, 430,
    284, 285, 430,
    428, 287, 431,
    287, 289, 431,
    432, 291, 431,
    431, 291, 433,
    291, 292, 433,
    292, 293, 433,
    433, 293, 434,
    293, 294, 434,
    435, 436, 437,
    438, 439, 440,
    440, 439, 441,
    434, 294, 442,
    442, 294, 443,
    443, 294, 444,
    445, 294, 446,
    294, 295, 446,
    446, 295, 447,
    295, 299, 447,
    447, 299, 448,
    299, 301, 448,
    449, 310, 450,
    310, 309, 450,
    451, 452, 453,
    13, 12, 454,
    455, 456, 457,
    458, 459, 460,
    461, 462, 463,
    457, 456, 464,
    456, 465, 464,
    460, 15, 466,
    15, 17, 466,
    17, 467, 466,
    321, 322, 466,
    466, 322, 468,
    322, 327, 468,
    468, 327, 469,
    327, 328, 469,
    328, 329, 469,
    469, 329, 470,
    329, 330, 470,
    470, 330, 471,
    330, 472, 471,
    472, 473, 471,
    471, 474, 475,
    474, 476, 475,
    476, 477, 475,
    475, 477, 478,
    477, 479, 478,
    479, 480, 478,
    480, 481, 478,
    482, 483, 484,
    485, 486, 487,
    488, 489, 490,
    491, 492, 493,
    493, 492, 494,
    2, 1, 495,
    496, 1, 497,
    1, 0, 497,
    497, 0, 498,
    0, 499, 498,
    500, 501, 502,
    503, 504, 505,
    506, 396, 505,
    396, 398, 505,
    398, 402, 505,
    505, 402, 507,
    507, 402, 508,
    402, 403, 508,
    403, 405, 508,
    405, 509, 508,
    508, 510, 511,
    408, 410, 512,
    511, 513, 514,
    515, 416, 514,
    416, 418, 514,
    418, 422, 514,
    422, 423, 514,
    514, 423, 516,
    516, 517, 518,
    519, 520, 521,
    520, 522, 521,
    522, 523, 521,
    521, 523, 524,
    525, 526, 527,
    526, 528, 527,
    427, 428, 529,
    428, 431, 529,
    431, 433, 529,
    433, 434, 529,
    529, 434, 530,
    434, 531, 530,
    532, 533, 534,
    535, 536, 537,
    537, 536, 538,
    536, 539, 538,
    539, 540, 538,
    540, 541, 538,
    542, 445, 538,
    538, 445, 543,
    445, 446, 543,
    446, 447, 543,
    543, 447, 544,
    447, 545, 544,
    455, 457, 544,
    544, 457, 546,
    457, 547, 546,
    548, 549, 550,
    551, 458, 552,
    552, 458, 553,
    458, 460, 553,
    460, 466, 553,
    466, 468, 553,
    468, 469, 553,
    469, 470, 553,
    553, 470, 554,
    470, 471, 554,
    471, 475, 554,
    554, 475, 555,
    555, 475, 556,
    475, 478, 556,
    556, 478, 557,
    558, 559, 560,
    561, 562, 557,
    562, 563, 557,
    557, 563, 564,
    563, 496, 564,
    496, 497, 564,
    565, 503, 564,
    503, 505, 564,
    505, 507, 564,
    564, 507, 566,
    507, 508, 566,
    566, 508, 567,
    508, 511, 567,
    511, 514, 567,
    514, 516, 567,
    567, 516, 568,
    568, 516, 569,
    516, 518, 569,
    521, 524, 570,
    571, 529, 572,
    529, 530, 572,
    530, 573, 572,
    574, 575, 576,
    537, 538, 577,
    577, 538, 578,
    538, 543, 578,
    543, 544, 578,
    578, 544, 579,
    544, 546, 579,
    580, 579, 581,
    579, 546, 581,
    551, 552, 582,
    583, 552, 584,
    552, 553, 584,
    584, 553, 585,
    553, 554, 585,
    554, 555, 585,
    585, 555, 586,
    555, 556, 586,
    556, 557, 586,
    586, 557, 587,
    557, 564, 587,
    564, 566, 587,
    587, 566, 588,
    588, 566, 589,
    566, 567, 589,
    589, 567, 590,
    567, 568, 590,
    568, 569, 590,
    569, 577, 590,
    590, 577, 591,
    577, 578, 591,
    591, 578, 592,
    578, 579, 592,
    579, 580, 592,
    593, 594, 595,
    583, 584, 596,
    596, 584, 597,
    584, 585, 597,
    585, 586, 597,
    586, 587, 597,
    587, 588, 597,
    597, 588, 598,
    588, 589, 599,
    589, 590, 600,
    600, 590, 601,
    590, 591, 601,
    602, 591, 603,
    591, 592, 603,
    604, 597, 605,
    597, 598, 605,
    602, 603, 606,
]);
// refer to //reality/engine/faces/face-geometry.cc
const attachmentPointToIndex = {
    'forehead': 151,
    'rightEyebrowInner': 55,
    'rightEyebrowMiddle': 52,
    'rightEyebrowOuter': 124,
    'leftEyebrowInner': 285,
    'leftEyebrowMiddle': 282,
    'leftEyebrowOuter': 353,
    'leftCheek': 280,
    'rightCheek': 50,
    'noseBridge': 6,
    'noseTip': 4,
    'leftEyeUpper': 386, // 'leftEye' is the average of 'leftEyeUpper' & 'leftEyeLower'
    'leftEyeLower': 374,
    'rightEyeUpper': 159, // 'rightEye' is the average of 'rightEyeUpper' & 'rightEyeLower'
    'rightEyeLower': 145,
    'leftEyeOuterCorner': 263,
    'rightEyeOuterCorner': 33,
    'upperLip': 12, // 'mouth' is the average of 'upperLip' & 'lowerLip'
    'lowerLip': 15,
    'mouthRightCorner': 78,
    'mouthLeftCorner': 308,
    'chin': 175,
    'leftIris': 473,
    'rightIris': 468,
    'leftUpperEyelid': 386,
    'rightUpperEyelid': 159,
    'leftLowerEyelid': 374,
    'rightLowerEyelid': 145,
    // TODO(yuyan): fix these when ear mesh is added
    'leftHelix': 585,
    'leftCanal': 277,
    'leftLobe': 420,
    'rightHelix': 585,
    'rightCanal': 277,
    'rightLobe': 420,
};
const faceAttachmentNames = [
    'forehead',
    'rightEyebrowInner',
    'rightEyebrowMiddle',
    'rightEyebrowOuter',
    'leftEyebrowInner',
    'leftEyebrowMiddle',
    'leftEyebrowOuter',
    'leftCheek',
    'rightCheek',
    'noseBridge',
    'noseTip',
    'leftEyeUpper',
    'leftEyeLower',
    'rightEyeUpper',
    'rightEyeLower',
    'leftEyeOuterCorner',
    'rightEyeOuterCorner',
    'upperLip',
    'lowerLip',
    'mouthRightCorner',
    'mouthLeftCorner',
    'chin',
    'leftIris',
    'rightIris',
    'leftUpperEyelid',
    'rightUpperEyelid',
    'leftLowerEyelid',
    'rightLowerEyelid',
    'leftEye',
    'rightEye',
    'mouth',
    'leftHelix',
    'leftCanal',
    'leftLobe',
    'rightHelix',
    'rightCanal',
    'rightLobe',
];
const earAttachmentNames = [
    'leftHelix',
    'leftCanal',
    'leftLobe',
    'rightHelix',
    'rightCanal',
    'rightLobe',
];
const FaceAttachmentPositions = new Float32Array([
    // leftEye
    -0.248311, 0.004078, 0.490786,
    // rightEye
    0.247234, 0.002947, 0.496590,
    // mouth
    0.001567, -0.533872, 0.585650,
]);
const earAttachmentPointPosition = (attachmentName) => {
    const isLeftEarPoint = (attachmentName === 'leftHelix') ||
        (attachmentName === 'leftCanal') ||
        (attachmentName === 'leftLobe');
    const index = attachmentPointToIndex[attachmentName];
    const mul = isLeftEarPoint ? 1 : -1;
    return {
        x: mul * EarLMeshPositions[3 * index],
        y: EarLMeshPositions[3 * index + 1],
        z: EarLMeshPositions[3 * index + 2],
    };
};
const faceAttachmentPointPosition = (attachmentName) => {
    if (attachmentName === 'leftEye') {
        return {
            x: -FaceAttachmentPositions[0],
            y: FaceAttachmentPositions[1],
            z: FaceAttachmentPositions[2],
        };
    }
    if (attachmentName === 'rightEye') {
        return {
            x: -FaceAttachmentPositions[3],
            y: FaceAttachmentPositions[4],
            z: FaceAttachmentPositions[5],
        };
    }
    if (attachmentName === 'mouth') {
        return {
            x: -FaceAttachmentPositions[6],
            y: FaceAttachmentPositions[7],
            z: FaceAttachmentPositions[8],
        };
    }
    if (earAttachmentNames.includes(attachmentName)) {
        return earAttachmentPointPosition(attachmentName);
    }
    const index = attachmentPointToIndex[attachmentName];
    return {
        x: -FaceMeshPositions[3 * index],
        y: FaceMeshPositions[3 * index + 1],
        z: FaceMeshPositions[3 * index + 2],
    };
};
const faceAttachmentPoints = Array.from(faceAttachmentNames, (name) => {
    const { x, y, z } = faceAttachmentPointPosition(name);
    return new THREE.Vector3(x, y, z);
});
export { FaceMeshPositions, FaceMeshNormals, FaceMeshUvs, FaceMeshIndices, FaceMeshEyeIndices, FaceMeshMouthIndices, FaceMeshIrisIndices, EarLMeshPositions, EarLMeshIndices, faceAttachmentPointPosition, faceAttachmentPoints, faceAttachmentNames, earAttachmentNames, earAttachmentPointPosition, };
