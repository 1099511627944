const hasParent = (scene, id) => {
    const object = scene.objects[id];
    return !!object?.parentId &&
        (!!scene.objects[object.parentId] || (scene.spaces && !!scene.spaces[object.parentId]));
};
const resolveSpaceForObject = (scene, objectId) => {
    let parentId = scene.objects[objectId]?.parentId;
    while (parentId) {
        const space = scene.spaces?.[parentId];
        if (space) {
            return space;
        }
        parentId = scene.objects[parentId]?.parentId;
    }
    return undefined;
};
const sortObjectIds = (scene, ids) => (ids.sort((leftId, rightId) => {
    const left = scene.objects[leftId];
    const right = scene.objects[rightId];
    if (!!left.ephemeral !== !!right.ephemeral) {
        return left.ephemeral ? 1 : -1;
    }
    return (left.order ?? 0) - (right.order ?? 0);
}));
const getChildObjectIds = (scene, parentId) => (sortObjectIds(scene, Object.keys(scene.objects)
    .filter(e => scene.objects[e].parentId === parentId)));
const getTopLevelObjectIds = (scene, spaceId) => {
    if (spaceId) {
        return getChildObjectIds(scene, spaceId);
    }
    return sortObjectIds(scene, Object.keys(scene.objects)
        .filter(e => !hasParent(scene, e)));
};
const deepSortObjects = (scene, parentId) => {
    const res = [];
    const sortAndFlatten = (id) => {
        res.push(id);
        const childIds = Object.keys(scene.objects)
            .filter(objId => scene.objects[objId].parentId === id);
        const sortedChildIds = sortObjectIds(scene, childIds);
        sortedChildIds.forEach(sortAndFlatten);
    };
    const topLevelObjectIds = parentId
        ? getChildObjectIds(scene, parentId) : getTopLevelObjectIds(scene, undefined);
    topLevelObjectIds.forEach(id => sortAndFlatten(id));
    return res;
};
const getSpaceObjects = (scene, spaceId) => {
    const ids = deepSortObjects(scene, spaceId);
    return ids.map(id => scene.objects[id]);
};
export { getTopLevelObjectIds, getChildObjectIds, resolveSpaceForObject, sortObjectIds, deepSortObjects, getSpaceObjects, };
