import { makeSceneSyncSystem } from './built-in-systems';
import { runBehaviors } from './registry';
import { spawnIntoObject, createEntity, deleteEntity, spawnScene, } from './entity';
import { getParent, setParent, getChildren } from './parent';
import { createTime, updateTime, clearDelta } from './time';
import { getAudioManager } from './audio-manager';
import * as Transform from './transform';
import { asm } from './asm';
import { Position, Scale, Quaternion, ThreeObject } from './components';
import { createEvents, flushEvents } from './events';
import { dispatchPhysicsEvents } from './physics-events';
import { createPointerListener } from './pointer-events';
import { createCameraManager } from './camera-manager';
import { createInputListener } from './input-events';
import { createXrManager } from './xr/xr-manager';
import { createInputManager } from './input-manager';
import { ACTIVE_SPACE_CHANGE } from '../shared/space-constants';
const createWorld = (scene, renderer, camera) => {
    const _id = asm.createWorld();
    const time = createTime();
    const allEntities = new Set([]);
    let loop;
    let tickMode = 'full';
    let running = false;
    let destroyed = false;
    const baseWorld = {
        _id,
        time,
        allEntities,
        three: {
            scene,
            renderer,
            activeCamera: camera,
            entityToObject: new Map(),
        },
        scene,
    };
    const world = baseWorld;
    const { audio, internalAudio } = getAudioManager(world);
    const events = createEvents(world);
    const pointerListener = createPointerListener(world, events, renderer.domElement, scene);
    const cameraManager = createCameraManager(world, internalAudio.listener, events);
    const inputListener = createInputListener(events);
    const xrManager = createXrManager(world, events);
    const inputManager = createInputManager(inputListener.api);
    asm.initWithComponents(world._id, Position.forWorld(world).id, Scale.forWorld(world).id, Quaternion.forWorld(world).id, ThreeObject.forWorld(world).id);
    const syncScene = makeSceneSyncSystem(world);
    const pipeline = () => {
        dispatchPhysicsEvents(world);
        runBehaviors(world);
        asm.progressWorld(world._id, world.time.delta);
        syncScene();
    };
    const zeroTimeFrame = () => {
        clearDelta(time);
        pipeline();
    };
    const processTick = (dt) => {
        if (tickMode === 'full') {
            updateTime(time, dt);
            xrManager.tick();
            flushEvents(events);
            inputListener.handleGamepadLoop();
            inputManager.handleActions();
            pipeline();
        }
        else if (tickMode === 'partial') {
            clearDelta(time);
            xrManager.drawPausedBackground();
            syncScene();
        }
        else if (tickMode === 'zero') {
            zeroTimeFrame();
        }
    };
    const frame = (dt) => {
        if (destroyed) {
            throw new Error('World was destroyed.');
        }
        processTick(dt);
        if (running) {
            loop = requestAnimationFrame(frame);
        }
    };
    const start = () => {
        cancelAnimationFrame(loop);
        loop = requestAnimationFrame(frame);
        running = true;
    };
    const stop = () => {
        cancelAnimationFrame(loop);
        running = false;
    };
    const tick = (dt = 0) => {
        if (destroyed) {
            throw new Error('World was destroyed.');
        }
        if (running) {
            throw new Error('World is running, cannot progress manually.');
        }
        processTick(dt);
    };
    const tock = () => {
        if (tickMode === 'full') {
            xrManager.tock();
        }
    };
    const loadScene = (graph) => {
        zeroTimeFrame(); // Allow queries to initialize before spawning
        const handle = spawnScene(world, graph);
        // TODO(christoph): Remove this after switching to synchronous rendering
        zeroTimeFrame(); // Sync ecs state with threejs state
        return handle;
    };
    const setTickMode = (mode) => {
        tickMode = mode;
        if (mode === 'full') {
            internalAudio.setStudioPause(false);
        }
        else {
            internalAudio.setStudioPause(true);
        }
    };
    const destroy = () => {
        if (destroyed) {
            return;
        }
        inputListener.detach();
        inputManager.detach();
        cameraManager.detach();
        pointerListener.detach();
        xrManager.detach();
        world.allEntities.forEach(eid => deleteEntity(world, eid));
        zeroTimeFrame(); // Sync to flush the observers + callbacks and perform any system cleanup
        internalAudio.destroy();
        asm.deleteWorld(world._id);
        destroyed = true;
        stop();
    };
    let spaceHook;
    const lateWorld = {
        start,
        stop,
        tick,
        tock,
        destroy,
        loadScene,
        createEntity: () => createEntity(world),
        deleteEntity: (eid) => deleteEntity(world, eid),
        getTickMode: () => tickMode,
        setTickMode: (mode) => setTickMode(mode),
        spawnIntoObject: spawnIntoObject.bind(null, world),
        setScale: Transform.setScale.bind(null, world),
        setPosition: Transform.setPosition.bind(null, world),
        setQuaternion: Transform.setQuaternion.bind(null, world),
        setTransform: Transform.setTransform.bind(null, world),
        getWorldTransform: Transform.getWorldTransform.bind(null, world),
        normalizeQuaternion: Transform.normalizeQuaternion.bind(null, world),
        setParent: setParent.bind(null, world),
        getParent: getParent.bind(null, world),
        getChildren: getChildren.bind(null, world),
        audio,
        camera: cameraManager,
        events,
        pointer: pointerListener,
        input: {
            ...inputListener.api,
            ...inputManager.api,
            attach: () => {
                inputListener.attach();
                inputManager.attach();
            },
            detach: () => {
                inputListener.detach();
                inputManager.detach();
            },
        },
        xr: xrManager,
        setSpaceHook: (hook) => {
            spaceHook = hook;
        },
        spaces: {
            loadSpace: (spaceIdOrName) => {
                if (!spaceHook) {
                    throw new Error('No space hook set');
                }
                spaceHook.loadSpace(spaceIdOrName);
                events.dispatch(events.globalId, ACTIVE_SPACE_CHANGE);
            },
            listSpaces: () => {
                if (!spaceHook) {
                    throw new Error('No space hook set');
                }
                return spaceHook.listSpaces();
            },
            getActiveSpace: () => {
                if (!spaceHook) {
                    throw new Error('No space hook set');
                }
                return spaceHook.getActiveSpace();
            },
        },
    };
    return Object.assign(world, lateWorld);
};
export { createWorld, };
