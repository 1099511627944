import { createInstanced } from '../shared/instanced';
import { registerComponent } from './registry';
import { Position, Quaternion as Quat } from './components';
import { mat4 } from './math/math';
import THREE from './three';
import { input } from './input';
const INERTIA_TIMER = 200;
const MOVING_TIMER = 30;
const MAX_SPEED_FACTOR = 0.01;
const cleanups = createInstanced(() => new Map());
const upVector = new THREE.Vector3(0, 1, 0);
const cameraPos = new THREE.Vector3();
const pivotPoint = new THREE.Vector3();
const tempMatrix = new THREE.Matrix4();
const tempMat4 = mat4.i();
const cameraRotation = new THREE.Quaternion();
const setCameraPosition = (world, id, orbitData) => {
    orbitData.curPitch = orbitData.pitch;
    orbitData.curYaw = orbitData.yaw;
    orbitData.curDistance = orbitData.distance;
    const sinPitch = Math.sin(orbitData.pitch);
    const cosPitch = Math.cos(orbitData.pitch);
    const sinYaw = Math.sin(orbitData.yaw);
    const cosYaw = Math.cos(orbitData.yaw);
    cameraPos.x = orbitData.distance * cosPitch * cosYaw;
    cameraPos.y = orbitData.distance * sinPitch;
    cameraPos.z = orbitData.distance * cosPitch * sinYaw;
    // Add the pivot point to the position to get the final position
    cameraPos.add(pivotPoint);
    tempMatrix.lookAt(pivotPoint, cameraPos, upVector);
    cameraRotation.setFromRotationMatrix(tempMatrix);
    const posCursor = Position.cursor(world, id);
    posCursor.x = cameraPos.x;
    posCursor.y = cameraPos.y;
    posCursor.z = cameraPos.z;
    const quatCursor = Quat.cursor(world, id);
    quatCursor.w = cameraRotation.w;
    quatCursor.x = cameraRotation.x;
    quatCursor.y = cameraRotation.y;
    quatCursor.z = cameraRotation.z;
};
const capSpeed = (speed, maxSpeed) => Math.sign(speed) *
    Math.min(Math.abs(speed), maxSpeed * MAX_SPEED_FACTOR);
const updateDistance = (cursor, distanceMin, distanceMax) => {
    const newDistance = cursor.distance + cursor.delta;
    const distanceClamped = Math.min(Math.max(newDistance, distanceMin), distanceMax);
    cursor.distance = distanceClamped;
};
const createGestureMoveHandler = (controls, data, eid) => (e) => {
    const { speed, maxAngularSpeed, maxZoomSpeed, distanceMin, distanceMax, invertedX, invertedY, invertedZoom, } = controls.get(eid);
    const cursor = data.cursor(eid);
    if (e.data.touchCount === 1) {
        const { positionChange } = e.data;
        // note: touch controls x axis is inverted by default
        cursor.dx = capSpeed(positionChange.x * speed, maxAngularSpeed) * (invertedX ? -1 : 1) * -1;
        cursor.dy = capSpeed(positionChange.y * speed, maxAngularSpeed) * (invertedY ? -1 : 1);
        const pitchAngle = cursor.pitch - cursor.dy;
        const yawAngle = cursor.yaw - cursor.dx;
        const pitchClamped = Math.min(Math.max(pitchAngle, cursor.minPitch), cursor.maxPitch);
        const yawAngleClamped = Math.min(Math.max(yawAngle, cursor.minYaw), cursor.maxYaw);
        cursor.pitch = pitchClamped;
        cursor.yaw = cursor.constrainYaw ? yawAngleClamped : yawAngle;
    }
    else if (e.data.touchCount === 2) {
        const { spreadChange } = e.data;
        cursor.delta = capSpeed(spreadChange * speed, maxZoomSpeed) * (invertedZoom ? -1 : 1);
        updateDistance(cursor, distanceMin, distanceMax);
    }
    cursor.moving = MOVING_TIMER;
    cursor.inertia = INERTIA_TIMER;
};
const handleMoveController = (world, controls, data, eid) => {
    const up = world.input.getAction('lookUp');
    const down = world.input.getAction('lookDown');
    const left = world.input.getAction('lookLeft');
    const right = world.input.getAction('lookRight');
    const cursor = data.cursor(eid);
    const { speed, maxAngularSpeed, maxZoomSpeed, distanceMin, distanceMax, horizontalSensitivity, verticalSensitivity, invertedX, invertedY, invertedZoom, } = controls.get(eid);
    cursor.dx = capSpeed((left - right) * (invertedX ? -1 : 1) * horizontalSensitivity * speed, maxAngularSpeed);
    cursor.dy = capSpeed((up - down) * (invertedY ? -1 : 1) * verticalSensitivity * speed, maxAngularSpeed);
    const pitchAngle = cursor.pitch - cursor.dy;
    const yawAngle = cursor.yaw - cursor.dx;
    const pitchClamped = Math.min(Math.max(pitchAngle, cursor.minPitch), cursor.maxPitch);
    const yawAngleClamped = Math.min(Math.max(yawAngle, cursor.minYaw), cursor.maxYaw);
    cursor.pitch = pitchClamped;
    cursor.yaw = cursor.constrainYaw ? yawAngleClamped : yawAngle;
    const zoomIn = world.input.getAction('zoomIn');
    const zoomOut = world.input.getAction('zoomOut');
    cursor.delta = capSpeed((zoomIn - zoomOut) * (invertedZoom ? -1 : 1) * speed, maxZoomSpeed);
    updateDistance(cursor, distanceMin, distanceMax);
    if (left || right || up || down || zoomIn || zoomOut) {
        cursor.moving = MOVING_TIMER;
        cursor.inertia = INERTIA_TIMER;
    }
};
const OrbitControls = registerComponent({
    name: 'orbit-controls',
    schema: {
        speed: 'f32',
        maxAngularSpeed: 'f32',
        maxZoomSpeed: 'f32',
        distanceMin: 'f32',
        distanceMax: 'f32',
        pitchAngleMin: 'f32',
        pitchAngleMax: 'f32',
        constrainYaw: 'boolean',
        // @condition constrainYaw=true
        yawAngleMin: 'f32',
        // @condition constrainYaw=true
        yawAngleMax: 'f32',
        inertiaFactor: 'f32',
        focusEntity: 'eid',
        invertedX: 'boolean',
        invertedY: 'boolean',
        invertedZoom: 'boolean',
        controllerSupport: 'boolean',
        // @condition controllerSupport=true
        horizontalSensitivity: 'f32',
        // @condition controllerSupport=true
        verticalSensitivity: 'f32',
    },
    schemaDefaults: {
        speed: 5,
        maxAngularSpeed: 10,
        maxZoomSpeed: 10,
        distanceMin: 5,
        distanceMax: 20,
        pitchAngleMin: -90,
        pitchAngleMax: 90,
        constrainYaw: false,
        yawAngleMin: 0,
        yawAngleMax: 0,
        inertiaFactor: 0.3,
        horizontalSensitivity: 1,
        verticalSensitivity: 1,
    },
    data: {
        pitch: 'f32',
        yaw: 'f32',
        distance: 'f32',
        dx: 'f32',
        dy: 'f32',
        delta: 'f32',
        moving: 'f32',
        inertia: 'f32',
        minPitch: 'f32',
        maxPitch: 'f32',
        curPitch: 'f32',
        constrainYaw: 'boolean',
        minYaw: 'f32',
        maxYaw: 'f32',
        curYaw: 'f32',
        curDistance: 'f32',
    },
    add: (world, component) => {
        const { schema, data } = component;
        const { pitchAngleMax, pitchAngleMin, distanceMax, distanceMin, focusEntity, yawAngleMax, yawAngleMin, } = schema;
        const validPitchInterval = pitchAngleMin <= pitchAngleMax;
        const validYawInterval = yawAngleMin <= yawAngleMax;
        const minPitch = validPitchInterval ? Math.max(pitchAngleMin, -89.9999) * (Math.PI / 180) : 0;
        const maxPitch = validPitchInterval ? Math.min(pitchAngleMax, 89.9999) * (Math.PI / 180) : 0;
        const minYaw = validYawInterval ? yawAngleMin * (Math.PI / 180) : 0;
        const maxYaw = validYawInterval ? yawAngleMax * (Math.PI / 180) : 0;
        data.minPitch = minPitch;
        data.maxPitch = maxPitch;
        data.minYaw = minYaw;
        data.maxYaw = maxYaw;
        data.distance = (distanceMin + distanceMax) / 2;
        data.pitch = (minPitch + maxPitch) / 2;
        data.constrainYaw = schema.constrainYaw;
        data.yaw = (minYaw + maxYaw) / 2;
        if (focusEntity) {
            world.getWorldTransform(focusEntity, tempMat4);
            tempMatrix.fromArray(tempMat4.data());
            pivotPoint.setFromMatrixPosition(tempMatrix);
        }
        else {
            pivotPoint.set(0, 0, 0);
        }
        // Set the initial camera position
        setCameraPosition(world, component.eid, data);
        const handleGesture = createGestureMoveHandler(component.schemaAttribute, component.dataAttribute, component.eid);
        // Note: Enable the orbit controls preset on the input manager for controller support
        if (schema.controllerSupport) {
            world.input.setActiveMap('orbit-controls');
            world.input.enablePointerLockRequest();
        }
        world.events.addListener(world.events.globalId, input.GESTURE_MOVE, handleGesture);
        const cleanup = () => {
            world.events.removeListener(world.events.globalId, input.GESTURE_MOVE, handleGesture);
        };
        cleanups(world).set(component.eid, cleanup);
    },
    tick: (world, component) => {
        const { schema, data } = component;
        const { speed, inertiaFactor, maxZoomSpeed, maxAngularSpeed, distanceMin, distanceMax } = schema;
        if (schema.controllerSupport) {
            handleMoveController(world, component.schemaAttribute, component.dataAttribute, component.eid);
        }
        if (data.inertia > 0 && inertiaFactor !== 0 && data.moving <= 0) {
            const currentInertia = Math.min(inertiaFactor * world.time.delta * (data.inertia / INERTIA_TIMER), 1);
            if (data.delta !== 0) {
                const newDistance = data.distance +
                    capSpeed((data.delta / speed) * currentInertia, maxZoomSpeed);
                const distanceClamped = Math.min(Math.max(newDistance, distanceMin), distanceMax);
                data.distance = distanceClamped;
            }
            if (data.dy !== 0 || data.dx !== 0) {
                const pitchAngle = data.pitch -
                    capSpeed((data.dy / speed) * currentInertia, maxAngularSpeed);
                const yawAngle = data.yaw - capSpeed((data.dx / speed) * currentInertia, maxAngularSpeed);
                const pitchClamped = Math.min(Math.max(pitchAngle, data.minPitch), data.maxPitch);
                const yawAngleClamped = Math.min(Math.max(yawAngle, data.minYaw), data.maxYaw);
                data.pitch = pitchClamped;
                data.yaw = data.constrainYaw ? yawAngleClamped : yawAngle;
            }
            data.inertia -= world.time.delta;
            if (data.inertia <= 0) {
                data.dx = 0;
                data.dy = 0;
                data.delta = 0;
            }
        }
        if (data.moving > 0) {
            data.moving -= world.time.delta;
        }
        if (data.curYaw !== data.yaw ||
            data.curPitch !== data.pitch ||
            data.curDistance !== data.distance) {
            setCameraPosition(world, component.eid, data);
        }
    },
    remove: (world, component) => {
        if (component.schema.controllerSupport) {
            world.input.disablePointerLockRequest();
        }
        cleanups(world).get(component.eid)?.();
        cleanups(world).delete(component.eid);
    },
});
export { OrbitControls, };
